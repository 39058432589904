<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :show_print="true"
        :excelFile="true"
        :excelData="excelData"
      />
      <!-- End Breadcrumb -->
      <!-- start of searchSection  -->
      <SearchSection @filterTable="searchTable"></SearchSection>
      <!-- end of searchSection  -->
      <!-- notFound  -->
      <Notfound v-if="!searchItems"></Notfound>

      <!-- Start Statistics Card-->
      <div v-else>
        <!-- Start Statistics Card-->
        <ul class="all-state-count">
          <li>
            <div class="state-count">
              <span>عدد العملاء :</span>
              <span>{{ table_length }}</span>
            </div>
          </li>
        </ul>
        <!-- End Statistics Card-->

        <main class="main-table table-print">
          <!-- start of  header of printSection  -->
          <PrintHead :title="'العملاء'" :number="table_length"></PrintHead>
          <!-- end of header of printSection  -->
          <v-data-table
            class="thumb"
            :headers="headers"
            :items="rows"
            :loading="lodaing"
            :loading-text="$t('table.loadingData')"
            v-model="selected"
            :single-select="singleSelect"
            item-key="id"
            :items-per-page="rows.length"
            hide-default-footer
          >
            <!-- ================== You Can use any slots you want ================== -->
            <!-- ====== Select row field ====== -->
            <!-- Select Image-->
            <template v-slot:[`item.index`]="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:[`item.image`]="{ item }">
              <img @click="show_model_1" class="image" :src="item.image" />
            </template>
            <!-- Select no data State -->
            <template v-slot:no-data>
              {{ $t("table.noData") }}
            </template>
            <template v-slot:[`item.has_email`]="{ item }">
              <span v-if="item.has_email == true"> نعم </span>
              <span v-if="item.has_email == false"> لا </span>
            </template>
            <!-- User Accounts-->
            <template v-slot:[`item.accounts`]="{ item }">
              <div class="_actions">
                <v-icon class="show" small @click="clientCommission(item)">
                  fal fa-eye
                </v-icon>
              </div>
            </template>

            <!-- Select actions-->
            <template v-slot:[`item.actions`]="{ item }">
              <div class="_actions">
                <v-icon class="edit" small @click="editItem(item)">
                  fal fa-edit
                </v-icon>
                <v-icon class="delete" small @click="deleteItem(item)">
                  fal fa-trash
                </v-icon>
              </div>
            </template>

            <!-- ======================== Start Top Section ======================== -->
            <template v-slot:top>
              <!-- Delete dialog -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">{{
                    $t("table.deletedialog.areYouSure")
                  }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="deleteItemConfirm"
                      >{{ $t("table.deletedialog.ok") }}
                      <span
                        class="btn_loader_model"
                        v-if="loadingBtnDialog"
                      ></span
                    ></v-btn>
                    <v-btn color="#F44336" @click="dialogDelete = false">{{
                      $t("table.deletedialog.cancel")
                    }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogShow" min-width="50%">
                <v-card class="p-0">
                  <div class="show_modal_content_wrapper" v-if="itemToShow">
                    <div class="row">
                      <!-- START:: SHOW DATA LINE -->
                      <div class="col-md-6">
                        <div class="data_line_wrapper">
                          <h4 class="mb-2">اسم العميل</h4>
                          <h5>{{ itemToShow.username }}</h5>
                        </div>
                      </div>
                      <!-- END:: SHOW DATA LINE -->

                      <!-- START:: SHOW DATA LINE -->
                      <div class="col-md-6">
                        <div class="data_line_wrapper">
                          <h4 class="mb-2">العنوان</h4>
                          <h5>{{ itemToShow.address }}</h5>
                        </div>
                      </div>
                      <!-- END:: SHOW DATA LINE -->

                      <!-- START:: SHOW DATA LINE -->
                      <div class="col-md-6">
                        <div class="data_line_wrapper">
                          <h4 class="mb-2">البريد الاكتروني</h4>
                          <h5>{{ itemToShow.email }}</h5>
                        </div>
                      </div>
                      <!-- END:: SHOW DATA LINE -->

                      <!-- START:: SHOW DATA LINE -->
                      <div class="col-md-6">
                        <div class="data_line_wrapper">
                          <h4 class="mb-2">رقم الهاتف</h4>
                          <h5>{{ itemToShow.phone }}</h5>
                        </div>
                      </div>
                      <!-- END:: SHOW DATA LINE -->

                      <!-- START:: SHOW DATA LINE -->
                      <div class="col-12">
                        <div class="data_line_wrapper">
                          <h4 class="mb-2">رقم الفاكس</h4>
                          <h5>{{ itemToShow.fax }}</h5>
                        </div>
                      </div>
                      <!-- END:: SHOW DATA LINE -->
                    </div>
                  </div>
                </v-card>
              </v-dialog>

              <!-- Add & Delete -->
            </template>
            <!-- ======================== End Top Section ======================== -->
          </v-data-table>
        </main>
        <!-- End Main Section -->
      </div>

      <!-- Start Image_Model -->
      <base-model
        @closeModel="model_1.show_model = false"
        :show="model_1.show_model"
      >
        <div class="image">
          <img
            v-if="model_1.model_img_src"
            :src="model_1.model_img_src"
            alt="..."
          />
        </div>
      </base-model>
      <!-- End Image_Model -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
import SearchSection from "@/components/Search/clients";
export default {
  components: {
    StatsCard,
    SearchSection,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // search
      searchItems: null,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "العملاء",
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-mug-hot",
          title: "كل العملاء",
          number: "10",
        },
      ],

      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Date_Menues
      from_date_menu: false,
      to_date_menu: false,

      // ========== Filter
      filter: {
        name: null,
        safe: [],
        from: null,
        to: null,
      },

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== Loding
      lodaing: false,

      // Fixed_data
      safes: ["foo", "bar", "fizz", "buzz"],
      table_length: null,
      // excel data
      excelData: {
        name: "العملاء",
        items: null,
        json_fields: {
          "إسم العميل": "name",
          العنوان: "address",
          "البريد الإلكتروني": "email",
          "رقم الهاتف": "phone",
          "رقم الفاكس": "fax",
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: "اسم العميل",
            value: "name",
            align: "center",
            width: 150,
          },
          {
            text: "العنوان",
            value: "address",
            align: "center",
            width: 150,
          },
          {
            text: "البريد الالكتروني",
            value: "email",
            align: "center",
            sortable: false,
            width: 120,
          },
          {
            text: "إرسال إيميل",
            value: "has_email",
            align: "center",
            sortable: false,
            width: 80,
          },
          {
            text: "رقم الهاتف",
            value: "phone",
            align: "center",
            sortable: false,
            width: 100,
          },
          {
            text: "رقم الفاكس",
            value: "fax",
            align: "center",
            sortable: false,
            width: 100,
          },
          {
            text: "حسابات العميل",
            value: "accounts",
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            sortable: false,
            width: 80,
          },
          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 80,
          },
        ];
      }
    },
  },

  methods: {
    // searchTable
    searchTable(items) {
      this.rows = [];
      this.searchItems = items;
      this.setRows();
    },
    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },
    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/clients/add" });
    },
    clientCommission(item) {
      this.$router.push({ path: "/clientCommission/" + item.id });

      // this.dialogShow = true;
      // this.itemToShow = item;
    },
    showItem(item) {
      this.$router.push({ path: "/clients/show/" + item.id });

      // this.dialogShow = true;
      // this.itemToShow = item;
    },
    editItem(item) {
      this.$router.push({ path: "/clients/edit/" + item.id });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/customer/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // Set Rows
    setRows() {
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/customer",
        params: {
          keyword: this.searchItems ? this.searchItems.username : null,
        },
      })
        .then((res) => {
          this.rows = res.data.data.map((item) => {
            return {
              id: item.id,
              name: item.name,
              phone: item.phone,
              image: item.image,
              email: item.email,
              address: item.address,
              has_email: item.has_email,
              fax: item.fax,
            };
          });
          this.table_length = this.rows.length;
          this.excelData.items = this.rows;
          this.lodaing = false;
        })
        .catch((err) => {
          this.lodaing = false;
        });
    },
  },

  // ======= hooks
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("lock_and_deals")
    ) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped></style>
