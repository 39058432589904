<template>
  <div class="create_wrapper">
    <!-- Start Breadcrumb -->
    <Breadcrumb
      :items="items"
      :search="true"
      search_route="/countries"
      search_title="الدول"
    />
    <!-- End Breadcrumb -->

    <div class="custom_card">
      <div class="card-header">
        <h4 class="card-title">{{ $t("addNew") }}</h4>
      </div>

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <!-- Image -->
            <!-- <uplode-image @inputChanged="uplodeImg_1"></uplode-image> -->

            <div class="col-lg-12 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.name"
                />
                <label for="name_input" class="form-label">اسم الدولة</label>
              </div>
            </div>

            <!-- <div class="col-lg-12 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.name_en"
                />
                <label for="name_input" class="form-label">{{
                  $t("forms.labels.name_en")
                }}</label>
              </div>
            </div> -->

            <!-- <div class="col-lg-12 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.nationality_ar"
                />
                <label for="name_input" class="form-label">{{
                  $t("forms.labels.nationality_ar")
                }}</label>
              </div>
            </div>

            <div class="col-lg-12 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.nationality_en"
                />
                <label for="name_input" class="form-label">{{
                  $t("forms.labels.nationality_en")
                }}</label>
              </div>
            </div>

            <div class="col-lg-12 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.currency_ar"
                />
                <label for="name_input" class="form-label">{{
                  $t("forms.labels.currency_ar")
                }}</label>
              </div>
            </div>

            <div class="col-lg-12 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.currency_en"
                />
                <label for="name_input" class="form-label">{{
                  $t("forms.labels.currency_en")
                }}</label>
              </div>
            </div>

            <div class="col-lg-12 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.phone_code"
                />
                <label for="name_input" class="form-label">{{
                  $t("forms.labels.phone_code")
                }}</label>
              </div>
            </div> -->
          </div>
        </div>

        <div class="buttons_wrapper">
          <button
            class="button_style_1"
            :class="{buttonIndex: btnIsLoading}"
            :disabled="btnIsLoading"
          >
            {{ $t("forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Update",

  props: ["id"],

  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.countries.title"),
          disabled: false,
          href: "/countries",
        },
        {
          text: this.$t("breadcrumb.countries.edit"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========== create_data
      data: {
        // image: null,
        name: null,
        // name_en: null,
        // nationality_ar: null,
        // nationality_en: null,
        // currency_ar: null,
        // currency_en: null,
        // phone_code: null,
      },

      // ========== Fixed Data
      categories: [],
    };
  },

  methods: {
    // Uplode Image
    // uplodeImg_1(obj) {
    //   this.data.image = obj;
    // },

    getData() {
      this.$axios({
        method: "GET",
        url: `client/country/${this.id}`,
      }).then((res) => {
        this.data.name = res.data.data.name_ar;
      });
    },

    // Validate Data
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.name) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.name_ar"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.submitData();
          return;
        }
      }
    },

    // Submit Data
    submitData() {
      const submit_data = {
        ar: {
          name: this.data.name,
        },
        en: {
          name: this.data.name,
        },
      };

      this.$axios({
        method: "PUT",
        url: `client/country/${this.id}`,
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("editSuccess"),
            position: "topRight",
          });
          this.$router.push({path: "/countries"});
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
  },

  created() {
    this.getData();
  },
};
</script>
