<template>
  <div class="search-section">
    <div class="divider">
      <h4>بحث في {{ title }}</h4>
      <hr
        role="separator"
        aria-orientation="horizontal"
        class="v-divider theme--dark"
      />
    </div>
    <div class="row">
      <div class="col-lg-3 py-0">
        <div class="input_wrapper top_label auto_complete">
          <label class="form-label">رقم الملف</label>
          <input
            type="text"
            class="form-control"
            v-model.trim="searchData.document_number"
            @change="$emit('filterTable', searchData)"
          />
        </div>
      </div>

      <div class="col-lg-3 py-0">
        <div class="input_wrapper top_label auto_complete">
          <label class="form-label">رقم البوليصة</label>
          <input
            type="text"
            class="form-control"
            v-model.trim="searchData.bl_number"
            @change="$emit('filterTable', searchData)"
          />
        </div>
      </div>

      <div class="col-lg-3 py-0">
        <div class="input_wrapper top_label mb-3">
          <label class="form-label"> الشركة </label>
          <multiselect
            v-model="searchData.company"
            :options="searchOptions.companies"
            label="name"
            track-by="id"
            placeholder=" "
            :searchable="true"
            :allow-empty="true"
            :show-labels="false"
            @input="
              chooseBank(searchData.company), $emit('filterTable', searchData)
            "
          >
          </multiselect>
        </div>
      </div>

      <!-- <div class="col-lg-3 py-0">
        <div class="input_wrapper top_label date_input">
          <label class="form-label">تاريخ وصول المستند</label>
          <v-menu
            ref="to_date_menu"
            v-model="searchOptions.arrive_date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="searchData.arrive_date"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchData.arrive_date"
              @input="searchOptions.arrive_date_menu = false"
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>
        </div>
      </div> -->

      <div class="col-lg-3 py-0">
        <div class="input_wrapper top_label mb-3">
          <label class="form-label"> الميناء </label>
          <multiselect
            v-model="searchData.port"
            :options="searchOptions.ports"
            label="name"
            track-by="id"
            placeholder=" "
            :searchable="true"
            :allow-empty="true"
            :show-labels="false"
            @input="
              choosePort(searchData.port), $emit('filterTable', searchData)
            "
          >
          </multiselect>
        </div>
      </div>
      <div class="col-lg-3 py-0">
        <div class="input_wrapper top_label auto_complete">
          <label class="form-label"> المبلغ </label>
          <input
            type="text"
            class="form-control"
            v-model.trim="searchData.cert_count"
            @change="$emit('filterTable', searchData)"
          />
        </div>
      </div>
      <div class="col-lg-3 py-0">
        <div class="input_wrapper top_label auto_complete">
          <label class="form-label">رقم الشهادة</label>
          <input
            type="text"
            class="form-control"
            v-model.trim="searchData.cert_num"
            @change="$emit('filterTable', searchData)"
          />
        </div>
      </div>

      <div class="col-lg-3 py-0">
        <div class="input_wrapper top_label date_input">
          <label class="form-label">تاريخ الشهادة</label>
          <v-menu
            ref="to_date_menu"
            v-model="searchOptions.cert_date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{on, attrs}">
              <v-text-field
                v-model="searchData.cert_date"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchData.cert_date"
              @input="searchOptions.cert_date_menu = false"
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>
        </div>
      </div>
      <div class="col-lg-3 py-0">
        <div class="input_wrapper top_label mb-3">
          <label class="form-label"> الحالة </label>
          <multiselect
            v-model="searchData.status"
            :options="searchOptions.status"
            label="text"
            track-by="id"
            placeholder=" "
            :searchable="true"
            :allow-empty="true"
            :show-labels="false"
            @input="
              chooseType(searchData.status), $emit('filterTable', searchData)
            "
          >
          </multiselect>
        </div>
      </div>
      <div class="col-lg-12 text-center py-0">
        <button
          class="button_style_1"
          @click="$emit('filterTable', searchData)"
        >
          بحث
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["title"],
  data() {
    return {
      load_users: false,

      // search
      searchData: {
        document_number: null,
        bl_number: null,
        company: null,
        companyId: null,
        port: null,
        portId: null,
        status: null,
        statusType: null,
        cert_num: null,
        cert_count: null,
        arrive_date: null,
        cert_date: null,
      },

      searchOptions: {
        companies: [],
        ports: [],
        status: [
          {
            id: "1",
            type: "pending",
            text: "منتظر",
          },
          {
            id: "2",
            type: "confirmed",
            text: "مأكد",
          },
        ],
        arrive_date_menu: false,
        cert_date_menu: false,
      },

      users: null,
    };
  },

  watch: {
    [`searchOptions.search_users`](newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.users = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_users(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_users(newVal);
        } else {
          this.get_filterd_users(newVal);
        }
      }
    },
  },

  methods: {
    // get_filterd_users
    get_filterd_users(keyword) {
      this.load_users = true;
      this.$axios({
        method: "GET",
        url: `client/customer/search?keyword=${keyword}`,
      })
        .then((res) => {
          this.users = res.data.data;
          this.load_users = false;
        })
        .catch((err) => {
          this.load_users = false;
        });
    },

    getCompanies() {
      // START:: GET SAFES
      this.$axios({
        method: "GET",
        url: `client/company_import`,
      })
        .then((res) => {
          this.searchOptions.companies = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
      // END:: GET SAFES
    },
    getPorts() {
      // START:: GET SAFES
      this.$axios({
        method: "GET",
        url: `client/port`,
      })
        .then((res) => {
          this.searchOptions.ports = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
      // END:: GET SAFES
    },
    chooseBank(item) {
      if (this.searchData.company) {
        this.searchData.companyId = item.id;
      } else {
        this.searchData.companyId = null;
      }
    },
    choosePort(item) {
      if (this.searchData.port) {
        this.searchData.portId = item.id;
      } else {
        this.searchData.portId = null;
      }
    },
    chooseType(item) {
      if (this.searchData.status) {
        this.searchData.statusType = item.type;
      } else {
        this.searchData.statusType = null;
      }
    },
  },

  created() {
    this.getCompanies();
    this.getPorts();
  },
};
</script>

<style lang="scss">
.search-section .load_inline {
  width: 30px !important;
  height: 25px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
  left: 1px;
  position: absolute !important;
  background: transparent !important;
  margin: 0 !important;

  span {
    right: 0 !important;
    left: 0 !important;
    margin: auto !important;
    border-top: 3px solid var(--mainColor-2) !important;
    border-right: 3px solid transparent !important;
  }
}
</style>
