<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->

  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/currency/transfer"
        search_title="تحويل عملة"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">تحويل عملة</h4>
        </div>

        <!-- START:: CREATE FORM WRAPPER -->
        <form @submit.prevent="validateForm">
          <div class="container">
            <div class="row justify-content-between">
              <!-- START:: RADIO INPUTS WRAPPER -->
              <div class="col-12 py-0">
                <div class="input_wrapper radio_button_input">
                  <v-radio-group v-model="transferType">
                    <v-radio
                      v-for="n in radios"
                      :key="n.id"
                      :label="n.label"
                      :value="n.value"
                      color="indigo lighten-2"
                      @click="emptyItem"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </div>
              <!-- END:: RADIO INPUTS WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="data.amount"
                  />
                  <label for="name_input" class="form-label">المبلغ</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="data.multiplication"
                  />
                  <label for="name_input" class="form-label">ضرب</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="data.division"
                  />
                  <label for="name_input" class="form-label">قسمة</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="calcTotal"
                    readonly
                  />
                  <label for="name_input" class="form-label">الإجمالي</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <div class="col-lg-12 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">{{
                    $t("forms.labels.safe")
                  }}</label>
                  <multiselect
                    v-model="data.safe"
                    :options="safes"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
                <h6
                  class="mt-5 mb-0 mx-0 amount-section"
                  v-for="(item, index) in data.safe.currencies"
                  :key="index"
                  :class="{
                    redColor: item.amount < 0,
                    greenColor: item.amount > 0,
                  }"
                  v-if="data.safe"
                >
                  الرصيد :
                  <span
                    >{{ Number(item.amount).toLocaleString() }}
                    {{ item.name_ar }}
                  </span>
                </h6>
              </div>

              <!-- ============ Client -->
              <div
                class="col-lg-6 py-0 mb-2"
                v-show="transferType == 'customer'"
              >
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> إسم العميل</label>
                  <v-autocomplete
                    v-model="data.username"
                    :search-input.sync="searchOptions.search_users_1"
                    :items="users_1"
                    item-text="name"
                    return-object
                    :hide-no-data="!users_1"
                    dense
                  ></v-autocomplete>
                  <button
                    class="button_style_1 load_inline"
                    v-if="load_users_1"
                  >
                    <span class="btn_loader"></span>
                  </button>
                </div>
                <h6
                  class="mt-0 mb-0 mx-0 amount-section"
                  :class="{
                    redColor: data.username.amount < 0,
                    greenColor: data.username.amount > 0,
                  }"
                  v-if="data.username"
                >
                  الرصيد :
                  {{ Number(data.username.amount).toLocaleString() }}
                </h6>
              </div>

              <!-- ============ Organization -->
              <div
                class="col-lg-6 py-0 mb-2"
                v-show="transferType == 'enterprise'"
              >
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> حساب المؤسسة</label>
                  <v-autocomplete
                    v-model="data.orgAccount"
                    :search-input.sync="searchOptions.search_enterprices_1"
                    :items="enterprices_1"
                    item-text="name"
                    return-object
                    :hide-no-data="!enterprices_1"
                    dense
                  ></v-autocomplete>
                  <button
                    class="button_style_1 load_inline"
                    v-if="load_enterprices_1"
                  >
                    <span class="btn_loader"></span>
                  </button>
                </div>

                <h6
                  class="mt-0 mb-0 mx-0 amount-section"
                  :class="{
                    redColor: data.orgAccount.amount < 0,
                    greenColor: data.orgAccount.amount > 0,
                  }"
                  v-if="data.orgAccount"
                >
                  الرصيد :
                  {{ Number(data.orgAccount.amount).toLocaleString() }}
                </h6>
              </div>

              <!-- ============ Client -->
              <div
                class="col-lg-6 py-0 mb-2"
                v-show="transferType == 'customer'"
              >
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label">اسم العميل المحول اليه</label>
                  <v-autocomplete
                    v-model="data.clientAccountToTransfer"
                    :search-input.sync="searchOptions.search_users_2"
                    :items="users_2"
                    item-text="name"
                    return-object
                    :hide-no-data="!users_2"
                    dense
                  ></v-autocomplete>
                  <button
                    class="button_style_1 load_inline"
                    v-if="load_users_2"
                  >
                    <span class="btn_loader"></span>
                  </button>
                </div>
                <h6
                  class="mt-0 mb-0 mx-0 amount-section"
                  :class="{
                    redColor: data.clientAccountToTransfer.amount < 0,
                    greenColor: data.clientAccountToTransfer.amount > 0,
                  }"
                  v-if="data.clientAccountToTransfer"
                >
                  الرصيد :
                  {{
                    Number(data.clientAccountToTransfer.amount).toLocaleString()
                  }}
                </h6>
              </div>

              <!-- ============ Organization -->
              <div
                class="col-lg-6 py-0 mb-2"
                v-show="transferType == 'enterprise'"
              >
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> حساب المؤسسة المحول اليها</label>
                  <v-autocomplete
                    v-model="data.orgAccountToTransfer"
                    :search-input.sync="searchOptions.search_enterprices_2"
                    :items="enterprices_2"
                    item-text="name"
                    return-object
                    :hide-no-data="!enterprices_2"
                    dense
                  ></v-autocomplete>
                  <button
                    class="button_style_1 load_inline"
                    v-if="load_enterprices_2"
                  >
                    <span class="btn_loader"></span>
                  </button>
                </div>
                <h6
                  class="mt-0 mb-0 mx-0 amount-section"
                  :class="{
                    redColor: data.orgAccountToTransfer.amount < 0,
                    greenColor: data.orgAccountToTransfer.amount > 0,
                  }"
                  v-if="data.orgAccountToTransfer"
                >
                  الرصيد :
                  {{
                    Number(data.orgAccountToTransfer.amount).toLocaleString()
                  }}
                </h6>
              </div>
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              إضافة
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- END:: CREATE FORM WRAPPER -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TransferCurrency",

  computed: {
    calcTotal() {
      const num =
        (this.data.amount * this.data.multiplication) / this.data.division;
      return parseFloat(num.toFixed()).toLocaleString();
    },
  },

  data() {
    return {
      // loader
      loading: true,

      load_enterprices_1: false,
      load_enterprices_2: false,
      load_users_1: false,
      load_users_2: false,

      // search
      searchOptions: {
        search_users_1: "",
        search_users_2: "",

        search_enterprices_1: "",
        search_enterprices_2: "",
      },

      users_1: null,
      users_2: null,
      enterprices_1: null,
      enterprices_2: null,

      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.currency.transfer"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========= Date Menu
      dateMenu: false,

      // ========= Radio Inputs Data
      radios: [
        {
          id: 1,
          label: "تحويل بين العملاء",
          value: "customer",
        },
        {
          id: 2,
          label: "تحويل بين المؤسسات",
          value: "enterprise",
        },
      ],
      transferType: "customer",

      // ========== create_data
      data: {
        amount: null,
        multiplication: 1,
        division: 1,
        safe: null,
        username: null,
        orgAccount: null,
        clientAccountToTransfer: null,
        orgAccountToTransfer: null,
      },

      // search_users
      search_users1: "",
      search_users2: "",
      // search_accounts
      search_accounts1: "",
      search_accounts2: "",
      users1: [],
      users2: [],
      enterpriceAccounts1: [],
      enterpriceAccounts2: [],
      safes: [],
      // ========== Fixed Data
    };
  },

  watch: {
    [`searchOptions.search_users_1`](newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.users_1 = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_users_1(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_users_1(newVal);
        } else {
          this.get_filterd_users_1(newVal);
        }
      }
    },
    [`searchOptions.search_users_2`](newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.users_2 = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_users_2(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_users_2(newVal);
        } else {
          this.get_filterd_users_2(newVal);
        }
      }
    },

    [`searchOptions.search_enterprices_1`](newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.enterprices_1 = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_enterprices_1(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_enterprices_1(newVal);
        } else {
          this.get_filterd_enterprices_1(newVal);
        }
      }
    },
    [`searchOptions.search_enterprices_2`](newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.enterprices_2 = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_enterprices_2(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_enterprices_2(newVal);
        } else {
          this.get_filterd_enterprices_2(newVal);
        }
      }
    },
  },

  methods: {
    // get_filterd_users
    get_filterd_users_1(keyword) {
      this.load_users_1 = true;
      this.$axios({
        method: "GET",
        url: `client/customer/search?keyword=${keyword}`,
      })
        .then((res) => {
          this.users_1 = res.data.data;
          this.load_users_1 = false;
        })
        .catch((err) => {
          this.load_users_1 = false;
        });
    },
    // get_filterd_users
    get_filterd_users_2(keyword) {
      this.load_users_2 = true;
      this.$axios({
        method: "GET",
        url: `client/customer/search?keyword=${keyword}`,
      })
        .then((res) => {
          this.users_2 = res.data.data;
          this.load_users_2 = false;
        })
        .catch((err) => {
          this.load_users_2 = false;
        });
    },

    // get_filterd_enterprices
    get_filterd_enterprices_1(keyword) {
      this.load_enterprices_1 = true;
      this.$axios({
        method: "GET",
        url: `help/accounts?keyword=${keyword}`,
      })
        .then((res) => {
          this.enterprices_1 = res.data.data;
          this.load_enterprices_1 = false;
        })
        .catch((err) => {
          this.load_enterprices_1 = false;
        });
    },
    get_filterd_enterprices_2(keyword) {
      this.load_enterprices_2 = true;
      this.$axios({
        method: "GET",
        url: `help/accounts?keyword=${keyword}`,
      })
        .then((res) => {
          this.enterprices_2 = res.data.data;
          this.load_enterprices_2 = false;
        })
        .catch((err) => {
          this.load_enterprices_2 = false;
        });
    },
    //END:: GET ENTERPRICES
    emptyItem() {
      this.data.amount = null;
      this.data.multiplication = 1;
      this.data.division = 1;
      this.data.username = null;
      this.data.safe = this.safes.find(x => x.id === 5);
      this.data.orgAccount = null; 
      this.data.clientAccountToTransfer = null;
      this.data.orgAccountToTransfer = null;
    },
    // START:: GET SAFES
    getSafes() {
      this.$axios({
        method: "GET",
        url: `client/wallet?type=in`,
      })
        .then((res) => {
          this.safes = res.data.data;
          this.data.safe = this.safes.find(x => x.id === 5);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    // END:: GET SAFES

    // START:: VALIDATE CREATE FORM
    validateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال المبلغ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.safe) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إختيار الخزنة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.transferType == "customer" && !this.data.username) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال إسم العميل",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (
          !this.transferType == "customer" &&
          !this.data.clientAccountToTransfer
        ) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال حساب العميل المحول إليه",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (
          !this.transferType == "enterprise" &&
          !this.data.orgAccount
        ) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال حساب المؤسسة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (
          !this.transferType == "enterprise" &&
          !this.data.orgAccountToTransfer
        ) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال حساب المؤسسة المحول إليه",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.submitData();
        }
      }
    },
    // Submit Data
    emptyFormData() {
      this.data.amount = null;
      this.data.multiplication = 1;
      this.data.division = 1;
      this.data.username = null;
      this.getSafes();
      this.data.safe = this.safes.find(x => x.id === 5);
      this.data.orgAccount = null;
      this.data.clientAccountToTransfer = null;
      this.data.orgAccountToTransfer = null;
    },
    submitData() {
      if (this.transferType == "customer") {
        var submit_data = {
          amount: this.data.amount,
          multiply: this.data.multiplication,
          dividing: this.data.division,
          total: Number(this.calcTotal.replace(/\D/g, "")),
          wallet_id: this.data.safe.id,
          type: this.transferType,
          balance_customer_id: this.data.username.id,
          transferred_balance_customer_id: this.data.clientAccountToTransfer.id,
        };
      }
      if (this.transferType == "enterprise") {
        var submit_data = {
          amount: this.data.amount,
          multiply: this.data.multiplication,
          dividing: this.data.division,
          total: Number(this.calcTotal.replace(/\D/g, "")),
          wallet_id: this.data.safe.id,
          type: this.transferType,
          account_id: this.data.orgAccount.id,
          transferred_account_id: this.data.orgAccountToTransfer.id,
        };
      }
      // END:: APPEND REQUEST DATA

      this.$axios({
        method: "POST",
        url: "client/currency_transformation",
        data: submit_data,
      })
        .then((res) => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "topRight",
          });
          this.emptyFormData();
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
    // END:: VALIDATE CREATE FORM
  },
  mounted() {
    this.getSafes();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      userType.includes("confirm_lock_and_deals") &&
      !userType.includes("lock_and_deals")
    ) {
      this.$router.push("/currency/transfer");
    } else if (
      !userType.includes("superadmin") &&
      !userType.includes("lock_and_deals")
    ) {
      this.$router.push("/");
    }
  },
};
</script>
