<template>
<!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
  <div class="custom_card table_card chart_card">
    <div class="chart_component" :class="{ diff: show }">
      <span :style="{ color: color }" class="icon"
        ><i :class="[icon]"></i
      ></span>
      <span class="title">{{ title }}</span>
      
      <h3
        class="number"
        v-if="link == null && routeLink == null && number != null && arr == null"
      >
        {{ number }}
      </h3>
      <h3 class="number" v-if="number == null">لا يوجد</h3>
      <h3 class="number" v-if="arr && number != null" v-for="i in number" :key="i.id">
        {{ i.name_ar }}
      </h3>
      <a :href="link" class="number" v-if="link != null" target="_blank">{{
        link
      }}</a>
      <router-link
        v-if="routeLink != null"
        class="number"
        :to="'/enterpriceOwners/show/' + routeLink"
      >
        {{ number }}
      </router-link>
      <slot> </slot>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  props: [
    "icon",
    "number",
    "title",
    "bgColor",
    "color",
    "show",
    "link",
    "routeLink",
    "arr"
  ],

  components: {
    VueApexCharts,
  },
};
</script>

<style lang="scss" scoped>
.custom_card {
  margin: 0 !important;
  padding: 20px 15px;

  .chart_component {
    position: relative;
  }
}
</style>

