<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :show_print="true"
        :excelFile="true"
        :excelData="excelData"
      />
      <!-- End Breadcrumb -->
      <!-- start of searchSection  -->
      <SearchSection @filterTable="searchTable"></SearchSection>
      <!-- end of searchSection  -->
      <!-- notFound  -->
      <Notfound v-if="!searchItems"></Notfound>
      <!-- Start Statistics Card-->
      <div v-else>
        <!-- Start Statistics Card-->
        <ul class="all-state-count">
          <li>
            <div class="state-count">
              <span> عدد الموظفين :</span>
              <span>{{ table_length }}</span>
            </div>
          </li>
        </ul>
        <!-- End Statistics Card-->

        <main class="main-table table-print">
          <!-- start of  header of printSection  -->
          <PrintHead :title="'الموظفين'" :number="table_length"></PrintHead>
          <!-- end of header of printSection  -->
          <v-data-table
            class="thumb"
            :headers="headers"
            :items="rows"
            :loading="lodaing"
            :loading-text="$t('table.loadingData')"
            v-model="selected"
            :single-select="singleSelect"
            item-key="id"
            :items-per-page="rows.length"
            hide-default-footer
          >
            <!-- ================== You Can use any slots you want ================== -->
            <!-- Select no data State -->
            <template v-slot:[`item.index`]="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:[`item.wallets`]="{ item }">
              <v-chip dark class="ml-2 mb-2" v-for="i in item.wallets" :key="i">
                {{ i.name }}
              </v-chip>
            </template>

            <template v-slot:[`item.in_wallets`]="{ item }">
              <v-chip
                class="ml-2 mb-2"
                v-for="i in item.in_wallets"
                :key="i"
                dark
              >
                {{ i.name }}
              </v-chip>
            </template>

            <template v-slot:[`item.permissions`]="{ item }">
              <v-chip
                color="blue"
                v-for="i in item.permissions"
                class="ml-2 mb-2"
                :key="i"
              >
                {{ i.title }}
              </v-chip>
            </template>
            <template v-slot:[`item.customers`]="{ item }">
              <span v-if="item.customers.length == 0"> كل العملاء </span>
              <v-chip
                color="blue"
                v-for="i in item.customers"
                v-else
                class="ml-2 mb-2"
                :key="i"
              >
                {{ i.name }}
              </v-chip>
            </template>
            <template v-slot:no-data>
              {{ $t("table.noData") }}
            </template>

            <!-- Select actions-->
            <template v-slot:[`item.actions`]="{ item }">
              <div class="_actions">
                <v-icon class="edit" small @click="editItem(item)">
                  fal fa-edit
                </v-icon>
                <v-icon class="delete" small @click="deleteItem(item)">
                  fal fa-trash
                </v-icon>
              </div>
            </template>

            <!-- ======================== Start Top Section ======================== -->
            <template v-slot:top>
              <!-- Delete dialog -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">{{
                    $t("table.deletedialog.areYouSure")
                  }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="deleteItemConfirm"
                      >{{ $t("table.deletedialog.ok") }}
                      <span
                        class="btn_loader_model"
                        v-if="loadingBtnDialog"
                      ></span
                    ></v-btn>
                    <v-btn color="#F44336" @click="dialogDelete = false">{{
                      $t("table.deletedialog.cancel")
                    }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Add & Delete -->

              <!-- Add & Delete -->
            </template>
            <!-- ======================== End Top Section ======================== -->
          </v-data-table>
        </main>
        <!-- End Main Section -->
      </div>

      <!-- Start Image_Model -->
      <base-model
        @closeModel="model_1.show_model = false"
        :show="model_1.show_model"
      >
        <div class="image">
          <img
            v-if="model_1.model_img_src"
            :src="model_1.model_img_src"
            alt="..."
          />
        </div>
      </base-model>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
import SearchSection from "@/components/Search/employees";
export default {
  components: {
    StatsCard,
    SearchSection,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // search
      searchItems: null,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.employees.title"),
          disabled: false,
          href: "/sliders",
        },
        {
          text: this.$t("breadcrumb.employees.all"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "الموظفين",
          number: "1",
        },
      ],

      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Date_Menues
      from_date_menu: false,
      to_date_menu: false,

      // ========== Filter
      filter: {
        name: null,
        safe: [],
        from: null,
        to: null,
      },

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== Loding
      lodaing: false,

      // Fixed_data
      safes: ["foo", "bar", "fizz", "buzz"],
      table_length: null,
      // excel data
      excelData: {
        name: "الموظفين",
        items: null,
        json_fields: {
          "اسم الموظف": "name",
          الفرع: "branch.name_ar",
          "البريد الالكتروني": "email",
          "خزن الموظف سحب": {
            field: "in_wallets",
            callback: (value) => {
              let finalVal = "<table><tbody>";
              value.forEach((val, index) => {
                if (index == value.length - 1) {
                  finalVal +=
                    "<tr><td>" + val.name + "</td></tr>" + "</tbody></table>";
                } else {
                  finalVal += "<tr><td>" + val.name + "</td></tr>";
                }
              });
              return finalVal;
            },
          },
          "خزن الموظف ايداع": {
            field: "wallets",
            callback: (value) => {
              let finalVal = "<table><tbody>";
              value.forEach((val, index) => {
                if (index == value.length - 1) {
                  finalVal +=
                    "<tr><td>" + val.name + "</td></tr>" + "</tbody></table>";
                } else {
                  finalVal += "<tr><td>" + val.name + "</td></tr>";
                }
              });
              return finalVal;
            },
          },
          "صلاحيات الموظف": {
            field: "permissions",
            callback: (value) => {
              let finalVal = "<table><tbody>";
              value.forEach((val, index) => {
                if (index == value.length - 1) {
                  finalVal +=
                    "<tr><td>" + val.title + "</td></tr>" + "</tbody></table>";
                } else {
                  finalVal += "<tr><td>" + val.title + "</td></tr>";
                }
              });
              return finalVal;
            },
          },
        },
        "حسابات العملاء": {
          field: "customers",
          callback: (value) => {
            let finalVal = "<table><tbody>";
            value.forEach((val, index) => {
              if (index == value.length - 1) {
                finalVal +=
                  "<tr><td>" + val.title + "</td></tr>" + "</tbody></table>";
              } else {
                finalVal += "<tr><td>" + val.title + "</td></tr>";
              }
            });
            return finalVal;
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          { text: "اسم الموظف", value: "name", align: "center" },
          { text: "الفرع ", value: "branch.name_ar", align: "center" },
          {
            text: "البريد الالكتروني",
            value: "email",
            align: "center",
          },
          {
            text: "خزن الموظف سحب",
            value: "in_wallets",
            align: "center",
            sortable: false,
          },
          {
            text: "خزن الموظف ايداع",
            value: "wallets",
            align: "center",
            sortable: false,
          },
          {
            text: "صلاحيات الموظف",
            value: "permissions",
            align: "center",
            sortable: false,
          },
          {
            text: "حسابات العملاء",
            value: "customers",
            align: "center",
            sortable: false,
          },
          {
            text: "كود الموظف",
            value: "user_code",
            align: "center",
            sortable: false,
          },
          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 100,
          },
        ];
      }
    },
  },

  methods: {
    // searchTable
    searchTable(items) {
      this.rows = [];
      this.searchItems = items;
      this.setRows();
    },
    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/employees/add" });
    },
    showItem(item) {
      this.dialogShow = true;
      this.itemToShow = item;
    },
    editItem(item) {
      this.$router.push({ path: "/employees/edit/" + item.id });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/user/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // Set Rows
    setRows() {
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/user",
        params: {
          branch_id: this.searchItems ? this.searchItems.branchId : null,
          user_code: this.searchItems ? this.searchItems.userCode : null,
        },
      })
        .then((res) => {
          this.rows = res.data.data;
          this.table_length = this.rows.length;
          this.excelData.items = this.rows;
          this.lodaing = false;
        })
        .catch((err) => {
          this.lodaing = false;
        });
    },
  },

  // ======= hooks
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
