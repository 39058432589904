<template>
  <div class="create_wrapper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->
    <div class="custom_card">
      <!-- START:: CARD TITLE -->
      <div class="card-header">
        <h4 class="card-title">{{ $t("CRUD.Update.main_title") }}</h4>
      </div>
      <!-- END:: CARD TITLE -->
      <!-- START:: CREATE FORM WRAPPER -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <!-- START:: RADIO BUTTON INPUT -->
            <div class="input_wrapper radio_button_input">
              <v-radio-group v-model="radioButtonsValue">
                <v-radio
                  v-for="n in statusOptions"
                  :key="n.id"
                  :label="`${n.name}`"
                  :value="n.value"
                  color="indigo lighten-2"
                ></v-radio>
              </v-radio-group>
            </div>
            <!-- END:: RADIO BUTTON INPUT -->

            <!-- START:: INPUT WRAPPER -->
            <div class="col-lg-6 py-0" v-if="radioButtonsValue == 'customer'">
              <!-- START:: MULTIPLE SELECT INPUT -->
              <div class="input_wrapper top_label">
                <label class="form-label">{{
                  $t("safeTransactions.data.employee")
                }}</label>
                <multiselect
                  v-model="data.customer"
                  :options="customers"
                  label="name"
                  track-by="name"
                  placeholder=""
                  :searchable="true"
                  :allow-empty="true"
                  :show-labels="false"
                  :multiple="false"
                >
                </multiselect>
              </div>
              <!-- END:: MULTIPLE SELECT INPUT -->
            </div>
            <!-- END:: INPUT WRAPPER -->

            <!-- START:: INPUT WRAPPER -->
            <div class="col-lg-6 py-0">
              <!-- START:: MULTIPLE SELECT INPUT -->
              <div class="input_wrapper top_label">
                <label class="form-label">{{
                  $t("safeTransactions.data.currency")
                }}</label>
                <multiselect
                  v-model="data.currency"
                  :options="currencies"
                  label="name_ar"
                  track-by="name_ar"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="true"
                  :show-labels="false"
                  :multiple="false"
                >
                </multiselect>
              </div>
              <!-- END:: MULTIPLE SELECT INPUT -->
            </div>
            <!-- END:: INPUT WRAPPER -->

            <!-- START:: INPUT WRAPPER -->
            <div class="col-lg-6 py-0" v-if="radioButtonsValue == 'wallet'">
              <!-- START:: MULTIPLE SELECT INPUT -->
              <div class="input_wrapper top_label">
                <label class="form-label">{{
                  $t("safeTransactions.data.name")
                }}</label>
                <multiselect
                  v-model="data.wallet"
                  :options="wallets"
                  label="name"
                  track-by="name"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="true"
                  :show-labels="false"
                  :multiple="false"
                >
                </multiselect>
              </div>
              <!-- END:: MULTIPLE SELECT INPUT -->
            </div>
            <!-- END:: INPUT WRAPPER -->

            <!-- START:: INPUT WRAPPER -->
            <div class="col-lg-6 py-0">
              <!-- START:: NUMBER INPUT IBAN NUMBER-->
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  id="accountNum"
                  v-model="data.amount"
                  @input="checkIfInputIsEmpty"
                />
                <label for="accountNum" class="form-label">{{
                  $t("safeTransactions.data.amount")
                }}</label>
              </div>
              <!-- END:: NUMBER INPUT IBAN NUMBER-->
            </div>
            <!-- END:: INPUT WRAPPER -->
          </div>
        </div>
        <div class="buttons_wrapper">
          <!-- START:: BUTTON -->
          <button
            class="button_style_1"
            :class="{buttonIndex: btnIsLoading}"
            :disabled="btnIsLoading"
          >
            {{ $t("Forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
          <!-- END:: BUTTON -->
        </div>
      </form>
      <!-- END:: CREATE FORM WRAPPER -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Update",
  props: ["id"],
  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("mainHome"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("safeTransactions.title"),
          disabled: false,
          href: "/allsafeTransactions",
        },
        {
          text: this.$t("edit"),
          disabled: true,
          href: "",
        },
      ],

      // START:: BUTTON LOADER HANDLING DATA
      btnIsLoading: false,
      // END:: BUTTON LOADER HANDLING DATA

      radioButtonsValue: null,
      statusOptions: [
        {
          id: 1,
          name: "عميل",
          value: "customer",
        },
        {
          id: 2,
          name: "خزنة",
          value: "wallet",
        },
      ],

      // START:: SELECTED FILE DATA
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },
      // END:: SELECTED FILE DATA

      // START:: SINGLE SELECT DATA
      single_select_options: [],
      // END:: SINGLE SELECT DATA

      // START:: SINGLE SELECT DATA
      single_select_options2: [],
      // END:: SINGLE SELECT DATA

      // START:: SINGLE SELECT DATA
      single_select_options3: [],
      // END:: SINGLE SELECT DATA

      // START:: CREATE DATA
      data: {
        customer: null,
        currency: null,
        wallet: null,
        amount: null,
      },
      // END:: CREATE DATA
    };
  },

  methods: {
    // START:: Uplode Avatar
    uplodeImg_1(obj) {
      this.updateData.avatar = obj;
    },
    // END:: Uplode Avatar

    // START:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)
    checkIfInputIsEmpty(e) {
      let inputElement = e.currentTarget;
      if (inputElement.value.length > 0) {
        inputElement.classList.add("not_empty");
      } else {
        inputElement.classList.remove("not_empty");
      }
    },
    // END:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)

    // START:: GET DATA
    getData() {
      this.$axios.get("client/credit/" + this.id).then((res) => {
        this.data.customer = res.data.data.customer;
        this.data.currency = res.data.data.currency;
        this.data.wallet = res.data.data.wallet;
        this.data.amount = res.data.data.value;
        this.radioButtonsValue = res.data.data.type;
      });
    },
    // END:: GET DATA

    // START:: GET CUSTOMERS
    get_customers() {
      this.$axios.get("client/customer").then((res) => {
        this.single_select_options = res.data.data;
      });
    },
    // END:: GET CUSTOMERS

    // START:: GET CURRENCY
    get_currency() {
      this.$axios.get("client/currency").then((res) => {
        this.single_select_options2 = res.data.data;
      });
    },
    // END:: GET CURRENCY

    // START:: GET WALLET
    get_wallet() {
      this.$axios.get("client/wallet").then((res) => {
        this.single_select_options3 = res.data.data;
      });
    },
    // END:: GET WALLET

    // START:: VALIDATE CREATE FORM
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.radioButtonsValue) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("safeTransactions.validation.type"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (
          !this.data.customer &&
          this.radioButtonsValue == "customer"
        ) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("safeTransactions.validation.categories"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.currency) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("safeTransactions.validation.currency"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.wallet && this.radioButtonsValue == "wallet") {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("safeTransactions.validation.wallet"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("safeTransactions.validation.amount"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.btnIsLoading = true;
          const data = {
            type: this.radioButtonsValue,
            currency_id: this.data.currency.id,
            value: this.data.amount,
            customer_id: this.data.customer?.id || null,
            wallet_id: this.data.wallet?.id || null,
          };
          if (this.radioButtonsValue == "customer") {
            delete data.wallet_id;
          } else if (this.radioButtonsValue == "wallet") {
            delete data.customer_id;
          }
          // data.append("type", this.radioButtonsValue);
          // data.append("currency_id", this.data.currency.id);
          // data.append("value", this.data.amount);
          // if (this.radioButtonsValue == "customer") {
          //   data.append("customer_id", this.data.customer.id);
          // }
          // if (this.radioButtonsValue == "wallet") {
          //   data.append("wallet_id", this.data.wallet.id);
          // }
          this.$axios
            .put("client/credit/" + this.id, data)
            .then(() => {
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("global.editSuccess"),
                position: "topRight",
              });
              this.btnIsLoading = false;
              this.$router.push({path: "/allSafeTransactions"});
            })
            .catch((err) => {
              this.btnIsLoading = false;
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "topRight",
              });
            });
        }
      }
    },
    // END:: VALIDATE CREATE FORM
  },

  created() {
    this.getData();
    this.get_customers();
    this.get_currency();
    this.get_wallet();
  },
  computed: {
    customers() {
      return this.single_select_options.map((g) => ({
        name: g.name,
        id: g.id,
      }));
    },
    currencies() {
      return this.single_select_options2.map((g) => ({
        name_ar: g.name_ar,
        id: g.id,
      }));
    },
    wallets() {
      return this.single_select_options3.map((g) => ({
        name: g.name,
        id: g.id,
      }));
    },
  },
};
</script>
