<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/products"
        search_title="السلع"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("edit") }}</h4>
        </div>

        <!-- ==== Start Form ==== -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="name_input"
                    @input="checkIfInputIsEmpty"
                    v-model.trim="data.name"
                  />
                  <label for="name_input" class="form-label">اسم السلعة</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label file_input">
                  <span class="file_input_label"> صورة السلعة </span>
                  <label
                    for="file_input"
                    class="form-label"
                    v-if="!selectedFile.name"
                  ></label>
                  <label for="file_input" class="form-label" v-else>
                    {{ selectedFile.name }}
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    id="file_input"
                    @change="handelSelectedFile"
                  />
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <!-- <div class="col-lg-4 py-0" v-if="categories">
                <div class="input_wrapper top_label">
                  <label class="form-label">مجالات الشركة</label>
                  <multiselect
                    v-model="selectedCategories"
                    :options="categories"
                    label="name_ar"
                    track-by="name_ar"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                    :multiple="true"
                  >
                  </multiselect>
                </div>
              </div> -->
              <!-- END:: INPUT WRAPPER -->
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              تعديل
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- ==== End Form ==== -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Update",

  props: ["id"],

  data() {
    return {
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "تعديل سلعة",
          disabled: true,
          href: "",
        },
      ],
      // START:: MULTIPLE SELECT DATA
      selectedCategories: "",
      categories: null,
      // END:: MULTIPLE SELECT DATA
      // ========== Loading
      btnIsLoading: false,

      // search_users
      search_users: "",

      // START:: SELECTED FILE DATA
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },
      // END:: SELECTED FILE DATA

      // ========== create_data
      data: {
        name: null,
      },
    };
  },

  watch: {
    [`search_users`](newVal) {
      this.get_filterd_users(newVal);
    },
  },

  methods: {
    // Get Categories
    getCategories() {
      this.$axios({
        method: "GET",
        url: `client/category`,
      }).then((res) => {
        this.categories = res.data.data;
      });
    },

    // Uplode Image
    uplodefiles(data) {
      this.data.files = data;
    },

    //Get Data
    getData() {
      this.$axios({
        method: "GET",
        url: "client/product/" + this.id,
      }).then((res) => {
        this.data.name = res.data.data.name;
        this.selectedFile.name = res.data.data.image;
        // this.selectedCategories = res.data.data.category;
      });
    },

    // Validate Data
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.name) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال إسم السلعة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.selectedFile.name) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة صورة السلعة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.submitData();
          return;
        }
      }
    },
    emptyFormData() {
      this.data.name = null;
      this.selectedFile.file = null;
      this.selectedFile.name = null;
    },
    // Submit Data
    submitData() {
      const submit_data = new FormData();
      submit_data.append("name", this.data.name);
      if (this.selectedFile.file) {
        submit_data.append("image", this.selectedFile.file);
      }
      this.$axios({
        method: "POST",
        url: "client/product/update_product/" + this.id,
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("editSuccess"),
            position: "topRight",
          });
          this.$router.push('/products');
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },

    // START:: HANDLING SELECTED FILE METHOD
    handelSelectedFile(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD
  },

  created() {
    this.getData();
    this.getCategories();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (!userType.includes("superadmin") && !userType.includes("companies")) {
      this.$router.push("/");
    }
  },
};
</script>
