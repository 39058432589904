<template>
  <div class="search-section">
    <div class="divider">
      <h4>بحث في الموظفين</h4>
      <hr
        role="separator"
        aria-orientation="horizontal"
        class="v-divider theme--dark"
      />
    </div>
    <div class="row">
      <div class="col-lg-6 py-0">
        <div class="input_wrapper top_label mb-3">
          <label class="form-label"> اختار الفرع </label>
          <multiselect
            v-model="searchData.branch"
            :options="searchOptions.branch"
            label="name_ar"
            track-by="name_ar"
            placeholder=" "
            @input="
              chooseLock(searchData.branch), $emit('filterTable', searchData)
            "
            :searchable="true"
            :allow-empty="true"
            :show-labels="false"
          >
          </multiselect>
        </div>
      </div>
      <div class="col-lg-6 py-0">
        <div class="input_wrapper top_label">
          <input
            type="text"
            class="form-control"
            v-model.trim="searchData.userCode"
            v-on:keydown.enter.prevent="$emit('filterTable', searchData)"
          />
          <label for="name_input" class="form-label">  كود الموظف</label>
        </div>
      </div>
      <div class="col-lg-12 text-center py-0">
        <button
          class="button_style_1"
          @click="$emit('filterTable', searchData)"
        >
          بحث
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // search
      searchData: {
        branch: null,
        branchId: null,
        userCode: null,
      },
      searchOptions: {
        branch: [],
      },
    };
  },
  methods: {
    getBranches() {
      // START:: GET SAFES
      this.$axios({
        method: "GET",
        url: `client/branch`,
      })
        .then((res) => {
          this.searchOptions.branch = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
      // END:: GET SAFES
    },
    chooseLock(item) {
      if (this.searchData.branch) {
        this.searchData.branchId = item.id;
      } else {
        this.searchData.branchId = null;
      }
    },
  },

  created() {
    this.getBranches();
  },
};
</script>

<style lang="scss">
.search-section .load_inline {
  width: 30px !important;
  height: 25px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
  left: 1px;
  position: absolute !important;
  background: transparent !important;
  margin: 0 !important;

  span {
    right: 0 !important;
    left: 0 !important;
    margin: auto !important;
    border-top: 3px solid var(--mainColor-2) !important;
    border-right: 3px solid transparent !important;
  }
}
</style>

