<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/bank_transfares_ultimate"
        search_title="التحويلات الخارجية"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("addNew") }}</h4>
        </div>

        <!-- ==== Start Form ==== -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-lg-3 py-0" v-if="currencies">
                <div class="input_wrapper top_label">
                  <label class="form-label">{{
                    $t("forms.labels.transfare_currency")
                  }}</label>
                  <multiselect
                    v-model="data.transfare_currency"
                    :options="currencies"
                    label="name_ar"
                    track-by="name_ar"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>

              <div class="col-lg-3 py-0" v-if="currencies">
                <div class="input_wrapper top_label">
                  <label class="form-label">{{
                    $t("forms.labels.transfare_to_currency")
                  }}</label>
                  <multiselect
                    v-model="data.transfare_to_currency"
                    :options="currencies"
                    label="name_ar"
                    track-by="name_ar"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>

              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">{{
                    $t("forms.labels.safe")
                  }}</label>
                  <multiselect
                    v-model="data.safe"
                    :options="safes"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>

              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> إسم العميل</label>
                  <v-autocomplete
                    v-model="data.clinet_name"
                    :search-input.sync="searchOptions.search_users"
                    :items="users"
                    item-text="name"
                    return-object
                    :hide-no-data="!users"
                    dense
                  ></v-autocomplete>
                  <button class="button_style_1 load_inline" v-if="load_users">
                    <span class="btn_loader"></span>
                  </button>
                </div>
                <h6
                  class="mt-0 mb-0 mx-0 amount-section"
                  :class="{
                    redColor: data.clinet_name.amount < 0,
                    greenColor: data.username.amount > 0,
                  }"
                  v-if="data.clinet_name"
                >
                  الرصيد :
                  {{ Number(data.clinet_name.amount).toLocaleString() }}
                </h6>
              </div>

              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.amount"
                  />
                  <label for="name_input" class="form-label">{{
                    $t("forms.labels.amount")
                  }}</label>
                </div>
              </div>

              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.comission"
                  />
                  <label for="name_input" class="form-label">{{
                    $t("forms.labels.comission")
                  }}</label>
                </div>
              </div>

              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.transfare_amount"
                  />
                  <label for="name_input" class="form-label">النسبة</label>
                </div>
              </div>

              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    readonly
                    type="text"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="total"
                  />
                  <label for="name_input" class="form-label">{{
                    $t("forms.labels.total")
                  }}</label>
                </div>
              </div>

              <div class="col-lg-12 py-0">
                <div class="input_wrapper top_label date_input">
                  <label class="form-label">{{
                    $t("forms.labels.date")
                  }}</label>
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        v-model="data.date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="data.date"
                      @input="dateMenu = false"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>

              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <label for="textarea_1" class="form-label">{{
                    $t("forms.labels.desc")
                  }}</label>
                  <textarea
                    v-on:keydown.enter.prevent="validateCreateForm"
                    v-model="data.desc"
                    class="form-control"
                    id="textarea_1"
                    rows="5"
                  ></textarea>
                </div>
              </div>

              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <label for="textarea_1" class="form-label">{{
                    $t("forms.labels.notes")
                  }}</label>
                  <textarea
                    v-on:keydown.enter.prevent="validateCreateForm"
                    v-model="data.notes"
                    class="form-control"
                    id="textarea_1"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              إضافة
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- ==== End Form ==== -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // loader
      loading: true,

      load_users: false,
      // search
      searchOptions: {
        search_users: "",
      },
      users: null,

      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "التحويلات الخارجية",
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // Date_menues
      dateMenu: false,

      // search_users
      search_users: "",

      // ========== create_data
      data: {
        transfare_currency: null,
        transfare_to_currency: null,
        safe: null,
        clinet_name: null,
        amount: null,
        comission: 1,
        transfare_amount: 1,
        date: new Date().toISOString().slice(0, 10),
        desc: null,
        notes: null,
      },

      // ========== Fixed_data
      safes: null,
      currencies: null,
      users: [],
    };
  },

  computed: {
    total() {
      const num = this.data.comission * this.data.transfare_amount;
      return parseFloat(num.toFixed()).toLocaleString();
    },
  },

  watch: {
    [`searchOptions.search_users`](newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.users = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_users(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_users(newVal);
        } else {
          this.get_filterd_users(newVal);
        }
      }
    },
  },

  methods: {
    // START:: GET SAFES
    getSafes() {
      this.$axios({
        method: "GET",
        url: `client/wallet`,
      })
        .then((res) => {
          this.safes = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    // END:: GET SAFES

    // START:: GET CURRENCIES
    getCurrencies() {
      this.$axios({
        method: "GET",
        url: `client/currency`,
      })
        .then((res) => {
          this.currencies = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    // END:: GET CURRENCIES

    // get_filterd_users
    get_filterd_users(keyword) {
      this.load_users = true;
      this.$axios({
        method: "GET",
        url: `client/customer/search?keyword=${keyword}`,
      })
        .then((res) => {
          this.users = res.data.data;
          this.load_users = false;
        })
        .catch((err) => {
          this.load_users = false;
        });
    },

    // Validate Data
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.transfare_currency) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.transfare_currency"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.transfare_to_currency) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.transfare_to_currency"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.safe) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.safe"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.clinet_name) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.clinet_name"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.amount"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.comission) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.comission"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.transfare_amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.transfare_amount"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.date) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.date"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.desc) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.desc"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.submitData();
          return;
        }
      }
    },
    emptyFormData() {
      this.data.transfare_currency = null;
      this.data.transfare_to_currency = 1;
      this.getSafes();
      this.data.safe = null;
      this.data.clinet_name = null;
      this.data.amount = null;
      this.data.comission = 1;
      this.data.transfare_amount = 1;
      this.data.desc = null;
      this.data.notes = null;
    },
    // Submit Data
    submitData() {
      const submit_data = new FormData();
      // START:: APPEND DATA
      submit_data.append("currency_id", this.data.transfare_currency.id);
      submit_data.append("to_currency_id", this.data.transfare_to_currency.id);
      submit_data.append("wallet_id", this.data.safe.id);
      submit_data.append("balance_customer_id", this.data.clinet_name.id);
      submit_data.append("amount", this.data.amount);
      submit_data.append("commission", this.data.comission);
      submit_data.append("rate", this.data.transfare_amount);
      submit_data.append("total", Number(this.total.replace(/\D/g, "")));
      submit_data.append("date", this.data.date);
      submit_data.append("desc", this.data.desc);
      if (this.data.notes) {
        submit_data.append("notes", this.data.notes);
      }
      // END:: APPEND DATA

      this.$axios({
        method: "POST",
        url: "client/external-transfer",
        data: submit_data,
      })
        .then((res) => {
          this.btnIsLoading = false;
          this.$iziToast.success({
            timeout: 5000,
            message:
              this.$t("addSuccess") +
              " " +
              " و اصبح رصيد العميل" +
              " " +
              res.data.data.balance_customer.currency.name_ar +
              " " +
              Number(res.data.wallet).toLocaleString(),
            position: "topRight",
          });
          this.emptyFormData();
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
  },

  created() {
    this.getSafes();
    this.getCurrencies();

    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>
