<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :show_print="true"
        :excelFile="true"
        :excelData="excelData"
      />
      <!-- End Breadcrumb -->
      <!-- start of searchSection  -->
      <SearchSection
        @filterTable="searchTable"
        title="ملفات الاستيراد"
      ></SearchSection>
      <!-- end of searchSection  -->
      <!-- notFound  -->
      <Notfound v-if="!searchItems"></Notfound>
      <!-- Start Statistics Card-->
      <div v-else>
        <!-- Start Statistics Card-->
        <ul class="all-state-count">
          <li>
            <div class="state-count">
              <span>عدد ملفات الاستيراد </span>
              <span>{{ table_length }}</span>
            </div>
          </li>
        </ul>
        <!-- End Statistics Card-->

        <main class="main-table table-print">
          <!-- start of  header of printSection  -->
          <PrintHead
            :title="'ملفات الاستيراد'"
            :number="table_length"
          ></PrintHead>
          <!-- end of header of printSection  -->
          <v-data-table
            class="thumb"
            :headers="headers"
            :items="rows"
            :loading="lodaing"
            :loading-text="$t('table.loadingData')"
            v-model="selected"
            :single-select="singleSelect"
            item-key="id"
            :items-per-page="rows.length"
            hide-default-footer
          >
            <!-- ================== You Can use any slots you want ================== -->
            <!-- ====== Select row field ====== -->
            <!-- Select no data State -->
            <template v-slot:[`item.index`]="{index}">
              {{ index + 1 }}
            </template>

            <template v-slot:no-data>
              {{ $t("table.noData") }}
            </template>
            <template v-slot:[`item.document_number`]="{item}">
              <!--  -->
              <div class="_actions actions2">
                <span v-if="item.document_number">
                  {{ item.document_number }}
                </span>
                <span v-else> ---- </span>
              </div>
            </template>
            <template v-slot:[`item.bank_image`]="{item}">
              <div class="_actions">
                <a :href="item.bank_image" target="_blank">
                  <v-icon class="show" small>
                    fal fa-eye
                  </v-icon>
                </a>
              </div>
            </template>
            <template v-slot:[`item.status`]="{item}">
              <v-chip color="#d7c100" v-if="item.status == 'pending'">
                منتظر
              </v-chip>
              <v-chip color="green" v-else> تأكيد </v-chip>
            </template>
            <!-- revision status-->
            <template v-slot:[`item.import_containers`]="{item}">
              <v-chip
                color="#7104c9"
                class="mb-1 mt-1"
                v-for="container in item.import_containers"
                :key="container.id"
              >
                {{ container.number }}
              </v-chip>
            </template>
            <!-- Confirm revision-->
            <template v-slot:[`item.cert_number`]="{item}">
              <!--  -->
              <div class="_actions actions2">
                <router-link
                  :to="'/importFiles/single/' + item.id"
                  v-if="!item.cert_number"
                >
                  ----
                </router-link>
                <span v-else> {{ item.cert_number }} </span>
              </div>
            </template>
            <template v-slot:[`item.cert_image`]="{item}">
              <!--  -->
              <div class="_actions actions2">
                <router-link
                  :to="'/importFiles/single/' + item.id"
                  v-if="!item.cert_image"
                >
                  ----
                </router-link>
                <a :href="item.cert_image" target="_blank" v-else>
                  <v-icon class="show" small>
                    fal fa-eye
                  </v-icon>
                </a>
              </div>
            </template>
            <template v-slot:[`item.release_file`]="{item}">
              <!--  -->
              <div class="_actions actions2">
                <router-link
                  :to="'/importFiles/single/' + item.id"
                  v-if="!item.release_file"
                >
                  ----
                </router-link>
                <a :href="item.release_file" target="_blank" v-else>
                  <v-icon class="show" small>
                    fal fa-eye
                  </v-icon>
                </a>
              </div>
            </template>
            <template v-slot:[`item.cert_date`]="{item}">
              <!--  -->
              <div class="_actions actions2">
                <router-link
                  :to="'/importFiles/single/' + item.id"
                  v-if="!item.cert_date"
                >
                  ----
                </router-link>
                <span v-else> {{ item.cert_date }} </span>
              </div>
            </template>
            <!-- Select actions-->
            <template v-slot:[`item.actions`]="{item}">
              <div class="_actions">
                <v-icon class="edit" small @click="editItem(item)">
                  fal fa-edit
                </v-icon>
                <v-icon class="delete" small @click="deleteItem(item)">
                  fal fa-trash
                </v-icon>
              </div>
            </template>

            <!-- ======================== Start Top Section ======================== -->
            <template v-slot:top>
              <!-- Delete dialog -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">{{
                    $t("table.deletedialog.areYouSure")
                  }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="deleteItemConfirm"
                      >{{ $t("table.deletedialog.ok") }}
                      <span
                        class="btn_loader_model"
                        v-if="loadingBtnDialog"
                      ></span
                    ></v-btn>
                    <v-btn color="#F44336" @click="dialogDelete = false">{{
                      $t("table.deletedialog.cancel")
                    }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Description Modal -->
              <DescModal
                :desc="selectedItemDesc"
                :handelModalApperance="modal.descriptionModalIsOpen"
                @close="closeDescModal"
              />

              <NotesModal
                :desc="selectedItemNotes"
                :handelModalApperance="modal.notesModalIsOpen"
                @close="closeNotesModal"
              />
            </template>
            <!-- ======================== End Top Section ======================== -->
          </v-data-table>
        </main>
        <!-- End Main Section -->
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import StatsCard from "@/components/Charts/ChartComponent.vue";
import DescModal from "@/components/modals/DescriptionModal.vue";
import NotesModal from "@/components/modals/NotesModal.vue";
import SearchSection from "@/components/Search/import_files";
export default {
  props: ["id"],

  components: {
    StatsCard,
    DescModal,
    NotesModal,
    SearchSection,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // search
      searchItems: null,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "ملفات الاستيراد",
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "عمليات الإيداع",
          number: "10",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,
      dialogConfirm: false,
      itemtoConfirm: null,
      // ========== Date_Menues
      from_date_menu: false,
      to_date_menu: false,

      // ========== Filter
      filter: {
        name: null,
        safe: [],
        from: null,
        to: null,
      },

      // START:: SELECTED FILE DATA
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // Date_menues
      dateMenu: false,
      debit_dateMenu: false,

      // ========== Loding
      lodaing: false,

      // Fixed_data
      safes: ["foo", "bar", "fizz", "buzz"],
      // Custom Modal Data
      modal: {
        descriptionModalIsOpen: false,
        notesModalIsOpen: false,
      },
      selectedItemDesc: null,
      selectedItemNotes: null,
      table_length: null,
      //Data
      data: {
        cert_num: null,
        date: new Date().toISOString().slice(0, 10),
      },
      // excel data
      excelData: {
        name: "ملفات الاستيراد",
        items: null,
        json_fields: {
          الملف: {
            field: "document_number",
            callback: (value) => {
              if (value) {
                return value;
              } else {
                return "----";
              }
            },
          },
          البوليصة: "bl_number",
          المبلغ: "total",
          الشركة: "company.name",
          الحاويات: {
            field: "import_containers",
            callback: (value) => {
              let finalVal = "<span>";
              value.forEach((val, index) => {
                if (index == value.length - 1) {
                  finalVal += val.number + "</span>" + " ";
                } else {
                  finalVal += val.number + "</span>" + " " + ",";
                }
              });
              return finalVal;
            },
          },
          الميناء: "port.name",
          "تاريخ المستند": "arrival_bank_document_date",
          "رقم الشهاده": {
            field: "cert_number",
            callback: (value) => {
              if (value) {
                return value;
              } else {
                return "----";
              }
            },
          },
          "تاريخ الشهاده": {
            field: "cert_date",
            callback: (value) => {
              if (value) {
                return value;
              } else {
                return "----";
              }
            },
          },
          الحالة: {
            field: "status",
            callback: (value) => {
              if (value == "pending") {
                return "منتظر";
              } else {
                return "مأكد";
              }
            },
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: " الملف",
            value: "document_number",
            align: "center",
            width: 50,
          },

          {
            text: " البوليصة",
            value: "bl_number",
            align: "center",
            width: 50,
          },
          {
            text: "المبلغ",
            value: "total",
            align: "center",
            width: 50,
            sortable: false,
          },
          {
            text: "الشركة",
            value: "company.name",
            align: "center",
            sortable: false,
            width: 80,
          },
          {
            text: "ملف البنك",
            value: "bank_image",
            align: "center",
            sortable: false,
            width: 50,
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
          {
            text: "الحاويات",
            value: "import_containers",
            align: "center",
            sortable: false,
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 80,
          },
          {
            text: "الميناء",
            value: "port.name",
            align: "center",
            sortable: false,
            width: 50,
          },
          {
            text: "تاريخ المستند",
            value: "arrival_bank_document_date",
            align: "center",
            width: 80,
          },
          {
            text: "رقم الشهاده",
            value: "cert_number",
            align: "center",
            sortable: false,
            width: 50,
          },
          {
            text: "ملف الشهادة",
            value: "cert_image",
            align: "center",
            sortable: false,
            width: 50,
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
          {
            text: "ملف الإفراج",
            value: "release_file",
            align: "center",
            sortable: false,
            width: 50,
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
          {
            text: "تاريخ الشهاده",
            value: "cert_date",
            align: "center",
            sortable: false,
            width: 70,
          },

          {
            text: "الحالة",
            value: "status",
            align: "center",
            sortable: false,
            width: 50,
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },

          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
        ];
      }
    },
  },

  methods: {
    // searchTable
    searchTable(items) {
      this.rows = [];
      this.searchItems = items;
      this.setRows();
    },
    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({
        path: this.id ? `/importFiles/${this.id}/add` : `/importFiles/add`,
      });
    },
    showItem(item) {
      this.dialogShow = true;
      this.itemToShow = item;
    },
    editItem(item) {
      this.$router.push({path: "/importFiles/edit/" + item.id});
    },

    // START:: HANDLING SELECTED FILE METHOD
    handelSelectedFile(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD

    // confirm item
    revisionDone(item) {
      this.dialogConfirm = true;
      this.itemtoConfirm = item;
    },
    revisionConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "POST",
        url: `client/walletoperation/review/${this.itemtoConfirm.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogConfirm = false;
          this.$iziToast.success({
            timeout: 2000,
            message: "تم التأكيد بنجاح",
            position: "topRight",
          });
        })
        .catch((err) => {
          this.dialogConfirm = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/import/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // Set Rows
    setRows() {
      this.lodaing = true;
      let myParams = {
        keyword: this.searchItems ? this.searchItems.document_number : null,
        bl_number: this.searchItems ? this.searchItems.bl_number : null,
        company_import_id: this.searchItems ? this.searchItems.companyId : null,
        arrival_bank_document_date: this.searchItems
          ? this.searchItems.arrive_date
          : null,
        port_id: this.searchItems ? this.searchItems.portId : null,
        cert_date: this.searchItems ? this.searchItems.cert_date : null,
        cert_number: this.searchItems ? this.searchItems.cert_num : null,
        total: this.searchItems ? this.searchItems.cert_count : null,
        status: this.searchItems ? this.searchItems.statusType : null,
      };
      this.$axios({
        method: "GET",
        url: "client/import",
        params: myParams,
      })
        .then((res) => {
          this.rows = res.data.data;
          this.table_length = this.rows.length;
          this.excelData.items = this.rows;
          this.lodaing = false;
        })
        .catch((err) => {
          this.lodaing = false;
        });
    },
    setItemDesc(desc) {
      this.selectedItemDesc = desc;
    },
    setItemNotes(notes) {
      this.selectedItemNotes = notes;
    },
    closeDescModal() {
      this.modal.descriptionModalIsOpen = false;
    },
    closeNotesModal() {
      this.modal.notesModalIsOpen = false;
    },
  },

  // ======= hooks
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("containerOperations")
    ) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped></style>
