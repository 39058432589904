<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/companyTransfers"
        search_title="تحويلات الشركات"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("addNew") }}</h4>
        </div>

        <!-- ==== Start Form ==== -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> Client Name</label>
                  <v-autocomplete
                    v-model="data.clientName"
                    :search-input.sync="searchOptions.search_users"
                    :items="users"
                    item-text="name"
                    return-object
                    :hide-no-data="!users"
                    dense
                  ></v-autocomplete>
                  <button class="button_style_1 load_inline" v-if="load_users">
                    <span class="btn_loader"></span>
                  </button>
                </div>
                <h6
                  class="mt-0 mb-0 mx-0 amount-section"
                  :class="{
                    redColor: data.clientName.amount < 0,
                    greenColor: data.clientName.amount > 0,
                  }"
                  v-if="data.clientName"
                >
                  Amount :
                  {{ Number(data.clientName.amount).toLocaleString() }}
                </h6>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.all_amount"
                  />
                  <label for="name_input" class="form-label">Amount </label>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label mb-3">
                  <label class="form-label"> Currency </label>
                  <input
                    type="text"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.currency"
                  />
                </div>
              </div>

              <div class="col-lg-12 py-0">
                <!--Divider-->
                <div class="divider">
                  <h4>Company Data</h4>
                  <v-divider></v-divider>
                </div>
                <!--Divider-->
              </div>
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="accountNum"
                    v-model="data.ibanNumber"
                    @input="emptyBankData"
                    v-on:keydown.enter.prevent="getCompanyData"
                  />
                  <label for="accountNum" class="form-label"
                    >IBAN NO or A/C NO</label
                  >
                </div>
              </div>
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="accountNum"
                    v-model="data.refNum"
                  />
                  <label for="accountNum" class="form-label"
                    >Reference NO</label
                  >
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">{{
                    $t("companies.data.name")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="accountNum"
                    v-model="data.company_name"
                    disabled
                  />
                  <button class="button_style_1 loadSwift" v-if="loadSwift">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>

              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="accountNum"
                    v-model="data.swift_number"
                    disabled
                  />
                  <label for="accountNum" class="form-label">{{
                    $t("companies.data.swiftNumber")
                  }}</label>
                  <button class="button_style_1 loadSwift" v-if="loadSwift">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>

              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="bank"
                    v-model="data.bank_name"
                    disabled
                  />
                  <label for="bank" class="form-label">Bank Name</label>
                  <button class="button_style_1 loadSwift" v-if="loadSwift">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>

              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="bank"
                    v-model="data.country"
                    disabled
                  />
                  <label for="bank" class="form-label">Country</label>
                  <button class="button_style_1 loadSwift" v-if="loadSwift">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>

              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="bank"
                    v-model="data.address"
                    disabled
                  />
                  <label for="bank" class="form-label">Address</label>
                  <button class="button_style_1 loadSwift" v-if="loadSwift">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>

              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">Product</label>
                  <multiselect
                    v-model="data.products"
                    :options="products"
                    label="name"
                    track-by="name"
                    placeholder=""
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                    :multiple="true"
                    disabled
                  >
                  </multiselect>
                  <button class="button_style_1 loadSwift" v-if="loadSwift">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1 m-2"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              إضافة
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- ==== End Form ==== -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // loader
      loading: true,

      load_users: false,
      // search
      searchOptions: {
        search_users: "",
      },
      users: null,

      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.companyTransfers.add"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // Date_menues
      dateMenu: false,
      debit_dateMenu: false,

      add_Company_dialog: false,

      // search_users
      search_users: "",

      // ========== create_data
      data: {
        clientName: "",
        all_amount: null,
        company_name: null,
        company_id: null,
        ibanNumber: null,
        refNum: null,
        swift_number: null,
        bank_name: null,
        country: null,
        address: null,
        products: null,
        currency: null,
      },

      // ========== Fixed_data
      companies: [
        {id: 1, name: "company"},
        {id: 2, name: "company"},
        {id: 3, name: "company"},
      ],
      products: [
        {id: 1, name: "product"},
        {id: 2, name: "product"},
        {id: 3, name: "product"},
      ],
      categories: [],
      banks: [],
      companyLogo: {
        file: null,
        name: null,
        path: null,
      },
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },
      users: [],
      currencies: [],
      loadSwift: false,
    };
  },

  watch: {
    [`searchOptions.search_users`](newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.users = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_users(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_users(newVal);
        } else {
          this.get_filterd_users(newVal);
        }
      }
    },

    [`data.bank`](newVal) {
      this.data.swift_number = newVal.swift_number;
    },

    [`data.swift_number`](newVal) {
      this.data.bank =
        this.banks.find((item) => item.swift_number == newVal) || null;
    },
  },

  methods: {
    // ============== Get Data
    getData() {
      // ===== Categories
      this.$axios({
        method: "GET",
        url: "client/category",
      })
        .then((res) => {
          this.categories = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });

      // ===== Banks
      this.$axios({
        method: "GET",
        url: "client/bank",
      }).then((res) => {
        this.banks = res.data.data;
      });

      // ===== Banks
      this.$axios({
        method: "GET",
        url: "client/company",
      }).then((res) => {
        this.companies = res.data.data;
      });
      this.$axios({
        method: "GET",
        url: `client/currency`,
      })
        .then((res) => {
          this.currencies = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    emptyBankData() {
      this.data.company_name = null;
      this.data.swift_number = null;
      this.data.bank_name = null;
      this.data.country = null;
      this.data.address = null;
      this.data.products = null;
    },
    getCompanyData() {
      this.loadSwift = true;
      this.$axios({
        method: "GET",
        url: "client/company/filter",
        params: {
          number: this.data.ibanNumber,
        },
      })
        .then((res) => {
          if (
            this.data.ibanNumber.length != 0 &&
            res.data.data != null &&
            res.data.is_accepted == true
          ) {
            this.data.company_name = res.data.data.name;
            this.data.company_id = res.data.data.id;
            this.data.swift_number = res.data.data.swift_number;
            this.data.bank_name = res.data.data.bank.name;
            this.data.country = res.data.data.country.name_ar;
            this.data.address = res.data.data.address;
            this.data.products = res.data.data.products;
          } else if (
            this.data.ibanNumber.length != 0 &&
            res.data.data != null &&
            res.data.is_accepted == false
          ) {
            this.$iziToast.warning({
              timeout: 2000,
              message: "الشركة مسجلة و لكن لم يتم الموافقه عليها",
              position: "topCenter",
            });
            this.data.company_name = null;
            this.data.company_id = null;
            this.data.swift_number = null;
            this.data.bank_name = null;
            this.data.country = null;
            this.data.address = null;
            this.data.products = [];
          } else {
            this.$iziToast.error({
              timeout: 2000,
              message: "لا يوجد حساب شركة بهذا الرقم",
              position: "topCenter",
            });
            this.data.company_name = null;
            this.data.company_id = null;
            this.data.swift_number = null;
            this.data.bank_name = null;
            this.data.country = null;
            this.data.address = null;
            this.data.products = [];
          }
          this.loadSwift = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // Get Companies
    getCompanies() {
      this.$axios({
        method: "GET",
        url: `client/company`,
      }).then((res) => {
        this.companies = res.data.data;
      });
    },

    // Get Companies
    getProducts() {
      this.$axios({
        method: "GET",
        url: `client/product`,
      }).then((res) => {
        this.products = res.data.data;
      });
    },

    // get_filterd_users
    get_filterd_users(keyword) {
      this.load_users = true;
      this.$axios({
        method: "GET",
        url: `client/customer/search?keyword=${keyword}`,
      })
        .then((res) => {
          this.users = res.data.data;
          this.load_users = false;
        })
        .catch((err) => {
          this.load_users = false;
        });
    },

    // START:: HANDLING SELECTED FILE METHOD
    handelSelectedFile(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD

    // START:: HANDLING SELECTED FILE METHOD
    handelCompanyLogo(e) {
      this.companyLogo.file = e.target.files[0];
      this.companyLogo.name = e.target.files[0].name;
      this.companyLogo.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD

    // START:: HANDLING SELECTED FILE METHOD
    handelCommerceLogo(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD

    // Validate Data
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.clientName) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة إسم العميل",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.all_amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة المبلغ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.currency) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إختيار العملة ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.ibanNumber) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة رقم الحساب او ال iban",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.submitData();
          return;
        }
      }
    },
    emptyFormData() {
      this.data.clientName = null;
      this.data.all_amount = null;
      this.data.currency = null;
      this.data.ibanNumber = null;
      this.data.company_id = null;
      this.data.refNum = null;
      this.data.company_name = null;
      this.data.swift_number = null;
      this.data.bank_name = null;
      this.data.country = null;
      this.data.address = null;
      this.data.products = null;
    },
    // Submit Data
    submitData() {
      const submit_data = new FormData();
      submit_data.append("balance_customer_id", this.data.clientName.id);
      submit_data.append("amount", this.data.all_amount);
      submit_data.append("currency", this.data.currency);
      submit_data.append("company_id", this.data.company_id);
      submit_data.append("reference", this.data.refNum);
      this.$axios({
        method: "POST",
        url: "client/company_transfer",
        data: submit_data,
      })
        .then(() => {
          this.btnIsLoading = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "topRight",
          });
          this.emptyFormData();
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
  },

  created() {
    this.getData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      userType.includes("confirm_company_actions") &&
      !userType.includes("company_actions")
    ) {
      this.$router.push("/enterpriceActions");
    } else if (
      !userType.includes("superadmin") &&
      !userType.includes("company_actions")
    ) {
      this.$router.push("/");
    }
  },
};
</script>
