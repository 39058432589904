<template>
  <div>
    <Loader v-if="loading"></Loader>

    <div class="wrraper" v-else>
      <Breadcrumb :items="items" :show_print="true" :excelFile="true" :excelData="excelData"/>

      <SearchSection
        @filterTable="searchTable"
        title="صندوق الواردات"
      ></SearchSection>

      <Notfound v-if="!searchItems"></Notfound>

      <div v-else>
        <!-- Start Statistics Card-->
        <ul class="all-state-count">
          <li>
            <div class="state-count">
              <span> عدد صندوق الواردات :</span>
              <span> {{ table_length }} </span>
            </div>
          </li>
        </ul>
        <!-- End Statistics Card-->
        <div class="table-print">
          <main class="main-table">
            <!-- start of  header of printSection  -->
            <PrintHead
              :title="'صندوق الواردات'"
              :number="table_length"
            ></PrintHead>
            <!-- end of header of printSection  -->
            <v-data-table
              class="thumb"
              :headers="headers"
              :items="rows"
              :search="search"
              :loading="lodaing"
              :loading-text="$t('table.loadingData')"
              v-model="selected"
              :items-per-page="rows.length"
              :single-select="singleSelect"
              item-key="id"
              hide-default-footer
            >
              <!-- ================== You Can use any slots you want ================== -->
              <!-- ====== Select row field ====== -->

              <!-- Select no data State -->
              <template v-slot:no-data>
                {{ $t("table.noData") }}
              </template>
              <template v-slot:[`item.index`]="{ index }">
                  {{ index + 1 }}
                </template>
              <template v-slot:[`item.extra_amount_before`]="{ item }">
                <span :class="{ redColor: item.extra_amount_before < 0, greenColor: item.extra_amount_before > 0 }">
                  {{ item.extra_amount_before }}
                </span>
              </template>
              <template v-slot:[`item.extra_amount_after`]="{ item }">
                <span :class="{ redColor: item.extra_amount_after < 0, greenColor: item.extra_amount_after > 0 }">
                  {{ item.extra_amount_after }}
                </span>
              </template>
              <template v-slot:[`item.type`]="{ item }">
                <span v-if="item.type == 'transfer'"> تحويل خارجي </span>
                <span v-if="item.type == 'in'"> إيداع </span>
                <span v-if="item.type == 'out'"> سحب </span>
                <span v-if="item.type == 'bank_in'"> إيداع بنكي </span>
                <span v-if="item.type == 'bank_out'"> سحب بنكي </span>
              </template>
              <template v-slot:[`item.desc`]="{ item }">
                <div class="_actions">
                  <v-icon
                    class="show"
                    small
                    @click="
                      modal.descriptionModalIsOpen = true;
                      setItemDesc(item.desc);
                    "
                  >
                    fal fa-eye
                  </v-icon>
                </div>
              </template>
              <template>
                <!-- Row -->
                <v-row>
                  <!-- Add & Delete -->
                  <v-col cols="12" sm="4"> </v-col>
                </v-row>
              </template>
              <!-- ======================== End Top Section ======================== -->
            </v-data-table>
          </main>
          <div class="showMoney" v-if="total_expence">
            <ul class="new_ul">
              <li v-for="item in total_expence" :key="item.currency_id">
                <div class="state-count">
                  <span>الرصيد :</span>
                  <span :class="{ redColor: item.total < 0, greenColor: item.total > 0 }">
                    {{ Number(item.total).toLocaleString() }}
                    {{ item.currency.name }}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- End Main Section -->
      </div>
    </div>
    <DescModal
      :desc="selectedItemDesc"
      :handelModalApperance="modal.descriptionModalIsOpen"
      @close="closeDescModal"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "@/components/Charts/ChartComponent.vue";
import SearchSection from "@/components/Search/inbox-balances";
import DescModal from "@/components/modals/DescriptionModal.vue";
export default {
  name: "LockMovement",

  components: {
    StatsCard,
    SearchSection,
    DescModal,
  },

  props: ["id"],

  data() {
    return {
      // search
      searchItems: null,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "صندوق الواردات",
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "الرصيد الكلي",
          number: "10",
        },
        {
          id: 2,
          icon: "fal fa-images",
          title: "الرصيد بالريال",
          number: "10",
        },
        {
          id: 3,
          icon: "fal fa-images",
          title: "الرصيد بالجنيه",
          number: "10",
        },
      ],

      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,
      // Custom Modal Data
      modal: {
        descriptionModalIsOpen: false,
      },
      selectedItemDesc: null,

      // ========== Loding
      lodaing: false,
      table_length: null,
      currencies_amount: null,
      total_expence: null,
       // excel data
      excelData: {
        name: "صندوق الواردات",
        items: null,
        json_fields: {
          "حساب العميل": "customer.name",
          "حساب الشركة": "company.name",
          "حساب المؤسسة": "account.name",
          "مبلغ العملية": {
            field: "paid",
            callback: (value) => {
              return Number(value).toLocaleString();
            },
          },
          "الرصيد قبل العملية": {
            field: "extra_amount_before",
            callback: (value) => {
              return Number(value).toLocaleString();
            },
          },
          "الرصيد بعد العملية": {
            field: "extra_amount_after",
            callback: (value) => {
              return Number(value).toLocaleString();
            },
          },
          "العملة": "currency",
          "تاريخ العملية": "created_at",
          "الوصف": "desc",
        },
        footer: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: "حساب العميل",
            value: "customer.name",
            align: "center",
            width: 150,
          },
          {
            text: "حساب الشركة",
            value: "company.name",
            align: "center",
            width: 150,
          },
          {
            text: "حساب المؤسسة",
            value: "account.name",
            align: "center",
            width: 150,
          },

          {
            text: "مبلغ العملية",
            value: "paid",
            sortable: false,
            align: "center",
            width: 100,
          },
          {
            text: "الرصيد قبل العملية",
            value: "extra_amount_before",
            sortable: false,
            align: "center",
            width: 120,
          },
          {
            text: "الرصيد بعد العملية",
            value: "extra_amount_after",
            sortable: false,
            align: "center",
            width: 120,
          },
          {
            text: "العملة",
            value: "currency",
            align: "center",
            width: 80,
          },

          {
            text: "تاريخ العملية",
            value: "created_at",
            sortable: false,
            align: "center",
            width: 100,
          },
          {
            text: "الوصف",
            value: "desc",
            sortable: false,
            align: "center",
            width: 50,
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
        ];
      }
    },
  },

  methods: {
    // searchTable
    searchTable(items) {
      this.rows = [];
      this.searchItems = items;
      this.setRows();
    },
    setItemDesc(desc) {
      this.selectedItemDesc = desc;
    },
    closeDescModal() {
      this.modal.descriptionModalIsOpen = false;
    },
    // ================ Set Rows
    setRows() {
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/extra",
        params: {
          customer: this.searchItems ? this.searchItems.username : null,
          company: this.searchItems ? this.searchItems.company : null,
          from_date: this.searchItems ? this.searchItems.from : null,
          to_date: this.searchItems ? this.searchItems.to : null,
        },
      })
        .then((res) => {
          this.currencies_amount = res.data.currencies_amount;
          this.rows = res.data.data;
          this.table_length = this.rows.length;
          this.excelData.items = this.rows;
          if (res.data.total.length > 0) {
            this.total_expence = res.data.total;
          }
          this.excelData.footer = [];
          res.data.total.forEach((val) => {
            this.excelData.footer.push("الرصيد : " + " " + Number(val.total).toLocaleString() + val.currency.name) 
          });
          this.lodaing = false;
        })
        .catch((err) => {
          this.lodaing = false;
        });
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>

