<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="row" v-else>
      <div class="col-md-4">
        <profile-card
          :link="`/clients/show/${id}/info`"
          :icon="'fal fa-user'"
          title="البيانات الشخصية"
          :color="'#C2C6DC'"
          :bgColor="'rgba(31, 37, 54, 0.5)'"
        ></profile-card>
      </div>

      <div class="col-md-4">
        <profile-card
          :link="`/client_accounts/${id}`"
          :icon="'fal fa-user'"
          title="حسابات العميل"
          :color="'#C2C6DC'"
          :bgColor="'rgba(31, 37, 54, 0.5)'"
        ></profile-card>
      </div>

      <div class="col-md-4">
        <profile-card
          :link="`/deposits/${id}`"
          :icon="'fal fa-user'"
          title="عمليات الايداع"
          :color="'#C2C6DC'"
          :bgColor="'rgba(31, 37, 54, 0.5)'"
        ></profile-card>
      </div>

      <div class="col-md-4">
        <profile-card
          :link="`/withdraws/${id}`"
          :icon="'fal fa-user'"
          title="عمليات السحب"
          :color="'#C2C6DC'"
          :bgColor="'rgba(31, 37, 54, 0.5)'"
        ></profile-card>
      </div>

      <div class="col-md-4">
        <profile-card
          :link="`/service_operations/${id}/deposit`"
          :icon="'fal fa-user'"
          title="عمليات إيداع السرفيس"
          :color="'#C2C6DC'"
          :bgColor="'rgba(31, 37, 54, 0.5)'"
        ></profile-card>
      </div>

      <div class="col-md-4">
        <profile-card
          :link="`/service_operations/${id}/withdraw`"
          :icon="'fal fa-user'"
          title="عمليات سحب السرفيس"
          :color="'#C2C6DC'"
          :bgColor="'rgba(31, 37, 54, 0.5)'"
        ></profile-card>
      </div>

      <div class="col-md-4">
        <profile-card
          :link="`/bank_deposits/${id}`"
          :icon="'fal fa-user'"
          title="عمليات الايداع البنكي"
          :color="'#C2C6DC'"
          :bgColor="'rgba(31, 37, 54, 0.5)'"
        ></profile-card>
      </div>

      <div class="col-md-4">
        <profile-card
          :link="`/bank_withdraws/${id}`"
          :icon="'fal fa-user'"
          title="عمليات السحب البنكي"
          :color="'#C2C6DC'"
          :bgColor="'rgba(31, 37, 54, 0.5)'"
        ></profile-card>
      </div>

      <div class="col-md-4">
        <profile-card
          :link="`/bank_transfares/${id}`"
          :icon="'fal fa-user'"
          title="التحويلات الخارجية"
          :color="'#C2C6DC'"
          :bgColor="'rgba(31, 37, 54, 0.5)'"
        ></profile-card>
      </div>

      <div class="col-md-4">
        <profile-card
          :link="`/debits/${id}`"
          :icon="'fal fa-user'"
          title="المديونيات"
          :color="'#C2C6DC'"
          :bgColor="'rgba(31, 37, 54, 0.5)'"
        ></profile-card>
      </div>

      <div class="col-md-4">
        <profile-card
          :link="`/deliverables/${id}`"
          :icon="'fal fa-user'"
          title="التسليمات"
          :color="'#C2C6DC'"
          :bgColor="'rgba(31, 37, 54, 0.5)'"
        ></profile-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      // loader
        loading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>

