<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb :items="items" />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("edit") }}</h4>
        </div>

        <!-- ==== Start Form ==== -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-lg-12 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label">إسم العميل</label>
                  <v-autocomplete
                    v-model="data.username"
                    :search-input.sync="search_users"
                    :items="users"
                    item-text="name"
                    return-object
                    dense
                  ></v-autocomplete>
                </div>
                <h6
                  class="mt-0 mb-0 mx-0 amount-section"
                  :class="{
                    redColor: data.username.amount < 0,
                    greenColor: data.username.amount > 0,
                  }"
                  v-if="data.username"
                >
                  الرصيد :
                  {{ Number(data.username.amount).toLocaleString() }}
                </h6>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.amount"
                  />
                  <label for="name_input" class="form-label">{{
                    $t("forms.labels.amount")
                  }}</label>
                </div>
                <div class="col-lg-3 py-0">
                  <div class="input_wrapper top_label">
                    <input
                      type="text"
                      class="form-control"
                      @input="helper_checkIfInputIsEmpty"
                      v-model.trim="data.transfare_amount"
                    />
                    <label for="name_input" class="form-label">{{
                      $t("forms.labels.transfare_amount")
                    }}</label>
                  </div>
                </div>
                <div class="col-lg-3 py-0">
                  <div class="input_wrapper top_label">
                    <input
                      type="text"
                      class="form-control"
                      @input="helper_checkIfInputIsEmpty"
                      v-model.trim="data.comission"
                    />
                    <label for="name_input" class="form-label">{{
                      $t("forms.labels.comission")
                    }}</label>
                  </div>
                </div>
                <div class="col-lg-3 py-0">
                  <div class="input_wrapper top_label">
                    <input
                      type="text"
                      readonly
                      class="form-control"
                      @input="helper_checkIfInputIsEmpty"
                      v-model.trim="total"
                    />
                    <label for="name_input" class="form-label">{{
                      $t("forms.labels.total")
                    }}</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 py-0" v-if="currencies">
                <div class="input_wrapper top_label">
                  <label class="form-label"> العملة </label>
                  <multiselect
                    v-model="data.currency"
                    :options="currencies"
                    label="name_ar"
                    track-by="name_ar"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">{{
                    $t("forms.labels.safe")
                  }}</label>
                  <multiselect
                    v-model="data.safe"
                    :options="safes"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label date_input">
                  <label class="form-label">{{
                    $t("forms.labels.date")
                  }}</label>
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        v-model="data.date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="data.date"
                      @input="dateMenu = false"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <label for="textarea_1" class="form-label">{{
                    $t("forms.labels.desc")
                  }}</label>
                  <textarea
                    v-on:keydown.enter.prevent="validateCreateForm"
                    v-model="data.desc"
                    class="form-control"
                    id="textarea_1"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <label for="textarea_1" class="form-label">{{
                    $t("forms.labels.notes")
                  }}</label>
                  <textarea
                    v-on:keydown.enter.prevent="validateCreateForm"
                    v-model="data.notes"
                    class="form-control"
                    id="textarea_1"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="buttons_wrapper">
              <button
                class="button_style_1"
                :class="{buttonIndex: btnIsLoading}"
                :disabled="btnIsLoading"
              >
                {{ $t("forms.submit") }}
                <span class="btn_loader" v-if="btnIsLoading"></span>
              </button>
            </div>
          </div>
        </form>
        <!-- ==== End Form ==== -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Edit",

  props: ["id", "type"],

  data() {
    return {
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "عمليات السيرفس",
          disabled: false,
          href: "/",
        },
        {
          text: "تعديل عملية سيرفس",
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // Date_menues
      dateMenu: false,
      debit_dateMenu: false,

      // search_users
      search_users: "",

      // ========== create_data
      data: {
        username: null,
        amount: 0,
        transfare_amount: 0,
        comission: 0,
        total: 0,
        date: null,
        currency: null,
        safe: null,
        desc: null,
        notes: null,
      },

      // ========== Fixed_data
      currencies: [],
      safes: [],
      users: [],
    };
  },

  computed: {
    total() {
      const num =
        this.data.amount * this.data.comission * this.data.transfare_amount;
      return parseFloat(num.toFixed()).toLocaleString();
    },
  },

  watch: {
    [`search_users`](newVal, oldVal) {
      if (!newVal & (oldVal == "")) {
        this.get_filterd_users(oldVal);
      } else if ((newVal == "") & (oldVal != "")) {
        this.get_filterd_users(newVal);
      } else {
        this.get_filterd_users(newVal);
      }
    },
  },

  methods: {
    // get_filterd_users
    get_filterd_users(keyword) {
      this.$axios({
        method: "GET",
        url: `client/customer/search?keyword=${keyword}`,
      }).then((res) => {
        this.users = res.data.data;
      });
    },

    // Get Data
    getSafes() {
      // START:: GET SAFES
      this.$axios({
        method: "GET",
        url: `client/wallet`,
      })
        .then((res) => {
          this.safes = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
      // END:: GET SAFES
    },

    getCurrencies() {
      // START:: GET SAFES
      this.$axios({
        method: "GET",
        url: `client/currency`,
      })
        .then((res) => {
          this.currencies = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
      // END:: GET SAFES
    },

    getDataToEdit() {
      // START:: GET SAFES
      this.$axios({
        method: "GET",
        url: `client/service_operation/${this.id}`,
      })
        .then((res) => {
          this.data.username = res.data.data.customer.id;
          this.data.amount = res.data.data.amount;
          this.data.transfare_amount = res.data.data.rate;
          this.data.comission = res.data.data.commission;
          this.data.currency = res.data.data.currency;
          this.data.safe = res.data.data.wallet;
          this.data.date = res.data.data.date;
          this.data.desc = res.data.data.desc;
          this.data.notes = res.data.data.notes;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
      // END:: GET SAFES
    },

    // Validate Data
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.username) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب تحديد إسم العميل",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.amount"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.transfare_amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.transfare_amount"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.comission) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.comission"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.total) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.total"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.safe) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.safe"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.date) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب تحديد تاريخ العملية",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.desc) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.desc"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.submitData();
          return;
        }
      }
    },

    // Submit Data
    submitData() {
      const submit_data = {};

      // START:: APPEND REQUEST DATA
      if (this.type == "deposit") {
        submit_data["type"] = "in";
      } else {
        submit_data["type"] = "out";
      }
      submit_data["customer_id"] = this.data.username;
      submit_data["wallet_id"] = this.data.safe.id;
      submit_data["amount"] = this.data.amount;
      submit_data["rate"] = this.data.transfare_amount;
      submit_data["commission"] = this.data.comission;
      submit_data["total"] = this.data.total;
      submit_data["date"] = this.data.date;
      submit_data["desc"] = this.data.desc;
      submit_data["notes"] = this.data.notes;
      // submit_data.append("customer_id", this.data.username);
      // submit_data.append("wallet_id", this.data.safe.id);
      // submit_data.append("amount", this.data.amount);
      // submit_data.append("rate", this.data.transfare_amount);
      // submit_data.append("commission", this.data.comission);
      // submit_data.append("total", this.total);
      // submit_data.append("date", this.data.date);
      // submit_data.append("desc", this.data.desc);
      // submit_data.append("notes", this.data.notes);
      // END:: APPEND REQUEST DATA

      this.$axios({
        method: "PUT",
        url: `client/service_operation/${this.id}`,
        data: submit_data,
      })
        .then((res) => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("editSuccess"),
            position: "topRight",
          });
          this.$router.back();
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
  },

  created() {
    this.getCurrencies();
    this.getSafes();
    this.getDataToEdit();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>
