<template>
  <div class="create_wrapper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->
    <div class="custom_card">
      <!-- START:: CARD TITLE -->
      <div class="card-header">
        <h4 class="card-title">فتح مؤسسة</h4>
      </div>
      <!-- END:: CARD TITLE -->

      <!-- START:: CREATE FORM WRAPPER -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <!-- START:: INPUT WRAPPER -->
            <div class="col-lg-6 py-0">
              <!-- START:: TITLE INPUT -->
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  id="name_input_ar"
                  @input="checkIfInputIsEmpty"
                  v-model.trim="createData.nameAr"
                />
                <label for="name_input_ar" class="form-label">{{
                  $t("comunities.data.nameAr")
                }}</label>
              </div>
              <!-- END:: TITLE INPUT -->
            </div>
            <!-- END:: INPUT WRAPPER -->

            <!-- START:: INPUT WRAPPER -->
            <div class="col-lg-6 py-0">
              <!-- START:: TITLE INPUT -->
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  id="name_input_en"
                  @input="checkIfInputIsEmpty"
                  v-model.trim="createData.nameEn"
                />
                <label for="name_input_en" class="form-label">{{
                  $t("comunities.data.nameEn")
                }}</label>
              </div>
              <!-- END:: TITLE INPUT -->
            </div>
            <!-- END:: INPUT WRAPPER -->

            <!-- START:: INPUT WRAPPER -->
            <div class="col-lg-6 py-0">
              <!-- START:: NUMBER INPUT ACCOUNT NUMBER-->
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  id="address_ar"
                  v-model="createData.addressAr"
                  @input="checkIfInputIsEmpty"
                />
                <label for="address_ar" class="form-label">{{
                  $t("comunities.data.addressAr")
                }}</label>
              </div>
              <!-- END:: NUMBER INPUT ACCOUNT NUMBER-->
            </div>
            <!-- END:: INPUT WRAPPER -->

            <!-- START:: INPUT WRAPPER -->
            <div class="col-lg-6 py-0">
              <!-- START:: NUMBER INPUT ACCOUNT NUMBER-->
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  id="address_en"
                  v-model="createData.addressEn"
                  @input="checkIfInputIsEmpty"
                />
                <label for="address_en" class="form-label">{{
                  $t("comunities.data.addressEn")
                }}</label>
              </div>
              <!-- END:: NUMBER INPUT ACCOUNT NUMBER-->
            </div>
            <!-- END:: INPUT WRAPPER -->
          </div>
        </div>

        <div class="buttons_wrapper">
          <!-- START:: BUTTON -->
          <button
            class="button_style_1"
            :class="{buttonIndex: btnIsLoading}"
            :disabled="btnIsLoading"
          >
            {{ $t("Forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
          <!-- END:: BUTTON -->
        </div>
      </form>
      <!-- END:: CREATE FORM WRAPPER -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("mainHome"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("comunities.title"),
          disabled: false,
          href: "/allcomunities",
        },
        {
          text: this.$t("addNew"),
          disabled: true,
          href: "",
        },
      ],

      // START:: BUTTON LOADER HANDLING DATA
      btnIsLoading: false,
      // END:: BUTTON LOADER HANDLING DATA

      // START:: SINGLE SELECT DATA
      single_select_options: [
        {id: 1, name: "طالب"},
        {id: 2, name: "ولي امر"},
        {id: 3, name: "معلم"},
        {id: 4, name: "عام"},
      ],
      // END:: SINGLE SELECT DATA

      // START:: CREATE DATA
      createData: {
        nameAr: null,
        nameEn: null,
        addressAr: null,
        addressEn: null,
      },
      // END:: CREATE DATA
    };
  },

  methods: {
    // START:: Uplode Avatar
    uplodeImg_1(obj) {
      this.createData.avatar = obj;
    },
    // END:: Uplode Avatar

    // START:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)
    checkIfInputIsEmpty(e) {
      let inputElement = e.currentTarget;
      if (inputElement.value.length > 0) {
        inputElement.classList.add("not_empty");
      } else {
        inputElement.classList.remove("not_empty");
      }
    },
    // END:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)

    // START:: VALIDATE CREATE FORM
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;

        if (!this.createData.nameAr) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("comunities.validation.nameAr"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.createData.nameEn) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("comunities.validation.addressEn"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.btnIsLoading = true;
          const data = new FormData();
          data.append("ar[name]", this.createData.nameAr);
          data.append("en[name]", this.createData.nameEn);
          data.append("ar[desc]", this.createData.addressAr);
          data.append("en[desc]", this.createData.addressEn);
          this.$axios
            .post("client/enterprise", data)
            .then(() => {
              this.btnIsLoading = false;
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("global.addSuccess"),
                position: "topRight",
              });
              this.$router.push({path: "/allComunities"});
            })
            .catch((err) => {
              this.btnIsLoading = false;
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "topRight",
              });
            });
        }
      }
    },
    // END:: VALIDATE CREATE FORM
  },
};
</script>
