<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="sentInvoice create_wrapper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/bills-export"
        search_title="فواتير شغل المؤسسات"
      />
      <!-- End Breadcrumb -->
      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">إنشاء فاتورة جديده</h4>
        </div>
        <!-- Start Statistics Card-->
        <div class="bill-details">
          <div class="divider">
            <h4>بيانات الشركة</h4>
            <hr
              role="separator"
              aria-orientation="horizontal"
              class="v-divider theme--dark"
            />
          </div>
          <ul class="all-state-count">
            <li>
              <div class="state-count">
                <span>إسم الشركة :</span>
                <span>sssss</span>
              </div>
            </li>
            <li>
              <div class="state-count">
                <span> عنوان الشركة :</span>
                <span>ssssss</span>
              </div>
            </li>
            <li>
              <div class="state-count">
                <span>رقم الحساب :</span>
                <span>ssss</span>
              </div>
            </li>
            <li>
              <div class="state-count">
                <span>رقم ال SWIFT :</span>
                <span>ssss</span>
              </div>
            </li>
            <li>
              <div class="state-count">
                <span>إسم البنك :</span>
                <span>ssss</span>
              </div>
            </li>
          </ul>
        </div>
        <!-- End Statistics Card-->
        <!-- Start Statistics Card-->
        <div class="bill-details">
          <div class="divider">
            <h4>بيانات المؤسسه</h4>
            <hr
              role="separator"
              aria-orientation="horizontal"
              class="v-divider theme--dark"
            />
          </div>
          <ul class="all-state-count">
            <li>
              <div class="state-count">
                <span>إسم المؤسسه :</span>
                <span>sssss</span>
              </div>
            </li>
          </ul>
        </div>
        <!-- End Statistics Card-->
        <!-- Start Statistics Card-->
        <div class="bill-details">
          <div class="divider">
            <h4>بيانات العميل</h4>
            <hr
              role="separator"
              aria-orientation="horizontal"
              class="v-divider theme--dark"
            />
          </div>
          <ul class="all-state-count">
            <li>
              <div class="state-count">
                <span>إسم العميل :</span>
                <span>sssss</span>
              </div>
            </li>
          </ul>
        </div>
        <!-- End Statistics Card-->
        <!-- Start Statistics Card-->
        <div class="bill-details">
          <div class="divider">
            <h4>تفاصيل العمليه</h4>
            <hr
              role="separator"
              aria-orientation="horizontal"
              class="v-divider theme--dark"
            />
          </div>
          <ul class="all-state-count">
            <li>
              <div class="state-count">
                <span> السلعه :</span>
                <span>sssss</span>
              </div>
            </li>
            <li>
              <div class="state-count">
                <span> المبلغ الكلي :</span>
                <span>sssss</span>
              </div>
            </li>
          </ul>
        </div>
        <!-- End Statistics Card-->
        <div class="col-lg-12 py-0">
          <div class="divider">
            <h4>ختم الشركة</h4>
            <hr
              role="separator"
              aria-orientation="horizontal"
              class="v-divider theme--dark"
            />
          </div>
        </div>
        <div class="col-lg-12 py-0">
          <div class="input_wrapper top_label file_input">
            <span class="file_input_label"> صورة ختم الشركة </span>
            <label
              for="reseat_image"
              class="form-label"
              v-if="!selectedFile.file"
            ></label>
            <label for="reseat_image" class="form-label" v-else>
              {{ selectedFile.name }}
            </label>
            <input
              type="file"
              class="form-control"
              id="reseat_image"
              @change="handelSelectedFile"
            />
          </div>
        </div>
        <!-- ==== Start Form ==== -->
        <form>
          <div class="divider">
            <h4>المنتجات</h4>
            <hr
              role="separator"
              aria-orientation="horizontal"
              class="v-divider theme--dark"
            />
          </div>
          <div class="container">
            <div
              class="row justify-content-between"
              v-for="(row, index) in data.my_rows"
              :key="row.id"
            >
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">إختر المنتج</label>
                  <multiselect
                    v-model="row.product"
                    @input="updatePrice(row.product.price, index)"
                    :options="products"
                    label="name"
                    track-by="id"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    @keyup="checkLimit(index)"
                    type="text"
                    class="form-control"
                    v-model.trim="row.quantity"
                  />
                  <label for="name_input" class="form-label">الكمية </label>
                </div>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="row.price"
                    readonly
                  />
                  <label for="name_input" class="form-label">سعر المنتج</label>
                </div>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    :value="
                      parseFloat(
                        (row.price * row.quantity).toFixed()
                      ).toLocaleString()
                    "
                    readonly
                  />
                  <label for="name_input" class="form-label">المجموع</label>
                </div>
              </div>
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1 ml-4"
              type="button"
              @click="appendRow"
            >
              إضافة منتج أخر
            </button>
            <button
              type="button"
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
              @click="generateRandomStyle"
            >
              إنشاء فاتوره
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- ==== End Form ==== -->
      </div>
      <!-- print section -->
      <Bill
        :myStyles="myStyles"
        :rows="data.my_rows"
        :sumAmount="sumAmount"
        :companyLogo="selectedFile.path"
      ></Bill>
    </div>
  </div>
</template>

<script>
import Bill from "./bill.vue";
export default {
  data() {
    return {
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "إنشاء فاتوره جديده",
          disabled: true,
          href: "",
        },
      ],
      // ========== Loading
      btnIsLoading: false,
      // products rows
      data: {
        my_rows: [
          {
            product: null,
            quantity: 1,
            price: 0,
          },
        ],
      },
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },
      amount: 10000,

      // Injected From Api At Create
      products: [],
      // create style of invoice
      myStyles: null,
      randomData: {
        align: ["flex-start", "center", "flex-end"],
        color: [
          "#333",
          "#777",
          "#000",
          "#915719",
          "#911972",
          "#4247f3",
          "#5e0000",
          "#f00000",
        ],
        border: ["dashed", "dotted", "solid", "double"],
        directionCol: ["column", "column-reverse"],
        directionRow: ["row", "row-reverse"],
        family: ["Amiri", "Cairo", "IBM Plex Sans Arabic", "Tajawal"],
        order: Math.ceil(Math.random() * 10),
        background: ["#ffffff", "#f5f5f5"],
      },
    };
  },
  components: {
    Bill,
  },
  methods: {
    // start of Get Data
    getData() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "client/company_work/enterprise/" + this.$route.params.id,
      })
        .then((res) => {
          console.log(res.data.data);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // end of Get Data
    // START:: HANDLING SELECTED FILE METHOD
    handelSelectedFile(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD
    updatePrice(price, index) {
      this.data.my_rows[index].price = price;
    },

    checkLimit(index) {
      if (this.sumAmount > this.amount) {
        this.data.my_rows[index].quantity = 1;
        this.$iziToast.error({
          timeout: 2000,
          message: `لا يمنكن تجاوز الحد الأقصي: ${this.amount}`,
          position: "bottomRight",
        });
      }
    },

    appendRow() {
      this.data.my_rows.push({
        product: null,
        quantity: 1,
        price: 0,
      });
    },
    // create style of invoices
    createRandomIndex(arr) {
      return Math.floor(Math.random() * arr.length);
    },

    createRandomValues() {
      return {
        color: this.randomData.color[
          this.createRandomIndex(this.randomData.color)
        ],
        align: this.randomData.align[
          this.createRandomIndex(this.randomData.align)
        ],
        family:
          this.randomData.family[
            this.createRandomIndex(this.randomData.family)
          ] + ", serif",
        border: this.randomData.border[
          this.createRandomIndex(this.randomData.border)
        ],
        order: this.randomData.order[
          this.createRandomIndex(this.randomData.order)
        ],
        directionCol: this.randomData.directionCol[
          this.createRandomIndex(this.randomData.directionCol)
        ],
        directionRow: this.randomData.directionRow[
          this.createRandomIndex(this.randomData.directionRow)
        ],
        background: this.randomData.background[
          this.createRandomIndex(this.randomData.background)
        ],
      };
    },
    generateRandomStyle() {
      if (this.selectedFile.name == null) {
        this.$iziToast.error({
          timeout: 2000,
          message: `يرجي اضافة صورة ختم الشركة `,
          position: "bottomRight",
        });
      }
      else if (this.data.my_rows[0].product == null) {
        this.$iziToast.error({
          timeout: 2000,
          message: `يرجي إختيار منتج`,
          position: "bottomRight",
        });
      }
      else {
        this.btnIsLoading = true;
        this.myStyles = {
          title: this.createRandomValues(),
          address: this.createRandomValues(),
          flex_section_1: this.createRandomValues(),
          invoiceInfo: this.createRandomValues(),
          invoice: this.createRandomValues(),
          footer: this.createRandomValues(),
          table: this.createRandomValues(),
          accountDetails: this.createRandomValues(),
          thead: this.createRandomValues(),
          tr_1: this.createRandomValues(),
          tr_2: this.createRandomValues(),
        };
        setTimeout(() => {
          this.printInvoice();
          this.btnIsLoading = false;
        }, 500);
        
      }
    },
    printInvoice() {
      var iframePrint = document.querySelector(".iframePrint");
      var iframeHead = document.head.innerHTML;
      var printContents = document.querySelector(".invoice").innerHTML;
      iframePrint.contentWindow.document.write(iframeHead + printContents);
      setTimeout(() => {
        window.frames["printf"].print();
        iframePrint.contentWindow.document.close();
      }, 500);
    },
  },

  computed: {
    sumAmount() {
      let sum = 0;
      for (let row of this.data.my_rows) {
        sum += row.price * row.quantity;
      }
      return +sum;
    },
  },

  created() {
    this.getData();
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("lock_and_deals")
    ) {
      this.$router.push("/");
    }
    this.products = [
      {
        id: 1,
        name: "product one",
        price: 200,
      },
      {
        id: 2,
        name: "product tow",
        price: 4200,
      },
    ];
  },
};
</script>
