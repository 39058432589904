<template>
  <div class="search-section">
    <div class="divider">
      <h4>بحث في {{ title }}</h4>
      <hr
        role="separator"
        aria-orientation="horizontal"
        class="v-divider theme--dark"
      />
    </div>

    <div class="row">
      <div class="col-lg-3 py-0">
        <div class="input_wrapper top_label auto_complete">
          <label class="form-label"> حساب بنك دبي</label>
          <v-autocomplete
            v-model="searchData.user"
            :search-input.sync="searchOptions.search_users"
            :items="users"
            item-text="name"
            return-object
            @input="addUserData(searchData.user)"
            @change="$emit('filterTable', searchData)"
            :hide-no-data="!users"
            dense
          ></v-autocomplete>
          <button class="button_style_1 load_inline" v-if="load_users">
            <span class="btn_loader"></span>
          </button>
        </div>
      </div>

      <div class="col-lg-3 py-0">
        <div class="input_wrapper top_label mb-3">
          <label class="form-label"> نوع العملية </label>
          <multiselect
            v-model="searchData.type"
            :options="searchOptions.types"
            label="name"
            track-by="id"
            @input="
              chooseLock(searchData.type), $emit('filterTable', searchData)
            "
            placeholder=" "
            :searchable="true"
            :allow-empty="true"
            :show-labels="false"
          >
          </multiselect>
        </div>
      </div>

      <div class="col-lg-3 py-0">
        <div class="input_wrapper top_label date_input">
          <label class="form-label">من</label>
          <v-menu
            ref="to_date_menu"
            v-model="searchOptions.from_date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="searchData.from"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchData.from"
              @input="searchOptions.from_date_menu = false"
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>
        </div>
      </div>

      <div class="col-lg-3 py-0">
        <div class="input_wrapper top_label date_input">
          <label class="form-label">إلي</label>
          <v-menu
            ref="to_date_menu"
            v-model="searchOptions.to_date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="searchData.to"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchData.to"
              @input="searchOptions.to_date_menu = false"
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>
        </div>
      </div>

      <div class="col-lg-12 text-center py-0">
        <button
          class="button_style_1"
          @click="$emit('filterTable', searchData)"
        >
          بحث
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["title"],
  data() {
    return {
      load_users: false,
      // search
      searchData: {
        type: null,
        typeVal: null,
        user: null,
        userId: null,
        userName: null,
        from: new Date().toISOString().slice(0, 10),
        to: new Date().toISOString().slice(0, 10),
      },
      searchOptions: {
        types: [
          {
            id: 1,
            value: "in",
            name: "إيداع",
          },
          {
            id: 2,
            value: "out",
            name: "سحب",
          },
        ],
        search_users: "",
        from_date_menu: false,
        to_date_menu: false,
      },
      users: null,
    };
  },
  watch: {
    [`searchOptions.search_users`](newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.users = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_users(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_users(newVal);
        } else {
          this.get_filterd_users(newVal);
        }
      }
    },
  },
  methods: {
    // get_filterd_users
    get_filterd_users(keyword) {
      this.load_users = true;
      this.$axios({
        method: "GET",
        url: `client/account_bank_dubai/get_all?keyword=${keyword}`,
      })
        .then((res) => {
          this.users = res.data.data;
          this.load_users = false;
        })
        .catch((err) => {
          this.load_users = false;
        });
    },

    getSafes() {
      // START:: GET SAFES
      this.$axios({
        method: "GET",
        url: `client/wallet`,
      })
        .then((res) => {
          this.searchOptions.locks = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
      // END:: GET SAFES
    },
    addUserData(item) {
      if (this.searchData.user) {
        this.searchData.userId = item.id;
        this.searchData.userName = item.name;
      } else {
        this.searchData.userId = null;
        this.searchData.userName = null;
      }
    },
    chooseLock(item) {
      if (this.searchData.type) {
        this.searchData.typeVal = item.value;
      } else {
        this.searchData.typeVal = null;
      }
    },
  },

  created() {
    this.getSafes();
  },
};
</script>
<style lang="scss">
.search-section .load_inline {
  width: 30px !important;
  height: 25px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
  left: 1px;
  position: absolute !important;
  background: transparent !important;
  margin: 0 !important;

  span {
    right: 0 !important;
    left: 0 !important;
    margin: auto !important;
    border-top: 3px solid var(--mainColor-2) !important;
    border-right: 3px solid transparent !important;
  }
}
</style>
