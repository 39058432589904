<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper enterprice_account" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/enterpriceOwners"
        search_title="أصحاب المؤسسات"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("CRUD.Update.main_title") }}</h4>
        </div>

        <!-- START:: CREATE FORM WRAPPER -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-12 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.name"
                  />
                  <label for="name_input" class="form-label"
                    >اسم صاحب المؤسسة</label
                  >
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label file_input">
                  <span class="file_input_label">{{
                    $t("enterpriceOwner.data.identity_img")
                  }}</span>
                  <label
                    for="file_input_1"
                    class="form-label"
                    v-if="!selectedFile.name"
                  ></label>
                  <label for="file_input_1" class="form-label" v-else>
                    {{ selectedFile.name }}
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    id="file_input_1"
                    @change="handelSelectedFile"
                  />
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label file_input">
                  <span class="file_input_label">{{
                    $t("enterpriceOwner.data.address")
                  }}</span>
                  <label
                    for="file_input_2"
                    class="form-label"
                    v-if="!selectAddress.name"
                  ></label>
                  <label for="file_input_2" class="form-label" v-else>
                    {{ selectAddress.name }}
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    id="file_input_2"
                    @change="handelSelectAddress"
                  />
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="email"
                    class="form-control"
                    v-model.trim="data.email"
                  />
                  <label for="name_input" class="form-label">{{
                    $t("enterpriceOwner.data.email")
                  }}</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.phone"
                  />
                  <label for="name_input" class="form-label">{{
                    $t("enterpriceOwner.data.phone")
                  }}</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->
              <!--START:: SUB TITLE-->
              <div class="col-lg-12 py-0">
                <div class="divider">
                  <h4>بيانات أبشر</h4>
                  <hr
                    role="separator"
                    aria-orientation="horizontal"
                    class="v-divider theme--dark"
                  />
                </div>
              </div>
              <!--START:: SUB TITLE-->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.identity_num"
                  />
                  <label for="name_input" class="form-label">{{
                    $t("enterpriceOwner.data.identity_num")
                  }}</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <label for="password_input_1" class="form-label">{{
                    $t("enterpriceOwner.data.password")
                  }}</label>
                  <input
                    type="password"
                    class="form-control"
                    id="password_input_1"
                    v-model.trim="data.password"
                  />
                  <button
                    type="button"
                    class="toggle_pass_btn"
                    @click="togglePasswordVisibility"
                  >
                    <i class="fal fa-eye open_eye"></i>
                    <i class="fal fa-eye-slash closed_eye"></i>
                  </button>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <div class="col-lg-12 py-0">
                <div class="divider">
                  <h4>بيانات العميل المسئول</h4>
                  <hr
                    role="separator"
                    aria-orientation="horizontal"
                    class="v-divider theme--dark"
                  />
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label">إسم العميل</label>
                  <v-autocomplete
                    v-model="data.clientName"
                    :search-input.sync="searchOptions.search_users"
                    :items="users"
                    item-text="name"
                    return-object
                    :hide-no-data="!users"
                    dense
                  ></v-autocomplete>
                  <button class="button_style_1 load_inline" v-if="load_users">
                    <span class="btn_loader"></span>
                  </button>
                </div>
                <h6
                  class="mt-0 mb-0 mx-0 amount-section"
                  :class="{
                    redColor: data.clientName.amount < 0,
                    greenColor: data.clientName.amount > 0,
                  }"
                  v-if="data.clientName"
                >
                  الرصيد :
                  {{ Number(data.clientName.amount).toLocaleString() }}
                </h6>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.multiplication"
                  />
                  <label for="name_input" class="form-label">
                    ضرب (عمولة العميل)</label
                  >
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.division"
                  />
                  <label for="name_input" class="form-label"> قسمة</label>
                </div>
              </div>
              <!--START:: SUB TITLE-->

              <div class="col-lg-12 py-0">
                <div class="divider">
                  <h4>بيانات المسئول عن تشغيل المؤسسات</h4>
                  <hr
                    role="separator"
                    aria-orientation="horizontal"
                    class="v-divider theme--dark"
                  />
                </div>
              </div>
              <div class="col-lg-12 py-0">
                <div class="input_wrapper radio_button_input">
                  <div class="flex_wrapper">
                    <v-checkbox
                      v-model="resClientRadio"
                      label="إضافة مسئول عن تشغيل المؤسسات"
                      @change="emptyResData"
                    ></v-checkbox>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 py-0" v-if="resClientRadio">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label">
                    إسم المسئول عن تشغيل المؤسسات</label
                  >
                  <v-autocomplete
                    v-model="data.clientNameRes"
                    :search-input.sync="searchOptions.search_users_res"
                    :items="users_res"
                    item-text="name"
                    return-object
                    :hide-no-data="!users_res"
                    dense
                  ></v-autocomplete>
                  <button
                    class="button_style_1 load_inline"
                    v-if="load_users_res"
                  >
                    <span class="btn_loader"></span>
                  </button>
                </div>
                <h6
                  class="mt-0 mb-0 mx-0 amount-section"
                  :class="{
                    redColor: data.clientNameRes.amount < 0,
                    greenColor: data.clientNameRes.amount > 0,
                  }"
                  v-if="data.clientNameRes"
                >
                  الرصيد :
                  {{ Number(data.clientNameRes.amount).toLocaleString() }}
                </h6>
              </div>
              <div class="col-lg-4 py-0" v-if="resClientRadio">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.multiplicationRes"
                  />
                  <label for="name_input" class="form-label"> ضرب </label>
                </div>
              </div>
              <div class="col-lg-4 py-0" v-if="resClientRadio">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.divisionRes"
                  />
                  <label for="name_input" class="form-label"> قسمة</label>
                </div>
              </div>
              <!--START:: SUB TITLE-->
              <div class="col-lg-12 py-0">
                <div class="divider">
                  <h4>بيانات مسئول السعودية</h4>
                  <hr
                    role="separator"
                    aria-orientation="horizontal"
                    class="v-divider theme--dark"
                  />
                </div>
              </div>

              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.responsible_name"
                  />
                  <label for="name_input" class="form-label"
                    >إسم مسئول السعودية</label
                  >
                </div>
              </div>
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.responsible_num"
                  />
                  <label for="name_input" class="form-label"
                    >رقم هاتف مسئول السعودية</label
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              تعديل
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- END:: CREATE FORM WRAPPER -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",
  props: ["id"],
  data() {
    return {
      // loader
      loading: true,
      
      // ========== Breadcrumbs
      items: [
        {
          text: "الرئيسية",
          disabled: false,
          href: "/",
        },
        {
          text: "اصحاب المؤسسات",
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // START:: SELECTED FILE DATA
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },
      selectAddress: {
        file: null,
        name: null,
        path: null,
      },
      // END:: SELECTED FILE DATA

      // ========== create_data
      data: {
        name: null,
        identity_num: null,
        password: null,
        address: null,
        email: null,
        phone: null,
        responsible_name: null,
        responsible_num: null,
        clientName: null,
        multiplication: null,
        division: 1,
        clientNameRes: "",
        multiplicationRes: null,
        divisionRes: 1,
      },
      // search_clients
      load_users: false,
      load_owners: false,
      load_users_res: false,

      // search
      searchData: {
        username: null,
        enterpriceOwner: null,
      },
      searchOptions: {
        search_users: "",
        search_users_res: "",
        enterpriceOwners: "",
      },
      users: null,
      enterpriceOwners: null,
      // ========== Fixed Data
      resClientRadio: null,
      showResClient: false,
    };
  },
  watch: {
    [`searchOptions.search_users`](newVal, oldVal) {
      if ((!newVal || newVal.length < 3) && this.data.clientName == null) {
        this.users = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_users(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_users(newVal);
        } else {
          this.get_filterd_users(newVal);
        }
      }
    },
    [`searchOptions.search_users_res`](newVal, oldVal) {
      if ((!newVal || newVal.length < 3) && this.data.clientNameRes == null) {
        this.users_res = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_users_res(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_users_res(newVal);
        } else {
          this.get_filterd_users_res(newVal);
        }
      }
    },
  },

  methods: {
    // get_filterd_users
    get_filterd_users(keyword) {
      this.load_users = true;
      this.$axios({
        method: "GET",
        url: `client/customer/search?keyword=${keyword}`,
      })
        .then((res) => {
          this.users = res.data.data;
          this.load_users = false;
        })
        .catch((err) => {
          this.load_users = false;
        });
    },
    get_filterd_users_res(keyword) {
      this.load_users_res = true;
      this.$axios({
        method: "GET",
        url: `client/customer/search?keyword=${keyword}`,
      })
        .then((res) => {
          this.users_res = res.data.data;
          this.load_users_res = false;
        })
        .catch((err) => {
          this.load_users_res = false;
        });
    },
    get_enterprice_owners(keyword) {
      this.load_owners = true;
      this.$axios({
        method: "GET",
        url: `help/owners?keyword=${keyword}`,
      })
        .then((res) => {
          this.load_owners = false;
          this.enterpriceOwners = res.data.data;
        })
        .catch((err) => {
          this.load_owners = false;
        });
    },

    // START:: TOGGLE PASSWORD VISIBILITY METHOD
    togglePasswordVisibility(e) {
      let passwordElement = e.currentTarget.parentElement.children[1];
      let innerPasswordElement = e.currentTarget.parentElement.children[0];
      let passwordTogglerBtn = e.currentTarget;
      if (
        passwordElement.type == "password" ||
        innerPasswordElement.type == "password"
      ) {
        passwordElement.type = "text";
        innerPasswordElement.type = "text";
        passwordTogglerBtn.classList.add("password_is_visible");
      } else if (
        passwordElement.type == "text" ||
        innerPasswordElement.type == "text"
      ) {
        passwordElement.type = "password";
        innerPasswordElement.type = "password";
        passwordTogglerBtn.classList.remove("password_is_visible");
      }
    },
    // END:: TOGGLE PASSWORD VISIBILITY METHOD

    // START:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)
    checkIfInputIsEmpty(e) {
      let inputElement = e.currentTarget;
      if (inputElement.value.length > 0) {
        inputElement.classList.add("not_empty");
      } else {
        inputElement.classList.remove("not_empty");
      }
    },
    // END:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)
    emptyResData() {
      if (!this.resClientRadio) {
        this.data.clientNameRes = null;
        this.data.multiplicationRes = null;
        this.data.divisionRes = 1;
      }
    },
    // START::
    getData() {
      this.$axios({
        method: "GET",
        url: "client/owner/" + this.id,
      }).then((res) => {
        this.data.name = res.data.data.name;
        this.data.identity_num = res.data.data.absher_name;
        this.data.password = res.data.data.absher_password;
        this.data.phone = res.data.data.phone;
        this.data.email = res.data.data.email;
        this.selectAddress.name = res.data.data.address;
        this.selectedFile.name = res.data.data.identity;
        if (res.data.data.responsible_name != null) {
          this.data.responsible_name = res.data.data.responsible_name;
        }
        if (res.data.data.responsible_phone != null) {
          this.data.responsible_num = res.data.data.responsible_phone;
        }
        this.data.clientName = res.data.data.customer;
        this.data.multiplication = res.data.data.rate;
        if (res.data.data.divide != null) {
          this.data.division = res.data.data.divide;
        }
      });
    },
    // END::

    // START:: VALIDATE CREATE FORM
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.name) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("enterpriceOwner.validation.name"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.selectedFile.name) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("enterpriceOwner.validation.identity_image"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.selectAddress.name) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إرفاق العنوان الوطني",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.email) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("enterpriceOwner.validation.email"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.phone) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("enterpriceOwner.validation.phone"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.identity_num) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("enterpriceOwner.validation.identity_num"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.password) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("enterpriceOwner.validation.password"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.clientName) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال إسم العميل المسئول",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.multiplication) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال عمولة العميل المسئول",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          console.log(this.selectAddress.file);
          const submit_data = new FormData();
          submit_data.append("name", this.data.name);
          submit_data.append("email", this.data.email);
          submit_data.append("phone", this.data.phone);
          submit_data.append("absher_name", this.data.identity_num);
          submit_data.append("absher_password", this.data.password);
          if (this.selectedFile.file) {
            submit_data.append("identity", this.selectedFile.file);
          }
          if (this.selectAddress.file) {
            submit_data.append("address", this.selectAddress.file);
          }
          submit_data.append("balance_customer_id", this.data.clientName.id);
          submit_data.append("rate", this.data.multiplication);
          submit_data.append("divide", this.data.division);
          if (this.data.responsible_name) {
            submit_data.append("responsible_name", this.data.responsible_name);
          }
          if (this.data.responsible_num) {
            submit_data.append("responsible_phone", this.data.responsible_num);
          }
          this.$axios({
            method: "POST",
            url: "client/owner/update_owner/" + this.id,
            data: submit_data,
          })
            .then(() => {
              this.btnIsLoading = false;
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("global.editSuccess"),
                position: "topRight",
              });
              this.$router.push("/enterpriceOwners");
            })
            .catch((err) => {
              this.btnIsLoading = false;
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "topRight",
              });
            });
          return;
        }
      }
    },
    // END:: VALIDATE CREATE FORM

    // START:: ADD NEW ITEM
    addItem() {
      this.data.responsible.push({
        id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
        responsible_name: null,
        responsible_num: null,
      });
    },
    // END:: ADD NEW ITEM

    // START:: HANDLING SELECTED FILE METHOD
    handelSelectedFile(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    handelSelectAddress(e) {
      this.selectAddress.file = e.target.files[0];
      this.selectAddress.name = e.target.files[0].name;
      this.selectAddress.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD
  },

  created() {
    this.getData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      userType.includes("confirm_enterprise") &&
      !userType.includes("enterprise")
    ) {
      this.$router.push("/enterpriceOwners");
    } else if (
      !userType.includes("superadmin") &&
      !userType.includes("enterprise")
    ) {
      this.$router.push("/");
    }
  },
};
</script>
