<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/expenses"
        search_title="المصروفات"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">تعديل مصروفات</h4>
        </div>

        <!-- START:: CREATE FORM WRAPPER -->
        <form @submit.prevent="validateForm">
          <div class="container">
            <div class="row justify-content-between">
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.amount"
                  />
                  <label for="name_input" class="form-label">المبلغ</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START::  INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label mb-3">
                  <label class="form-label"> الخزنة </label>
                  <multiselect
                    v-model="data.theLock"
                    :options="theLockValues"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label"> الخارج من الخزنة </label>
                  <multiselect
                    v-model="data.currency"
                    :options="currenciesValues"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label date_input">
                  <label class="form-label">التاريخ</label>
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        v-model="data.date"
                        label="Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="data.date"
                      @input="dateMenu = false"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label"> وصف </label>
                  <textarea
                    v-on:keydown.enter.prevent="validateForm"
                    class="form-control"
                    rows="5"
                    v-model.trim="data.description"
                  ></textarea>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label"> ملاحظات </label>
                  <textarea
                    v-on:keydown.enter.prevent="validateForm"
                    class="form-control"
                    rows="5"
                    v-model.trim="data.notes"
                  ></textarea>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              تعديل
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- END:: CREATE FORM WRAPPER -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddExpenses",

  props: ["id"],

  data() {
    return {
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.expenses.edit"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========= Date Menu
      dateMenu: false,

      // ========== create_data
      data: {
        amount: null,
        currency: "",
        theLock: "",
        date: null,
        description: null,
        notes: null,
      },

      theLockValues: [],
      currenciesValues: [],
      // ========== Fixed Data
    };
  },

  methods: {
    // ============== Get Data
    getData() {
      // ===== Currencies
      this.$axios({
        method: "GET",
        url: `client/currency`,
      }).then((res) => {
        this.currenciesValues = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name_ar,
          };
        });
      });

      // ===== Safes
      this.$axios({
        method: "GET",
        url: `client/wallet?type=out`,
      }).then((res) => {
        this.theLockValues = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      });

      // ===== MainDate
      this.$axios({
        method: "GET",
        url: `client/expense/${this.id}`,
      }).then((res) => {
        this.data.amount = res.data.data.amount;
        this.data.currency = {
          id: res.data.data.currency.id,
          name: res.data.data.currency.name_ar,
        };
        this.data.theLock = {
          id: res.data.data.wallet.id,
          name: res.data.data.wallet.name,
        };
        this.data.date = res.data.data.date;
        this.data.description = res.data.data.desc;
        this.data.notes = res.data.data.notes;
      });
    },

    // START:: VALIDATE CREATE FORM
    validateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.currency) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال العملة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.theLock) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال الخزنة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال المبلغ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.description) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال وصف",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.submitData();
          return;
        }
      }
    },

    // Submit Data
    submitData() {
      const submit_data = {
        amount: this.data.amount,
        currency_id: this.data.currency.id,
        wallet_id: this.data.theLock.id,
        date: this.data.date,
        desc: this.data.description,
        notes: this.data.notes,
      };

      this.$axios({
        method: "PUT",
        url: `client/expense/${this.id}`,
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "topRight",
          });
          this.$router.push({path: "/expenses"});
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
    // END:: VALIDATE CREATE FORM
  },

  created() {
    this.getData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      userType.includes(
        "confirm_lock_and_deals" && !userType.includes("lock_and_deals")
      )
    ) {
      this.$router.push("/expenses");
    } else if (
      !userType.includes("superadmin") &&
      !userType.includes("lock_and_deals")
    ) {
      this.$router.push("/");
    }
  },
};
</script>
