<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/service_operations/deposit"
        search_title="عمليات إيداع السيرفس"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("addNew") }}</h4>
        </div>

        <!-- ==== Start Form ==== -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-lg-12 py-0">
                <!-- START:: CHECKBOX INPUT -->
                <div class="input_wrapper checkbox_input">
                  <v-checkbox
                    v-model="checkboxValue"
                    label="سيرفيس الميناء"
                    @change="emptyFormData"
                  ></v-checkbox>
                </div>
                <!-- END:: CHECKBOX INPUT -->
              </div>

              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.amount"
                  />
                  <label for="name_input" class="form-label">{{
                    $t("forms.labels.amount")
                  }}</label>
                </div>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.comission"
                  />
                  <label for="name_input" class="form-label">{{
                    $t("forms.labels.comission")
                  }}</label>
                </div>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.transfare_amount"
                  />
                  <label for="name_input" class="form-label">{{
                    $t("forms.labels.transfare_amount")
                  }}</label>
                </div>
              </div>

              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    readonly
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="total"
                  />
                  <label for="name_input" class="form-label">{{
                    $t("forms.labels.total")
                  }}</label>
                </div>
              </div>
              <div
                class="py-0"
                :class="{
                  'col-lg-6': !checkboxValue,
                  'col-lg-4': checkboxValue,
                }"
              >
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> إسم العميل</label>
                  <v-autocomplete
                    v-model="data.username"
                    :search-input.sync="searchOptions.search_users"
                    :items="users"
                    item-text="name"
                    return-object
                    :hide-no-data="!users"
                    dense
                  ></v-autocomplete>
                  <button class="button_style_1 load_inline" v-if="load_users">
                    <span class="btn_loader"></span>
                  </button>
                </div>
                <h6
                  class="mt-0 mb-0 mx-0 amount-section"
                  :class="{
                    redColor: data.username.amount < 0,
                    greenColor: data.username.amount > 0,
                  }"
                  v-if="data.username"
                >
                  الرصيد :
                  {{ Number(data.username.amount).toLocaleString() }}
                </h6>
              </div>

              <div
                class="py-0"
                :class="{
                  'col-lg-6': !checkboxValue,
                  'col-lg-4': checkboxValue,
                }"
              >
                <div class="input_wrapper top_label date_input">
                  <label class="form-label">{{
                    $t("forms.labels.date")
                  }}</label>
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        v-model="data.date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="data.date"
                      @input="dateMenu = false"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>
              <div class="col-lg-4 py-0" v-if="checkboxValue">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-on:keydown.enter.prevent="getPoliceData"
                    v-model.trim="data.police_num"
                  />
                  <label for="name_input" class="form-label">
                    رقم البوليصة
                  </label>
                  <button class="button_style_1 load_inline" v-if="load_Police">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <label for="textarea_1" class="form-label">{{
                    $t("forms.labels.desc")
                  }}</label>
                  <textarea
                    v-on:keydown.enter.prevent="validateCreateForm"
                    v-model="data.desc"
                    class="form-control"
                    id="textarea_1"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <label for="textarea_1" class="form-label">{{
                    $t("forms.labels.notes")
                  }}</label>
                  <textarea
                    v-on:keydown.enter.prevent="validateCreateForm"
                    v-model="data.notes"
                    class="form-control"
                    id="textarea_1"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              إضافة
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- ==== End Form ==== -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "إضافة عملية إيداع سيرفس",
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,
      load_Police: false,
      checkboxValue: false,

      // Date_menues
      dateMenu: false,
      debit_dateMenu: false,

      load_users: false,

      searchOptions: {
        search_users: "",
      },
      users: null,

      // ========== create_data
      data: {
        username: null,
        amount: null,
        transfare_amount: 1,
        police_num: null,
        police_id: null,
        comission: 1,
        total: null,
        safe: null,
        date: new Date().toISOString().slice(0, 10),
        desc: null,
        notes: null,
      },

      // ========== Fixed_data
      currencies: null,
      users: [],
      safes: [],
    };
  },

  computed: {
    total() {
      const num =
        this.data.amount * this.data.comission * this.data.transfare_amount;
      return parseFloat(num.toFixed()).toLocaleString();
    },
  },

  watch: {
    [`searchOptions.search_users`](newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.users = null;
        this.data.username = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_users(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_users(newVal);
        } else {
          this.get_filterd_users(newVal);
        }
      }
    },
  },

  methods: {
    // START:: GET SAFES
    getSafes() {
      this.$axios({
        method: "GET",
        url: `client/wallet?type=in`,
      })
        .then((res) => {
          this.safes = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    // END:: GET SAFES

    get_filterd_users(keyword) {
      this.load_users = true;
      this.$axios({
        method: "GET",
        url: `client/customer/search?keyword=${keyword}`,
      })
        .then((res) => {
          this.users = res.data.data;
          this.load_users = false;
        })
        .catch((err) => {
          this.load_users = false;
        });
    },

    getCurrencies() {
      // START:: GET SAFES
      this.$axios({
        method: "GET",
        url: `client/currency`,
      })
        .then((res) => {
          this.currencies = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
      // END:: GET SAFES
    },

    // Validate Data
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.username) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب تحديد إسم العميل",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.amount"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.transfare_amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.transfare_amount"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.comission) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.comission"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.total) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.total"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.date) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب تحديد تاريخ العملية",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.desc) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.desc"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.submitData();
          return;
        }
      }
    },
    emptyFormData() {
      this.data.username = null;
      this.data.amount = null;
      this.data.transfare_amount = 1;
      this.data.comission = 1;
      this.data.date = new Date().toISOString().slice(0, 10);
      this.data.desc = null;
      this.data.notes = null;
      this.data.safe = null;
      this.data.police_num = null;
    },
    // Submit Data
    submitData() {
      const submit_data = new FormData();

      // START:: APPEND REQUEST DATA
      submit_data.append("type", "in");
      if (this.checkboxValue) {
        submit_data.append("operation_type", this.checkboxValue);
        submit_data.append("import_id", this.data.police_id);
      }
      submit_data.append("balance_customer_id", this.data.username.id);
      submit_data.append("amount", this.data.amount);
      submit_data.append("rate", this.data.transfare_amount);
      submit_data.append("commission", this.data.comission);
      submit_data.append("total", Number(this.total.replace(/\D/g, "")));
      submit_data.append("date", this.data.date);
      submit_data.append("desc", this.data.desc);
      if (this.data.notes) {
        submit_data.append("notes", this.data.notes);
      }
      // END:: APPEND REQUEST DATA

      this.$axios({
        method: "POST",
        url: "client/service_operation",
        data: submit_data,
      })
        .then((res) => {
          this.$iziToast.success({
            timeout: 5000,
            message: this.$t("addSuccess"),
            position: "topRight",
          });
          this.emptyFormData();
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
    // Set Rows
    getPoliceData() {
      if (this.data.police_num) {
        this.load_Police = true;
        let myParams = {
          bl_number: this.data.police_num ? this.data.police_num : null,
          search_option: "true",
        };
        this.$axios({
          method: "GET",
          url: "client/import",
          params: myParams,
        })
          .then((res) => {
            if (res.data.data.length != 0) {
              this.data.police_id = res.data.data[0].id;
              var containerVals = [];
              res.data.data[0].import_containers.forEach((ele) => {
                containerVals.push(ele.number);
              });
              this.data.desc =
                "شهادة رقم" +
                " " +
                res.data.data[0].cert_number +
                " " +
                "برقم بوليصه" +
                " " +
                res.data.data[0].bl_number +
                " " +
                "بعدد حاويات" +
                " " +
                res.data.data[0].containers +
                " " +
                "بأرقام" +
                " " +
                containerVals +
                " " +
                "لشركة" +
                " " +
                res.data.data[0].company.name +
                " " +
                "بمبلغ" +
                " " +
                res.data.data[0].total;
            } else {
              this.data.desc = null;
            }
            this.load_Police = false;
          })

          .catch((err) => {
            this.load_Police = false;
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "topRight",
            });
            this.btnIsLoading = false;
          });
      }
    },
  },

  created() {
    this.getCurrencies();
    this.getSafes();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      userType.includes("confirm_lock_and_deals") &&
      !userType.includes("lock_and_deals")
    ) {
      this.$router.push("/service_operations/deposit");
    } else if (
      !userType.includes("superadmin") &&
      !userType.includes("lock_and_deals")
    ) {
      this.$router.push("/");
    }
  },
};
</script>
