<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper enterprice_account" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/export_app"
        search_title="نموذج 4 تصدير"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">نموذج 4 تصدير</h4>
        </div>

        <!-- START:: CREATE FORM WRAPPER -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="data.amount"
                    @input="getData()"
                  />
                  <label for="name_input" class="form-label"> المبلغ </label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    @input="getData()"
                    v-model.trim="data.transfare_amount"
                  />
                  <label for="name_input2" class="form-label">قسمه</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    @input="getData()"
                    v-model.trim="data.comission"
                  />
                  <label for="name_input3" class="form-label">ضرب</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    readonly
                    class="form-control"
                    v-model.trim="total"
                  />
                  <label for="name_input4" class="form-label">الاجمالي</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: SINGLE SELECT INPUT -->
              <div class="col-lg-12 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label"> اسم البنك </label>
                  <multiselect
                    v-model="data.bank"
                    :options="banks"
                    label="name"
                    track-by="id"
                    placeholder=""
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                    @input="getBankData()"
                  >
                  </multiselect>
                  <button class="button_style_1 load_inline" v-if="load_banks">
                    <span class="btn_loader"></span>
                  </button>
                </div>

                <template v-if="bankData">
                  <h6 class="amount_section_parent">
                    <span class="amount-section">
                      الرصيد الاجمالي قبل الخصم :
                      <span
                        :class="{
                          redColor: bankTotal < 0,
                          greenColor: bankTotal > 0,
                        }"
                      >
                        {{ Number(bankTotal).toLocaleString() }}
                      </span>
                    </span>
                    <span class="amount-section">
                      الرصيد المخصوم من الاجمالي :
                      <span class="redColor">
                        {{ total }}
                      </span>
                    </span>
                    <span class="amount-section">
                      الرصيد الاجمالي بعد الخصم :
                      <span
                        :class="{
                          redColor:
                            Number(
                              bankTotal - Number(total.replace(/\D/g, ''))
                            ) < 0,
                          greenColor:
                            Number(
                              bankTotal - Number(total.replace(/\D/g, ''))
                            ) > 0,
                        }"
                      >
                        {{
                          Number(
                            bankTotal - Number(total.replace(/\D/g, ""))
                          ).toLocaleString()
                        }}
                      </span>
                    </span>
                  </h6>

                  <h6
                    v-for="item in bankData"
                    :key="item.id"
                    class="amount_section_parent"
                  >
                    <span class="amount-section dir_ltr">
                      {{ item.reference }} : Reference
                    </span>
                    <span class="amount-section"
                      >الرصيد قبل الخصم :
                      <span
                        :class="{
                          redColor: item.amount < 0,
                          greenColor: item.amount > 0,
                        }"
                      >
                        {{ Number(item.amount).toLocaleString() }}
                      </span>
                    </span>
                    <span class="amount-section"
                      >الرصيد المخصوم :
                      <span class="redColor">
                        {{ Number(item.discountAmount).toLocaleString() }}
                      </span>
                    </span>
                    <span class="amount-section"
                      >الرصيد بعد الخصم :
                      <span
                        :class="{
                          redColor: item.newAmount < 0,
                          greenColor: item.newAmount > 0,
                        }"
                      >
                        {{ item.newAmount.toLocaleString() }}
                      </span>
                    </span>
                  </h6>
                </template>
              </div>
              <!-- START:: SINGLE SELECT INPUT -->

              <!-- START:: SINGLE SELECT INPUT -->
              <div class="col-lg-12 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> إسم العميل</label>
                  <v-autocomplete
                    v-model="data.username"
                    :search-input.sync="searchOptions.search_users"
                    :items="users"
                    item-text="name"
                    return-object
                    :hide-no-data="!users"
                    dense
                  ></v-autocomplete>
                  <button class="button_style_1 load_inline" v-if="load_users">
                    <span class="btn_loader"></span>
                  </button>
                </div>
                <h6
                  class="mt-0 mb-0 mx-0 amount_section_parent"
                  v-if="data.username"
                >
                  <span class="amount-section">
                    الرصيد قبل الخصم :
                    <span
                      :class="{
                        redColor: Number(data.username.amount) < 0,
                        greenColor: Number(data.username.amount) > 0,
                      }"
                      >{{ Number(data.username.amount).toLocaleString() }}</span
                    >
                  </span>
                  <span class="amount-section">
                    الرصيد المخصوم :
                    <span class="redColor">
                      {{ total }}
                    </span>
                  </span>
                  <span class="amount-section">
                    الرصيد بعد الخصم :
                    <span
                      :class="{
                        redColor:
                          Number(
                            data.username.amount -
                              Number(total.replace(/\D/g, ''))
                          ) < 0,
                        greenColor:
                          Number(
                            data.username.amount -
                              Number(total.replace(/\D/g, ''))
                          ) > 0,
                      }"
                      >{{
                        Number(
                          data.username.amount -
                            Number(total.replace(/\D/g, ""))
                        ).toLocaleString()
                      }}</span
                    >
                  </span>
                </h6>
              </div>
              <!-- END:: SINGLE SELECT INPUT -->
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              إضافة
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- END:: CREATE FORM WRAPPER -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // loader
      loading: true,
      load_banks: false,
      load_users: false,
      load_enterprices: false,

      // search
      searchOptions: {
        search_users: "",
        search_enterprices: "",
      },
      users: null,
      enterprices: null,

      // ========== Breadcrumbs
      items: [
        {
          text: "الرئيسية",
          disabled: false,
          href: "/",
        },
        {
          text: "نموذج 4 تصدير",
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      radioButtonsValue: null,

      // START:: enterpriceAccounts
      enterpriceAccounts: [],
      // END:: enterpriceAccounts

      // search_users
      search_users: "",

      // search_accounts
      search_accounts: "",

      // ========== create_data
      data: {
        enterpriceAccount: "",
        username: null,
        amount: null,
        transfare_amount: 1,
        comission: 1,
        total: null,
        bank: "",
      },
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },

      // ========== Fixed Data
      users: [],
      banks: [],
      bankTotal: "",
      bankData: null,
    };
  },

  watch: {
    [`searchOptions.search_users`](newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.users = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_users(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_users(newVal);
        } else {
          this.get_filterd_users(newVal);
        }
      }
    },
  },

  methods: {
    // get_filterd_users
    get_filterd_users(keyword) {
      this.load_users = true;
      this.$axios({
        method: "GET",
        url: `client/customer_eg?keyword=${keyword}`,
      })
        .then((res) => {
          this.users = res.data.data;
          this.load_users = false;
        })
        .catch((err) => {
          this.load_users = false;
        });
    },

    // START:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATEسD PLACEHOLDER INPUTS)
    checkIfInputIsEmpty(e) {
      let inputElement = e.currentTarget;
      if (inputElement.value.length > 0) {
        inputElement.classList.add("not_empty");
      } else {
        inputElement.classList.remove("not_empty");
      }
    },
    // END:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)

    // START:: VALIDATE CREATE FORM
    emptyFormData() {
      this.data.amount = null;
      this.data.transfare_amount = 1;
      this.data.comission = 1;
      this.data.username = null;
      this.data.bank = null;
      this.bankData = null;
      this.bankTotal = null;
    },

    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال المبلغ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.transfare_amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال النسبة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.comission) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال العمولة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.bank) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إختيار البنك",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.username) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال إسم العميل",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          const submit_data = new FormData();
          console.log(this.bankData);
          submit_data.append("amount", this.data.amount);
          submit_data.append("dividing", this.data.transfare_amount);
          submit_data.append("multiply", this.data.comission);
          submit_data.append("total", Number(this.total.replace(/\D/g, "")));
          submit_data.append("bank_eg_id", this.data.bank.id);
          submit_data.append("customer_eg_id", this.data.username.id);
          this.bankData.forEach((element, index) => {
            submit_data.append(`references[${index}][id]`, element.id);
            submit_data.append(
              `references[${index}][amount_before]`,
              element.amount
            );
            submit_data.append(
              `references[${index}][total]`,
              element.discountAmount
            );
            submit_data.append(
              `references[${index}][amount_after]`,
              element.newAmount
            );
          });
          this.$axios({
            method: "post",
            url: "client/bank_eg_operation",
            data: submit_data,
          })
            .then(() => {
              this.btnIsLoading = false;
              this.$iziToast.success({
                timeout: 2000,
                message: "تمت الاضافة بنجاح",
                position: "topRight",
              });
              this.emptyFormData();
            })
            .catch((err) => {
              this.btnIsLoading = false;
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "topRight",
              });
            });
          return;
        }
      }
    },
    // END:: VALIDATE CREATE FORM
    getData() {
      console.log("getData");
      this.load_banks = true;
      this.banks = [];
      this.data.bank = null;
      this.bankData = null;
      this.bankTotal = null;
      if (this.data.amount) {
        this.$axios({
          method: "GET",
          url: "client/bank_eg",
        }).then((res) => {
          this.banks = res.data.data;
          this.load_banks = false;
        });
      } else {
        this.load_banks = false;
      }
    },
    getBankData() {
      if (this.data.bank) {
        this.load_banks = true;
        this.$axios({
          method: "GET",
          url: `client/bank_eg_reference/all`,
          params: {
            amount: this.data.amount ? this.data.amount : null,
            bank_eg_id: this.data.bank ? this.data.bank.id : null,
          },
        })
          .then((res) => {
            this.bankData = res.data.data;
            this.bankTotal = res.data.total;
            this.balanceCheckValue();
            this.load_banks = false;
          })
          .catch((err) => {
            this.load_baks = false;
          });
      } else {
        this.load_banks = false;
        this.bankData = null;
        this.bankTotal = null;
      }
    },
    balanceCheckValue() {

      if (this.bankData) {
        var indexNum = 0;
        var mainVal =
          Number(this.bankData[indexNum].amount) -
          Number(this.total.replace(/\D/g, ""));
        var discountVal = null;
        var subVal = null;
        // if one referance
        if (mainVal > 0) {
          subVal =
            Number(this.bankData[indexNum].amount) -
            Number(this.total.replace(/\D/g, ""));
          discountVal = Number(this.total.replace(/\D/g, ""));
          this.bankData[indexNum]["discountAmount"] = discountVal;
          this.bankData[indexNum]["newAmount"] = subVal;
        }
        // if value equal referance
        else if (mainVal == 0) {
          subVal = 0;
          discountVal = Number(this.total.replace(/\D/g, ""));
          this.bankData[indexNum]["discountAmount"] = discountVal;
          this.bankData[indexNum]["newAmount"] = subVal;
        }
        // if multi refernce
        else if (mainVal < 0) {
          var minusVal = Number(this.total.replace(/\D/g, ""));
          for (let num = 0; num < this.bankData.length; num++) {
            minusVal = Number(this.bankData[num].amount) - Math.abs(minusVal);
            if (minusVal < 0 || minusVal == 0) {
              this.bankData[num]["discountAmount"] = Number(
                this.bankData[num].amount
              );
              this.bankData[num]["newAmount"] = 0;
            } else {
              this.bankData[num]["discountAmount"] = Number(
                this.bankData[num].amount - minusVal
              );
              this.bankData[num]["newAmount"] = minusVal;
            }
          }
        }
      }
    },
    // START:: HANDLING SELECTED FILE METHOD
    handelSelected(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD
  },

  computed: {
    total() {
      const num =
        (this.data.amount * this.data.comission) / this.data.transfare_amount;
      return parseFloat(num.toFixed()).toLocaleString();
    },
  },

  created() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);

    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("referane_export")
    ) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss">
.amount-section {
  margin-top: 15px;
}

.amount_section_parent {
  display: flex;
  justify-content: space-between;
  .amount-section {
    margin: 3px 5px !important;
    width: 48%;
  }
}
</style>
