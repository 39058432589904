<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb :items="items" />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">تعديل عملية نقل رصيد</h4>
        </div>

        <!-- START:: CREATE FORM WRAPPER -->
        <form @submit.prevent="validateForm">
          <div class="container">
            <div class="row justify-content-between">
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.amount"
                  />
                  <label for="name_input" class="form-label">المبلغ</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label"> العملة </label>
                  <multiselect
                    v-model="data.currency"
                    :options="currenciesValues"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label mb-3">
                  <label class="form-label"> الخزنة المسحوب إليها </label>
                  <multiselect
                    v-model="data.withdrawnToLock"
                    :options="theLockValues"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-12 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label"> وصف </label>
                  <textarea
                    v-on:keydown.enter.prevent="validateForm"
                    class="form-control"
                    rows="5"
                    v-model.trim="data.description"
                  ></textarea>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              {{ $t("Forms.submit") }}
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- END:: CREATE FORM WRAPPER -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditTransferBalance",

  props: ["id"],

  data() {
    return {
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "نقل رصيد صندوق الواردات",
          disabled: false,
          href: "/transfer_balances",
        },
        {
          text: "تعديل عملية نقل رصيد لخزنة",
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========= Date Menu
      dateMenu: false,

      // ========== create_data
      data: {
        amount: null,
        currency: {},
        withdrawnFromLock: {},
        withdrawnToLock: {},
        description: null,
      },

      theLockValues: [
        {id: 1, name: "Option_1"},
        {id: 2, name: "Option_2"},
        {id: 3, name: "Option_3"},
      ],
      currenciesValues: [
        {id: 1, name: "Option_1"},
        {id: 2, name: "Option_2"},
        {id: 3, name: "Option_3"},
      ],
      // ========== Fixed Data
    };
  },

  methods: {
    // START:: VALIDATE CREATE FORM
    validateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.name) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال إسم",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.phone) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال رقم هاتف",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال المبلغ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.percentage) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال نسبة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (Object.keys(this.data.theLock).length == 0) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إختيار خزنة ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (Object.keys(this.data.currencyIntoTheLock).length == 0) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إختيار العملة الداخلة الي الخزنة ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (Object.keys(this.data.currencyOutOfTheLock).length == 0) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إختيار العملة الخارجة من الخزنة ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.date) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إختيار تاريخ العملية ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          // START:: SEND REQUEST
          // END:: SEND REQUEST
        }
      }
    },
    // END:: VALIDATE CREATE FORM
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>
