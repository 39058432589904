<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :show_print="true"
        :excelFile="true"
        :excelData="excelData"
      />
      <!-- End Breadcrumb -->
      <div>
        <!-- Start Statistics Card-->
        <ul class="all-state-count">
          <li>
            <div class="state-count">
              <span>عدد المواني :</span>
              <span>{{ table_length }}</span>
            </div>
          </li>
        </ul>
        <!-- End Statistics Card-->

        <main class="main-table table-print">
          <!-- start of  header of printSection  -->
          <PrintHead
            :title="'المواني'"
            :number="table_length"
          ></PrintHead>
          <!-- end of header of printSection  -->
          <v-data-table
            class="thumb"
            :headers="headers"
            :items="rows"
            :loading="lodaing"
            :loading-text="$t('table.loadingData')"
            v-model="selected"
            :single-select="singleSelect"
            item-key="id"
            :items-per-page="rows.length"
            hide-default-footer
          >
            <!-- ================== You Can use any slots you want ================== -->
            <!-- ====== Select row field ====== -->
            <!-- Select no data State -->
            <template v-slot:[`item.index`]="{ index }">
              {{ index + 1 }}
            </template>

            <template v-slot:no-data>
              {{ $t("table.noData") }}
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              {{ Number(item.amount).toLocaleString() }}
            </template>
            <template v-slot:[`item.total`]="{ item }">
              {{ Number(item.total).toLocaleString() }}
            </template>
            <template v-slot:[`item.desc`]="{ item }">
              <div class="_actions">
                <v-icon
                  class="show"
                  small
                  @click="
                    modal.descriptionModalIsOpen = true;
                    setItemDesc(item.desc);
                  "
                >
                  fal fa-eye
                </v-icon>
              </div>
            </template>
            <!-- revision status-->
            <template v-slot:[`item.review_status`]="{ item }">
              <v-chip color="#d7c100" v-if="item.review_status == 0">
                منتظر
              </v-chip>
              <v-chip color="green" v-else> تأكيد </v-chip>
            </template>
            <!-- Confirm revision-->
            <template v-slot:[`item.revision_done`]="{ item }">
              <!--  -->
              <div class="_actions actions2">
                <button
                  type="button"
                  class="accept confirm_btn ml-0"
                  @click="revisionDone(item)"
                  v-if="item.review_status == 0"
                >
                  <i class="far fa-check"></i>
                </button>
                <span v-else> ---- </span>
              </div>
            </template>

            <template v-slot:[`item.notes`]="{ item }">
              <div class="_actions">
                <span v-if="item.notes == 'null'"> ---- </span>
                <v-icon
                  class="show"
                  small
                  v-else
                  @click="
                    modal.notesModalIsOpen = true;
                    setItemNotes(item.notes);
                  "
                >
                  fal fa-eye
                </v-icon>
              </div>
            </template>
            <!-- Select actions-->
            <template v-slot:[`item.actions`]="{ item }">
              <div class="_actions">
                <v-icon class="edit" small @click="editItem(item)">
                  fal fa-edit
                </v-icon>
                <v-icon class="delete" small @click="deleteItem(item)">
                  fal fa-trash
                </v-icon>
              </div>
            </template>

            <!-- ======================== Start Top Section ======================== -->
            <template v-slot:top>
              <!-- Delete dialog -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">{{
                    $t("table.deletedialog.areYouSure")
                  }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="deleteItemConfirm"
                      >{{ $t("table.deletedialog.ok") }}
                      <span
                        class="btn_loader_model"
                        v-if="loadingBtnDialog"
                      ></span
                    ></v-btn>
                    <v-btn color="#F44336" @click="dialogDelete = false">{{
                      $t("table.deletedialog.cancel")
                    }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- confirm dialog -->
              <v-dialog v-model="dialogConfirm" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">
                    هل تمت المراجعه علي العملية ؟
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="revisionConfirm"
                      >{{ $t("table.deletedialog.ok") }}
                      <span
                        class="btn_loader_model"
                        v-if="loadingBtnDialog"
                      ></span
                    ></v-btn>
                    <v-btn color="#F44336" @click="dialogConfirm = false">{{
                      $t("table.deletedialog.cancel")
                    }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Add & Delete -->
              <!-- Description Modal -->
              <DescModal
                :desc="selectedItemDesc"
                :handelModalApperance="modal.descriptionModalIsOpen"
                @close="closeDescModal"
              />

              <NotesModal
                :desc="selectedItemNotes"
                :handelModalApperance="modal.notesModalIsOpen"
                @close="closeNotesModal"
              />
            </template>
            <!-- ======================== End Top Section ======================== -->
          </v-data-table>
        </main>
        <!-- End Main Section -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "@/components/Charts/ChartComponent.vue";
import DescModal from "@/components/modals/DescriptionModal.vue";
import NotesModal from "@/components/modals/NotesModal.vue";
import SearchSection from "@/components/Search/movement-search";
export default {
  props: ["id"],

  components: {
    StatsCard,
    DescModal,
    NotesModal,
    SearchSection,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // search
      searchItems: null,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text:  "المواني",
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "المواني",
          number: "10",
        },
      ],

      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,
      dialogConfirm: false,
      itemtoConfirm: null,
      // ========== Date_Menues
      from_date_menu: false,
      to_date_menu: false,

      // ========== Filter
      filter: {
        name: null,
        safe: [],
        from: null,
        to: null,
      },

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== Loding
      lodaing: false,

      // Fixed_data
      safes: ["foo", "bar", "fizz", "buzz"],
      // Custom Modal Data
      modal: {
        descriptionModalIsOpen: false,
        notesModalIsOpen: false,
      },
      selectedItemDesc: null,
      selectedItemNotes: null,
      table_length: null,
      // excel data
      excelData: {
        name: "المواني",
        items: null,
        json_fields: {
          "اسم العميل": "balance_customer.name",
          الخزنة: "wallet.name",
          المبلغ: {
            field: "amount",
            callback: (value) => {
              return Number(value).toLocaleString();
            },
          },
          العمولة: "commission",
          النسبة: "rate",
          الإجمالي: {
            field: "total",
            callback: (value) => {
              return Number(value).toLocaleString();
            },
          },
          التاريخ: "created_at",
          وصف: "desc",
          ملاحظات: "notes",
          بواسطة: "by.name",
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
            {
              text: "#",
              value: "index",
              sortable: false,
              align: "center",
              width: 10,
            },
            {
              text: "اسم الميناء",
              value: "name",
              align: "center",
              width: 150,
            },
            {
              text: "اسم المخلص",
              value: "username",
              align: "center",
              width: 150,
            },
            {
              text: "التحكم",
              value: "actions",
              sortable: false,
              align: "center",
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 50,
            },
          ];
      }
    },
  },

  methods: {
    // searchTable
    searchTable(items) {
      this.rows = [];
      this.searchItems = items;
      this.setRows();
    },
    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({
        path: this.id ? `/deposits/${this.id}/add` : `/deposits_ultimate/add`,
      });
    },
    showItem(item) {
      this.dialogShow = true;
      this.itemToShow = item;
    },
    editItem(item) {
      this.$router.push({ path: "/ports/edit/" + item.id });
    },
    // confirm item
    revisionDone(item) {
      this.dialogConfirm = true;
      this.itemtoConfirm = item;
    },
    revisionConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "POST",
        url: `client/walletoperation/review/${this.itemtoConfirm.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogConfirm = false;
          this.$iziToast.success({
            timeout: 2000,
            message: "تم التأكيد بنجاح",
            position: "topRight",
          });
        })
        .catch((err) => {
          this.dialogConfirm = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/port/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // Set Rows
    setRows() {
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/port",
      })
        .then((res) => {
          this.rows = res.data.data;
          this.table_length = this.rows.length;
          this.excelData.items = this.rows;
          this.lodaing = false;
        })
        .catch((err) => {
          this.lodaing = false;
        });
    },
    setItemDesc(desc) {
      this.selectedItemDesc = desc;
    },
    setItemNotes(notes) {
      this.selectedItemNotes = notes;
    },
    closeDescModal() {
      this.modal.descriptionModalIsOpen = false;
    },
    closeNotesModal() {
      this.modal.notesModalIsOpen = false;
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    this.setRows();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("containerOperations") 
    ) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped></style>
