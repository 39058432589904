<template>
    <div class="desc_modal_wrapper">
        <base-model
            class="enterprice_account"
            @closeModel="$emit('close')"
            :show="handelModalApperance"
        >
            <div class="comments" v-if="desc">
            <h2 class="mb-7 mt-3">ملاحظات</h2>
            <div>
                <div class="comment_card">
                <p>
                    {{ desc }}
                </p>
                </div>
            </div>
            </div>
        </base-model>
    </div>
</template>

<script>
import BaseModel from "@/components/ui/BaseModel.vue";

export default {
    name: "NotesModal",

    components: {
        BaseModel
    },

    props: ["handelModalApperance", "desc"],

    emits: ["close"],
};
</script>
