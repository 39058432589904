<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        @openFilter="$store.commit('openFilter')"
        :show_print="true"
        :show_filter="true"
      />
      <!-- End Breadcrumb -->

      <!-- Start Statistics Card-->
      <div class="row mb-5">
        <div
          class="col-lg-3 col-md-4 col-12"
          v-for="item in statisticsItems"
          :key="item.id"
        >
          <stats-card
            :icon="'fal fa-box-usd'"
            :title="'حركات صندوق الواردات'"
            :number="rows.length"
            :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
            :bgColor="'transparent'"
          ></stats-card>
        </div>
      </div>
      <!-- End Statistics Card-->

      <!-- Start Main Section -->
      <main class="main-table">
        <v-data-table
          class="thumb"
          :headers="headers"
          :items="rows"
          :search="search"
          :custom-filter="filterSearch"
          :loading="lodaing"
          :loading-text="$t('table.loadingData')"
          v-model="selected"
          :single-select="singleSelect"
          :items-per-page="rows.length"
          item-key="id"
          hide-default-footer
        >
          <!-- ================== You Can use any slots you want ================== -->
          <!-- ====== Select row field ====== -->
          <!-- title -->
          <template v-slot:[`item.title`]="{ item }">
            <v-chip color="red">
              {{ item.title }}
            </v-chip>
          </template>
          <template v-slot:[`item.index`]="{ index }">
            {{ index + 1 }}
          </template>
          <!-- Select no data State -->
          <template v-slot:no-data>
            {{ $t("table.noData") }}
          </template>

          <!-- Lock Currency -->
          <template v-slot:[`item.lockCurrency`]="{ item }">
            <div class="_actions">
              <v-icon
                class="show"
                small
                @click="$router.push(`locks/lock-currencies/${item.id}`)"
              >
                fal fa-eye
              </v-icon>
            </div>
          </template>

          <!-- Lock Movement -->
          <template v-slot:[`item.lockMovement`]="{ item }">
            <div class="_actions">
              <v-icon
                class="show"
                small
                @click="$router.push(`locks/lock-movement/${item.id}`)"
              >
                fal fa-eye
              </v-icon>
            </div>
          </template>

          <!-- Lock Balances -->
          <template v-slot:[`item.lockBalances`]="{ item }">
            <div class="_actions">
              <v-icon
                class="show"
                small
                @click="$router.push(`locks/lock-balances/${item.id}`)"
              >
                fal fa-eye
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </main>
      <!-- End Main Section -->
      <!-- Start Filter Menu -->
      <table-filter
        :show="$store.state.show_filter"
        @submit_filter="filterRows"
        @closeFilter="$store.commit('closeFilter')"
      ></table-filter>
      <!-- End Filter Menu -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "@/components/Charts/ChartComponent.vue";

export default {
  name: "LockMovement",

  components: {
    StatsCard,
  },

  props: ["id"],

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.locks.title"),
          disabled: false,
          href: "/locks",
        },
        {
          text: this.$t("breadcrumb.locks.lock_movement"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "الرصيد الكلي",
          number: "10",
        },
      ],

      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== Loding
      lodaing: false,
    };
  },

  computed: {
    current_theme() {
      return this.$store.getters["theme_module/current_theme"];
    },
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: "حساب العميل",
            value: "clientAccount",
            sortable: false,
            align: "center",
          },
          {
            text: "نوع العملية",
            value: "operationType",
            sortable: false,
            align: "center",
          },
          {
            text: "الخزنه",
            value: "safe",
            sortable: false,
            align: "center",
          },
          {
            text: "الرصيد",
            value: "balance",
            sortable: false,
            align: "center",
          },

          {
            text: "العملة",
            value: "currency",
            sortable: false,
            align: "center",
          },
          {
            text: "الرصيد قبل",
            value: "balanceBefore",
            sortable: false,
            align: "center",
          },
          {
            text: "الرصيد بعد",
            value: "balanceAfter",
            sortable: false,
            align: "center",
          },
          {
            text: "تاريخ العملية",
            value: "date",
            sortable: false,
            align: "center",
          },
          {
            text: "بواسطة",
            value: "operationBy",
            sortable: false,
            align: "center",
          },
        ];
      }
    },
  },

  methods: {
    // ===== Search Method =====
    filterClick(word) {
      if (!this.lodaing) {
        if (word != "all") {
          this.search = word;
        } else {
          this.search = "";
        }
        this.filterSearch();
      }
    },

    filterSearch(value, search, item) {
      return (
        value != null &&
        search != null &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: `${this.id}/add` });
    },
    showItem(item) {
      this.dialogShow = true;
      this.itemToShow = item;
    },
    editItem() {
      this.$router.push({ path: `${this.id}/edit` });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/currency/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("CRUD.Delete"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // ================ Set Rows
    setRows() {
      this.lodaing = false;
      this.rows = [
        {
          id: 1,
          clientAccount: "Client Account",
          operationType: "Withdraw",
          safe: "الخزنه الرئيسيه",
          balance: 30000,
          currency: "دولار",
          balanceBefore: 50000,
          balanceAfter: 20000,
          date: "30-3-2022",
          operationBy: "Operator Name",
        },
      ];
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    this.setRows();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
