<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        @openFilter="$store.commit('openFilter')"
        :show_print="true"
        :show_filter="true"
      />
      <!-- End Breadcrumb -->

      <!-- Start Statistics Card-->
      <div class="row mb-5">
        <div class="col-lg-3 col-md-4 col-12">
          <stats-card
            :icon="'fal fa-box-usd'"
            :title="'عمليات نقل رصيد صندوق الواردات'"
            :number="rows.length"
            :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
            :bgColor="'transparent'"
          ></stats-card>
        </div>
      </div>
      <!-- End Statistics Card-->

      <!-- Start Main Section -->
      <main class="main-table">
        <v-data-table
          class="thumb"
          :headers="headers"
          :items="rows"
          :search="search"
          :custom-filter="filterSearch"
          :loading="lodaing"
          :loading-text="$t('table.loadingData')"
          v-model="selected"
          :single-select="singleSelect"
          :items-per-page="rows.length"
          item-key="id"
          hide-default-footer
        >
          <!-- ================== You Can use any slots you want ================== -->
          <!-- ====== Select row field ====== -->
          <!-- title -->
          <template v-slot:[`item.title`]="{ item }">
            <v-chip color="red">
              {{ item.title }}
            </v-chip>
          </template>
          <!-- revision status-->
          <template v-slot:[`item.review_status`]="{ item }">
            <v-chip color="#d7c100" v-if="item.review_status == 0">
              منتظر
            </v-chip>
            <v-chip color="green" v-else> تأكيد </v-chip>
          </template>
          <!-- Confirm revision-->
          <template v-slot:[`item.revision_done`]="{ item }">
            <!--  -->
            <div class="_actions actions2">
              <button
                type="button"
                class="accept confirm_btn ml-0"
                @click="revisionDone(item)"
                v-if="item.review_status == 0"
              >
                <i class="far fa-check"></i>
              </button>
              <span v-else> ---- </span>
            </div>
          </template>
          <template v-slot:[`item.index`]="{ index }">
            {{ index + 1 }}
          </template>
          <!-- Select no data State -->
          <template v-slot:no-data>
            {{ $t("table.noData") }}
          </template>

          <!-- Lock Currency -->
          <template v-slot:[`item.description`]="{ item }">
            <div class="_actions">
              <v-icon
                class="show"
                small
                @click="
                  modal.descriptionModalIsOpen = true;
                  setItemDesc(item.description);
                "
              >
                fal fa-eye
              </v-icon>
            </div>
          </template>
          <!-- Select actions-->
          <template v-slot:[`item.actions`]="{ item }">
            <div class="_actions">
              <!-- <v-icon class="show" small @click="showItem(item)">
              fal fa-eye
            </v-icon> -->
              <v-icon class="edit" small @click="editItem(item)">
                fal fa-edit
              </v-icon>
            </div>
          </template>

          <!-- ======================== Start Top Section ======================== -->
          <template v-slot:top>
            <!-- Delete dialog -->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">{{
                  $t("table.deletedialog.areYouSure")
                }}</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#1B5E20" @click="deleteItemConfirm"
                    >{{ $t("table.deletedialog.ok") }}
                    <span
                      class="btn_loader_model"
                      v-if="loadingBtnDialog"
                    ></span
                  ></v-btn>
                  <v-btn color="#F44336" @click="dialogDelete = false">{{
                    $t("table.deletedialog.cancel")
                  }}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- confirm dialog -->
            <v-dialog v-model="dialogConfirm" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">
                  هل تمت المراجعه علي العملية ؟
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#1B5E20" @click="revisionConfirm"
                    >{{ $t("table.deletedialog.ok") }}
                    <span
                      class="btn_loader_model"
                      v-if="loadingBtnDialog"
                    ></span
                  ></v-btn>
                  <v-btn color="#F44336" @click="dialogConfirm = false">{{
                    $t("table.deletedialog.cancel")
                  }}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Description Modal -->
            <DescModal
              :desc="selectedItemDesc"
              :handelModalApperance="modal.descriptionModalIsOpen"
              @close="closeDescModal"
            />

            <!-- Row -->
            <v-row>
              <v-col cols="12" sm="8"> </v-col>
            </v-row>
          </template>
          <!-- ======================== End Top Section ======================== -->
        </v-data-table>
      </main>
      <!-- End Main Section -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "@/components/Charts/ChartComponent.vue";
import DescModal from "@/components/modals/DescriptionModal.vue";

export default {
  name: "AllExpenses",

  components: {
    StatsCard,
    DescModal,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "نقل رصيد صندوق الواردات",
          disabled: false,
          href: "/transfer_balances",
        },
        {
          text: "عرض الكل",
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "all",
          number: "10",
        },
      ],

      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,
      dialogConfirm: false,
      itemtoConfirm: null,
      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // Custom Modal Data
      modal: {
        descriptionModalIsOpen: false,
      },
      selectedItemDesc: null,

      // ========== Loding
      lodaing: false,
    };
  },

  computed: {
    current_theme() {
      return this.$store.getters["theme_module/current_theme"];
    },
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: "الخزنة المحول إليها",
            value: "withdrawnToLock",
            sortable: false,
            align: "center",
          },
          {
            text: "العملة",
            value: "currency",
            sortable: false,
            align: "center",
          },
          {
            text: "المبلغ المحول",
            value: "amount",
            sortable: false,
            align: "center",
          },
          {
            text: "التاريخ",
            value: "date",
            sortable: false,
            align: "center",
          },
          {
            text: "الموظف",
            value: "employee",
            sortable: false,
            align: "center",
          },
          {
            text: "وصف",
            value: "description",
            sortable: false,
            align: "center",
          },
          {
            text: "المراجعة",
            value: "review_status",
            align: "center",
            sortable: false,
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
          {
            text: "تأكيد",
            value: "revision_done",
            align: "center",
            sortable: false,
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
        ];
      }
    },
  },

  methods: {
    // ===== Search Method =====
    filterClick(word) {
      if (!this.lodaing) {
        if (word != "all") {
          this.search = word;
        } else {
          this.search = "";
        }
        this.filterSearch();
      }
    },

    filterSearch(value, search, item) {
      return (
        value != null &&
        search != null &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/inbox/transfer-balances/add" });
    },
    showItem(item) {
      this.dialogShow = true;
      this.itemToShow = item;
    },
    editItem(item) {
      this.$router.push({ path: "/inbox/transfer-balances/edit/" + item.id });
    },
    // confirm item
    revisionDone(item) {
      this.dialogConfirm = true;
      this.itemtoConfirm = item;
    },
    revisionConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "POST",
        url: `client/currency/review/${this.itemtoConfirm.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogConfirm = false;
          this.$iziToast.success({
            timeout: 2000,
            message: "تم التأكيد بنجاح",
            position: "topRight",
          });
        })
        .catch((err) => {
          this.dialogConfirm = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/currency/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("CRUD.Delete"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    setItemDesc(desc) {
      this.selectedItemDesc = desc;
    },
    closeDescModal() {
      this.modal.descriptionModalIsOpen = false;
    },
    // ==================== End CRUD ====================

    // ================ Set Rows
    setRows() {
      this.lodaing = false;
      this.rows = [
        {
          id: 1,
          amount: "7000",
          currency: "جنية مصرى",
          withdrawnFromLock: "Lock 1",
          withdrawnToLock: "Lock 2",
          date: "02-02.2022",
          description:
            "Test Test  Test  Test  Test  Test  Test  Test  Test  Test  Test  Test  Test  Test  Test  Test  Test",
          employee: "موسي",
        },
      ];
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    this.setRows();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
