<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :show_print="true"
        :excelFile="true"
        :excelData="excelData"
      />
      <!-- End Breadcrumb -->
      <!-- start of searchSection  -->
      <SearchSection
        @filterTable="searchTable"
        title="تقارير عمليات السيرفس"
      ></SearchSection>
      <!-- end of searchSection  -->
      <!-- notFound  -->
      <Notfound v-if="!searchItems"></Notfound>
      <!-- Start Statistics Card-->

      <div v-else>
        <ul class="all-state-count">
          <li>
            <div class="state-count">
              <span>عدد تقارير عمليات السيرفس :</span>
              <span>{{ table_length }}</span>
            </div>
          </li>
        </ul>
        <!-- Start Statistics Card-->
        <h6 class="mt-7 mb-6 title-search" v-if="showReport">
          كشف حساب لعميل سيرفس "{{ searchItems.userName }}" عن الفتره من "{{
            searchItems.from
          }}" الي "{{ searchItems.to }}"
        </h6>

        <!-- End Statistics Card-->
        <div class="table-print">
          <main class="main-table">
            <!-- start of  header of printSection  -->
            <PrintHead
              :title="'تقارير عمليات السيرفس'"
              :number="table_length"
            ></PrintHead>
            <!-- end of header of printSection  -->
            <v-data-table
              class="thumb"
              :headers="headers"
              :items="rows"
              :loading="lodaing"
              :loading-text="$t('table.loadingData')"
              v-model="selected"
              :single-select="singleSelect"
              item-key="id"
              :items-per-page="rows.length"
              hide-default-footer
            >
              <!-- ================== You Can use any slots you want ================== -->
              <!-- ====== Select row field ====== -->
              <!-- Select no data State -->
              <template v-slot:no-data>
                {{ $t("table.noData") }}
              </template>
              <template v-slot:[`item.index`]="{index}">
                {{ index + 1 }}
              </template>
              <template v-slot:[`item.type`]="{item}">
                <span v-if="item.type == 'in'" class="greenColor"> إيداع </span>
                <span v-if="item.type == 'out'" class="redColor"> سحب </span>
              </template>
              <template v-slot:[`item.operation_type`]="{item}">
                <span v-if="item.operation_type == 'true'">
                  سيرفس ميناء
                </span>
                <span color="green" v-else> سيرفس عميل </span>
              </template>
              <template v-slot:[`item.commission`]="{item}">
                {{ item.commission }}
              </template>
              <template v-slot:[`item.rate`]="{item}">
                {{ item.rate }}
              </template>
              <template v-slot:[`item.amount`]="{item}">
                <span
                  :class="{
                    redColor: item.type == 'out',
                    greenColor: item.type == 'in',
                  }"
                >
                  {{ Number(item.amount).toLocaleString() }}
                </span>
              </template>
              <template v-slot:[`item.total`]="{item}">
                <span
                  :class="{
                    redColor: item.total < 0,
                    greenColor: item.total > 0,
                  }"
                >
                  {{ Number(item.total).toLocaleString() }}
                </span>
              </template>
              <template v-slot:[`item.da2en`]="{item}">
                <span
                  :class="{
                    greenColor: item.type == 'in',
                  }"
                >
                  {{ item.da2en }}
                </span>
              </template>
              <template v-slot:[`item.maden`]="{item}">
                <span
                  :class="{
                    redColor: item.type == 'out',
                  }"
                >
                  {{ item.maden }}
                </span>
              </template>
              <template v-slot:[`item.customer_amount_before`]="{item}">
                <span
                  :class="{
                    redColor: item.customer_amount_before < 0,
                    greenColor: item.customer_amount_before > 0,
                  }"
                >
                  {{ item.customer_amount_before }}
                </span>
              </template>
              <template v-slot:[`item.amount_after`]="{item}">
                <span
                  v-if="
                    item.type == 'currency_in' || item.type == 'currency_out'
                  "
                >
                  ------
                </span>
                <span
                  :class="{
                    redColor: item.amount_after < 0,
                    greenColor: item.amount_after > 0,
                  }"
                  v-else
                >
                  {{ Number(item.amount_after).toLocaleString() }}
                </span>
              </template>
              <template v-slot:[`item.notes`]="{item}">
                <div class="_actions">
                  <span v-if="item.notes == 'null' || item.notes == null">
                    ----
                  </span>
                  <v-icon
                    class="show"
                    small
                    v-else
                    @click="
                      modal.notesModalIsOpen = true;
                      setItemNotes(item.notes);
                    "
                  >
                    fal fa-eye
                  </v-icon>
                </div>
              </template>

              <!-- Select actions-->
              <template v-slot:[`item.actions`]="{item}">
                <div class="_actions">
                  <v-icon class="delete" small @click="deleteItem(item)">
                    fal fa-trash
                  </v-icon>
                </div>
              </template>

              <!-- ======================== Start Top Section ======================== -->
              <template v-slot:top>
                <!-- Delete dialog -->
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5 justify-center">{{
                      $t("table.deletedialog.areYouSure")
                    }}</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="#1B5E20" @click="deleteItemConfirm"
                        >{{ $t("table.deletedialog.ok") }}
                        <span
                          class="btn_loader_model"
                          v-if="loadingBtnDialog"
                        ></span
                      ></v-btn>
                      <v-btn color="#F44336" @click="dialogDelete = false">{{
                        $t("table.deletedialog.cancel")
                      }}</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- Add & Delete -->

                <!-- Description Modal -->
                <DescModal
                  :desc="selectedItemDesc"
                  :handelModalApperance="modal.descriptionModalIsOpen"
                  @close="closeDescModal"
                />

                <NotesModal
                  :desc="selectedItemNotes"
                  :handelModalApperance="modal.notesModalIsOpen"
                  @close="closeNotesModal"
                />
              </template>
              <!-- ======================== End Top Section ======================== -->
            </v-data-table>
          </main>
          <div class="showMoney" v-if="showReport">
            <ul class="horizintal-ul">
              <li>
                <div class="state-count">
                  <span> {{ user_data[1].name }} : </span>
                  <span class="greenColor"
                    >{{ Number(user_data[1].value).toLocaleString() }}
                    {{ user_currency }}</span
                  >
                </div>
              </li>
              <li>
                <div class="state-count">
                  <span> {{ user_data[2].name }} : </span>
                  <span class="redColor"
                    >{{ Number(user_data[2].value).toLocaleString() }}
                    {{ user_currency }}</span
                  >
                </div>
              </li>
            </ul>
            <ul class="last_ul">
              <li style="width: 100%;">
                <div class="state-count">
                  <span> {{ user_data[0].name }} : </span>
                  <span
                    :class="{
                      redColor: user_data[0].value < 0,
                      greenColor: user_data[0].value > 0,
                    }"
                    >{{ Number(this.user_data[0].value).toLocaleString() }}
                    {{ user_currency }}
                    <span class="mr-2"> "{{ allAmount }}" </span></span
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- End Pagination -->
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
import DescModal from "@/components/modals/DescriptionModal.vue";
import NotesModal from "@/components/modals/NotesModal.vue";
import SearchSection from "@/components/Search/serviceOpration";
import Tafgeet from "tafgeetjs";
export default {
  props: ["id"],

  components: {
    StatsCard,
    DescModal,
    NotesModal,
    SearchSection,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // search
      searchItems: null,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "تقارير عمليات السيرفس",
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "عمليات الإيداع",
          number: "10",
        },
      ],

      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,
      // ========== Date_Menues
      from_date_menu: false,
      to_date_menu: false,

      // ========== Filter
      filter: {
        name: null,
        safe: [],
        from: null,
        to: null,
      },

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== Loding
      lodaing: false,

      // Fixed_data
      safes: ["foo", "bar", "fizz", "buzz"],
      // Custom Modal Data
      modal: {
        descriptionModalIsOpen: false,
        notesModalIsOpen: false,
      },
      selectedItemDesc: null,
      selectedItemNotes: null,
      table_length: null,
      user_data: null,
      showReport: false,
      allAmount: null,
      user_currency: null,
      // excel data
      excelData: {
        name: "تقارير عمليات السيرفس",
        items: null,
        json_fields: {
          الحساب: "balance_customer.name",
          "نوع العملية": {
            field: "type",
            callback: (value) => {
              if (value == "in") {
                return "إيداع";
              } else if (value == "out") {
                return "سحب";
              }
            },
          },
          المبلغ: {
            field: "amount",
            callback: (value) => {
              return Number(value).toLocaleString();
            },
          },
          العمولة: "commission",
          النسبة: "rate",
          دائن: "da2en",
          مدين: "maden",
          "الرصيد بعد العملية": {
            field: "amount_after",
            callback: (value) => {
              return Number(value).toLocaleString();
            },
          },
          "تاريخ العملية": "created_at",
          وصف: "desc",
        },
        footer: null,
      },
    };
  },

  watch: {
    [`paginations.current_page`]() {
      this.setRows();
    },
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: "الحساب",
            value: "balance_customer.name",
            align: "center",
            width: 150,
          },
          {
            text: "النوع",
            value: "type",
            align: "center",
            width: 120,
          },
          {
            text: "المبلغ",
            value: "amount",
            align: "center",
            sortable: false,
            width: 100,
          },
          {
            text: "العمولة",
            value: "commission",
            align: "center",
            sortable: false,
            width: 80,
          },
          {
            text: "النسبة",
            value: "rate",
            align: "center",
            sortable: false,
            width: 120,
          },
          {
            text: "دائن",
            value: "da2en",
            align: "center",
            sortable: false,
            width: 100,
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
          {
            text: "مدين",
            value: "maden",
            align: "center",
            sortable: false,
            width: 100,
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
          {
            text: "الرصيد بعد العملية",
            value: "amount_after",
            align: "center",
            sortable: false,
            width: 150,
          },
          {
            text: "نوع العملية",
            value: "operation_type",
            align: "center",
            width: 100,
          },
          {
            text: "تاريخ العملية",
            value: "created_at",
            align: "center",
            width: 120,
          },
          {
            text: "وصف",
            value: "desc",
            align: "center",
            sortable: false,
            width: 300,
          },
        ];
      }
    },
  },

  methods: {
    // searchTable
    searchTable(items) {
      if (items.userName) {
        this.showReport = true;
      } else {
        this.showReport = false;
      }
      this.rows = [];
      this.searchItems = items;
      this.setRows();
    },
    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({
        path: this.id ? `/deposits/${this.id}/add` : `/deposits_ultimate/add`,
      });
    },
    showItem(item) {
      this.dialogShow = true;
      this.itemToShow = item;
    },
    editItem(item) {
      this.$router.push({path: "/deposits/edit/" + item.id});
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `CrudNsame/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // Set Rows
    setRows() {
      this.allAmount = null;
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/service_operation",
        params: {
          operation_type: this.searchItems ? this.searchItems.operation_typeVal : null,
          customer: this.searchItems ? this.searchItems.userId : null,
          type: this.searchItems ? this.searchItems.typeVal : null,
          from_date: this.searchItems ? this.searchItems.from : null,
          to_date: this.searchItems ? this.searchItems.to : null,
        },
      })
        .then((res) => {
          this.lodaing = false;
          this.rows = res.data.data;
          this.rows.forEach((ele) => {
            if (
              ele.type == "bank_in" ||
              ele.type == "in" ||
              ele.type == "transfer" ||
              ele.type == "currency_in"
            ) {
              ele["da2en"] = Number(ele.total).toLocaleString();
            } else {
              ele["da2en"] = "-------";
            }
            if (
              ele.type == "bank_out" ||
              ele.type == "out" ||
              ele.type == "currency_out"
            ) {
              ele["maden"] = Number(ele.total).toLocaleString();
            } else {
              ele["maden"] = "-------";
            }
          });
          this.table_length = this.rows.length;
          this.excelData.items = this.rows;
          this.user_data = res.data.user_data;
          this.user_currency = res.data.currency;
          if (this.user_data[0].value > 0) {
            var totalNum = Number(this.user_data[0].value);
          } else {
            var totalNum = Number(this.user_data[0].value.substring(1));
          }
          var Tafgeet = require("tafgeetjs");
          if (this.user_data[0].value > 0) {
            this.allAmount = new Tafgeet(totalNum, "").parse().substring(3);
            this.allAmount =
              this.allAmount.slice(0, this.allAmount.length - 7) +
              " " +
              this.user_currency +
              " " +
              "فقط لا غير";
          } else if (this.user_data[0].value < 0) {
            this.allAmount = new Tafgeet(totalNum, "").parse().substring(3);
            this.allAmount =
              "سالب" +
              " " +
              this.allAmount.slice(0, this.allAmount.length - 7) +
              " " +
              this.user_currency +
              " " +
              "فقط لا غير";
          } else {
            this.allAmount =
              "صفر" + " " + this.user_currency + " " + "فقط لا غير";
          }
          this.excelData.footer = [
            "إجمالي الإيداع : " +
              " " +
              Number(this.user_data[1].value).toLocaleString() +
              this.user_currency,
            "إجمالي السحب : " +
              " " +
              Number(this.user_data[2].value).toLocaleString() +
              this.user_currency,
            "رصيد العميل الحالي : " +
              " " +
              Number(this.user_data[0].value).toLocaleString() +
              this.user_currency +
              " " +
              `"${this.allAmount}"`,
          ];
        })
        .catch((err) => {
          this.lodaing = false;
          console.log(err);
        });
    },
    setItemDesc(desc) {
      this.selectedItemDesc = desc;
    },
    setItemNotes(notes) {
      this.selectedItemNotes = notes;
    },
    closeDescModal() {
      this.modal.descriptionModalIsOpen = false;
    },
    closeNotesModal() {
      this.modal.notesModalIsOpen = false;
    },
  },

  // ======= hooks
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("lock_and_deals")
    ) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped></style>
