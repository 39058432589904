<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb :items="items" :show_print="true" />
      <!-- End Breadcrumb -->

      <!-- Start Statistics Card-->
      <div class="row mb-5">
        <div class="col-lg-3 col-md-4 col-12">
          <stats-card
            :icon="'fal fa-fingerprint'"
            :title="'عملات الخزنة'"
            :number="20"
            :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
            :bgColor="'transparent'"
          ></stats-card>
        </div>
      </div>
      <!-- End Statistics Card-->

      <!-- Start Main Section -->
      <main class="main-table table-print">
        <!-- start of  header of printSection  -->
        <PrintHead :title="'الخزن'" :number="table_length"></PrintHead>
        <!-- end of header of printSection  -->
        <v-data-table
          class="thumb"
          :headers="headers"
          :items="rows"
          :search="search"
          :custom-filter="filterSearch"
          :loading="lodaing"
          :loading-text="$t('table.loadingData')"
          v-model="selected"
          :single-select="singleSelect"
          :items-per-page="rows.length"
          item-key="id"
          hide-default-footer
        >
          <!-- ================== You Can use any slots you want ================== -->
          <!-- ====== Select row field ====== -->
          <!-- title -->
          <template v-slot:[`item.title`]="{item}">
            <v-chip color="red">
              {{ item.title }}
            </v-chip>
          </template>
          <!-- revision status-->
          <template v-slot:[`item.review_status`]="{item}">
            <v-chip color="#d7c100" v-if="item.review_status == 0">
              منتظر
            </v-chip>
            <v-chip color="green" v-else> تأكيد </v-chip>
          </template>
          <!-- Confirm revision-->
          <template v-slot:[`item.revision_done`]="{item}">
            <!--  -->
            <div class="_actions actions2">
              <button
                type="button"
                class="accept confirm_btn ml-0"
                @click="revisionDone(item)"
                v-if="item.review_status == 0"
              >
                <i class="far fa-check"></i>
              </button>
              <span v-else> ---- </span>
            </div>
          </template>
          <template v-slot:[`item.index`]="{index}">
            {{ index + 1 }}
          </template>
          <!-- Select no data State -->
          <template v-slot:no-data>
            {{ $t("table.noData") }}
          </template>

          <!-- Lock Currency -->
          <template v-slot:[`item.lockCurrency`]="{item}">
            <div class="_actions">
              <v-icon
                class="show"
                small
                @click="$router.push(`locks/lock-currencies/${item.id}`)"
              >
                fal fa-eye
              </v-icon>
            </div>
          </template>

          <!-- Lock Movement -->
          <template v-slot:[`item.lockMovement`]="{item}">
            <div class="_actions">
              <v-icon
                class="show"
                small
                @click="$router.push(`locks/lock-movement/${item.id}`)"
              >
                fal fa-eye
              </v-icon>
            </div>
          </template>

          <!-- Lock Balances -->
          <template v-slot:[`item.lockBalances`]="{item}">
            <div class="_actions">
              <v-icon
                class="show"
                small
                @click="$router.push(`locks/lock-balances/${item.id}`)"
              >
                fal fa-eye
              </v-icon>
            </div>
          </template>

          <!-- Select actions-->
          <template v-slot:[`item.actions`]="{item}">
            <div class="_actions">
              <v-icon class="delete" small @click="deleteItem(item)">
                fal fa-trash
              </v-icon>
            </div>
          </template>

          <!-- ======================== Start Top Section ======================== -->
          <template v-slot:top>
            <!-- Delete dialog -->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">{{
                  $t("table.deletedialog.areYouSure")
                }}</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#1B5E20" @click="deleteItemConfirm"
                    >{{ $t("table.deletedialog.ok") }}
                    <span
                      class="btn_loader_model"
                      v-if="loadingBtnDialog"
                    ></span
                  ></v-btn>
                  <v-btn color="#F44336" @click="dialogDelete = false">{{
                    $t("table.deletedialog.cancel")
                  }}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- confirm dialog -->
            <v-dialog v-model="dialogConfirm" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">
                  هل تمت المراجعه علي العملية ؟
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#1B5E20" @click="revisionConfirm"
                    >{{ $t("table.deletedialog.ok") }}
                    <span
                      class="btn_loader_model"
                      v-if="loadingBtnDialog"
                    ></span
                  ></v-btn>
                  <v-btn color="#F44336" @click="dialogConfirm = false">{{
                    $t("table.deletedialog.cancel")
                  }}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogAdd" min-width="50%">
              <v-card class="p-0">
                <div class="show_modal_content_wrapper">
                  <h4 class="text-center">اضف عملة جديدة</h4>
                  <form @submit.prevent="validateForm">
                    <div class="container">
                      <div class="row justify-content-between">
                        <!-- START:: INPUT WRAPPER -->
                        <div class="col-12 py-0">
                          <div class="input_wrapper top_label mb-3">
                            <label class="form-label"> إختر العملة </label>
                            <multiselect
                              v-model="data.currencyName"
                              :options="currenciesValues"
                              label="name"
                              track-by="name"
                              placeholder=" "
                              :searchable="true"
                              :allow-empty="false"
                              :show-labels="false"
                            >
                            </multiselect>
                          </div>
                          <h6 class="mt-0 mb-0 mx-0 amount-section">
                            إختر العملة لخزنة ( Lock Name )
                          </h6>
                        </div>
                        <!-- END:: INPUT WRAPPER -->
                      </div>
                    </div>

                    <div class="buttons_wrapper">
                      <button
                        class="button_style_1"
                        :class="{buttonIndex: btnIsLoading}"
                        :disabled="btnIsLoading"
                      >
                        {{ $t("Forms.submit") }}
                        <span class="btn_loader" v-if="btnIsLoading"></span>
                      </button>
                    </div>
                  </form>
                </div>
              </v-card>
            </v-dialog>
          </template>
          <!-- ======================== End Top Section ======================== -->
        </v-data-table>
      </main>
      <!-- End Main Section -->
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";

export default {
  name: "LockCurrencies",

  components: {
    StatsCard,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.locks.title"),
          disabled: false,
          href: "/locks",
        },
        {
          text: this.$t("breadcrumb.locks.lock_currencies"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "all",
          number: "10",
        },
      ],

      // ========== Top Section
      search: "",

      btnIsLoading: false,

      // ========== dialog Status
      dialogAdd: false,
      itemToShow: null,
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,
      dialogConfirm: false,
      itemtoConfirm: null,
      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== Loding
      lodaing: false,

      data: {
        currencyName: {},
      },
      currenciesValues: [
        {id: 1, name: "Option_1"},
        {id: 2, name: "Option_2"},
        {id: 3, name: "Option_3"},
      ],
    };
  },
  computed: {
    current_theme() {
      return this.$store.getters["theme_module/current_theme"];
    },
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: "إسم العملة",
            value: "currencyName",
            sortable: false,
            align: "center",
          },
          {
            text: "تاريخ الإضافة",
            value: "date",
            sortable: false,
            align: "center",
          },
          {
            text: "المراجعة",
            value: "review_status",
            align: "center",
            sortable: false,
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
          {
            text: "تأكيد",
            value: "revision_done",
            align: "center",
            sortable: false,
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
        ];
      }
    },
  },

  methods: {
    // ===== Search Method =====
    filterClick(word) {
      if (!this.lodaing) {
        if (word != "all") {
          this.search = word;
        } else {
          this.search = "";
        }
        this.filterSearch();
      }
    },

    filterSearch(value, search, item) {
      return (
        value != null &&
        search != null &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.dialogAdd = true;
    },
    showItem(item) {
      this.dialogAdd = true;
      this.itemToShow = item;
    },
    editItem(item) {
      this.$router.push({path: "/locks/edit/" + item.id});
    },
    // confirm item
    revisionDone(item) {
      this.dialogConfirm = true;
      this.itemtoConfirm = item;
    },
    revisionConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "POST",
        url: `client/currency/review/${this.itemtoConfirm.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogConfirm = false;
          this.$iziToast.success({
            timeout: 2000,
            message: "تم التأكيد بنجاح",
            position: "topRight",
          });
        })
        .catch((err) => {
          this.dialogConfirm = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/currency/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("CRUD.Delete"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // ================ Set Rows
    setRows() {
      this.rows = [
        {
          id: 1,
          currencyName: "Currency Name",
          date: "29-3-2022",
        },
      ];
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    this.setRows();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("lock_and_deals")
    ) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped></style>
