<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_title="العملات"
        search_route="/currency"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">إسم العملة</h4>
        </div>

        <!-- START:: CREATE FORM WRAPPER -->
        <form @submit.prevent="validateForm">
          <div class="container">
            <div class="row justify-content-between">
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-12 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.name_ar"
                  />
                  <label for="name_input" class="form-label">إسم العملة</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <!-- <div class="col-lg-12 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.name_en"
                />
                <label for="name_input" class="form-label">{{
                  $t("CRUDS.Currency.data.name_en")
                }}</label>
              </div>
            </div> -->
              <!-- END:: INPUT WRAPPER -->
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              {{ $t("Forms.submit") }}
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- END:: CREATE FORM WRAPPER -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Update",

  props: ["id"],

  data() {
    return {
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.currency.edit"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========== create_data
      data: {
        name_ar: null,
        name_en: null,
      },

      // ========== Fixed Data
    };
  },

  methods: {
    // START:: VALIDATE CREATE FORM
    validateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;

        let submit_data = {
          ar: {
            name: this.data.name_ar,
          },
          en: {
            name: this.data.name_ar,
          },
        };

        if (!this.data.name_ar) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("CRUDS.Currency.validation.name_ar"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.$axios({
            method: "PUT",
            url: `client/currency/${this.id}`,
            data: submit_data,
          })
            .then(() => {
              this.btnIsLoading = false;
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("editSuccess"),
                position: "topRight",
              });
              this.$router.push({path: "/currency"});
            })
            .catch((err) => {
              this.btnIsLoading = false;
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "topRight",
              });
            });
          return;
        }
      }
    },
    // END:: VALIDATE CREATE FORM

    // =============== Get Data
    getData() {
      this.$axios({
        method: "GET",
        url: `client/currency/${this.id}`,
      })
        .then((res) => {
          this.data.name_ar = res.data.data.name_ar;
          this.data.name_en = res.data.data.name_en;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  created() {
    this.getData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("lock_and_deals")
    ) {
      this.$router.push("/");
    }
  },
};
</script>
