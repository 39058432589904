<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :show_print="true"
        :excelFile="true"
        :excelData="excelData"
      />
      <!-- End Breadcrumb -->

      <!-- Start Statistics Card-->
      <ul class="all-state-count">
        <li>
          <div class="state-count">
            <span>عدد العملات :</span>
            <span>{{ table_length }}</span>
          </div>
        </li>
      </ul>
      <!-- End Statistics Card-->

      <!-- Start Main Section -->
      <main class="main-table table-print">
        <!-- start of  header of printSection  -->
        <PrintHead :title="'العملات'" :number="table_length"></PrintHead>
        <!-- end of header of printSection  -->
        <v-data-table
          class="thumb"
          :headers="headers"
          :items="rows"
          :search="search"
          :custom-filter="filterSearch"
          :loading="lodaing"
          :loading-text="$t('table.loadingData')"
          v-model="selected"
          :single-select="singleSelect"
          :items-per-page="rows.length"
          item-key="id"
          hide-default-footer
        >
          <!-- ================== You Can use any slots you want ================== -->
          <!-- ====== Select row field ====== -->
          <!-- title -->
          <template v-slot:[`item.title`]="{ item }">
            <v-chip color="red">
              {{ item.title }}
            </v-chip>
          </template>
          <template v-slot:[`item.index`]="{ index }">
            {{ index + 1 }}
          </template>
          <!-- Select no data State -->
          <template v-slot:no-data>
            {{ $t("table.noData") }}
          </template>

          <!-- Select actions-->
          <template v-slot:[`item.actions`]="{ item }">
            <div class="_actions">
              <v-icon class="edit" small @click="editItem(item)">
                fal fa-edit
              </v-icon>
              <v-icon
                class="delete"
                small
                @click="deleteItem(item)"
                v-if="item.id != 28"
              >
                fal fa-trash
              </v-icon>
            </div>
          </template>

          <!-- ======================== Start Top Section ======================== -->
          <template v-slot:top>
            <!-- Delete dialog -->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">{{
                  $t("table.deletedialog.areYouSure")
                }}</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#1B5E20" @click="deleteItemConfirm"
                    >{{ $t("table.deletedialog.ok") }}
                    <span
                      class="btn_loader_model"
                      v-if="loadingBtnDialog"
                    ></span
                  ></v-btn>
                  <v-btn color="#F44336" @click="dialogDelete = false">{{
                    $t("table.deletedialog.cancel")
                  }}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <!-- ======================== End Top Section ======================== -->
        </v-data-table>
      </main>
      <!-- End Main Section -->
      <!-- Start Filter Menu -->
      <table-filter
        :show="$store.state.show_filter"
        @submit_filter="filterRows"
        @closeFilter="$store.commit('closeFilter')"
      ></table-filter>
      <!-- End Filter Menu -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";

export default {
  components: {
    StatsCard,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.currency.title"),
          disabled: false,
          href: "/currency",
        },
        {
          text: this.$t("breadcrumb.currency.all"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "all",
          number: "10",
        },
      ],

      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== Loding
      lodaing: false,
      // excel data
      excelData: {
        name: "العملات",
        items: null,
        json_fields: {
          "اسم العملة": "name_ar",
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          { text: "اسم العملة", value: "name_ar", align: "center" },
          // {
          //   text: "اسم العملة (الانجليزية)",
          //   value: "name_en",
          //   align: "center",
          // },
          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
        ];
      } else {
        return [
          {
            text: "currency_name",
            value: "name_ar",
            align: "center",
          },
          // {
          //   text: "currency_name (English)",
          //   value: "name_en",
          //   align: "center",
          // },
          {
            text: "Acctions",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ];
      }
    },
  },

  methods: {
    // ===== Search Method =====
    filterClick(word) {
      if (!this.lodaing) {
        if (word != "all") {
          this.search = word;
        } else {
          this.search = "";
        }
        this.filterSearch();
      }
    },

    filterSearch(value, search, item) {
      return (
        value != null &&
        search != null &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/currency/add" });
    },
    showItem(item) {
      // this.$router.push({ path: "/sliders/show/" + item.id });
    },
    editItem(item) {
      this.$router.push({ path: "/currency/edit/" + item.id });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/currency/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("CRUD.Delete"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // ================ Set Rows
    setRows() {
      this.rows = [];
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/currency",
      })
        .then((res) => {
          this.rows = res.data.data;
          this.table_length = this.rows.length;
          this.excelData.items = this.rows;
          this.lodaing = false;
        })
        .catch((err) => {
          console.log(err);
          this.lodaing = false;
        });
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    this.setRows();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("lock_and_deals")
    ) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped></style>
