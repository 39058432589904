<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb :items="items" :show_print="false" />
      <!-- End Breadcrumb -->
      <!-- start of searchSection  -->
      <SearchSection
        @filterTable="searchTable"
        title="معاملات السحب"
      ></SearchSection>
      <!-- end of searchSection  -->
      <!-- Start Statistics Card-->
      <div>
        <!-- Start Statistics Card-->
        <ul class="all-state-count">
          <li>
            <div class="state-count">
              <span>محذوفات عمليات السحب :</span>
              <span>{{ table_length }}</span>
            </div>
          </li>
        </ul>
        <!-- End Statistics Card-->

        <main class="main-table table-print">
          <!-- start of  header of printSection  -->
          <PrintHead
            :title="'معاملات السحب'"
            :number="table_length"
          ></PrintHead>
          <!-- end of header of printSection  -->
          <v-data-table
            class="thumb"
            :headers="headers"
            :items="rows"
            :loading="lodaing"
            :loading-text="$t('table.loadingData')"
            v-model="selected"
            :single-select="singleSelect"
            item-key="id"
            :items-per-page="rows.length"
            hide-default-footer
          >
            <!-- ================== You Can use any slots you want ================== -->
            <!-- ====== Select row field ====== -->
            <!-- Select no data State -->
            <template v-slot:no-data>
              {{ $t("table.noData") }}
            </template>
            <template v-slot:[`item.index`]="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              {{ Number(item.amount).toLocaleString() }}
            </template>
            <template v-slot:[`item.total`]="{ item }">
              {{ Number(item.total).toLocaleString() }}
            </template>
            <template v-slot:[`item.desc`]="{ item }">
              <div class="_actions">
                <v-icon
                  class="show"
                  small
                  @click="
                    modal.descriptionModalIsOpen = true;
                    setItemDesc(item.desc);
                  "
                >
                  fal fa-eye
                </v-icon>
              </div>
            </template>

            <template v-slot:[`item.notes`]="{ item }">
              <div class="_actions">
                <span v-if="item.notes == 'null' || item.notes == null">
                  ----
                </span>
                <v-icon
                  class="show"
                  small
                  v-else
                  @click="
                    modal.notesModalIsOpen = true;
                    setItemNotes(item.notes);
                  "
                >
                  fal fa-eye
                </v-icon>
              </div>
            </template>
            <!-- Select actions-->
            <template v-slot:[`item.actions`]="{ item }">
              <div class="_actions">
                <v-icon
                  class="restore"
                  color="success"
                  small
                  @click="restoreItem(item)"
                >
                  fal fa-undo
                </v-icon>
              </div>
            </template>
            <template v-slot:[`item.delete`]="{ item }">
              <div class="_actions">
                <v-icon class="delete" small @click="deleteItemEnd(item)">
                  fal fa-trash
                </v-icon>
              </div>
            </template>

            <!-- ======================== Start Top Section ======================== -->
            <template v-slot:top>
              <!-- Restore dialog -->
              <v-dialog v-model="dialogRestore" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">
                    {{ $t("table.restoredialog.areYouSure") }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="restoreItemConfirm">
                      {{ $t("table.restoredialog.ok") }}
                      <span
                        class="btn_loader_model"
                        v-if="loadingBtnDialog"
                      ></span>
                    </v-btn>
                    <v-btn color="#F44336" @click="dialogRestore = false">
                      {{ $t("table.restoredialog.cancel") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Add & Delete -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">
                    {{ $t("table.deletedialog.areYouSure") }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="deleteItemEndConfirm">
                      {{ $t("table.deletedialog.ok") }}
                      <span
                        class="btn_loader_model"
                        v-if="loadingBtnDialog"
                      ></span>
                    </v-btn>
                    <v-btn color="#F44336" @click="dialogDelete = false">
                      {{ $t("table.deletedialog.cancel") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Add & Delete -->

              <!-- Description Modal -->
              <DescModal
                :desc="selectedItemDesc"
                :handelModalApperance="modal.descriptionModalIsOpen"
                @close="closeDescModal"
              />

              <NotesModal
                :desc="selectedItemNotes"
                :handelModalApperance="modal.notesModalIsOpen"
                @close="closeNotesModal"
              />
            </template>
            <!-- ======================== End Top Section ======================== -->
          </v-data-table>
        </main>
        <!-- End Main Section -->
      </div>
      <!-- End Pagination -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
import DescModal from "@/components/modals/DescriptionModal.vue";
import NotesModal from "@/components/modals/NotesModal.vue";
import SearchSection from "@/components/Search/deletedTable";
export default {
  props: ["id"],

  components: {
    StatsCard,
    DescModal,
    NotesModal,
    SearchSection,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // search
      searchItems: null,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "محذوفات معاملات السحب",
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "عمليات السحب",
          number: "10",
        },
      ],

      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogRestore: false,
      itemtoRestore: null,
      dialogDelete: false,
      itemtoDelete: null,

      // ========== Date_Menues
      from_date_menu: false,
      to_date_menu: false,

      // ========== Filter
      filter: {
        name: null,
        safe: [],
        from: null,
        to: null,
      },

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,
      // ========== Loding
      lodaing: false,

      // Fixed_data
      safes: ["foo", "bar", "fizz", "buzz"],
      // Custom Modal Data
      modal: {
        descriptionModalIsOpen: false,
        notesModalIsOpen: false,
      },
      selectedItemDesc: null,
      selectedItemNotes: null,
      table_length: null,
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: "اسم العميل",
            value: "balance_customer.name",
            align: "center",
            width: 150,
          },

          {
            text: "الخزنة",
            value: "wallet.name",
            align: "center",
            width: 100,
          },
          {
            text: "المبلغ",
            value: "amount",
            align: "center",
            width: 100,
            sortable: false,
          },
          {
            text: "العمولة",
            value: "commission",
            align: "center",
            sortable: false,
            width: 80,
          },
          {
            text: "النسبة",
            value: "rate",
            align: "center",
            sortable: false,
            width: 80,
          },

          {
            text: "الإجمالي",
            value: "total",
            align: "center",
            sortable: false,
            width: 100,
          },
          {
            text: "التاريخ",
            value: "created_at",
            align: "center",
            width: 100,
          },
          {
            text: "وصف",
            value: "desc",
            align: "center",
            sortable: false,
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 80,
          },
          {
            text: "ملاحظات",
            value: "notes",
            align: "center",
            sortable: false,
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 80,
          },
          {
            text: "بواسطة",
            value: "by.name",
            align: "center",
            sortable: false,
            width: 100,
          },
          {
            text: "الحاذف",
            value: "deletedBy.name",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 100,
          },
          {
            text: "تاريخ الحذف",
            value: "deleted_at",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 100,
          },
          {
            text: "إرجاع",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
          {
            text: "حذف",
            value: "delete",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
        ];
      }
    },
  },

  methods: {
    // searchTable
    searchTable(items) {
      this.rows = [];
      this.searchItems = items;
      this.setRows();
    },
    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({
        path: this.id ? `/deposits/${this.id}/add` : `/deposits_ultimate/add`,
      });
    },
    showItem(item) {
      this.dialogShow = true;
      this.itemToShow = item;
    },
    editItem(item) {
      this.$router.push({ path: "/withdraws_ultimate/edit/" + item.id });
    },
    // delete end
    deleteItemEnd(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemEndConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "GET",
        url: `client/walletoperation/force_delete/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: "تم مسح العنصر نهائياّ بنجاح",
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
      // Submit the delete service then submit this
    },
    // ===== Restore
    restoreItem(item) {
      this.dialogRestore = true;
      this.itemtoRestore = item;
    },
    restoreItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "GET",
        url: `client/walletoperation/restore_trash_item/${this.itemtoRestore.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogRestore = false;
          this.$iziToast.success({
            timeout: 2000,
            message: "تم إرجاع العنصر بنجاح",
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogRestore = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
      // Submit the delete service then submit this
    },
    // ==================== End CRUD ====================
    // Set Rows
    setRows() {
      this.lodaing = true;
      let myParams = {
        type: "out",
        deleted_by_id: this.searchItems ? this.searchItems.username : null,
        from_date: this.searchItems ? this.searchItems.from : null,
        to_date: this.searchItems ? this.searchItems.to : null,
      };
      this.$axios({
        method: "GET",
        url: "client/walletoperation/get_trash",
        params: myParams,
      })
        .then((res) => {
          this.rows = res.data.data;
          this.table_length = this.rows.length;

          this.lodaing = false;
        })
        .catch((err) => {
          this.lodaing = false;
        });
    },
    setItemDesc(desc) {
      this.selectedItemDesc = desc;
    },
    setItemNotes(notes) {
      this.selectedItemNotes = notes;
    },
    closeDescModal() {
      this.modal.descriptionModalIsOpen = false;
    },
    closeNotesModal() {
      this.modal.notesModalIsOpen = false;
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    this.setRows();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (!userType.includes("superadmin")) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped></style>
