<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/locks"
        search_title="الخزن"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">تعديل خزنة</h4>
        </div>

        <!-- START:: CREATE FORM WRAPPER -->
        <form @submit.prevent="validateForm">
          <div class="container">
            <div class="row justify-content-between">
              <!-- START:: INPUT WRAPPER -->
              <div class="col-12 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.lockName"
                  />
                  <label for="name_input" class="form-label">إسم الخزنة</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              تعديل
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- END:: CREATE FORM WRAPPER -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Add",

  data() {
    return {
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.locks.edit"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========= Date Menu
      dateMenu: false,

      // search_users
      search_users: "",

      // ========== create_data
      data: {
        lockName: null,
        branch: "",
        username: null,
      },

      // ========== Fixed Data
      branches: [],
      employees: [],
    };
  },

  watch: {
    [`search_users`](newVal) {
      this.get_filterd_users(newVal);
    },
  },

  methods: {
    getEmployee() {
      // ===== Currencies
      this.$axios({
        method: "GET",
        url: `client/user`,
      }).then((res) => {
        this.employees = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      });
    },
    // ============== Get Data
    getData() {
      this.$axios({
        method: "GET",
        url: `client/wallet/${this.$route.params.id}`,
      }).then((res) => {
        this.data = {
          lockName: res.data.data.name,
          branch: res.data.data.branch,
          username: res.data.data.user,
        };
      });
      // ===== Currencies
      this.$axios({
        method: "GET",
        url: `client/branch`,
      }).then((res) => {
        this.branches = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name_ar,
          };
        });
      });
    },

    // get_filterd_users
    get_filterd_users(keyword) {
      this.$axios({
        method: "GET",
        url: `client/customer`,
        params: {keyword: keyword},
      }).then((res) => {
        this.users = res.data.data;
      });
    },

    // START:: VALIDATE CREATE FORM
    validateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.lockName) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال اسم الخزنة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.submitData();
        }
      }
    },
    // END:: VALIDATE CREATE FORM

    // Submit Data
    submitData() {
      const submit_data = {
        name: this.data.lockName,
      };
      this.$axios({
        method: "PUT",
        url: "client/wallet/" + this.$route.params.id,
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "topRight",
          });
          this.$router.push({path: "/locks"});
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
  },

  created() {
    this.getData();
    this.getEmployee();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("lock_and_deals")
    ) {
      this.$router.push("/");
    }
  },
};
</script>
