<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb :items="items" :show_print="false" />
      <!-- End Breadcrumb -->
      <!-- Start Statistics Card-->
      <ul class="all-state-count">
        <li>
          <div class="state-count">
            <span>محذوفات بنوك دبي :</span>
            <span>{{ table_length }}</span>
          </div>
        </li>
      </ul>
      <!-- End Statistics Card-->

      <!-- Start Main Section -->
      <main class="main-table table-print">
        <!-- start of  header of printSection  -->
        <PrintHead :title="'البنوك'" :number="table_length"></PrintHead>
        <!-- end of header of printSection  -->
        <v-data-table
          class="thumb"
          :headers="headers"
          :items="rows"
          :loading="lodaing"
          :loading-text="$t('table.loadingData')"
          v-model="selected"
          :single-select="singleSelect"
          item-key="id"
          :items-per-page="rows.length"
          hide-default-footer
        >
          <!-- ================== You Can use any slots you want ================== -->
          <!-- ====== Select row field ====== -->
          <!-- Select no data State -->
          <template v-slot:no-data>
            {{ $t("table.noData") }}
          </template>
          <template v-slot:[`item.index`]="{ index }">
            {{ index + 1 }}
          </template>
          <!-- User Accounts-->
          <template v-slot:[`item.accounts`]="{ item }">
            <div class="_actions">
              <v-icon class="show" small @click="clientCommission(item)">
                fal fa-eye
              </v-icon>
            </div>
          </template>
          <!-- Select actions-->
          <template v-slot:[`item.actions`]="{ item }">
            <div class="_actions">
              <v-icon
                class="restore"
                color="success"
                small
                @click="restoreItem(item)"
              >
                fal fa-undo
              </v-icon>
            </div>
          </template>
          <template v-slot:[`item.delete`]="{ item }">
            <div class="_actions">
              <v-icon class="delete" small @click="deleteItemEnd(item)">
                fal fa-trash
              </v-icon>
            </div>
          </template>

          <!-- ======================== Start Top Section ======================== -->
          <template v-slot:top>
            <!-- Restore dialog -->
            <v-dialog v-model="dialogRestore" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">
                  {{ $t("table.restoredialog.areYouSure") }}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#1B5E20" @click="restoreItemConfirm">
                    {{ $t("table.restoredialog.ok")
                    }}<span
                      class="btn_loader_model"
                      v-if="loadingBtnDialog"
                    ></span>
                  </v-btn>
                  <v-btn color="#F44336" @click="dialogRestore = false">
                    {{ $t("table.restoredialog.cancel") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Add & Delete -->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">
                  {{ $t("table.deletedialog.areYouSure") }}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#1B5E20" @click="deleteItemEndConfirm">
                    {{ $t("table.deletedialog.ok") }}
                    <span
                      class="btn_loader_model"
                      v-if="loadingBtnDialog"
                    ></span>
                  </v-btn>
                  <v-btn color="#F44336" @click="dialogDelete = false">
                    {{ $t("table.deletedialog.cancel") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <!-- ======================== End Top Section ======================== -->
        </v-data-table>
      </main>
      <!-- End Main Section -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "./../../../../components/Charts/ChartComponent.vue";

export default {
  props: ["id"],

  components: {
    StatsCard,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "محذوفات بنوك دبي",
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "كل بنوك دبي",
          number: "10",
        },
      ],

      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogRestore: false,
      itemtoRestore: null,
      dialogDelete: false,
      itemtoDelete: null,

      // ========== Date_Menues
      from_date_menu: false,
      to_date_menu: false,

      // ========== Filter
      filter: {
        name: null,
        safe: [],
        from: null,
        to: null,
      },

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== Loding
      lodaing: false,

      // Fixed_data
      safes: ["foo", "bar", "fizz", "buzz"],
    };
  },

  watch: {
    [`filter.name`]() {
      this.filterRows();
    },
    [`filter.safe`]() {
      this.filterRows();
    },
    [`filter.from`]() {
      this.filterRows();
    },
    [`filter.to`]() {
      this.filterRows();
    },
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: "اسم البنك",
            value: "name",
            align: "center",
          },
          {
            text: "السويفت كود",
            value: "swift_number",
            align: "center",
          },
          {
            text: "حسابات البنك",
            value: "accounts",
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            sortable: false,
          },
          {
            text: "تاريخ الحذف",
            value: "deleted_at",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 100,
          },
          {
            text: "الحاذف",
            value: "deletedBy",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 100,
          },
          {
            text: "إرجاع",
            value: "actions",
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
          {
            text: "حذف",
            value: "delete",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
        ];
      }
    },
  },

  methods: {
    // Filter
    filterRows() {
      console.log(this.filter);
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: `/banks/add` });
    },
    showItem(item) {
      this.dialogShow = true;
      this.itemToShow = item;
    },
    // delete end
    deleteItemEnd(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemEndConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "GET",
        url: `client/bank_dubai/force_delete/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: "تم مسح العنصر نهائياّ بنجاح",
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
      // Submit the delete service then submit this
    },
    // ===== Restore
    restoreItem(item) {
      this.dialogRestore = true;
      this.itemtoRestore = item;
    },
    restoreItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "GET",
        url: `client/bank_dubai/restore_trash_item/${this.itemtoRestore.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogRestore = false;
          this.$iziToast.success({
            timeout: 2000,
            message: "تم الإرجاع بنجاح",
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogRestore = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
      // Submit the delete service then submit this
    },
    // ==================== End CRUD ====================

    // Set Rows
    setRows() {
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/bank_dubai/get_trash",
        params: {
          deleted_by_id: this.searchItems ? this.searchItems.username : null,
          from_date: this.searchItems ? this.searchItems.from : null,
          to_date: this.searchItems ? this.searchItems.to : null,
        },
      })
        .then((res) => {
          this.rows = res.data.data.map((item) => {
            return {
              id: item.id,
              name: item.name,
              deletedBy: item.deletedBy.name,
              deleted_at: item.deleted_at,
              swift_number: item.swift,
            };
          });
          this.table_length = this.rows.length;
          this.lodaing = false;
        })
        .catch((err) => {
          this.lodaing = false;
        });
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    this.setRows();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (!userType.includes("superadmin")) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped></style>
