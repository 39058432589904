<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper enterprice_account" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/enterpriceCredits"
        search_title=" إيصالات المؤسسات"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("CRUD.Create.main_title") }}</h4>
        </div>

        <!-- START:: CREATE FORM WRAPPER -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="data.amount"
                  />
                  <label for="name_input" class="form-label"> المبلغ </label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="data.transfare_amount"
                  />
                  <label for="name_input2" class="form-label">النسبة</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="data.comission"
                  />
                  <label for="name_input3" class="form-label">العمولة</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    readonly
                    class="form-control"
                    v-model.trim="total"
                  />
                  <label for="name_input4" class="form-label">الاجمالي</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: SINGLE SELECT INPUT -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> إسم العميل</label>
                  <v-autocomplete
                    v-model="data.username"
                    :search-input.sync="searchOptions.search_users"
                    :items="users"
                    item-text="name"
                    return-object
                    :hide-no-data="!users"
                    dense
                  ></v-autocomplete>
                  <button class="button_style_1 load_inline" v-if="load_users">
                    <span class="btn_loader"></span>
                  </button>
                </div>
                <h6
                  class="mt-0 mb-0 mx-0 amount-section"
                  :class="{
                    redColor: data.username.amount < 0,
                    greenColor: data.username.amount > 0,
                  }"
                  v-if="data.username"
                >
                  الرصيد :
                  {{ Number(data.username.amount).toLocaleString() }}
                </h6>
              </div>
              <!-- END:: SINGLE SELECT INPUT -->

              <!-- START:: SINGLE SELECT INPUT -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> حساب المؤسسة</label>
                  <v-autocomplete
                    v-model="data.enterpriceAccount"
                    :search-input.sync="searchOptions.search_enterprices"
                    :items="enterprices"
                    item-text="name"
                    return-object
                    :hide-no-data="!enterprices"
                    dense
                  ></v-autocomplete>
                  <button
                    class="button_style_1 load_inline"
                    v-if="load_enterprices"
                  >
                    <span class="btn_loader"></span>
                  </button>
                </div>
                <h6
                  class="mt-0 mb-0 mx-0 amount-section"
                  v-if="data.enterpriceAccount"
                  :class="{
                    redColor: data.enterpriceAccount.amount < 0,
                    greenColor: data.enterpriceAccount.amount > 0,
                  }"
                >
                  الرصيد :
                  <span v-if="data.enterpriceAccount.amount">
                    {{
                      Number(data.enterpriceAccount.amount).toLocaleString()
                    }}</span
                  >
                  <span v-else> 0 </span>
                </h6>
              </div>
              <!-- END:: SINGLE SELECT INPUT -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <label for="textarea_1" class="form-label">وصف</label>
                  <textarea
                    v-on:keydown.enter.prevent="validateCreateForm"
                    class="form-control"
                    rows="5"
                    v-model="data.desc"
                  ></textarea>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <label for="textarea_1" class="form-label">ملاحظات</label>
                  <textarea
                    v-on:keydown.enter.prevent="validateCreateForm"
                    class="form-control"
                    rows="5"
                    v-model="data.notes"
                  ></textarea>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!--START:: ASK TO OPEN ACCOUNT -->
              <div class="col-lg-12 py-0">
                <div class="sub_title">
                  <span>الحالة</span>
                </div>
                <!-- START:: RADIO BUTTON INPUT -->
                <div class="input_wrapper radio_button_input">
                  <div class="flex_wrapper">
                    <v-radio-group v-model="radioButtonsValue">
                      <v-radio
                        label="يوجد"
                        color="indigo lighten-2"
                        value="true"
                      ></v-radio>
                      <v-radio
                        label="لا يوجد"
                        color="indigo lighten-2"
                        value="false"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <!-- END:: RADIO BUTTON INPUT -->
              </div>
              <!--END:: ASK TO OPEN ACCOUNT -->
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              إضافة
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- END:: CREATE FORM WRAPPER -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // loader
      loading: true,

      load_users: false,
      load_enterprices: false,

      // search
      searchOptions: {
        search_users: "",
        search_enterprices: "",
      },
      users: null,
      enterprices: null,

      // ========== Breadcrumbs
      items: [
        {
          text: "الرئيسية",
          disabled: false,
          href: "/",
        },
        {
          text: "إضافة إيصال لمؤسسة",
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      radioButtonsValue: null,

      // START:: enterpriceAccounts
      enterpriceAccounts: [],
      // END:: enterpriceAccounts

      // search_users
      search_users: "",

      // search_accounts
      search_accounts: "",

      // ========== create_data
      data: {
        enterpriceAccount: "",
        username: null,
        amount: null,
        transfare_amount: 1,
        comission: 1,
        total: null,
        desc: null,
        notes: null,
      },
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },

      // ========== Fixed Data
      users: [],
    };
  },

  watch: {
    [`searchOptions.search_users`](newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.users = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_users(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_users(newVal);
        } else {
          this.get_filterd_users(newVal);
        }
      }
    },

    [`searchOptions.search_enterprices`](newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.enterprices = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_enterprices(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_enterprices(newVal);
        } else {
          this.get_filterd_enterprices(newVal);
        }
      }
    },
  },

  methods: {
    // get_filterd_users
    get_filterd_users(keyword) {
      this.load_users = true;
      this.$axios({
        method: "GET",
        url: `client/customer/search?keyword=${keyword}`,
      })
        .then((res) => {
          this.users = res.data.data;
          this.load_users = false;
        })
        .catch((err) => {
          this.load_users = false;
        });
    },

    // get_filterd_enterprices
    get_filterd_enterprices(keyword) {
      this.load_enterprices = true;
      this.$axios({
        method: "GET",
        url: `help/accounts?keyword=${keyword}`,
      })
        .then((res) => {
          this.enterprices = res.data.data.map((item) => {
            return {
              id: item.id,
              name: item.name,
              amount: item.amount,
            };
          });
          this.load_enterprices = false;
        })
        .catch((err) => {
          this.load_enterprices = false;
        });
    },

    // START:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATEسD PLACEHOLDER INPUTS)
    checkIfInputIsEmpty(e) {
      let inputElement = e.currentTarget;
      if (inputElement.value.length > 0) {
        inputElement.classList.add("not_empty");
      } else {
        inputElement.classList.remove("not_empty");
      }
    },
    // END:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)

    // START:: VALIDATE CREATE FORM
    emptyFormData() {
      this.data.amount = null;
      this.data.transfare_amount = 1;
      this.data.comission = 1;
      this.data.desc = null;
      this.data.notes = null;
      this.radioButtonsValue = null;
    },
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال المبلغ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.transfare_amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال النسبة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.comission) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال العمولة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.username) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال إسم العميل",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.enterpriceAccount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال المؤسسة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.desc) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال وصف",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.radioButtonsValue) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إختيار حالة العملية",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          const submit_data = new FormData();
          submit_data.append("type", "bill");
          submit_data.append("amount", this.data.amount);
          submit_data.append("rate", this.data.transfare_amount);
          submit_data.append("commission", this.data.comission);
          submit_data.append("total", Number(this.total.replace(/\D/g, "")));
          submit_data.append("balance_customer_id", this.data.username.id);
          submit_data.append("account_id", this.data.enterpriceAccount.id);
          submit_data.append("desc", this.data.desc);
          if (this.data.notes) {
            submit_data.append("notes", this.data.notes);
          }
          submit_data.append("active", this.radioButtonsValue);
          this.$axios({
            method: "post",
            url: "client/credit_enterprise",
            data: submit_data,
          })
            .then(() => {
              this.btnIsLoading = false;
              this.$iziToast.success({
                timeout: 2000,
                message: "تمت الاضافة بنجاح",
                position: "topRight",
              });
              this.emptyFormData();
            })
            .catch((err) => {
              this.btnIsLoading = false;
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "topRight",
              });
            });
          return;
        }
      }
    },
    // END:: VALIDATE CREATE FORM

    getData() {
      // data
      this.$axios({
        method: "GET",
        url: `countries`,
      }).then((res) => {
        if (this.$store.getters["lang_module/lang"] == "ar") {
          this.countries = res.data.data.map((item) => {
            return {
              id: item.id,
              name: item.ar.name,
            };
          });
        } else {
          this.countries = res.data.data.map((item) => {
            return {
              id: item.id,
              name: item.en.name,
            };
          });
        }
      });
    },

    // START:: HANDLING SELECTED FILE METHOD
    handelSelected(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD
  },

  computed: {
    total() {
      const num =
        this.data.amount * this.data.comission * this.data.transfare_amount;
      return parseFloat(num.toFixed()).toLocaleString();
    },
  },

  created() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      userType.includes("confirm_enterprise_add_money") &&
      !userType.includes("enterprise_add_money")
    ) {
      this.$router.push("/enterpriceCredits");
    } else if (
      !userType.includes("superadmin") &&
      !userType.includes("enterprise_add_money")
    ) {
      this.$router.push("/");
    }
  },
};
</script>
