<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/companyTransfers"
        search_title="تحويلات الشركات"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("addNew") }}</h4>
        </div>

        <!-- ==== Start Form ==== -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> إسم العميل</label>
                  <v-autocomplete
                    v-model="data.clinet_name"
                    :search-input.sync="searchOptions.search_users"
                    :items="users"
                    item-text="name"
                    return-object
                    :hide-no-data="!users"
                    dense
                  ></v-autocomplete>
                  <button class="button_style_1 load_inline" v-if="load_users">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>

              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.all_amount"
                  />
                  <label for="name_input" class="form-label">المبلغ </label>
                </div>
              </div>
              <div class="col-lg-12 py-0">
                <!--Divider-->
                <div class="divider">
                  <h4>بيانات الشركة</h4>
                  <v-divider></v-divider>
                </div>
                <!--Divider-->
              </div>
              <div class="col-lg-12 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="accountNum"
                    v-model="data.ibanNumber"
                    @input="emptyBankData"
                    v-on:keydown.enter.prevent="getCompanyData"
                  />
                  <label for="accountNum" class="form-label"
                    >IBAN NO or A/C NO</label
                  >
                </div>
              </div>

              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">{{
                    $t("companies.data.name")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="accountNum"
                    v-model="data.company_name"
                    disabled
                  />
                </div>
              </div>

              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="accountNum"
                    v-model="data.swift_number"
                    disabled
                  />
                  <label for="accountNum" class="form-label">{{
                    $t("companies.data.swiftNumber")
                  }}</label>
                </div>
              </div>

              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="bank"
                    v-model="data.bank_name"
                    disabled
                  />
                  <label for="bank" class="form-label">{{
                    $t("forms.labels.bank_name")
                  }}</label>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="bank"
                    v-model="data.country"
                    disabled
                  />
                  <label for="bank" class="form-label">الدولة</label>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="bank"
                    v-model="data.address"
                    disabled
                  />
                  <label for="bank" class="form-label">العنوان</label>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">السلع</label>
                  <input
                    type="text"
                    class="form-control"
                    id="bank"
                    v-model="data.products"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1 m-2"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              إضافة
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- ==== End Form ==== -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // loader
      loading: true,

      load_users: false,
      // search
      searchOptions: {
        search_users: "",
      },
      users: null,

      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.companyTransfers.add"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // Date_menues
      dateMenu: false,
      debit_dateMenu: false,

      add_Company_dialog: false,

      // search_users
      search_users: "",

      // ========== create_data
      data: {
        username: null,
        all_amount: null,
        company_name: null,
        ibanNumber: null,
        swift_number: null,
        bank_name: null,
        country: null,
        address: null,
        products: null,
      },

      // ========== Fixed_data
      companies: [
        {id: 1, name: "company"},
        {id: 2, name: "company"},
        {id: 3, name: "company"},
      ],
      products: [
        {id: 1, name: "product"},
        {id: 2, name: "product"},
        {id: 3, name: "product"},
      ],
      categories: [],
      banks: [],
      companyLogo: {
        file: null,
        name: null,
        path: null,
      },
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },
      users: [],
    };
  },

  watch: {
    [`searchOptions.search_users`](newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.users = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_users(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_users(newVal);
        } else {
          this.get_filterd_users(newVal);
        }
      }
    },

    [`data.bank`](newVal) {
      this.data.swift_number = newVal.swift_number;
    },

    [`data.swift_number`](newVal) {
      this.data.bank =
        this.banks.find((item) => item.swift_number == newVal) || null;
    },
  },

  methods: {
    // ============== Get Data
    getData() {
      // ===== Categories
      this.$axios({
        method: "GET",
        url: "client/category",
      })
        .then((res) => {
          this.categories = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });

      // ===== Banks
      this.$axios({
        method: "GET",
        url: "client/bank",
      }).then((res) => {
        this.banks = res.data.data;
      });

      // ===== Banks
      this.$axios({
        method: "GET",
        url: "client/company",
      }).then((res) => {
        this.companies = res.data.data;
      });
    },

    // Get Companies
    getCompanies() {
      this.$axios({
        method: "GET",
        url: `client/company`,
      }).then((res) => {
        this.companies = res.data.data;
      });
    },

    // Get Companies
    getProducts() {
      this.$axios({
        method: "GET",
        url: `client/product`,
      }).then((res) => {
        this.products = res.data.data;
      });
    },

    // get_filterd_users
    get_filterd_users(keyword) {
      this.load_users = true;
      this.$axios({
        method: "GET",
        url: `client/customer/search?keyword=${keyword}`,
      })
        .then((res) => {
          this.users = res.data.data;
          this.load_users = false;
        })
        .catch((err) => {
          this.load_users = false;
        });
    },

    getCompanyData() {
      if (this.data.ibanNumber) {
        this.data.company_name = "إسم الشركة";
        this.data.swift_number = "12876789";
        this.data.bank_name = "البنك العربي الإفريقي";
        this.data.country = "السعودية";
        this.data.address = "الرياض";
        this.data.products = "إسم السلعة";
      }
    },
    emptyBankData() {
      this.data.company_name = null;
      this.data.swift_number = null;
      this.data.bank_name = null;
      this.data.country = null;
      this.data.address = null;
      this.data.products = null;
    },
    addCompany() {
      let submit_data = {
        name: this.data.name,
        account_number: this.data.accountNumber,
        iban_number: this.data.accountNumber,
        swift_number: this.data.swift_number,
        categories: this.data.category.map((item) => item.id),
      };

      if (!this.data.name) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرجي إضافة إسم الشركة",
          position: "topRight",
        });
        return;
      } else if (this.data.category.length == 0) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرجي إضافة مجالات الشركة",
          position: "topRight",
        });
        return;
      } else if (!this.companyLogo.file) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرجى اضافة لوجو الشركة",
          position: "topRight",
        });
        return;
      } else if (!this.data.address) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرجي إضافة عنوان الشركة",
          position: "topRight",
        });
        return;
      } else if (!this.data.daraba_num) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرجي إضافة الرقم الضريبي للشركة",
          position: "topRight",
        });
        return;
      } else if (!this.data.commerce_num) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرجي إضافة رقم السجل التجاري للشركة",
          position: "topRight",
        });
        return;
      } else if (!this.selectedFile.file) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرجى اضافة ملف السجل التجاري للشركة",
          position: "topRight",
        });
        return;
      } else if (!this.data.bank) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرجي إختيار البنك للشركة",
          position: "topRight",
        });
        return;
      } else if (!this.data.accountNumber) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرجي إضافة رقم حساب الشركة او الايبان",
          position: "topRight",
        });
        return;
      }
      // else if (!this.data.ibanNumber) {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: "يرجي إضافة رقم الiban للشركة",
      //     position: "topRight",
      //   });
      //   return;
      // }
      else if (!this.data.swift_number) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرجي إضافة رقم الswift للشركة",
          position: "topRight",
        });
      } else {
        this.$axios({
          method: "POST",
          url: `client/company`,
          data: submit_data,
        })
          .then(() => {
            this.btnIsLoading = false;
            this.$iziToast.success({
              timeout: 2000,
              message: this.$t("CRUD.Create.added"),
              position: "topRight",
            });
            this.getData();
            this.add_Company_dialog = false;
          })
          .catch((err) => {
            this.btnIsLoading = false;
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "topRight",
            });
          });
        return;
      }
    },

    // START:: HANDLING SELECTED FILE METHOD
    handelSelectedFile(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD

    // START:: HANDLING SELECTED FILE METHOD
    handelCompanyLogo(e) {
      this.companyLogo.file = e.target.files[0];
      this.companyLogo.name = e.target.files[0].name;
      this.companyLogo.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD

    // START:: HANDLING SELECTED FILE METHOD
    handelCommerceLogo(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD

    // Validate Data
    validateCreateForm() {
      // this.btnIsLoading = true;

      if (false) {
        console.log("false");
      } else {
        this.submitData();
        return;
      }
    },
    emptyFormData() {
      this.data.username = null;
      this.data.currency = null;
      this.data.owner_name = null;
      this.data.safe = null;
      this.data.amount = null;
      this.data.type = null;
      this.data.date = new Date().toISOString().slice(0, 10);
      this.data.debit_date = null;
      this.data.desc = null;
      this.data.notes = null;
    },
    // Submit Data
    submitData() {
      const submit_data = new FormData();
      submit_data.append("customer_id", this.data.username);
      submit_data.append("currency_id", this.data.currency.id);
      submit_data.append("owner_name", this.data.owner_name);
      submit_data.append("wallet_id", this.data.safe.id);
      submit_data.append("amount", this.data.amount);
      submit_data.append("type", this.data.type.name);
      submit_data.append("date", this.data.date);
      submit_data.append("expire_date", this.data.debit_date);
      submit_data.append("desc", this.data.desc);
      if (this.data.notes) {
        submit_data.append("notes", this.data.notes);
      }
      submit_data.append(
        "image",
        this.data.files.map((item) => item.file)
      );

      this.$axios({
        method: "POST",
        url: "client/debt",
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "topRight",
          });
          this.emptyFormData();
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
  },

  created() {
    this.getData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      userType.includes("confirm_company_actions") &&
      !userType.includes("company_actions")
    ) {
      this.$router.push("/enterpriceActions");
    } else if (
      !userType.includes("superadmin") &&
      !userType.includes("company_actions")
    ) {
      this.$router.push("/");
    }
  },
};
</script>
