<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :show_print="true"
        :excelFile="true"
        :excelData="excelData"
      />
      <!-- End Breadcrumb -->
      <!-- Start Statistics Card-->
      <ul class="all-state-count">
        <li>
          <div class="state-count">
            <span>عدد بنوك تصدير :</span>
            <span>{{ table_length }}</span>
          </div>
        </li>
      </ul>
      <!-- End Statistics Card-->

      <!-- Start Main Section -->
      <main class="main-table table-print">
        <!-- start of  header of printSection  -->
        <PrintHead :title="'بنوك التصدير'" :number="table_length"></PrintHead>
        <!-- end of header of printSection  -->
        <v-data-table
          class="thumb"
          :headers="headers"
          :items="rows"
          :loading="lodaing"
          :loading-text="$t('table.loadingData')"
          v-model="selected"
          :single-select="singleSelect"
          item-key="id"
          :items-per-page="rows.length"
          hide-default-footer
        >
          <!-- ================== You Can use any slots you want ================== -->
          <!-- ====== Select row field ====== -->
          <!-- Select no data State -->
          <template v-slot:no-data>
            {{ $t("table.noData") }}
          </template>
          <template v-slot:[`item.index`]="{index}">
            {{ index + 1 }}
          </template>
          <template v-slot:[`item.amount`]="{item}">
            <span
              :class="{
                redColor: item.amount < 0,
                greenColor: item.amount > 0,
              }"
              >{{ Number(item.amount).toLocaleString() }}</span
            >
          </template>
          <!-- Select actions-->
          <template v-slot:[`item.actions`]="{item}">
            <div class="_actions">
              <v-icon class="edit" small @click="editItem(item)">
                fal fa-edit
              </v-icon>
              <v-icon class="delete" small @click="deleteItem(item)">
                fal fa-trash
              </v-icon>
            </div>
          </template>

          <!-- ======================== Start Top Section ======================== -->
          <template v-slot:top>
            <!-- Delete dialog -->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">{{
                  $t("table.deletedialog.areYouSure")
                }}</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#1B5E20" @click="deleteItemConfirm"
                    >{{ $t("table.deletedialog.ok") }}
                    <span
                      class="btn_loader_model"
                      v-if="loadingBtnDialog"
                    ></span
                  ></v-btn>
                  <v-btn color="#F44336" @click="dialogDelete = false">{{
                    $t("table.deletedialog.cancel")
                  }}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <!-- ======================== End Top Section ======================== -->
        </v-data-table>
      </main>
      <!-- End Main Section -->
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";

export default {
  props: ["id"],

  components: {
    StatsCard,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "كل بنوك التصدير",
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "كل بنوك التصدير",
          number: "10",
        },
      ],

      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Date_Menues
      from_date_menu: false,
      to_date_menu: false,

      // ========== Filter
      filter: {
        name: null,
        safe: [],
        from: null,
        to: null,
      },

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== Loding
      lodaing: false,
      bankTotal: null,
      // Fixed_data
      safes: ["foo", "bar", "fizz", "buzz"],
      // excel data
      excelData: {
        name: "بنوك التصدير",
        items: null,
        json_fields: {
          "اسم البنك": "name",
        },
      },
    };
  },

  watch: {
    [`filter.name`]() {
      this.filterRows();
    },
    [`filter.safe`]() {
      this.filterRows();
    },
    [`filter.from`]() {
      this.filterRows();
    },
    [`filter.to`]() {
      this.filterRows();
    },
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: "اسم البنك",
            value: "name",
            align: "center",
          },
          {
            text: "الرصيد",
            value: "amount",
            align: "center",
          },
          {
            text: "التحكم",
            value: "actions",
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
        ];
      }
    },
  },

  methods: {
    // Filter
    filterRows() {
      console.log(this.filter);
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({path: `/export_banks/add`});
    },
    showItem(item) {
      this.dialogShow = true;
      this.itemToShow = item;
    },
    editItem(item) {
      this.$router.push({path: "/export_banks/edit/" + item.id});
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/bank_eg/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // Set Rows
    setRows() {
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/bank_eg",
      })
        .then((res) => {
          this.rows = res.data.data.map((item) => {
            return {
              id: item.id,
              name: item.name,
              amount: item.amount,
            };
          });
          this.table_length = this.rows.length;
          this.bankTotal = res.data.total;
          this.excelData.items = this.rows;
          this.lodaing = false;
        })
        .catch((err) => {
          this.lodaing = false;
        });
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    this.setRows();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("referane_export")
    ) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped></style>
