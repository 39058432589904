<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb :items="items" />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("addNew") }}</h4>
        </div>

        <!-- ==== Start Form ==== -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <!-- Image -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> إسم العميل</label>
                  <v-autocomplete
                    v-model="data.username"
                    :search-input.sync="searchOptions.search_users"
                    :items="users"
                    item-text="name"
                    return-object
                    :hide-no-data="!users"
                    dense
                  ></v-autocomplete>
                  <button class="button_style_1 load_inline" v-if="load_users">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">نوع المديونة</label>
                  <multiselect
                    v-model="data.type"
                    :options="types"
                    label="name"
                    track-by="id"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-lg-12 py-0">
                <div class="input_wrapper top_label file_input">
                  <span class="file_input_label"> المرفقات</span>
                  <label
                    for="file_input_1"
                    class="form-label"
                    v-if="!selectedFile.file"
                  ></label>
                  <label for="file_input_1" class="form-label" v-else>
                    {{ selectedFile.name }}
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    id="file_input_1"
                    @change="handelSelectedFile"
                  />
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.amount"
                  />
                  <label for="name_input" class="form-label">{{
                    $t("forms.labels.amount")
                  }}</label>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">{{
                    $t("forms.labels.safe")
                  }}</label>
                  <multiselect
                    v-model="data.safe"
                    :options="safes"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>

              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">{{
                    $t("forms.labels.currency")
                  }}</label>
                  <multiselect
                    v-model="data.currency"
                    :options="currencies"
                    label="name"
                    track-by="id"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>

              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label date_input">
                  <label class="form-label">{{
                    $t("forms.labels.date")
                  }}</label>
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        v-model="data.date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="data.date"
                      @input="dateMenu = false"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>

              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label date_input">
                  <label class="form-label">{{
                    $t("forms.labels.debit_date")
                  }}</label>
                  <v-menu
                    v-model="debit_dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        v-model="data.debit_date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="data.debit_date"
                      @input="debit_dateMenu = false"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>

              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <label for="textarea_1" class="form-label">{{
                    $t("forms.labels.desc")
                  }}</label>
                  <textarea
                    v-on:keydown.enter.prevent="validateCreateForm"
                    v-model="data.desc"
                    class="form-control"
                    id="textarea_1"
                    rows="5"
                  ></textarea>
                </div>
              </div>

              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <label for="textarea_1" class="form-label">{{
                    $t("forms.labels.notes")
                  }}</label>
                  <textarea
                    v-on:keydown.enter.prevent="validateCreateForm"
                    v-model="data.notes"
                    class="form-control"
                    id="textarea_1"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              {{ $t("forms.submit") }}
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- ==== End Form ==== -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Update",

  props: ["id"],

  data() {
    return {
      // loader
      loading: true,

      load_users: false,
      // search
      searchOptions: {
        search_users: "",
      },
      users: null,

      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.debits.title"),
          disabled: false,
          href: "/sliders",
        },
        {
          text: this.$t("breadcrumb.debits.add"),
          disabled: true,
          href: "",
        },
      ],
      // START:: SELECTED FILE DATA
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },
      // END:: SELECTED FILE DATA
      // ========== Loading
      btnIsLoading: false,

      // Date_menues
      dateMenu: false,
      debit_dateMenu: false,

      // search_users
      search_users: "",

      // ========== create_data
      data: {
        files: [],
        username: null,
        amount: null,
        type: null,
        safe: null,
        currency: null,
        date: null,
        debit_date: null,
        desc: null,
        notes: null,
      },

      // ========== Fixed_data
      safes: [],
      currencies: [],
      types: [
        {id: 1, name: "دائن"},
        {id: 2, name: "مدين"},
      ],
      users: [],
    };
  },

  watch: {
    [`searchOptions.search_users`](newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.users = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_users(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_users(newVal);
        } else {
          this.get_filterd_users(newVal);
        }
      }
    },
  },

  methods: {
    // ============== Get Data
    getData() {
      // ===== Currencies
      this.$axios({
        method: "GET",
        url: `client/currency`,
      }).then((res) => {
        this.currencies = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name_ar,
          };
        });
      });

      // ===== Safes
      this.$axios({
        method: "GET",
        url: `client/wallet`,
      }).then((res) => {
        this.safes = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      });

      // ===== MainDate
      this.$axios({
        method: "GET",
        url: `client/debt/${this.id}`,
      }).then((res) => {
        this.data.username = res.data.data.customer.id;
        this.data.amount = res.data.data.amount;
        this.data.safe = res.data.data.wallet;
        this.data.type = {
          name: res.data.data.type,
        };
        this.data.currency = {
          id: res.data.data.currency.id,
          name: res.data.data.currency.name_ar,
        };
        this.data.date = res.data.data.date;
        this.data.debit_date = res.data.data.expire_date;
        this.data.desc = res.data.data.desc;
        this.data.notes = res.data.data.notes;
      });
    },

    // get_filterd_users
    get_filterd_users(keyword) {
      this.load_users = true;
      this.$axios({
        method: "GET",
        url: `client/customer/search?keyword=${keyword}`,
      })
        .then((res) => {
          this.users = res.data.data;
          this.load_users = false;
        })
        .catch((err) => {
          this.load_users = false;
        });
    },

    // START:: HANDLING SELECTED FILE METHOD
    handelSelectedFile(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD

    // Validate Data
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.username) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.name"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.amount"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.type) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.type"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.safe) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.safe"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.currency) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.currency"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.date) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.date"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.debit_date) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.debit_date"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.desc) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.desc"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.submitData();
          return;
        }
      }
    },

    // Submit Data
    submitData() {
      const submit_data = new FormData();
      submit_data.append("customer_id", this.data.username);
      submit_data.append("currency_id", this.data.currency.id);
      submit_data.append("wallet_id", this.data.safe.id);
      submit_data.append("amount", this.data.amount);
      submit_data.append("type", this.data.type.name);
      submit_data.append("date", this.data.date);
      submit_data.append("expire_date", this.data.debit_date);
      submit_data.append("desc", this.data.desc);
      submit_data.append("notes", this.data.notes);
      submit_data.append("image", this.selectedFile.file);

      this.$axios({
        method: "POST",
        url: `client/debt/update_debt/${this.id}`,
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "topRight",
          });
          this.$router.push({path: "/debits"});
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
  },

  created() {
    this.getData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>
