<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="teacher_data" v-else>
      <div class="personal_data_wrapper">
        <div class="row">
          <!-- START:: FULL NAME INPUT -->
          <div class="col-lg-6">
            <div class="input_wrapper top_label only_read my-2">
              <label class="form-label">إسم العميل</label>
              <input
                type="text"
                class="form-control"
                v-model.trim="data.name"
                readonly
              />
            </div>
          </div>
          <!-- END:: FULL NAME INPUT -->

          <!-- START:: FULL NAME INPUT -->
          <div class="col-lg-6">
            <div class="input_wrapper top_label only_read my-2">
              <label class="form-label">العنوان</label>
              <input
                type="text"
                class="form-control"
                v-model.trim="data.address"
                readonly
              />
            </div>
          </div>
          <!-- END:: FULL NAME INPUT -->

          <!-- START:: FULL NAME INPUT -->
          <div class="col-lg-6">
            <div class="input_wrapper top_label only_read my-2">
              <label class="form-label">البريد الالكتروني</label>
              <input
                type="text"
                class="form-control"
                v-model.trim="data.email"
                readonly
              />
            </div>
          </div>
          <!-- END:: FULL NAME INPUT -->

          <!-- START:: FULL NAME INPUT -->
          <div class="col-lg-6">
            <div class="input_wrapper top_label only_read my-2">
              <label class="form-label">رقم الهاتف</label>
              <input
                type="text"
                class="form-control"
                v-model.trim="data.phone"
                readonly
              />
            </div>
          </div>
          <!-- END:: FULL NAME INPUT -->

          <!-- START:: FULL NAME INPUT -->
          <div class="col-lg-6">
            <div class="input_wrapper top_label only_read my-2">
              <label class="form-label">رقم الفاكس</label>
              <input
                type="text"
                class="form-control"
                v-model.trim="data.fax"
                readonly
              />
            </div>
          </div>
          <!-- END:: FULL NAME INPUT -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],

  data() {
    return {
      // loader
        loading: true,
      data: {
        
        name: "Omar",
        address: "sdvsdd idsh ildsh i",
        email: "asdasd",
        phone: "asdasdasdasdsad",
        fax: "asasdasd",
      },
    };
  },

  methods: {
    getData() {
      this.$axios({
        method: "GET",
        url: `client/customer/${this.id}`,
      }).then((res) => {
        // this.data.avatar = res.data.data.profile_image;
        this.data.name = res.data.data.name;
        this.data.phone = res.data.data.phone;
        this.data.address = res.data.data.address;
        this.data.email = res.data.data.email;
        this.data.fax = res.data.data.fax;
      });
    },
  },

  created() {
    this.getData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>

<style></style>

