<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper enterprice_account" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/enterpriceAccount"
        search_title="حسابات المؤسسات"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("CRUD.Update.main_title") }}</h4>
        </div>

        <!-- START:: CREATE FORM WRAPPER -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <!-- START:: SINGLE SELECT INPUT -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> إسم المؤسسة</label>
                  <v-autocomplete
                    v-model="data.enterpriceAccount"
                    :search-input.sync="searchOptions.search_enterprices"
                    :items="enterprices"
                    item-text="name"
                    return-object
                    :hide-no-data="!enterprices"
                    dense
                  ></v-autocomplete>
                  <button
                    class="button_style_1 load_inline"
                    v-if="load_enterprices"
                  >
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>
              <!-- END:: SINGLE SELECT INPUT -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label file_input">
                  <span class="file_input_label">صورة كارت الصراف</span>
                  <label
                    for="file_inputt_1"
                    class="form-label"
                    v-if="!selectedFile.name"
                  ></label>
                  <label for="file_inputt_1" class="form-label" v-else>
                    {{ selectedFile.name }}
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    id="file_inputt_1"
                    @change="handelSelected"
                  />
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->
              <!-- START:: SINGLE SELECT INPUT -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">
                    {{ $t("enterpriceAccount.data.bank") }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.bank"
                  />
                </div>
              </div>
              <!-- END:: SINGLE SELECT INPUT -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.account_number"
                  />
                  <label for="name_input" class="form-label">{{
                    $t("enterpriceAccount.data.account_number")
                  }}</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <div class="col-lg-12 py-0">
                <div class="divider">
                  <h4><i class="fal fa-arrows-h"></i></h4>
                  <hr
                    role="separator"
                    aria-orientation="horizontal"
                    class="v-divider theme--dark"
                  />
                </div>
              </div>
              <!--START:: ASK TO OPEN ACCOUNT -->
              <div class="col-lg-6 py-0">
                <div class="sub_title">
                  <span>هل تم تقديم الطلب لفتح حساب؟</span>
                </div>
                <!-- START:: RADIO BUTTON INPUT -->
                <div class="input_wrapper radio_button_input">
                  <div class="flex_wrapper">
                    <v-radio-group v-model="radioButtonsValue">
                      <v-radio
                        label="نعم"
                        color="indigo lighten-2"
                        value="1"
                      ></v-radio>
                      <v-radio
                        label="لا"
                        color="indigo lighten-2"
                        value="0"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <div class="input_wrapper top_label" v-if="radioButtonsValue">
                  <label for="textarea_1" class="form-label">{{
                    $t("enterpriceAccount.data.addDetails")
                  }}</label>
                  <textarea
                    v-on:keydown.enter.prevent="validateCreateForm"
                    class="form-control"
                    id="textarea_1"
                    rows="5"
                    v-model="data.details_one"
                  ></textarea>
                </div>
                <!-- END:: RADIO BUTTON INPUT -->
              </div>
              <!--START:: ASK IF REQUEST APPLIED -->
              <div class="col-lg-6 py-0">
                <div class="sub_title">
                  <span>هل تم تقديم الطلب اونلاين؟</span>
                </div>
                <!-- START:: RADIO BUTTON INPUT -->
                <div class="input_wrapper radio_button_input">
                  <div class="flex_wrapper">
                    <v-radio-group v-model="radioButtonsValue2">
                      <v-radio
                        label="نعم"
                        color="indigo lighten-2"
                        value="1"
                      ></v-radio>
                      <v-radio
                        label="لا"
                        color="indigo lighten-2"
                        value="0"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <div class="input_wrapper top_label" v-if="radioButtonsValue2">
                  <label for="textarea_1" class="form-label">{{
                    $t("enterpriceAccount.data.addDetails")
                  }}</label>
                  <textarea
                    v-on:keydown.enter.prevent="validateCreateForm"
                    class="form-control"
                    id="textarea_1"
                    rows="5"
                    v-model="data.details_two"
                  ></textarea>
                </div>
                <!-- END:: RADIO BUTTON INPUT -->
              </div>
              <!--END:: ASK IF REQUEST APPLIED -->
              <div class="col-lg-12 py-0">
                <div class="divider">
                  <h4><i class="fal fa-arrows-h"></i></h4>
                  <hr
                    role="separator"
                    aria-orientation="horizontal"
                    class="v-divider theme--dark"
                  />
                </div>
              </div>
              <!--START:: ACCOUNT STATUS -->
              <div class="col-lg-6 py-0">
                <div class="sub_title">
                  <span>هل تم تقديم طلب إضافة صرافه ؟</span>
                </div>
                <!-- START:: RADIO BUTTON INPUT -->
                <div class="input_wrapper radio_button_input">
                  <div class="flex_wrapper">
                    <v-radio-group v-model="radioButtonsValue3">
                      <v-radio
                        label="نعم"
                        color="indigo lighten-2"
                        value="1"
                      ></v-radio>
                      <v-radio
                        label="لا"
                        color="indigo lighten-2"
                        value="0"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <div class="input_wrapper top_label" v-if="radioButtonsValue3">
                  <label for="textarea_1" class="form-label">{{
                    $t("enterpriceAccount.data.addDetails")
                  }}</label>
                  <textarea
                    v-on:keydown.enter.prevent="validateCreateForm"
                    class="form-control"
                    id="textarea_1"
                    rows="5"
                    v-model="data.details_three"
                  ></textarea>
                </div>
                <!-- END:: RADIO BUTTON INPUT -->
              </div>
              <!--END:: ACCOUNT STATUS -->
              <!--START:: ACCOUNT STATUS -->
              <div class="col-lg-6 py-0">
                <div class="sub_title">
                  <span>هل تم تسليم الصرافة للمندوب ؟</span>
                </div>
                <!-- START:: RADIO BUTTON INPUT -->
                <div class="input_wrapper radio_button_input">
                  <div class="flex_wrapper">
                    <v-radio-group v-model="radioButtonsValue4">
                      <v-radio
                        label="نعم"
                        color="indigo lighten-2"
                        value="1"
                      ></v-radio>
                      <v-radio
                        label="لا"
                        color="indigo lighten-2"
                        value="0"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <div class="input_wrapper top_label" v-if="radioButtonsValue4">
                  <label for="textarea_1" class="form-label">{{
                    $t("enterpriceAccount.data.addDetails")
                  }}</label>
                  <textarea
                    v-on:keydown.enter.prevent="validateCreateForm"
                    class="form-control"
                    id="textarea_1"
                    rows="5"
                    v-model="data.details_four"
                  ></textarea>
                </div>
                <!-- END:: RADIO BUTTON INPUT -->
              </div>
              <!--END:: ACCOUNT STATUS -->
              <div class="col-lg-12 py-0">
                <div class="divider">
                  <h4><i class="fal fa-arrows-h"></i></h4>
                  <hr
                    role="separator"
                    aria-orientation="horizontal"
                    class="v-divider theme--dark"
                  />
                </div>
              </div>
              <!--START:: ACCOUNT STATUS -->
              <div class="col-lg-12 py-0">
                <div class="sub_title">
                  <span>{{ $t("enterpriceAccount.data.accountStatus") }}</span>
                </div>
                <!-- START:: RADIO BUTTON INPUT -->
                <div class="input_wrapper radio_button_input">
                  <div class="flex_wrapper">
                    <v-radio-group v-model="radioButtonsValue5">
                      <v-radio
                        :label="$t('enterpriceAccount.data.active')"
                        color="indigo lighten-2"
                        value="1"
                      ></v-radio>
                      <v-radio
                        :label="$t('enterpriceAccount.data.disactive')"
                        color="indigo lighten-2"
                        value="0"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <!-- END:: RADIO BUTTON INPUT -->
              </div>
              <!--END:: ACCOUNT STATUS -->
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              تعديل
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- END:: CREATE FORM WRAPPER -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",
  props: ["id"],
  data() {
    return {
      // loader
      loading: true,

      load_enterprices: false,
      searchOptions: {
        search_enterprices: "",
      },
      enterprices: null,

      // ========== Breadcrumbs
      items: [
        {
          text: "الرئيسية",
          disabled: false,
          href: "/",
        },
        {
          text: "حساب المؤسسة",
          disabled: false,
          href: "/enterpriceAccount",
        },
        {
          text: "تعديل",
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      radioButtonsValue: null,
      radioButtonsValue2: null,
      radioButtonsValue3: null,
      radioButtonsValue4: null,
      radioButtonsValue5: null,
      // START:: enterpriceAccounts
      enterpriceAccounts: [],
      // END:: enterpriceAccounts

      // START:: Banks
      banks: [],
      // END:: Banks

      // ========== create_data
      data: {
        enterpriceAccount: "",
        bank: "",
        account_number: "",
        swift_num: null,
        details_one: null,
        details_two: null,
        details_three: null,
        details_four: null,
      },
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },
      // ========== Atm Data
      atms: [
        {
          id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
          password: null,
          atmNum: null,
          file: null,
          name: null,
          path: null,
          status: "",
        },
      ],

      // ========== Fixed Data
      // search_accounts
      search_accounts: "",
      loadSwift: false,
    };
  },

  watch: {
    [`searchOptions.search_enterprices`](newVal, oldVal) {
      if ((!newVal || newVal.length < 3) && this.data.enterpriceAccount == "") {
        this.enterprices = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_enterprices(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_enterprices(newVal);
        } else {
          this.get_filterd_enterprices(newVal);
        }
      }
    },
  },

  methods: {
    // get_filterd_users
    get_filterd_enterprices(keyword) {
      this.load_enterprices = true;
      this.$axios({
        method: "GET",
        url: `help/enterprises?keyword=${keyword}`,
      })
        .then((res) => {
          this.enterprices = res.data.data.map((item) => {
            return {
              id: item.id,
              name: item.name_ar,
            };
          });
          this.load_enterprices = false;
        })
        .catch((err) => {
          this.load_enterprices = false;
        });
    },

    //END:: GET ENTERPRICES
    getBankData() {
      this.loadSwift = true;
      this.$axios({
        method: "GET",
        url: "client/bank/get_all",
        params: {
          swift: this.data.swift_num,
        },
      })
        .then((res) => {
          if (res.data.data.length > 0 && this.data.swift_num.length != 0) {
            res.data.data.forEach((ele) => {
              this.data.bank = ele;
            });
          } else {
            this.data.bank = null;
          }
          this.loadSwift = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // START:: TOGGLE PASSWORD VISIBILITY METHOD
    togglePasswordVisibility(e) {
      let passwordElement = e.currentTarget.parentElement.children[1];
      let innerPasswordElement = e.currentTarget.parentElement.children[0];
      let passwordTogglerBtn = e.currentTarget;
      if (
        passwordElement.type == "password" ||
        innerPasswordElement.type == "password"
      ) {
        passwordElement.type = "text";
        innerPasswordElement.type = "text";
        passwordTogglerBtn.classList.add("password_is_visible");
      } else if (
        passwordElement.type == "text" ||
        innerPasswordElement.type == "text"
      ) {
        passwordElement.type = "password";
        innerPasswordElement.type = "password";
        passwordTogglerBtn.classList.remove("password_is_visible");
      }
    },
    // END:: TOGGLE PASSWORD VISIBILITY METHOD

    // START:: ADD NEW ITEM
    addItem() {
      this.atms.push({
        id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
        password: null,
        atmNum: null,
        file: null,
        name: null,
        path: null,
        status: "",
      });
    },
    // END:: ADD NEW ITEM

    // START:: GET DATA
    getAllData() {
      this.$axios({
        method: "GET",
        url: "client/account/" + this.id,
      }).then((res) => {
        this.data.enterpriceAccount = {
          id: res.data.data.enterprise.id,
          name: res.data.data.enterprise.name_ar,
        };
        this.data.bank = res.data.data.bank_name;
        this.data.account_number = res.data.data.bank_account_number;
        if (res.data.data.head_letter != null) {
          this.selectedFile.name = res.data.data.head_letter;
        }
        // ---------------
        this.radioButtonsValue = String(res.data.data.is_account_opened.status);
        if(res.data.data.is_account_opened.comments.length > 0) {
          this.data.details_one = res.data.data.is_account_opened.comments[0].comment
        }
        // ---------------
        this.radioButtonsValue2 = String(
          res.data.data.is_online_request.status
        );
        if(res.data.data.is_online_request.comments.length > 0) {
          this.data.details_two = res.data.data.is_online_request.comments[0].comment
        }
        // ---------------
        this.radioButtonsValue3 = String(res.data.data.is_request_atm.status);
        if(res.data.data.is_request_atm.comments.length > 0) {
          this.data.details_three = res.data.data.is_request_atm.comments[0].comment
        }
        // ---------------
        this.radioButtonsValue4 = String(res.data.data.is_atm_delivered.status);
        if(res.data.data.is_atm_delivered.comments.length > 0) {
          this.data.details_four = res.data.data.is_atm_delivered.comments[0].comment
        }
        // ---------------
        this.radioButtonsValue5 = String(res.data.data.active);
      });
    },
    // END:: GET DATA

    //START:: GET BANKS
    get_banks() {
      this.$axios({
        method: "GET",
        url: "client/bank",
      }).then((res) => {
        this.banks = res.data.data;
      });
    },
    //END:: GET BANKS

    // START:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)
    checkIfInputIsEmpty(e) {
      let inputElement = e.currentTarget;
      if (inputElement.value.length > 0) {
        inputElement.classList.add("not_empty");
      } else {
        inputElement.classList.remove("not_empty");
      }
    },
    // END:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)

    // START:: VALIDATE CREATE FORM
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.enterpriceAccount) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("enterpriceAccount.validation.enterprice"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.bank) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("enterpriceAccount.validation.bank"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.account_number) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("enterpriceAccount.validation.account_number"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.radioButtonsValue) {
          this.$iziToast.error({
            timeout: 2000,
            message: "هل تم تقديم طلب لفتح حساب ؟",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.radioButtonsValue2) {
          this.$iziToast.error({
            timeout: 2000,
            message: "هل تم تقديم طلب اون لاين ؟",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.radioButtonsValue3) {
          this.$iziToast.error({
            timeout: 2000,
            message: "هل تم تقديم طلب إضافة صرافة ؟",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.radioButtonsValue4) {
          this.$iziToast.error({
            timeout: 2000,
            message: "هل تم تسليم الصرافة للمندوب ؟",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.radioButtonsValue5) {
          this.$iziToast.error({
            timeout: 2000,
            message: "ما هي حالة الحساب ؟",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          const submit_data = new FormData();
          submit_data.append("enterprise_id", this.data.enterpriceAccount.id);
          if (this.selectedFile.file) {
            submit_data.append("head_letter", this.selectedFile.file);
          }
          submit_data.append("bank_name", this.data.bank);
          submit_data.append("bank_account_number", this.data.account_number);
          submit_data.append(
            "is_account_opened",
            Number(this.radioButtonsValue)
          );
          if (this.data.details_one != null) {
            submit_data.append(
              "is_account_opened_comment",
              this.data.details_one
            );
          }
          submit_data.append(
            "is_online_request",
            Number(this.radioButtonsValue2)
          );
          if (this.data.details_two != null) {
            submit_data.append(
              "is_online_request_comment",
              this.data.details_two
            );
          }
          submit_data.append("is_request_atm", Number(this.radioButtonsValue3));
          if (this.data.details_three != null) {
            submit_data.append(
              "is_request_atm_comment",
              this.data.details_three
            );
          }
          submit_data.append(
            "is_atm_delivered",
            Number(this.radioButtonsValue4)
          );
          if (this.data.details_four != null) {
            submit_data.append(
              "is_atm_delivered_comment",
              this.data.details_four
            );
          }
          submit_data.append("active", Number(this.radioButtonsValue5));
          this.$axios({
            method: "post",
            url: "client/account/update_account/" + this.$route.params.id,
            data: submit_data,
          })
            .then(() => {
              this.btnIsLoading = false;
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("global.editSuccess"),
                position: "topRight",
              });
              this.$router.push("/enterpriceAccount");
            })
            .catch((err) => {
              this.btnIsLoading = false;
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "topRight",
              });
            });
          return;
        }
      }
    },
    // END:: VALIDATE CREATE FORM

    getData() {
      // data
      this.$axios({
        method: "GET",
        url: `countries`,
      }).then((res) => {
        if (this.$store.getters["lang_module/lang"] == "ar") {
          this.countries = res.data.data.map((item) => {
            return {
              id: item.id,
              name: item.ar.name,
            };
          });
        } else {
          this.countries = res.data.data.map((item) => {
            return {
              id: item.id,
              name: item.en.name,
            };
          });
        }
      });
    },

    // START:: HANDLING SELECTED FILE METHOD
    handelSelected(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD
  },

  created() {
    this.get_banks();
    this.getAllData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      userType.includes("confirm_enterprise") &&
      !userType.includes("enterprise")
    ) {
      this.$router.push("/enterpriceAccount");
    } else if (
      !userType.includes("superadmin") &&
      !userType.includes("enterprise")
    ) {
      this.$router.push("/");
    }
  },
};
</script>
