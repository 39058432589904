<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->

  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/currency/sale"
        search_title="بيع عملة"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">بيع عملة</h4>
        </div>

        <!-- START:: CREATE FORM WRAPPER -->
        <form @submit.prevent="validateForm">
          <div class="container">
            <div class="row justify-content-between">
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> إسم الشخص</label>
                  <input
                    type="tel"
                    class="form-control"
                    v-model.trim="data.username"
                  />
                  <button class="button_style_1 load_inline" v-if="load_users">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="tel"
                    class="form-control"
                    v-model.trim="data.phone"
                  />
                  <label for="name_input" class="form-label">رقم الهاتف</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">{{
                    $t("forms.labels.safe")
                  }}</label>
                  <multiselect
                    v-model="data.safe"
                    :options="safes"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
                <h6
                  class="mt-5 mb-0 mx-0 amount-section"
                  v-for="(item, index) in data.safe.currencies"
                  :key="index"
                  :class="{
                    redColor: item.amount < 0,
                    greenColor: item.amount > 0,
                  }"
                  v-if="data.safe"
                >
                  الرصيد :
                  <span
                    >{{ Number(item.amount).toLocaleString() }}
                    {{ item.name_ar }}
                  </span>
                </h6>
              </div>
              <!-- END:: INPUT WRAPPER -->
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">الخارج من الخزنة </label>
                  <multiselect
                    v-model="data.currencyOutOfTheLock"
                    :options="currencies"
                    label="name_ar"
                    track-by="name_ar"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">الداخل إلي الخزنة </label>
                  <multiselect
                    v-model="data.currencyIntoTheLock"
                    :options="currencies"
                    label="name_ar"
                    track-by="name_ar"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="data.amount"
                  />
                  <label for="name_input" class="form-label">المبلغ</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">نوع العملية</label>
                  <multiselect
                    v-model="data.operationType"
                    :options="operationTypes"
                    label="name"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    @input="selectType"
                  >
                  </multiselect>
                </div>
              </div>
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="data.percentage"
                  />
                  <label for="name_input" class="form-label">النسبة</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    readonly
                    type="text"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="total"
                  />
                  <label for="name_input" class="form-label">{{
                    $t("forms.labels.total")
                  }}</label>
                </div>
              </div>
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-12 py-0">
                <div class="input_wrapper top_label date_input">
                  <label class="form-label">التاريخ</label>
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        v-model="data.date"
                        label="Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="data.date"
                      @input="dateMenu = false"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              إضافة
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- END:: CREATE FORM WRAPPER -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SaleCurrency",

  data() {
    return {
      // loader
      loading: true,

      load_users: false,
      // search
      searchOptions: {
        search_users: "",
      },
      users: null,

      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.currency.sale"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========= Date Menu
      dateMenu: false,

      // search_users
      search_users: "",

      // ========== create_data
      data: {
        username: null,
        phone: null,
        operationType: null,
        operationText: null,
        amount: null,
        percentage: 1,
        safe: null,
        currencyIntoTheLock: null,
        currencyOutOfTheLock: null,
        date: new Date().toISOString().slice(0, 10),
      },
      operationTypes: [
        {
          type: "divide",
          name: "قسمة",
        },
        {
          type: "multiply",
          name: "ضرب",
        },
      ],
      safes: [],
      currencies: [],
      users: [],
      // ========== Fixed Data
    };
  },

  watch: {
    [`searchOptions.search_users`](newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.users = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_users(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_users(newVal);
        } else {
          this.get_filterd_users(newVal);
        }
      }
    },
  },

  computed: {
    total() {
      if (this.data.operationText == "divide") {
        const num = this.data.amount / this.data.percentage;
        return parseFloat(num.toFixed()).toLocaleString();
      } else {
        const num = this.data.amount * this.data.percentage;
        return parseFloat(num.toFixed()).toLocaleString();
      }
    },
  },

  methods: {
    selectType() {
      this.data.operationText = this.data.operationType.type;
    },
    // START:: GET SAFES
    getSafes() {
      this.$axios({
        method: "GET",
        url: `client/wallet?type=out`,
      })
        .then((res) => {
          this.safes = res.data.data;
          this.data.safe = this.safes.find(x => x.id === 5);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    // END:: GET SAFES

    // START:: GET CURRENCIES
    getCurrencies() {
      this.$axios({
        method: "GET",
        url: `client/currency`,
      })
        .then((res) => {
          this.currencies = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    // END:: GET CURRENCIES

    // get_filterd_users
    get_filterd_users(keyword) {
      this.load_users = true;
      this.$axios({
        method: "GET",
        url: `client/customer/search?keyword=${keyword}`,
      })
        .then((res) => {
          this.users = res.data.data;
          this.load_users = false;
        })
        .catch((err) => {
          this.load_users = false;
        });
    },

    // START:: VALIDATE CREATE FORM
    validateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.username) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال إسم العميل",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.phone) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال رقم هاتف",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال المبلغ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.percentage) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال نسبة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.safe) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إختيار خزنة ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.currencyIntoTheLock) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إختيار العملة الداخلة الي الخزنة ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.currencyOutOfTheLock) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إختيار العملة الخارجة من الخزنة ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.date) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إختيار تاريخ العملية ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.submitData();
        }
      }
    },
    emptyFormData() {
      this.data.username = null;
      this.data.phone = null;
      this.data.amount = null;
      this.data.percentage = 1;
      this.getSafes();
      this.data.safe = this.safes.find(x => x.id === 5);
      this.data.currencyIntoTheLock = null;
      this.data.currencyOutOfTheLock = null;
      this.data.date = new Date().toISOString().slice(0, 10);
    },
    // Submit Data
    submitData() {
      const submit_data = new FormData();
      // START:: APPEND REQUEST DATA
      submit_data.append("type", "out");
      submit_data.append("customer_name", this.data.username);
      submit_data.append("phone", this.data.phone);
      submit_data.append("amount", this.data.amount);
      submit_data.append("rate", this.data.percentage);
      submit_data.append("total", Number(this.total.replace(/\D/g, "")));
      submit_data.append("wallet_id", this.data.safe.id);
      submit_data.append("currency_to", this.data.currencyIntoTheLock.id);
      submit_data.append("currency_from", this.data.currencyOutOfTheLock.id);
      submit_data.append("date", this.data.date);
      // END:: APPEND REQUEST DATA

      this.$axios({
        method: "POST",
        url: "client/buy_currency",
        data: submit_data,
      })
        .then((res) => {
          this.$iziToast.success({
            timeout: 5000,
            message: this.$t("addSuccess"),
            position: "topRight",
          });
          this.emptyFormData();
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
    // END:: VALIDATE CREATE FORM
  },
  mounted() {
    this.getSafes();
    this.getCurrencies();

    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      userType.includes("confirm_lock_and_deals") &&
      !userType.includes("lock_and_deals")
    ) {
      this.$router.push("/currency/sale");
    } else if (
      !userType.includes("superadmin") &&
      !userType.includes("lock_and_deals")
    ) {
      this.$router.push("/");
    }
  },
};
</script>
