<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/enterprices"
        search_title="المؤسسات"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("CRUD.Create.main_title") }}</h4>
        </div>

        <!-- START:: CREATE FORM WRAPPER -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <!-- START:: SINGLE SELECT INPUT -->
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> إسم صاحب المؤسسة</label>
                  <v-autocomplete
                    v-model="data.enterpriceOwner"
                    :search-input.sync="searchOptions.enterpriceOwners"
                    :items="enterpriceOwners"
                    item-text="name"
                    return-object
                    :hide-no-data="!enterpriceOwners"
                    dense
                  ></v-autocomplete>
                  <button class="button_style_1 load_inline" v-if="load_owners">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>
              <!-- END:: SINGLE SELECT INPUT -->

              <!-- START:: SINGLE SELECT INPUT -->
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label"> مجال المؤسسة </label>
                  <multiselect
                    v-model="data.category"
                    :options="categories"
                    label="name"
                    track-by="name"
                    placeholder=""
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                    :multiple="true"
                  >
                  </multiselect>
                </div>
              </div>
              <!-- END:: SINGLE SELECT INPUT -->
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"
                    >{{ $t("enterprice.data.name") }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.name"
                  />
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label file_input">
                  <span class="file_input_label">Head Letter</span>
                  <label
                    for="file_input_2"
                    class="form-label"
                    v-if="!selectedHead.file"
                  ></label>
                  <label for="file_input_2" class="form-label" v-else>
                    {{ selectedHead.name }}
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    id="file_input_2"
                    @change="handelSelectedHead"
                  />
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label for="record_num" class="form-label">
                    {{ $t("enterprice.data.recordNum") }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.record_num"
                    id="record_num"
                  />
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label file_input">
                  <span class="file_input_label">{{
                    $t("enterprice.data.recordFile")
                  }}</span>
                  <label
                    for="file_input_1"
                    class="form-label"
                    v-if="!selectedFile.file"
                  ></label>
                  <label for="file_input_1" class="form-label" v-else>
                    {{ selectedFile.name }}
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    id="file_input_1"
                    @change="handelSelectedFile"
                  />
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-12 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.taxNum"
                  />
                  <label for="name_input" class="form-label">{{
                    $t("enterprice.data.taxNum")
                  }}</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              {{ $t("Forms.submit") }}
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- END:: CREATE FORM WRAPPER -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: "الرئيسية",
          disabled: false,
          href: "/",
        },
        {
          text: "إضافة مؤسسة جديدة",
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // START:: SELECTED FILE DATA
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },
      selectedHead: {
        file: null,
        name: null,
        path: null,
      },
      // END:: SELECTED FILE DATA

      // ========== create_data
      data: {
        enterpriceOwner: "",
        name: "",
        category: [],
        taxNum: null,
        record_num: null,
      },
      categories: [],

      load_owners: false,

      // search
      searchOptions: {
        enterpriceOwners: "",
      },
      enterpriceOwners: null,
      // ========== Fixed Data
    };
  },

  watch: {
    [`searchOptions.enterpriceOwners`](newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.enterpriceOwners = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_enterprice_owners(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_enterprice_owners(newVal);
        } else {
          this.get_enterprice_owners(newVal);
        }
      }
    },
  },

  methods: {
    // get_filterd_owners
    get_enterprice_owners(keyword) {
      this.load_owners = true;
      this.$axios({
        method: "GET",
        url: `help/owners?keyword=${keyword}`,
      })
        .then((res) => {
          this.load_owners = false;
          this.enterpriceOwners = res.data.data;
        })
        .catch((err) => {
          this.load_owners = false;
        });
    },

    // START:: GET OWNERS
    getData() {
      this.$axios({
        method: "GET",
        url: `client/category`,
      }).then((res) => {
        this.categories = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name_ar,
          };
        });
      });
    },
    // END:: GET OWNERS

    // START:: VALIDATE CREATE FORM
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.enterpriceOwner) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("enterprice.validation.enterpriceOwner"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.name) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("enterprice.validation.name"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (this.data.category.length == 0) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إختيار مجال المؤسسة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.record_num) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("enterprice.validation.recordNum"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.selectedFile.file) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("enterprice.validation.recordFile"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.submitCreateForm();
        }
      }
    },
    // END:: VALIDATE CREATE FORM

    // START:: SUBMIT CREATE FORM
    emptyFormData() {
      this.data.name = null;
      this.data.enterpriceOwner = null;
      this.data.category = [];
      this.selectedFile.file = null;
      this.selectedFile.name = null;
      this.selectedHead.file = null;
      this.selectedHead.name = null;
      this.data.taxNum = null;
      this.data.record_num = null;
    },
    submitCreateForm() {
      const requestData = new FormData();
      requestData.append("ar[name]", this.data.name);
      requestData.append("en[name]", this.data.name);
      requestData.append("owner_id", this.data.enterpriceOwner.id);
      this.data.category.forEach((element, index) => {
        requestData.append(`categories[${index}]`, element.id);
      });
      requestData.append("image", this.selectedFile.file);
      if (this.selectedHead.file) {
        requestData.append("head_letter", this.selectedHead.file);
      }
      requestData.append("tax_number", this.data.taxNum);
      requestData.append("commercial_number", this.data.record_num);
      this.$axios({
        method: "post",
        url: "client/enterprise",
        data: requestData,
      })
        .then(() => {
          this.btnIsLoading = false;
          this.$iziToast.success({
            timeout: 2000,
            message: "تمت الاضافة بنجاح",
            position: "topRight",
          });
          this.emptyFormData();
        })
        .catch((err) => {
          this.btnIsLoading = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
      return;
    },
    // END:: SUBMIT CREATE FORM

    // START:: HANDLING SELECTED FILE METHOD
    handelSelectedFile(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD
    handelSelectedHead(e) {
      this.selectedHead.file = e.target.files[0];
      this.selectedHead.name = e.target.files[0].name;
      this.selectedHead.path = URL.createObjectURL(e.target.files[0]);
    },
  },

  created() {
    this.getData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      userType.includes("confirm_enterprise") &&
      !userType.includes("enterprise")
    ) {
      this.$router.push("/enterprices");
    } else if (
      !userType.includes("superadmin") &&
      !userType.includes("enterprise")
    ) {
      this.$router.push("/");
    }
  },
};
</script>
