<template>
  <div>
    <Loader v-if="loading"></Loader>

    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        :show_print="true"
        search_route="/banksDubai/account"
        search_title=" بنوك دبي"
        :excelFile="true"
        :excelData="excelData"
      />
      <!-- End Breadcrumb -->
      <!--Start Appended Form-->
      <div class="appended_form custom_card" v-if="show_form">
        <form @submit.prevent="validateCreateForm">
          <div class="row">
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.name"
                />
                <label for="name_input" class="form-label">اسم الحساب </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label class="form-label"> إختيار العملة</label>
                <multiselect
                  v-model="data.currency"
                  :options="currencies"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="false"
                  :show-labels="false"
                >
                </multiselect>
              </div>
            </div>

            <div class="buttons_wrapper">
              <button
                class="button_style_1"
                :class="{buttonIndex: btnIsLoading}"
                :disabled="btnIsLoading"
              >
                إضافة
                <span class="btn_loader" v-if="btnIsLoading"></span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <!--End Appended Form-->
      <!-- Start Statistics Card-->
      <div>
        <!-- Start Statistics Card-->
        <ul class="all-state-count">
          <li>
            <div class="state-count">
              <span>عدد حسابات البنك :</span>
              <span>{{ table_length }}</span>
            </div>
          </li>
          <li>
            <div class="state-count">
              <span> اسم البنك :</span>
              <span>{{ bankInfo.name }}</span>
            </div>
          </li>
        </ul>
        <!-- End Statistics Card-->

        <main class="main-table table-print">
          <!-- start of  header of printSection  -->
          <!-- <PrintHead :title="'حسابات بنك دبي' + ' ' + rows[0].customer.name" :number="table_length"></PrintHead> -->
          <!-- end of header of printSection  -->
          <v-data-table
            class="thumb"
            :headers="headers"
            :items="rows"
            :loading="lodaing"
            :loading-text="$t('table.loadingData')"
            v-model="selected"
            :single-select="singleSelect"
            item-key="id"
            :items-per-page="rows.length"
            hide-default-footer
          >
            <!-- ================== You Can use any slots you want ================== -->
            <!-- ====== Select row field ====== -->
            <!-- Select no data State -->
            <template v-slot:no-data>
              {{ $t("table.noData") }}
            </template>
            <template v-slot:[`item.index`]="{index}">
              {{ index + 1 }}
            </template>
            <!-- Select actions-->
            <template v-slot:[`item.amount`]="{item}">
              <span
                :class="{
                  redColor: item.amount < 0,
                  greenColor: item.amount > 0,
                }"
              >
                {{ Number(item.amount).toLocaleString() }}
              </span>
            </template>
            <template v-slot:[`item.actions`]="{item}">
              <div class="_actions">
                <v-icon class="delete" small @click="deleteItem(item)">
                  fal fa-trash
                </v-icon>
              </div>
            </template>

            <!-- ======================== Start Top Section ======================== -->
            <template v-slot:top>
              <!-- Delete dialog -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">{{
                    $t("table.deletedialog.areYouSure")
                  }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="deleteItemConfirm"
                      >{{ $t("table.deletedialog.ok") }}
                      <span
                        class="btn_loader_model"
                        v-if="loadingBtnDialog"
                      ></span
                    ></v-btn>
                    <v-btn color="#F44336" @click="dialogDelete = false">{{
                      $t("table.deletedialog.cancel")
                    }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogShow" min-width="50%">
                <v-card class="p-0">
                  <div class="show_modal_content_wrapper" v-if="itemToShow">
                    <div class="row">
                      <!-- START:: SHOW DATA LINE -->
                      <div class="col-md-6">
                        <div class="data_line_wrapper">
                          <h4 class="mb-2">اسم الحساب</h4>
                          <h5>{{ itemToShow.account_name }}</h5>
                        </div>
                      </div>
                      <!-- END:: SHOW DATA LINE -->

                      <!-- START:: SHOW DATA LINE -->
                      <div class="col-md-6">
                        <div class="data_line_wrapper">
                          <h4 class="mb-2">الرصيد</h4>
                          <h5>{{ itemToShow.amount }}</h5>
                        </div>
                      </div>
                      <!-- END:: SHOW DATA LINE -->

                      <!-- START:: SHOW DATA LINE -->
                      <div class="col-md-6">
                        <div class="data_line_wrapper">
                          <h4 class="mb-2">العملة</h4>
                          <h5>{{ itemToShow.currency }}</h5>
                        </div>
                      </div>
                      <!-- END:: SHOW DATA LINE -->
                    </div>
                  </div>
                </v-card>
              </v-dialog>

              <!-- Add & Delete -->
            </template>
            <!-- ======================== End Top Section ======================== -->
          </v-data-table>
        </main>
        <!-- End Main Section -->
      </div>
      <!-- End Pagination -->
      <!-- fixed button  -->
      <button @click="showAdd" class="fixed-route-link">إضافة حساب جديد</button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import StatsCard from "./../../../../components/Charts/ChartComponent.vue";
import SearchSection from "@/components/Search/client_account.vue";
export default {
  props: ["id"],

  components: {
    StatsCard,
    SearchSection,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      //show form
      show_form: false,
      // loader
      loading: true,
      // search
      searchItems: null,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "حسابات بنوك دبي",
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "حساب بنوك دبي",
          number: "10",
        },
      ],

      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Date_Menues
      from_date_menu: false,
      to_date_menu: false,

      // ========== Filter
      filter: {
        name: null,
        safe: [],
        from: null,
        to: null,
      },

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,
      // ========== Loding
      lodaing: false,
      btnIsLoading: false,

      data: {
        name: null,
        currency: "",
      },

      // Fixed_data
      safes: ["foo", "bar", "fizz", "buzz"],
      appendedData: [],
      currencies: [],
      table_length: null,
      bankInfo: null,
      // excel data
      excelData: {
        name: null,
        items: null,
        json_fields: {
          "اسم الحساب": "name",
          الرصيد: {
            field: "amount",
            callback: (value) => {
              return Number(value).toLocaleString();
            },
          },
          العملة: "currency.name_ar",
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: "اسم الحساب",
            value: "name",
            align: "center",
            sortable: false,
            width: 150,
          },
          {
            text: "الرصيد",
            value: "amount",
            align: "center",
            sortable: false,
            width: 120,
          },
          {
            text: "العملة",
            value: "currency.name_ar",
            align: "center",
            sortable: false,
            width: 80,
          },
          {
            text: "التحكم",
            value: "actions",
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            sortable: false,
            width: 80,
          },
        ];
      }
    },
  },

  methods: {
    // searchTable
    searchTable(items) {
      this.searchItems = items;
    },

    //Show Add
    showAdd() {
      this.show_form = true;
      this.data.currency = null;
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({path: `/client_accounts/${this.id}/add`});
    },
    showItem(item) {
      this.dialogShow = true;
      this.itemToShow = item;
    },
    editItem(item) {
      this.$router.push({path: "/client_accounts/edit/" + item.id});
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/account_bank_dubai/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // ============ Append Data ============
    addItem() {
      this.appendedData.push({
        id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
        name: null,
        currency: null,
      });
    },
    // ============ Append Data ============

    // ============== Get Data
    getData() {
      // ===== Currencies
      this.$axios({
        method: "GET",
        url: `client/currency`,
      }).then((res) => {
        this.currencies = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name_ar,
          };
        });
      });
    },

    // ======== Validate Form

    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.name) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال اسم الحساب",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.currency) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.currency"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.show_form = false;
          this.btnIsLoading = false;
          this.submitData();
          return;
        }
      }
    },

    // Submit Data
    submitData() {
      const submit_data = new FormData();
      submit_data.append("name", this.data.name);
      submit_data.append("currency_id", this.data.currency.id);
      submit_data.append("bank_dubai_id", this.id);
      this.$axios({
        method: "POST",
        url: "client/account_bank_dubai",
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "topRight",
          });
          this.setRows();
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },

    // Set Rows
    setRows() {
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/account_bank_dubai",
        params: {account_bank_dubai_id: this.id},
      })
        .then((res) => {
          this.rows = res.data.data;
          this.bankInfo = res.data.bank;
          this.table_length = this.rows.length;
          this.data.name = res.data.bank.name + " " + "-";
          this.excelData.items = this.rows;
          this.excelData.name = "حسسابات بنك دبي" + " " + this.bankInfo.name;
          this.lodaing = false;
        })
        .catch((err) => {
          this.lodaing = false;
        });
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    this.setRows();
    //Get Data
    this.getData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (!userType.includes("superadmin") && !userType.includes("banks_dubai")) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped></style>
