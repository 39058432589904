<template>
  <div class="search-section">
    <div class="divider">
      <h4>بحث في المصروفات</h4>
      <hr
        role="separator"
        aria-orientation="horizontal"
        class="v-divider theme--dark"
      />
    </div>
    <div class="row">
      <div class="col-lg-3 py-0">
        <div class="input_wrapper top_label mb-3">
          <label class="form-label"> الخزنة </label>
          <multiselect
            v-model="searchData.lock"
            :options="searchOptions.locks"
            v-on:keydown.enter.prevent="$emit('filterTable', searchData)"
            label="name"
            track-by="name"
            placeholder=" "
            :searchable="true"
            :allow-empty="true"
            :show-labels="false"
            @input="chooseLock(searchData.lock), $emit('filterTable', searchData)"
          >
          </multiselect>
        </div>
      </div>

      <div class="col-lg-3 py-0">
        <div class="input_wrapper top_label mb-3">
          <label class="form-label"> العملة </label>
          <multiselect
            v-model="searchData.currency"
            :options="searchOptions.currency"
            label="name_ar"
            track-by="name_ar"
            placeholder=" "
            :searchable="true"
            :allow-empty="true"
            :show-labels="false"
            @input="chooseCurrency(searchData.currency), $emit('filterTable', searchData)"
          >
          </multiselect>
        </div>
      </div>
      <div class="col-lg-3 py-0">
        <div class="input_wrapper top_label date_input">
          <label class="form-label">من</label>
          <v-menu
            ref="to_date_menu"
            v-model="searchOptions.from_date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="searchData.from"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchData.from"
              @input="searchOptions.from_date_menu = false"
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>
        </div>
      </div>
      <div class="col-lg-3 py-0">
        <div class="input_wrapper top_label date_input">
          <label class="form-label">إلي</label>
          <v-menu
            ref="to_date_menu"
            v-model="searchOptions.to_date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="searchData.to"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchData.to"
              @input="searchOptions.to_date_menu = false"
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>
        </div>
      </div>
      <div class="col-lg-12 text-center py-0">
        <button
          class="button_style_1"
          @click="$emit('filterTable', searchData)"
        >
          بحث
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // search
      searchData: {
        lock: null,
        lockId: null,
        currency: null,
        currencyId: null,
        from: new Date().toISOString().slice(0, 10),
        to: new Date().toISOString().slice(0, 10),
      },
      searchOptions: {
        locks: [],
        currency: [],
        from_date_menu: false,
        to_date_menu: false,
      },
    };
  },
  watch: {
    [`searchOptions.search_users`](newVal) {
      this.get_filterd_users(newVal);
    },
  },
  methods: {
    // get_filterd_users
    get_filterd_users(keyword) {
      this.$axios({
        method: "GET",
        url: `client/customer`,
        params: { keyword: keyword },
      }).then((res) => {
        this.users = res.data.data;
      });
    },
    getSafes() {
      // START:: GET SAFES
      this.$axios({
        method: "GET",
        url: `client/wallet`,
      })
        .then((res) => {
          this.searchOptions.locks = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
      // END:: GET SAFES
    },
    getCurrencies() {
      // START:: GET SAFES
      this.$axios({
        method: "GET",
        url: `client/currency`,
      })
        .then((res) => {
          this.searchOptions.currency = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
      // END:: GET SAFES
    },
    chooseLock(item) {
      if (this.searchData.lock) {
        this.searchData.lockId = item.id;
      } else {
        this.searchData.lockId = null;
      }
    },

    chooseCurrency(item) {
      if (this.searchData.currency) {
        this.searchData.currencyId = item.id;
      } else {
        this.searchData.currencyId = null;
      }
    },
  },

  created() {
    this.getSafes();
    this.getCurrencies();
  },
};
</script>
<style lang="scss">
.search-section .load_inline {
  width: 30px !important;
  height: 25px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
  left: 1px;
  position: absolute !important;
  background: transparent !important;
  margin: 0 !important;

  span {
    right: 0 !important;
    left: 0 !important;
    margin: auto !important;
    border-top: 3px solid var(--mainColor-2) !important;
    border-right: 3px solid transparent !important;
  }
}
</style>

