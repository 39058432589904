<template>
  <div>
    <Loader v-if="loading"></Loader>

    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb :items="items" :show_print="true" :excelFile="false" />
      <!-- Start Statistics Card-->
      <div>
        <div class="table-print">
          <!-- -------------------------====================================---------------------------------- -->
          <!-- Start Statistics Card-->
          <ul class="all-state-count showOnPrint">
            <li>
              <div class="state-count">
                <span>عدد أرصدة العملاء :</span>
                <span>{{ table_length_client }}</span>
              </div>
            </li>
          </ul>
          <!-- End Statistics Card-->

          <main class="main-table">
            <!-- start of  header of printSection  -->
            <PrintHead
              :title="'أرصدة العملاء'"
              :hideHeader="true"
              :number="table_length_client"
            ></PrintHead>
            <!-- end of header of printSection  -->
            <v-data-table
              class="thumb row-pointer"
              :headers="headersـclient"
              :items="rows_client"
              :loading="lodaing"
              :loading-text="$t('table.loadingData')"
              v-model="selected"
              :single-select="singleSelect"
              item-key="id"
              :items-per-page="rows_client.length"
              hide-default-footer
              @click:row="acceptAmount"
            >
              <!-- ================== You Can use any slots you want ================== -->
              <!-- ====== Select row field ====== -->
              <!-- Select no data State -->
              <template v-slot:no-data>
                {{ $t("table.noData") }}
              </template>
              <template v-slot:[`item.index`]="{index}">
                {{ index + 1 }}
              </template>
              <!-- Select actions-->
              <template v-slot:[`item.wallet_in`]="{item}">
                <span v-if="item.wallet_in < 0" class="redColor">
                  {{ Number(item.wallet_in).toLocaleString() }}
                </span>
                <span v-else-if="item.wallet_in > 0" class="greenColor">
                  {{ Number(item.wallet_in).toLocaleString() }}
                </span>
                <span v-else>
                  {{ Number(item.wallet_in).toLocaleString() }}
                </span>
              </template>
              <template v-slot:[`item.wallet_out`]="{item}">
                <span v-if="item.wallet_out < 0" class="redColor">
                  {{ Number(item.wallet_out).toLocaleString() }}
                </span>
                <span v-else-if="item.wallet_out > 0" class="greenColor">
                  {{ Number(item.wallet_out).toLocaleString() }}
                </span>
                <span v-else>
                  {{ Number(item.wallet_out).toLocaleString() }}
                </span>
              </template>
              <template v-slot:[`item.amount`]="{item}">
                <span v-if="item.amount < 0" class="redColor">
                  {{ Number(item.amount).toLocaleString() }}
                </span>
                <span v-else-if="item.amount > 0" class="greenColor">
                  {{ Number(item.amount).toLocaleString() }}
                </span>
                <span v-else> {{ Number(item.amount).toLocaleString() }} </span>
              </template>
              <template v-slot:[`item.actions`]="{item}">
                <div class="_actions actions2">
                  <button
                    type="button"
                    :class="{
                      accept: allItemsId.includes(item.id_name),
                      normal: !allItemsId.includes(item.id_name),
                    }"
                    class="confirm_btn"
                  >
                    <i class="far fa-check"></i>
                  </button>
                </div>
              </template>
              <!-- ======================== End Top Section ======================== -->
            </v-data-table>
          </main>
          <div class="showMoney mb-5">
            <ul class="new_ul">
              <li
                v-for="item in currencies_amount_client"
                :key="item.currency_id"
              >
                <div class="state-count">
                  <span> الرصيد :</span>
                  <span
                    :class="{
                      redColor: item.total < 0,
                      greenColor: item.total > 0,
                    }"
                  >
                    {{ Number(item.total).toLocaleString() }}
                    {{ item.currency.name }}</span
                  >
                </div>
              </li>
            </ul>
          </div>
          <!-- ----------------------------=========================================================------------------------------- -->
          <ul class="all-state-count showOnPrint">
            <li>
              <div class="state-count">
                <span>عدد أرصدة الخزن :</span>
                <span>{{ table_length_wallet }}</span>
              </div>
            </li>
          </ul>
          <main class="main-table">
            <!-- start of  header of printSection  -->
            <PrintHead
              :title="'أرصدة الخزن'"
              :hideHeader="true"
              :number="table_length_wallet"
            ></PrintHead>
            <!-- end of header of printSection  -->
            <v-data-table
              class="thumb row-pointer"
              :headers="headersـwallet"
              :items="rows_wallet"
              :loading="lodaing"
              :loading-text="$t('table.loadingData')"
              v-model="selected"
              :single-select="singleSelect"
              item-key="id"
              :items-per-page="rows_wallet.length"
              hide-default-footer
              @click:row="acceptAmount"
            >
              <!-- ================== You Can use any slots you want ================== -->
              <!-- ====== Select row field ====== -->
              <!-- Select no data State -->
              <template v-slot:no-data>
                {{ $t("table.noData") }}
              </template>
              <template v-slot:[`item.index`]="{index}">
                {{ index + 1 }}
              </template>
              <!-- Select actions-->
              <template v-slot:[`item.wallet_in`]="{item}">
                <span v-if="item.wallet_in < 0" class="redColor">
                  {{ Number(item.wallet_in).toLocaleString() }}
                </span>
                <span v-else-if="item.wallet_in > 0" class="greenColor">
                  {{ Number(item.wallet_in).toLocaleString() }}
                </span>
                <span v-else>
                  {{ Number(item.wallet_in).toLocaleString() }}
                </span>
              </template>
              <template v-slot:[`item.wallet_out`]="{item}">
                <span v-if="item.wallet_out < 0" class="redColor">
                  {{ Number(item.wallet_out).toLocaleString() }}
                </span>
                <span v-else-if="item.wallet_out > 0" class="greenColor">
                  {{ Number(item.wallet_out).toLocaleString() }}
                </span>
                <span v-else>
                  {{ Number(item.wallet_out).toLocaleString() }}
                </span>
              </template>
              <template v-slot:[`item.amount`]="{item}">
                <span v-if="item.amount < 0" class="redColor">
                  {{ Number(item.amount).toLocaleString() }}
                </span>
                <span v-else-if="item.amount > 0" class="greenColor">
                  {{ Number(item.amount).toLocaleString() }}
                </span>
                <span v-else> {{ Number(item.amount).toLocaleString() }} </span>
              </template>
              <template v-slot:[`item.actions`]="{item}">
                <div class="_actions actions2">
                  <button
                    type="button"
                    :class="{
                      accept: allItemsId.includes(item.id_name),
                      normal: !allItemsId.includes(item.id_name),
                    }"
                    class="confirm_btn"
                  >
                    <i class="far fa-check"></i>
                  </button>
                </div>
              </template>
              <!-- ======================== End Top Section ======================== -->
            </v-data-table>
          </main>
          <div class="showMoney mb-5">
            <ul class="new_ul">
              <li
                v-for="item in currencies_amount_wallet"
                :key="item.currency_id"
              >
                <div class="state-count">
                  <span> الرصيد :</span>
                  <span
                    :class="{
                      redColor: item.total < 0,
                      greenColor: item.total > 0,
                    }"
                  >
                    {{ Number(item.total).toLocaleString() }}
                    {{ item.currency.name }}</span
                  >
                </div>
              </li>
            </ul>
          </div>
          <!-- ----------------------------=========================================================------------------------------- -->
          <!-- Start Statistics Card-->
          <ul class="all-state-count showOnPrint">
            <li>
              <div class="state-count">
                <span>عدد حسابات بنوك دبي :</span>
                <span>{{ table_length_dubai }}</span>
              </div>
            </li>
          </ul>
          <!-- End Statistics Card-->

          <main class="main-table">
            <!-- start of  header of printSection  -->
            <PrintHead
              :title="'حسابات بنوك دبي'"
              :hideHeader="true"
              :number="table_length_dubai"
            ></PrintHead>
            <!-- end of header of printSection  -->
            <v-data-table
              class="thumb row-pointer"
              :headers="headersـdubai"
              :items="rows_dubai"
              :loading="lodaing"
              :loading-text="$t('table.loadingData')"
              v-model="selected"
              :single-select="singleSelect"
              item-key="id"
              :items-per-page="rows_dubai.length"
              hide-default-footer
              @click:row="acceptAmount"
            >
              <!-- ================== You Can use any slots you want ================== -->
              <!-- ====== Select row field ====== -->
              <!-- Select no data State -->
              <template v-slot:no-data>
                {{ $t("table.noData") }}
              </template>
              <template v-slot:[`item.index`]="{index}">
                {{ index + 1 }}
              </template>
              <!-- Select actions-->
              <template v-slot:[`item.operation_in`]="{item}">
                <span v-if="item.operation_in < 0" class="redColor">
                  {{ Number(item.operation_in).toLocaleString() }}
                </span>
                <span v-else-if="item.operation_in > 0" class="greenColor">
                  {{ Number(item.operation_in).toLocaleString() }}
                </span>
                <span v-else>
                  {{ Number(item.operation_in).toLocaleString() }}
                </span>
              </template>
              <template v-slot:[`item.operation_out`]="{item}">
                <span v-if="item.operation_out < 0" class="redColor">
                  {{ Number(item.operation_out).toLocaleString() }}
                </span>
                <span v-else-if="item.operation_out > 0" class="greenColor">
                  {{ Number(item.operation_out).toLocaleString() }}
                </span>
                <span v-else>
                  {{ Number(item.operation_out).toLocaleString() }}
                </span>
              </template>
              <template v-slot:[`item.amount`]="{item}">
                <span v-if="item.amount < 0" class="redColor">
                  {{ Number(item.amount).toLocaleString() }}
                </span>
                <span v-else-if="item.amount > 0" class="greenColor">
                  {{ Number(item.amount).toLocaleString() }}
                </span>
                <span v-else> {{ Number(item.amount).toLocaleString() }} </span>
              </template>
              <template v-slot:[`item.actions`]="{item}">
                <div class="_actions actions2">
                  <button
                    type="button"
                    :class="{
                      accept: allItemsId.includes(item.id_name),
                      normal: !allItemsId.includes(item.id_name),
                    }"
                    class="confirm_btn"
                  >
                    <i class="far fa-check"></i>
                  </button>
                  <!-- <span v-else> ---- </span> -->
                </div>
              </template>
              <!-- ======================== End Top Section ======================== -->
            </v-data-table>
          </main>
          <div class="showMoney mb-5">
            <ul class="new_ul">
              <li
                v-for="item in currencies_amount_dubai"
                :key="item.currency_id"
              >
                <div class="state-count">
                  <span> الرصيد :</span>
                  <span
                    :class="{
                      redColor: item.total < 0,
                      greenColor: item.total > 0,
                    }"
                  >
                    {{ Number(item.total).toLocaleString() }}
                    {{ item.currency.name }}</span
                  >
                </div>
              </li>
            </ul>
          </div>
          <!-- ----------------------------=========================================================------------------------------- -->
          <!-- Start Statistics Card-->
          <ul class="all-state-count showOnPrint">
            <li>
              <div class="state-count">
                <span>عدد حسابات المؤسسات :</span>
                <span>{{ table_length_account }}</span>
              </div>
            </li>
          </ul>
          <!-- End Statistics Card-->

          <main class="main-table">
            <!-- start of  header of printSection  -->
            <PrintHead
              :title="'حسابات المؤسسات'"
              :hideHeader="true"
              :number="table_length_account"
            ></PrintHead>
            <!-- end of header of printSection  -->
            <v-data-table
              class="thumb row-pointer"
              :headers="headersـaccount"
              :items="rows_account"
              :loading="lodaing"
              :loading-text="$t('table.loadingData')"
              v-model="selected"
              :single-select="singleSelect"
              item-key="id"
              :items-per-page="rows_account.length"
              hide-default-footer
              @click:row="acceptAmount"
            >
              <!-- ================== You Can use any slots you want ================== -->
              <!-- ====== Select row field ====== -->
              <!-- Select no data State -->
              <template v-slot:no-data>
                {{ $t("table.noData") }}
              </template>
              <template v-slot:[`item.index`]="{index}">
                {{ index + 1 }}
              </template>
              <!-- Select actions-->
              <template v-slot:[`item.wallet_in`]="{item}">
                <span v-if="item.wallet_in < 0" class="redColor">
                  {{ Number(item.wallet_in).toLocaleString() }}
                </span>
                <span v-else-if="item.wallet_in > 0" class="greenColor">
                  {{ Number(item.wallet_in).toLocaleString() }}
                </span>
                <span v-else>
                  {{ Number(item.wallet_in).toLocaleString() }}
                </span>
              </template>
              <template v-slot:[`item.wallet_out`]="{item}">
                <span v-if="item.wallet_out < 0" class="redColor">
                  {{ Number(item.wallet_out).toLocaleString() }}
                </span>
                <span v-else-if="item.wallet_out > 0" class="greenColor">
                  {{ Number(item.wallet_out).toLocaleString() }}
                </span>
                <span v-else>
                  {{ Number(item.wallet_out).toLocaleString() }}
                </span>
              </template>
              <template v-slot:[`item.amount`]="{item}">
                <span v-if="item.amount < 0" class="redColor">
                  {{ Number(item.amount).toLocaleString() }}
                </span>
                <span v-else-if="item.amount > 0" class="greenColor">
                  {{ Number(item.amount).toLocaleString() }}
                </span>
                <span v-else> {{ Number(item.amount).toLocaleString() }} </span>
              </template>
              <template v-slot:[`item.actions`]="{item}">
                <div class="_actions actions2">
                  <button
                    type="button"
                    :class="{
                      accept: allItemsId.includes(item.id_name),
                      normal: !allItemsId.includes(item.id_name),
                    }"
                    class="confirm_btn"
                  >
                    <i class="far fa-check"></i>
                  </button>
                  <!-- <span v-else> ---- </span> -->
                </div>
              </template>
              <!-- ======================== End Top Section ======================== -->
            </v-data-table>
          </main>
          <div class="showMoney mb-5">
            <ul class="new_ul">
              <li>
                <div class="state-count">
                  <span> الرصيد :</span>
                  <span
                    :class="{
                      redColor: currencies_amount_account < 0,
                      greenColor: currencies_amount_account > 0,
                    }"
                  >
                    {{ Number(currencies_amount_account).toLocaleString() }}
                    SAR</span
                  >
                </div>
              </li>
            </ul>
          </div>
          <!-- ----------------------------=========================================================------------------------------- -->
        </div>
        <!-- End Main Section -->
        <h3 class="showMoneyTitle">مجموع الأرصدة المحددة</h3>
        <ul class="all-state-count showOnPrint">
          <li>
            <div class="state-count">
              <span>إجمالي أخر عمليه تجميع رصيد :</span>
              <span
                v-if="last_collecting.total_price"
                :class="{
                  redColor: last_collecting.total_price < 0,
                  greenColor: last_collecting.total_price > 0,
                }"
                >{{
                  Number(last_collecting.total_price).toLocaleString()
                }}</span
              >
              <span v-else> لا يوجد </span>
            </div>
          </li>
          <li>
            <div class="state-count">
              <span> تاريخ اخر عمليه تجميع رصيد :</span>
              <span v-if="last_collecting.date">{{ last_collecting.date }}</span>
              <span v-else> لا يوجد </span>
            </div>
          </li>
        </ul>
        <div class="showMoney mb-5">
          <ul class="new_ul count_ul">
            <li v-for="(item, index) in form_inputs" :key="index">
              <div class="row justify-content-between">
                <div class="col-lg-3 py-0">
                  <div class="input_wrapper top_label">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      v-model="item.amount"
                    />
                    <label for="name_input" class="form-label"
                      >المبلغ ( {{ item.name }} )</label
                    >
                  </div>
                </div>
                <div class="col-lg-3 py-0">
                  <div class="input_wrapper top_label">
                    <input
                      type="number"
                      step="any"
                      class="form-control"
                      v-model="item.multiply"
                    />
                    <label for="name_input" class="form-label">ضرب </label>
                  </div>
                </div>
                <div class="col-lg-3 py-0">
                  <div class="input_wrapper top_label">
                    <input
                      type="text"
                      class="form-control"
                      v-model="item.divide"
                    />
                    <label for="name_input" class="form-label">قسمة </label>
                  </div>
                </div>
                <div class="col-lg-3 py-0">
                  <div class="input_wrapper top_label">
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      :value="
                        parseFloat(
                          (
                            (item.amount * item.multiply) /
                            item.divide
                          ).toFixed()
                        ).toLocaleString()
                      "
                    />
                    <label for="name_input" class="form-label">الإجمالي </label>
                  </div>
                </div>
              </div>
              <!-- <div class="state-count">
                <span> الرصيد :</span>
                <span :class="{redColor: item.newVal < 0 , greenColor: item.newVal > 0}">
                  {{ Number(item.newVal).toLocaleString() }}
                  {{ item.name_ar }}</span
                >
              </div> -->
            </li>
          </ul>
          <div class="total_amount">
            <div class="buttons-money">
              <button
                class="button_style_1"
                :class="{buttonIndex: btnIsLoading}"
                :disabled="btnIsLoading"
                @click="getTotals"
              >
                عرض تجميع الرصيد
                <span class="btn_loader" v-if="btnIsLoading"></span>
              </button>
              <button class="button_style_1 green" @click="showTableOfAmount">
                تجميعات الأرصده السابقه
              </button>
            </div>
            <p v-if="getAllTotal">
              إجمالي تجميع الرصيد :
              <span
                :class="{
                  redColor: getAllTotal < 0,
                  greenColor: getAllTotal > 0,
                }"
                >{{ parseFloat(getAllTotal).toLocaleString() }}</span
              >
            </p>
          </div>
        </div>
        <!-- -------------------------=========================================================---------------------------------- -->
        <!-- Start Statistics Card-->
        <div v-if="showTableAmount" class="fadeIn">
          <ul class="all-state-count showOnPrint">
            <li>
              <div class="state-count">
                <span>عدد عمليات تجميع الأرصدة :</span>
                <span>{{ table_length_last_amount }}</span>
              </div>
            </li>
            <li>
              <div class="state-count">
                <span>إجمالي أخر عمليه تجميع رصيد :</span>
                <span
                v-if="last_collecting.total_price"
                  :class="{
                    redColor: last_collecting.total_price < 0,
                    greenColor: last_collecting.total_price > 0,
                  }"
                  >{{
                    Number(last_collecting.total_price).toLocaleString()
                  }}</span
                >
                <span v-else> لا يوجد </span>
              </div>
            </li>
            <li>
              <div class="state-count">
                <span> تاريخ اخر عمليه تجميع رصيد :</span>
                <span v-if="last_collecting.date">{{ last_collecting.date }}</span>
                <span v-else> لا يوجد </span>
              </div>
            </li>
          </ul>
          <!-- End Statistics Card-->

          <main class="main-table">
            <v-data-table
              class="thumb row-pointer"
              :headers="headersـamount"
              :items="last_amount"
              :loading="lodaing_amount"
              :loading-text="$t('table.loadingData')"
              v-model="selected"
              :single-select="singleSelect"
              item-key="id"
              :items-per-page="table_length_last_amount"
              hide-default-footer
              @click:row="acceptAmount"
            >
              <!-- ================== You Can use any slots you want ================== -->
              <!-- ====== Select row field ====== -->
              <!-- Select no data State -->
              <template v-slot:no-data>
                {{ $t("table.noData") }}
              </template>
              <template v-slot:[`item.index`]="{index}">
                {{ index + 1 }}
              </template>
              <!-- Select actions-->
              <template v-slot:[`item.total_price`]="{item}">
                <span v-if="item.total_price < 0" class="redColor">
                  {{ Number(item.total_price).toLocaleString() }}
                </span>
                <span v-else-if="item.total_price > 0" class="greenColor">
                  {{ Number(item.total_price).toLocaleString() }}
                </span>
                <span v-else>
                  {{ Number(item.total_price).toLocaleString() }}
                </span>
              </template>
              <template v-slot:[`item.date`]="{item}">
                {{ item.day }} - {{ item.date }} - {{ item.time }}
              </template>
              <!-- Select actions-->
              <template v-slot:[`item.actions`]="{item}">
                <div class="_actions">
                  <v-icon class="delete" small @click="deleteItem(item)">
                    fal fa-trash
                  </v-icon>
                </div>
              </template>
              <!-- ======================== Start Top Section ======================== -->
              <template v-slot:top>
                <!-- Delete dialog -->
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5 justify-center">{{
                      $t("table.deletedialog.areYouSure")
                    }}</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="#1B5E20" @click="deleteItemConfirm"
                        >{{ $t("table.deletedialog.ok") }}
                        <span
                          class="btn_loader_model"
                          v-if="loadingBtnDialog"
                        ></span
                      ></v-btn>
                      <v-btn color="#F44336" @click="dialogDelete = false">{{
                        $t("table.deletedialog.cancel")
                      }}</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
              <!-- ======================== End Top Section ======================== -->
            </v-data-table>
          </main>
        </div>
      </div>
      <!-- End Pagination -->
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import StatsCard from "../../../components/Charts/ChartComponent.vue";
import SearchSection from "@/components/Search/clients-report";
export default {
  props: ["id"],

  components: {
    StatsCard,
    SearchSection,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // loader
      loading: true,
      // search
      searchItems: null,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "أرصدة العملاء",
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "أرصدة العملاء",
          number: "10",
        },
      ],

      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Date_Menues
      from_date_menu: false,
      to_date_menu: false,

      // ========== Filter
      filter: {
        name: null,
        safe: [],
        from: null,
        to: null,
      },

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows_client: [], // injected in created
      rows_wallet: [], // injected in created
      rows_dubai: [], // injected in created
      rows_account: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== Loding
      lodaing: false,
      lodaing_amount: false,

      // Fixed_data
      safes: ["foo", "bar", "fizz", "buzz"],
      table_length_client: null,
      table_length_wallet: null,
      table_length_dubai: null,
      table_length_account: null,
      table_length_last_amount: null,
      last_amount: null,
      showTableAmount: false,
      last_collecting: {
        total_price: null,
        date: null,
      },
      currencies: null,
      currencies_amount_client: null,
      currencies_amount_wallet: null,
      currencies_amount_dubai: null,
      currencies_amount_account: null,
      allItemsId: [],
      calculaterShow: false,
      currencyName: null,
      calculater: {
        amount: null,
        mulit: 1,
        divide: 1,
      },
      getAllTotal: null,
      form_inputs: [],
      btnIsLoading: false,
    };
  },

  computed: {
    calculaterAll() {
      var amount = this.calculater.amount;
      const num = (amount * this.calculater.mulit) / this.calculater.divide;
      return parseFloat(num.toFixed()).toLocaleString();
    },
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headersـclient() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {text: "إسم العميل", value: "name", align: "center"},
          {
            text: "إجمالي عمليات الإيداع",
            value: "wallet_in",
            align: "center",
          },
          {
            text: "إجمالي عمليات السحب",
            value: "wallet_out",
            align: "center",
          },
          {
            text: "إجمالي السحب و الإيداع",
            value: "amount",
            align: "center",
          },
          {
            text: "حساب العميل",
            value: "amount",
            align: "center",
          },
          {
            text: "التحكم",
            value: "actions",
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
        ];
      }
    },
    headersـwallet() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {text: "الخزنة", value: "name", align: "center"},
          {
            text: "إجمالي عمليات الإيداع",
            value: "wallet_in",
            align: "center",
          },
          {
            text: "إجمالي عمليات السحب",
            value: "wallet_out",
            align: "center",
          },
          {
            text: "إجمالي السحب و الإيداع",
            value: "amount",
            align: "center",
          },
          {
            text: "رصيد الخزنة",
            value: "amount",
            align: "center",
          },
          {
            text: "التحكم",
            value: "actions",
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
        ];
      }
    },
    headersـdubai() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {text: "بنك دبي", value: "name", align: "center"},
          {
            text: "إجمالي عمليات الإيداع",
            value: "operation_in",
            align: "center",
          },
          {
            text: "إجمالي عمليات السحب",
            value: "operation_out",
            align: "center",
          },
          {
            text: "إجمالي السحب و الإيداع",
            value: "amount",
            align: "center",
          },
          {
            text: "رصيد البنك",
            value: "amount",
            align: "center",
          },
          {
            text: "التحكم",
            value: "actions",
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
        ];
      }
    },
    headersـaccount() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {text: "حساب المؤسسة", value: "name", align: "center"},
          {
            text: "إجمالي عمليات الإيداع",
            value: "wallet_in",
            align: "center",
          },
          {
            text: "إجمالي عمليات السحب",
            value: "wallet_out",
            align: "center",
          },
          {
            text: "إجمالي السحب و الإيداع",
            value: "amount",
            align: "center",
          },
          {
            text: "حساب العميل",
            value: "amount",
            align: "center",
          },
          {
            text: "التحكم",
            value: "actions",
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
        ];
      }
    },
    headersـamount() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: "إجمالي تجميع الرصيد",
            value: "total_price",
            align: "center",
          },
          {
            text: "تاريخ تجميع الرصيد",
            value: "date",
            align: "center",
          },
          {
            text: "حذف",
            value: "actions",
            sortable: false,
            align: "center",
            width: 100,
          },
        ];
      }
    },
  },
  methods: {
    // searchTable
    searchTable(items) {
      this.rows_client = [];
      this.searchItems = items;
      this.setRows();
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({path: `/client_accounts/${this.id}/add`});
    },
    showItem(item) {
      this.dialogShow = true;
      this.itemToShow = item;
    },
    editItem(item) {
      this.$router.push({path: "/client_accounts/edit/" + item.id});
    },
    acceptAmount(ele) {
      const i = ele.id_name;
      const indexExpanded = this.allItemsId.findIndex((i) => i === ele.id_name);
      if (indexExpanded > -1) {
        this.allItemsId.splice(indexExpanded, 1);
        this.form_inputs.forEach((element) => {
          if (ele.currency.id == element.id) {
            element.amount -= Number(ele.amount);
          }
        });
      } else {
        this.allItemsId.push(ele.id_name);
        this.form_inputs.forEach((element) => {
          if (ele.currency.id == element.id) {
            element.amount += Number(ele.amount);
          }
        });
      }
      this.getAllTotal = null;
    },
    // ==================== End CRUD ====================
    getAllcurriences() {
      this.$axios({
        method: "GET",
        url: "client/currency",
      })
        .then((res) => {
          this.currencies = res.data.data;
          this.currencies.forEach((ele) => {
            this.form_inputs.push({
              id: ele.id,
              name: ele.name_ar,
              amount: 0,
              multiply: 1,
              divide: 1,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getTotals() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        let sum = 0;
        this.form_inputs.forEach((el) => {
          sum += (el.amount * el.multiply) / el.divide;
        });
        this.submitData(sum.toFixed().toLocaleString());
      }
    },
    // Submit Data
    submitData(total) {
      const submit_data = new FormData();
      submit_data.append("total_price", total);
      this.$axios({
        method: "POST",
        url: "/help/collecting_customer_balances",
        data: submit_data,
      })
        .then((res) => {
          this.getAllTotal = total;
          this.getAllAmount();
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.btnIsLoading = false;
        });
    },
    // get all last amount
    getAllAmount() {
      this.lodaing_amount = true;
      this.$axios({
        method: "GET",
        url: "/help/collecting_customer_balances",
      })
        .then((res) => {
          this.table_length_last_amount = res.data.data.length;
          this.last_amount = res.data.data;
          if (res.data.last_collecting) {
            this.last_collecting.total_price =
              res.data.last_collecting.total_price;
            this.last_collecting.date =
              res.data.last_collecting.day +
              " - " +
              res.data.last_collecting.date +
              " - " +
              res.data.last_collecting.time;
          }
          else {
            this.last_collecting.total_price = null;
            this.last_collecting.date = null;
          }
          this.lodaing_amount = false;
        })
        .catch((err) => {
          this.lodaing_amount = false;
        });
    },
    showTableOfAmount() {
      this.showTableAmount = !this.showTableAmount;
    },
    // Set Rows
    setRows() {
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/balance_customer/wallet_operation",
      })
        .then((res) => {
          this.rows_client = res.data.data.customers;
          this.rows_wallet = res.data.data.wallets;
          this.rows_dubai = res.data.data.dubai_accounts;
          this.rows_account = res.data.data.accounts;
          this.table_length_client = this.rows_client.length;
          this.table_length_wallet = this.rows_wallet.length;
          this.table_length_dubai = this.rows_dubai.length;
          this.table_length_account = this.rows_dubai.length;

          if (res.data.data.total_customer.length > 0) {
            this.currencies_amount_client = res.data.data.total_customer;
          }
          if (res.data.data.total_wallet.length > 0) {
            this.currencies_amount_wallet = res.data.data.total_wallet;
          }
          if (res.data.data.total_account_dubai.length > 0) {
            this.currencies_amount_dubai = res.data.data.total_account_dubai;
          }
          this.currencies_amount_account = res.data.data.total_account;

          this.lodaing = false;
        })
        .catch((err) => {
          this.lodaing = false;
        });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `/help/collecting_customer_balances/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.getAllAmount();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "topRight",
          });
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    this.getAllcurriences();
    this.setRows();
    this.getAllAmount();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (!userType.includes("superadmin")) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped></style>
