<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :show_print="true"
        :excelFile="true"
        :excelData="excelData"
      />
      <!-- End Breadcrumb -->
      <!-- start of searchSection  -->
      <SearchSection @filterTable="searchTable"></SearchSection>
      <!-- end of searchSection  -->
      <!-- notFound  -->
      <Notfound v-if="!searchItems"></Notfound>
      <div v-else>
        <!-- Start Statistics Card-->
        <ul class="all-state-count">
          <li>
            <div class="state-count">
              <span>عدد الإيصالات بدون كشف حساب :</span>
              <span> {{ table_length }} </span>
            </div>
          </li>
        </ul>
        <!-- End Statistics Card-->

        <!-- Start Main Section -->
        <main class="table-print main-table">
          <!-- start of  header of printSection  -->
          <PrintHead
            :title="'إيصالات بدون كشف حساب'"
            :number="table_length"
          ></PrintHead>
          <!-- end of header of printSection  -->
          <v-data-table
            class="thumb table-print"
            :headers="headers"
            :items="rows"
            :search="search"
            :loading="lodaing"
            :loading-text="$t('table.loadingData')"
            v-model="selected"
            :single-select="singleSelect"
            :items-per-page="rows.length"
            item-key="id"
            hide-default-footer
          >
            <!-- ================== You Can use any slots you want ================== -->
            <!-- Select header field -->
            <!-- ====== Select row field ====== -->
            <!-- title -->
            <template v-slot:[`item.title`]="{ item }">
              {{ item.title }}
            </template>
            <template v-slot:[`item.index`]="{ index }">
              {{ index + 1 }}
            </template>

            <template v-slot:[`item.notes`]="{ item }">
              <div class="_actions">
                <span v-if="item.notes == 'null' || item.notes == null">
                  ----
                </span>
                <v-icon
                  class="show"
                  small
                  v-else
                  @click="
                    modal.notesModalIsOpen = true;
                    setItemNotes(item.notes);
                  "
                >
                  fal fa-eye
                </v-icon>
              </div>
            </template>

            <!-- avatar -->
            <template v-slot:[`item.avatar`]="{ item }">
              <img @click="show_model_1" class="image" :src="item.avatar" />
            </template>

            <!-- Select no data State -->
            <template v-slot:no-data>
              {{ $t("table.noData") }}
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <span>
                {{ Number(item.amount).toLocaleString() }}
              </span>
            </template>
            <template v-slot:[`item.total`]="{ item }">
              <span>
                {{ Number(item.total).toLocaleString() }}
              </span>
            </template>
            <!-- Select actions-->
            <template v-slot:[`item.active`]="{ item }">
              <div class="_actions">
                <v-chip
                  v-if="item.active == 'false'"
                  class="ma-2"
                  color="red"
                  text-color="white"
                >
                  لا يوجد
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <div class="_actions">
                <v-chip color="#d7c100" v-if="item.status == 'pending'">
                  منتظر
                </v-chip>
                <v-chip color="green" v-if="item.status == 'confirmed'">
                  تأكيد
                </v-chip>
                <v-chip color="red" v-if="item.status == 'refused'">
                  مرفوض
                </v-chip>
                <v-chip color="red" v-if="item.status == 'cancelled'">
                  تم إلغاء العملية
                </v-chip>
              </div>
            </template>
            <!-- Select actions-->
            <template v-slot:[`item.cancel`]="{ item }">
              <div
                class="_actions"
                v-if="item.status !== 'cancelled' && item.active == 'false'"
              >
                <v-btn
                  depressed
                  color="error"
                  v-if="item.active == 'false'"
                  @click="cancelCompany(item.id)"
                >
                  الغاء العملية
                </v-btn>
              </div>
              <span v-else> ---- </span>
            </template>

            <!-- Confirm-->
            <template v-slot:[`item.confirm`]="{ item }">
              <div class="_actions actions2" v-if="item.status == 'pending'">
                <button
                  type="button"
                  class="accept confirm_btn"
                  @click="acceptCompany(item.id)"
                >
                  <i class="far fa-check"></i>
                </button>
                <button
                  type="button"
                  class="reject confirm_btn"
                  @click="rejectCompany(item.id)"
                >
                  <i class="far fa-times"></i>
                </button>
              </div>
              <span v-else> ---- </span>
            </template>

            <!-- Select actions-->
            <template v-slot:[`item.actions`]="{ item }">
              <div class="_actions">
                <v-icon
                  class="edit"
                  small
                  @click="editItem(item)"
                  v-if="item.status != 'confirmed'"
                >
                  fal fa-edit
                </v-icon>
                <v-icon class="delete" small @click="deleteItem(item)">
                  fal fa-trash
                </v-icon>
              </div>
            </template>

            <!-- ======================== Start Top Section ======================== -->
            <template v-slot:top>
              <!-- Delete dialog -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">{{
                    $t("table.deletedialog.areYouSure")
                  }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="deleteItemConfirm"
                      >{{ $t("table.deletedialog.ok") }}
                      <span
                        class="btn_loader_model"
                        v-if="loadingBtnDialog"
                      ></span
                    ></v-btn>
                    <v-btn color="#F44336" @click="dialogDelete = false">{{
                      $t("table.deletedialog.cancel")
                    }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Description Modal -->
              <DescModal
                :desc="selectedItemDesc"
                :handelModalApperance="modal.descriptionModalIsOpen"
                @close="closeDescModal"
              />

              <NotesModal
                :desc="selectedItemNotes"
                :handelModalApperance="modal.notesModalIsOpen"
                @close="closeNotesModal"
              />
              <!-- Row -->
            </template>

            <!-- ======================== End Top Section ======================== -->
          </v-data-table>
        </main>
        <!-- End Main Section -->
      </div>

      <!-- Start Image_Model -->
      <base-model
        @closeModel="model_1.show_model = false"
        :show="model_1.show_model"
      >
        <div class="image">
          <img
            v-if="model_1.model_img_src"
            :src="model_1.model_img_src"
            alt="..."
          />
        </div>
      </base-model>
      <!-- End Image_Model -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
import SearchSection from "@/components/Search/enterpriceCredit";
import DescModal from "@/components/modals/DescriptionModal.vue";
import NotesModal from "@/components/modals/NotesModal.vue";

export default {
  components: {
    StatsCard,
    SearchSection,
    DescModal,
    NotesModal,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // search
      searchItems: null,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: "الرئيسية",
          disabled: false,
          href: "/",
        },
        {
          text: "إيصالات يدون كشف حساب",
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "all",
          number: "10",
        },
      ],

      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,
      modal: {
        descriptionModalIsOpen: false,
        notesModalIsOpen: false,
      },

      selectedItemDesc: null,
      selectedItemNotes: null,

      // ========== Loding
      lodaing: false,
      table_length: null,
      // excel data
      excelData: {
        name: "إيصالات مؤسسات بدون كشف حساب",
        items: null,
        json_fields: {
          "اسم العميل": "username",
          "حساب المؤسسة": "enterprise_name",
          المبلغ: {
            field: "amount",
            callback: (value) => {
              return Number(value).toLocaleString();
            },
          },
          النسبة: "commission",
          العمولة: "amount_average",
          الاجمالي: {
            field: "total",
            callback: (value) => {
              return Number(value).toLocaleString();
            },
          },
          "تاريخ الإنشاء": "created_at",
          وصف: "desc",
          ملاحظات: "notes",
          "حالة الإيصال": {
            field: "active",
            callback: (value) => {
              if (value == "true") {
                return "يوجد";
              } else {
                return "لا يوجد";
              }
            },
          },
          "حالة العملية": {
            field: "status",
            callback: (value) => {
              if (value == "pending") {
                return "منتظر";
              } else if (value == "confirmed") {
                return "تأكيد";
              } else if (value == "refused") {
                return "مرفوض";
              } else if (value == "cancelled") {
                return "تم إلغاء العملية";
              } else {
                return "----";
              }
            },
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      var userType = JSON.parse(this.$store.state.usertype) || null;
      if (
        userType.includes("superadmin") ||
        (userType.includes("enterprise_add_money") &&
          userType.includes("confirm_enterprise_add_money"))
      ) {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: "اسم العميل",
            value: "username",
            align: "center",
            width: 150,
          },
          {
            text: "حساب المؤسسة",
            value: "enterprise_name",
            align: "center",
            width: 150,
          },
          {
            text: "المبلغ",
            value: "amount",
            align: "center",
            sortable: false,
            width: 100,
          },
          {
            text: "النسبة",
            value: "commission",
            align: "center",
            sortable: false,
            width: 50,
          },
          {
            text: "العمولة",
            value: "amount_average",
            align: "center",
            sortable: false,
            width: 50,
          },
          {
            text: "الاجمالي",
            value: "total",
            align: "center",
            sortable: false,
            width: 150,
          },
          {
            text: "تاريخ الإنشاء",
            value: "created_at",
            align: "center",
            sortable: false,
            width: 100,
          },
          {
            text: "وصف",
            value: "desc",
            align: "center",
            sortable: false,
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 150,
          },
          {
            text: "ملاحظات",
            value: "notes",
            align: "center",
            sortable: false,
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
          {
            text: "حالة الإيصال",
            value: "active",
            align: "center",
            sortable: false,
            width: 120,
          },
          {
            text: "حالة العملية",
            value: "status",
            align: "center",
            width: 120,
          },
          // { text: "الغاء العملية", value: "cancel", align: "center" },
          {
            text: "التأكيد",
            sortable: false,
            value: "confirm",
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 80,
          },
        ];
      } else if (!userType.includes("confirm_enterprise_add_money")) {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: "اسم العميل",
            value: "username",
            align: "center",
            width: 150,
          },
          {
            text: "حساب المؤسسة",
            value: "enterprise_name",
            align: "center",
            width: 150,
          },
          {
            text: "المبلغ",
            value: "amount",
            align: "center",
            sortable: false,
            width: 100,
          },
          {
            text: "النسبة",
            value: "commission",
            align: "center",
            sortable: false,
            width: 50,
          },
          {
            text: "العمولة",
            value: "amount_average",
            align: "center",
            sortable: false,
            width: 50,
          },
          {
            text: "الاجمالي",
            value: "total",
            align: "center",
            sortable: false,
            width: 150,
          },
          {
            text: "تاريخ الإنشاء",
            value: "created_at",
            align: "center",
            sortable: false,
            width: 100,
          },
          {
            text: "وصف",
            value: "desc",
            align: "center",
            sortable: false,
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 150,
          },
          {
            text: "ملاحظات",
            value: "notes",
            align: "center",
            sortable: false,
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
          {
            text: "حالة الإيصال",
            value: "active",
            align: "center",
            sortable: false,
            width: 120,
          },
          {
            text: "حالة العملية",
            value: "status",
            align: "center",
            width: 120,
          },
          // { text: "الغاء العملية", value: "cancel", align: "center" },
          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 80,
          },
        ];
      } else {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: "اسم العميل",
            value: "username",
            align: "center",
            width: 150,
          },
          {
            text: "حساب المؤسسة",
            value: "enterprise_name",
            align: "center",
            width: 150,
          },
          {
            text: "المبلغ",
            value: "amount",
            align: "center",
            sortable: false,
            width: 100,
          },
          {
            text: "النسبة",
            value: "commission",
            align: "center",
            sortable: false,
            width: 50,
          },
          {
            text: "العمولة",
            value: "amount_average",
            align: "center",
            sortable: false,
            width: 50,
          },
          {
            text: "الاجمالي",
            value: "total",
            align: "center",
            sortable: false,
            width: 150,
          },
          {
            text: "تاريخ الإنشاء",
            value: "created_at",
            align: "center",
            sortable: false,
            width: 100,
          },
          {
            text: "وصف",
            value: "desc",
            align: "center",
            sortable: false,
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 150,
          },
          {
            text: "ملاحظات",
            value: "notes",
            align: "center",
            sortable: false,
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
          {
            text: "حالة الإيصال",
            value: "active",
            align: "center",
            sortable: false,
            width: 120,
          },
          {
            text: "حالة العملية",
            value: "status",
            align: "center",
            width: 120,
          },
          // { text: "الغاء العملية", value: "cancel", align: "center" },
          {
            text: "التأكيد",
            sortable: false,
            value: "confirm",
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
        ];
      }
    },
  },

  methods: {
    // searchTable
    searchTable(items) {
      this.rows = [];
      this.searchItems = items;
      this.setRows();
    },

    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/enterpriceCredits/add" });
    },
    showItem(item) {
      this.$router.push({ path: "/enterpriceCredits/show/" + item.id });
    },
    editItem(item) {
      this.$router.push({ path: "/enterpriceCredits/edit/" + item.id });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/credit_enterprise/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("CRUD.Delete"),
            position: "topRight",
          });
          this.setRows();
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
      // Submit the delete service then submit this
    },
    // ==================== End CRUD ====================

    setItemDesc(desc) {
      this.selectedItemDesc = desc;
    },
    setItemNotes(notes) {
      this.selectedItemNotes = notes;
    },
    closeDescModal() {
      this.modal.descriptionModalIsOpen = false;
    },
    closeNotesModal() {
      this.modal.notesModalIsOpen = false;
    },

    setRows() {
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/credit_enterprise?type=bill&active=false",
        params: {
          enterprise: this.searchItems ? this.searchItems.enterprice : null,
          customer: this.searchItems ? this.searchItems.username : null,
          desc: this.searchItems ? this.searchItems.desc : null,
          from_date: this.searchItems ? this.searchItems.from : null,
          to_date: this.searchItems ? this.searchItems.to : null,
        },
      })
        .then((res) => {
          this.rows = res.data.data.map((item) => {
            return {
              id: item.id,
              username: item.customer.name,
              enterprise_name: item.account.name,
              amount: item.amount,
              amount_average: item.commission,
              commission: item.rate,
              total: item.total,
              created_at: item.created_at,
              desc: item.desc,
              notes: item.notes,
              active: item.active,
              status: item.status,
            };
          });
          this.table_length = this.rows.length;
          this.excelData.items = this.rows;
          this.lodaing = false;
        })
        .catch((err) => {
          console.log(err);
          this.lodaing = false;
        });
    },
    acceptCompany(id) {
      this.rows = [];
      this.lodaing = true;
      this.$axios({
        method: "POST",
        url: "client/credit_enterprise/accept_credit_enterprise/" + id,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: "تأكيد بنجاح",
            position: "topRight",
          });
          this.setRows();
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
    rejectCompany(id) {
      this.rows = [];
      this.lodaing = true;
      this.$axios({
        method: "POST",
        url: "client/credit_enterprise/refuse_credit_enterprise/" + id,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: "تم الإلغاء بنجاح",
            position: "topRight",
          });
          this.setRows();
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
    cancelCompany(id) {
      this.rows = [];
      this.$axios({
        method: "POST",
        url: "client/credit_enterprise/cancel_credit_enterprise/" + id,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: "تم الإلغاء بنجاح",
            position: "topRight",
          });
          this.setRows();
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
  },

  // ======= hooks
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("enterprise_add_money") &&
      !userType.includes("confirm_enterprise_add_money")
    ) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped>
.actions2 {
  display: flex;
  justify-content: center;
  align-items: center;
  .confirm_btn {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-inline-end: 7px;
    &.accept {
      background-color: #5ab55e;
    }
    &.reject {
      background-color: #f55246;
    }
  }
}
</style>
