<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :show_print="true"
        :excelFile="true"
        :excelData="excelData"
      />

      <!-- End Breadcrumb -->

      <!-- start of searchSection  -->
      <SearchSection
        @filterTable="searchTable"
        title="نموذج 4 تصدير"
      ></SearchSection>
      <!-- end of searchSection  -->

      <!-- notFound  -->
      <Notfound v-if="!searchItems"></Notfound>
      <div v-else>
        <!-- Start Statistics Card-->
        <ul class="all-state-count">
          <li>
            <div class="state-count">
              <span>نموذج 4 تصدير :</span>
              <span> {{ table_length }} </span>
            </div>
          </li>
        </ul>

        <!-- Start Main Section -->
        <main class="table-print main-table">
          <!-- start of  header of printSection  -->
          <PrintHead
            :title="'نموذج 4 تصدير'"
            :number="table_length"
          ></PrintHead>
          <!-- end of header of printSection  -->
          <v-data-table
            class="thumb table-print"
            :headers="headers"
            :items="rows"
            :search="search"
            :loading="lodaing"
            :loading-text="$t('table.loadingData')"
            v-model="selected"
            :single-select="singleSelect"
            :items-per-page="rows.length"
            item-key="id"
            hide-default-footer
          >
            <!-- ================== You Can use any slots you want ================== -->
            <!-- Select header field -->
            <template v-slot:[`item.amount`]="{item}">
              <span>
                {{ Number(item.amount).toLocaleString() }}
              </span>
            </template>
            <template v-slot:[`item.index`]="{index}">
              {{ index + 1 }}
            </template>
            <template v-slot:[`item.total`]="{item}">
              <span>
                {{ Number(item.total).toLocaleString() }}
              </span>
            </template>
            <!-- ====== Select row field ====== -->

            <!-- title -->
            <template v-slot:[`item.title`]="{item}">
              {{ item.title }}
            </template>

            <!-- Select no data State -->
            <template v-slot:no-data>
              {{ $t("table.noData") }}
            </template>
            <template v-slot:[`item.desc`]="{item}">
              <p style="text-align: right;">
                نموذج 4 تصدير من عميل "{{ item.customer_eg.name }}" بمبلغ قبل
                الخصم {{ Number(item.customer_before).toLocaleString() }} و مبلغ
                مخصوم {{ Number(item.total).toLocaleString() }} و مبلغ بعد الخصم
                {{ Number(item.customer_after).toLocaleString() }} .
              </p>
              <p style="text-align: right;">
                وبنك "{{ item.bank.name }}" بمبلغ قبل الخصم
                {{ Number(item.bank_before).toLocaleString() }} و مبلغ مخصوم
                {{ Number(item.total).toLocaleString() }} و مبلغ بعد الخصم
                {{ Number(item.bank_after).toLocaleString() }} .
              </p>
              <p style="text-align: right;">
                و Refernces عباره عن :-
                <br />
                <span v-for="ref in item.references" :key="ref.id" style="display: block">
                  #{{ ref.reference }} :  بمبلغ قبل الخصم
                  {{ Number(ref.amount_before).toLocaleString() }} و مبلغ مخصوم
                  {{ Number(ref.total).toLocaleString() }} و مبلغ بعد الخصم
                  {{ Number(ref.amount_after).toLocaleString() }} .
                </span>
              </p>
            </template>
            <!-- Select actions-->
            <template v-slot:[`item.actions`]="{item}">
              <div class="_actions">
                <v-icon class="delete" small @click="deleteItem(item)">
                  fal fa-trash
                </v-icon>
              </div>
            </template>

            <!-- ======================== Start Top Section ======================== -->
            <template v-slot:top>
              <!-- Delete dialog -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">{{
                    $t("table.deletedialog.areYouSure")
                  }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="deleteItemConfirm"
                      >{{ $t("table.deletedialog.ok") }}
                      <span
                        class="btn_loader_model"
                        v-if="loadingBtnDialog"
                      ></span
                    ></v-btn>
                    <v-btn color="#F44336" @click="dialogDelete = false">{{
                      $t("table.deletedialog.cancel")
                    }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Row -->
            </template>

            <!-- ======================== End Top Section ======================== -->
          </v-data-table>
        </main>
        <!-- End Main Section -->
      </div>

      <!-- Start Image_Model -->
      <base-model
        @closeModel="model_1.show_model = false"
        :show="model_1.show_model"
      >
        <div class="image">
          <img
            v-if="model_1.model_img_src"
            :src="model_1.model_img_src"
            alt="..."
          />
        </div>
      </base-model>
      <!-- End Image_Model -->
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import StatsCard from "../../../components/Charts/ChartComponent.vue";
import SearchSection from "@/components/Search/export-report";
export default {
  components: {
    StatsCard,
    SearchSection,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // search
      searchItems: null,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: "الرئيسية",
          disabled: false,
          href: "/",
        },
        {
          text: "نموذج 4 تصدير",
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "all",
          number: "10",
        },
      ],

      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      modal: {
        descriptionModalIsOpen: false,
        notesModalIsOpen: false,
      },

      selectedItemDesc: null,
      selectedItemNotes: null,

      // ========== Loding
      lodaing: false,
      table_length: null,
      user_data: null,
      showReport: false,
      allAmountUser: null,
      allAmountEnterprice: null,
      user_currency: null,
      // excel data
      excelData: {
        name: "نموذج 4 تصدير",
        items: null,
        json_fields: {
          "اسم العميل": "customer_eg.name",
          "اسم البنك": "bank.name",
          المبلغ: {
            field: "amount",
            callback: (value) => {
              return Number(value).toLocaleString();
            },
          },
          قسمه: "dividing",
          ضرب: "multiply",
          الاجمالي: {
            field: "total",
            callback: (value) => {
              return Number(value).toLocaleString();
            },
          },
          "تاريخ الإنشاء": "created_at",
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
      return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: "اسم العميل",
            value: "customer_eg.name",
            align: "center",
            width: 120,
          },
          {
            text: "اسم البنك",
            value: "bank.name",
            align: "center",
            width: 120,
          },
          {
            text: "المبلغ",
            value: "amount",
            align: "center",
            sortable: false,
            width: 100,
          },
          {
            text: "قسمه",
            value: "dividing",
            align: "center",
            sortable: false,
            width: 50,
          },
          {
            text: "ضرب",
            value: "multiply",
            align: "center",
            sortable: false,
            width: 50,
          },
          {
            text: "الاجمالي",
            value: "total",
            align: "center",
            sortable: false,
            width: 100,
          },
          {
            text: "تاريخ الإنشاء",
            value: "created_at",
            align: "center",
            sortable: false,
            width: 80,
          },
          {
            text: "الوصف",
            value: "desc",
            align: "center",
            sortable: false,
            width: 250,
          },
          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 80,
          },
        ];
      }
    },
  },

  methods: {
    // searchTable
    searchTable(items) {
      this.rows = [];
      this.searchItems = items;
      this.setRows();
    },

    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({path: "/export_app/add"});
    },
    showItem(item) {
      this.$router.push({path: "/export_app/show/" + item.id});
    },
    editItem(item) {
      this.$router.push({path: "/export_app/edit/" + item.id});
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/bank_eg_operation/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("CRUD.Delete"),
            position: "topRight",
          });
          this.setRows();
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
      // Submit the delete service then submit this
    },
    // ==================== End CRUD ====================

    setRows() {
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/bank_eg_operation",
        params: {
          bank_eg_id: this.searchItems ? this.searchItems.lockId : null,
          customer_eg_id: this.searchItems ? this.searchItems.username : null,
          from_date: this.searchItems ? this.searchItems.from : null,
          to_date: this.searchItems ? this.searchItems.to : null,
        },
      })
        .then((res) => {
          this.rows = res.data.data;
          this.table_length = this.rows.length;
          this.excelData.items = this.rows;
          this.lodaing = false;
        })
        .catch((err) => {
          console.log(err);
          this.lodaing = false;
        });
    },
  },

  // ======= hooks
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("referane_export")
    ) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped>
.actions2 {
  display: flex;
  justify-content: center;
  align-items: center;
  .confirm_btn {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-inline-end: 7px;
    &.accept {
      background-color: #5ab55e;
    }
    &.reject {
      background-color: #f55246;
    }
  }
}
</style>
