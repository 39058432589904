<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/categories"
        search_title="مجالات المؤسسات"
      />
      <!-- End Breadcrumb -->
      <div class="custom_card">
        <!-- START:: CARD TITLE -->
        <div class="card-header">
          <h4 class="card-title">{{ $t("CRUD.Create.main_title") }}</h4>
        </div>
        <!-- END:: CARD TITLE -->

        <!-- START:: CREATE FORM WRAPPER -->
        <form @submit.prevent="validateForm">
          <div class="container">
            <div class="row justify-content-between">
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-12 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.nameAr"
                  />
                  <label for="name_input_ar" class="form-label">{{
                    $t("categories.data.nameAr")
                  }}</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->
            </div>
          </div>

          <div class="buttons_wrapper">
            <!-- START:: BUTTON -->
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              إضافة
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
            <!-- END:: BUTTON -->
          </div>
        </form>
        <!-- END:: CREATE FORM WRAPPER -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "إضافة مجال مؤسسة",
          disabled: true,
          href: "",
        },
      ],

      // START:: BUTTON LOADER HANDLING DATA
      btnIsLoading: false,
      // END:: BUTTON LOADER HANDLING DATA

      // START:: CREATE DATA
      data: {
        nameAr: null,
        nameEn: null,
        descAr: null,
        descEn: null,
      },
      // END:: CREATE DATA
    };
  },

  methods: {
    // START:: VALIDATE CREATE FORM
    emptyFormData() {
      this.data.nameAr = null;
    },
    validateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        let submit_data = {
          ar: {
            name: this.data.nameAr,
            desc: this.data.descAr,
          },
          en: {
            name: this.data.nameAr,
            desc: this.data.descEn,
          },
        };

        if (!this.data.nameAr) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("categories.validation.nameAr"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.$axios({
            method: "POST",
            url: `client/category`,
            data: submit_data,
          })
            .then(() => {
              this.btnIsLoading = false;
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("CRUD.Create.added"),
                position: "topRight",
              });
              this.emptyFormData();
            })
            .catch((err) => {
              this.btnIsLoading = false;
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "topRight",
              });
            });
          return;
        }
      }
    },
    // END:: VALIDATE CREATE FORM
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (!userType.includes("superadmin") && !userType.includes("enterprise")) {
      this.$router.push("/");
    }
  },
};
</script>
