<template>
  <div class="create_wrapper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <div class="custom_card">
      <div class="card-header">
        <h4 class="card-title">{{ $t("addNew") }}</h4>
      </div>

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-lg-12 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.account_name"
                />
                <label for="name_input" class="form-label">{{
                  $t("forms.labels.account_name")
                }}</label>
              </div>
            </div>

            <div class="col-lg-12 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.amount"
                />
                <label for="name_input" class="form-label">{{
                  $t("forms.labels.banck_amount")
                }}</label>
              </div>
            </div>

            <div class="col-lg-12 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.currency"
                />
                <label for="name_input" class="form-label">{{
                  $t("forms.labels.currency")
                }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="buttons_wrapper">
          <button
            class="button_style_1"
            :class="{buttonIndex: btnIsLoading}"
            :disabled="btnIsLoading"
          >
            {{ $t("forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.client_accounts.title"),
          disabled: false,
          href: "/sliders",
        },
        {
          text: this.$t("breadcrumb.client_accounts.add"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // Date_menues
      dateMenu: false,
      debit_dateMenu: false,

      // search_users
      search_users: "",

      // ========== create_data
      data: {
        account_name: null,
        amount: null,
        currency: null,
      },
    };
  },

  watch: {
    [`search_users`](newVal) {
      this.get_filterd_users(newVal);
    },
  },

  methods: {
    // get_filterd_users
    get_filterd_users(newVal) {
      if (newVal == "o") {
        this.users = ["omar"];
      } else {
        this.users = ["omar", "momen", "zoheir", "mahmoud", "essam", "diaa"];
      }
    },

    // Validate Data
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.account_name) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.account_name"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.bank_amount"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.currency) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.currency"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.submitData();
          return;
        }
      }
    },

    // Submit Data
    submitData() {
      const submit_data = new FormData();
      submit_data.append("teacher_slider", this.data.image.img_file);

      this.$axios({
        method: "POST",
        url: "slider",
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "topRight",
          });
          this.$router.push({path: "/sliders"});
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
  },

  created() {
    // this.getData();
  },
};
</script>
