<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/ports/all"
        search_title="المواني"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("addNew") }}</h4>
        </div>

        <!-- ==== Start Form ==== -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    step="any"
                    class="form-control"
                    v-model.trim="data.portName"
                  />
                  <label for="name_input" class="form-label">إسم الميناء</label>
                </div>
              </div>

              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    step="any"
                    class="form-control"
                    v-model.trim="data.responsibleName"
                  />
                  <label for="name_input" class="form-label">إسم المخلص</label>
                </div>
              </div>
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              إضافة
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- ==== End Form ==== -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // loader
      loading: true,

      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "المواني",
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========== create_data
      data: {
        portName: null,
        responsibleName: null,
      },
    };
  },

  methods: {
    // Validate Data
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.portName) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال اسم الميناء",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.responsibleName) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي ادخال اسم المخلص",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.submitData();
          return;
        }
      }
    },
    emptyFormData() {
      this.data.portName = null;
      this.data.responsibleName = null;
    },
    // Submit Data
    submitData() {
      const submit_data = new FormData();
      submit_data.append("name", this.data.portName);
      submit_data.append("username", this.data.responsibleName);
      this.$axios({
        method: "POST",
        url: "client/port",
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: "تمت الاضافة بنجاح",
            position: "topRight",
          });
          this.btnIsLoading = false;
          this.emptyFormData();
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
  },

  created() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);

    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("containerOperations")
    ) {
      this.$router.push("/");
    }
  },
};
</script>
