<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <div class="row">
        <div class="col-md-12">
          <div class="show_title">
            <span>بيانات المؤسسة</span>
          </div>
        </div>
        <div class="col-md-3">
          <stats-card
            :icon="'fal fa-pencil'"
            :title="'اسم المؤسسة'"
            :number="data.name_ar"
            :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
            :bgColor="'transparent'"
            :show="true"
          ></stats-card>
        </div>
        <div class="col-md-3">
          <stats-card
            :icon="'fal fa-user-tie'"
            :title="'اسم صاحب المؤسسة'"
            :number="data.owner_name"
            :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
            :bgColor="'transparent'"
            :show="true"
            :routeLink="data.owner_id"
          ></stats-card>
        </div>
        <div class="col-md-3">
          <stats-card
            :icon="'fal fa-user-tie'"
            :title="'مجالات المؤسسة'"
            :number="data.categories"
            :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
            :bgColor="'transparent'"
            :show="true"
            :arr="true"
          ></stats-card>
        </div>
        <div class="col-md-3">
          <stats-card
            :icon="'fal fa-calendar-alt'"
            :title="'تاريخ الانشاء'"
            :number="data.created_at"
            :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
            :bgColor="'transparent'"
            :show="true"
          ></stats-card>
        </div>
        <div class="col-md-3">
          <stats-card
            :icon="'fal fa-file-edit'"
            :title="'Head Letter'"
            :number="data.head_letter"
            :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
            :bgColor="'transparent'"
            :link="data.head_letter"
            :show="true"
          ></stats-card>
        </div>
        <div class="col-md-3">
          <stats-card
            :icon="'fal fa-tag'"
            :title="'الرقم الضريبي'"
            :number="data.tax_number"
            :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
            :bgColor="'transparent'"
            :show="true"
          ></stats-card>
        </div>

        <div class="col-md-3">
          <stats-card
            :icon="'fal fa-cash-register'"
            :title="'رقم السجل التجاري'"
            :number="data.commercial_number"
            :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
            :bgColor="'transparent'"
            :show="true"
          ></stats-card>
        </div>
        <div class="col-md-3">
          <stats-card
            :icon="'fal fa-file-edit'"
            :title="'ملف السجل التجاري'"
            :number="data.image"
            :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
            :bgColor="'transparent'"
            :link="data.image"
            :show="true"
          ></stats-card>
        </div>

        <div class="col-md-12">
          <div class="show_title">
            <span>الحسابات البنكية</span>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-lg-3 col-md-4 col-12">
            <div class="wrraper">
              <stats-card
                :icon="'fal fa-university'"
                :title="'الحسابات البنكية'"
                :number="rows.length"
                :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
                :bgColor="'transparent'"
              ></stats-card>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <!-- Start Main Section -->
          <main class="main-table">
            <v-data-table
              class="thumb"
              :headers="headers"
              :items="rows"
              :search="search"
              :custom-filter="filterSearch"
              :loading="lodaing"
              :loading-text="$t('table.loadingData')"
              v-model="selected"
              :single-select="singleSelect"
              :items-per-page="rows.length"
              item-key="id"
              hide-default-footer
            >
              <!-- ================== You Can use any slots you want ================== -->
              <!-- Select header field -->
              <template v-slot:[`header.desc`]="{ header }">
                {{ header.text + " ..." }}
              </template>
              <!-- revision status-->
              <template v-slot:[`item.review_status`]="{ item }">
                <v-chip color="#d7c100" v-if="item.review_status == 0">
                  منتظر
                </v-chip>
                <v-chip color="green" v-else> تأكيد </v-chip>
              </template>
              <!-- Confirm revision-->
              <template v-slot:[`item.revision_done`]="{ item }">
                <!--  -->
                <div class="_actions actions2">
                  <button
                    type="button"
                    class="accept confirm_btn ml-0"
                    @click="revisionDone(item)"
                    v-if="item.review_status == 0"
                  >
                    <i class="far fa-check"></i>
                  </button>
                  <span v-else> ---- </span>
                </div>
              </template>
              <template v-slot:[`item.index`]="{ index }">
                {{ index + 1 }}
              </template>
              <!-- ====== Select row field ====== -->
              <!-- title -->
              <template v-slot:[`item.title`]="{ item }">
                <v-chip color="red">
                  {{ item.title }}
                </v-chip>
              </template>

              <!-- descreption -->
              <template v-slot:[`item.desc`]="{ item }">
                {{ item.desc.slice(0, 40) + "..." }}
              </template>
              <template v-slot:[`item.active`]="{ item }">
                <v-chip color="red" v-if="item.active == 0"> غير مفعل </v-chip>
                <v-chip color="green" v-if="item.active == 1"> مفعل </v-chip>
              </template>
              <!-- avatar -->
              <template v-slot:[`item.avatar`]="{ item }">
                <img @click="show_model_1" class="image" :src="item.avatar" />
              </template>

              <!-- Select no data State -->
              <template v-slot:no-data>
                {{ $t("table.noData") }}
              </template>

              <!-- Select actions-->
              <template v-slot:[`item.actions`]="{ item }">
                <div class="_actions">
                  <v-icon class="show" small @click="showItem(item)">
                    fal fa-eye
                  </v-icon>
                  <v-icon class="edit" small @click="editItem(item)">
                    fal fa-edit
                  </v-icon>
                  <v-icon class="delete" small @click="deleteItem(item)">
                    fal fa-trash
                  </v-icon>
                </div>
              </template>

              <!-- ======================== Start Top Section ======================== -->
              <template v-slot:top>
                <!-- Delete dialog -->
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5 justify-center">{{
                      $t("table.deletedialog.areYouSure")
                    }}</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="#1B5E20" @click="deleteItemConfirm"
                        >{{ $t("table.deletedialog.ok") }}
                        <span
                          class="btn_loader_model"
                          v-if="loadingBtnDialog"
                        ></span
                      ></v-btn>
                      <v-btn color="#F44336" @click="dialogDelete = false">{{
                        $t("table.deletedialog.cancel")
                      }}</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- confirm dialog -->
                <v-dialog v-model="dialogConfirm" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5 justify-center">
                      هل تمت المراجعه علي العملية ؟
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="#1B5E20" @click="revisionConfirm"
                        >{{ $t("table.deletedialog.ok") }}
                        <span
                          class="btn_loader_model"
                          v-if="loadingBtnDialog"
                        ></span
                      ></v-btn>
                      <v-btn color="#F44336" @click="dialogConfirm = false">{{
                        $t("table.deletedialog.cancel")
                      }}</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- Row -->
              </template>
              <!-- ======================== End Top Section ======================== -->
            </v-data-table>
          </main>
          <!-- End Main Section -->
        </div>
      </div>
      <!-- Start Image_Model -->
      <base-model
        @closeModel="model_1.show_model = false"
        :show="model_1.show_model"
      >
        <div class="image">
          <img v-if="model_1.model_img_src" :src="data.image" alt="..." />
        </div>
      </base-model>
    </div>
  </div>
</template>

<script>
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    StatsCard,
  },
  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // loader
      loading: true,
      search: "",
      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "@/assets/images/people/profile.jpg",
      },

      data: {
        id: null,
        name: null,
        enterpriceOwnerName: null,
        taxNum: null,
      },

      // ========== dialog Status
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,
      dialogConfirm: false,
      itemtoConfirm: null,
      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== pagination
      paginations: {
        current_page: 1,
        last_page: 2,
      },

      // ========== Loding
      lodaing: false,
    };
  },
  methods: {
    // ===== Search Method =====

    filterSearch(value, search, item) {
      return (
        value != null &&
        search != null &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },

    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    // ========== Lang

    // START:: GET OWNERS
    getEnterpriseData() {
      // data
      this.$axios({
        method: "GET",
        url: `client/enterprise/${this.$route.params.id}`,
      }).then((res) => {
        this.data = res.data.data;
        this.rows = this.data.accounts;
      });
    },
    // END:: GET OWNERS

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/enterpriceAccount/add" });
    },
    showItem(item) {
      this.$router.push({ path: "/enterpriceAccount/show/" + item.id });
    },
    editItem(item) {
      this.$router.push({ path: "/enterpriceAccount/edit/" + item.id });
    },
    // confirm item
    revisionDone(item) {
      this.dialogConfirm = true;
      this.itemtoConfirm = item;
    },
    revisionConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "POST",
        url: `cities/review/${this.itemtoConfirm.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogConfirm = false;
          this.$iziToast.success({
            timeout: 2000,
            message: "تم التأكيد بنجاح",
            position: "topRight",
          });
        })
        .catch((err) => {
          this.dialogConfirm = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `cities/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("CRUD.Delete"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
      // Submit the delete service then submit this
    },

    // ==================== End CRUD ====================
  },

  computed: {
    current_theme() {
      return this.$store.getters["theme_module/current_theme"];
    },
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          // {
          //   text: "الصورة", // the name to display
          //   align: "center", // [center, start]
          //   value: "avatar", // Key must be equal to the name in rows objects
          //   sortable: false,
          // },
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          { text: "اسم البنك", value: "bank_name", align: "center" },
          { text: "A/C NO", value: "bank_account_number", align: "center" },
          { text: "حالة الحساب", value: "active", align: "center" },
          { text: "رصيد الحساب", value: "amount", align: "center" },
          // { text: "عدد الصرافات", value: "atms_number", align: "center" },
          {
            text: "المراجعة",
            value: "review_status",
            align: "center",
            sortable: false,
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
          {
            text: "تأكيد",
            value: "revision_done",
            align: "center",
            sortable: false,
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
        ];
      } else {
        return [
          // {
          //   text: "Avatar",
          //   align: "center",
          //   value: "avatar",
          // },
          {
            text: "Enterprice Name",
            value: "enterprise_name",
            align: "center",
          },
          { text: "Bank Name", value: "bank_name", align: "center" },
          {
            text: "Account Number",
            value: "bank_account_number",
            align: "center",
          },
          {
            text: "Account Status",
            value: "active",
            align: "center",
          },
          // {
          //   text: "Atms Number",
          //   value: "atms_numbers",
          //   align: "center",
          // },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ];
      }
    },
  },

  mounted() {
    this.getEnterpriseData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (!userType.includes("superadmin") && !userType.includes("enterprise")) {
      this.$router.push("/");
    }
  },
};
</script>

<style></style>
