<template>
  <div class="search-section">
    <div class="divider">
      <h4>بحث في أرصدة الخزن</h4>
      <hr
        role="separator"
        aria-orientation="horizontal"
        class="v-divider theme--dark"
      />
    </div>
    <div class="row">
      <div class="col-lg-3 py-0">
        <div class="input_wrapper top_label mb-3">
          <label class="form-label"> الخزنة المحول منها </label>
          <multiselect
            v-model="searchData.withdrawnFromLock"
            :options="searchOptions.locks"
            track-by="id"
            label="name"
            placeholder=""
            @input="
              chooseLockFrom(searchData.withdrawnFromLock),
                $emit('filterTable', searchData)
            "
            :searchable="true"
            :allow-empty="true"
            :show-labels="false"
          >
          </multiselect>
        </div>
      </div>
      <div class="col-lg-3 py-0">
        <div class="input_wrapper top_label mb-3">
          <label class="form-label"> الخزنة المحول إليها </label>
          <multiselect
            v-model="searchData.withdrawnToLock"
            :options="searchOptions.locks"
            track-by="id"
            @input="
              chooseLockTo(searchData.withdrawnToLock),
                $emit('filterTable', searchData)
            "
            label="name"
            placeholder=""
            :searchable="true"
            :allow-empty="true"
            :show-labels="false"
          >
          </multiselect>
        </div>
      </div>
      <div class="col-lg-3 py-0">
        <div class="input_wrapper top_label date_input">
          <label class="form-label">من</label>
          <v-menu
            ref="to_date_menu"
            v-model="searchOptions.from_date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="searchData.from"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchData.from"
              @input="searchOptions.from_date_menu = false"
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>
        </div>
      </div>
      <div class="col-lg-3 py-0">
        <div class="input_wrapper top_label date_input">
          <label class="form-label">إلي</label>
          <v-menu
            ref="to_date_menu"
            v-model="searchOptions.to_date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="searchData.to"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchData.to"
              @input="searchOptions.to_date_menu = false"
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>
        </div>
      </div>
      <div class="col-lg-12 text-center py-0">
        <button
          class="button_style_1"
          @click="$emit('filterTable', searchData)"
        >
          بحث
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // search
      searchData: {
        withdrawnFromLock: null,
        withdrawnFromLockId: null,
        withdrawnToLock: null,
        withdrawnToLockId: null,
        from: new Date().toISOString().slice(0, 10),
        to: new Date().toISOString().slice(0, 10),
      },
      searchOptions: {
        locks: [],
        from_date_menu: false,
        to_date_menu: false,
      },
    };
  },

  methods: {
    getSafes() {
      // START:: GET SAFES
      this.$axios({
        method: "GET",
        url: `client/wallet`,
      })
        .then((res) => {
          this.searchOptions.locks = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
      // END:: GET SAFES
    },
    chooseLockFrom(item) {
      if (this.searchData.withdrawnFromLock) {
        this.searchData.withdrawnFromLockId = item.id;
      } else {
        this.searchData.withdrawnFromLockId = null;
      }
    },
    chooseLockTo(item) {
      if (this.searchData.withdrawnToLock) {
        this.searchData.withdrawnToLockId = item.id;
      } else {
        this.searchData.withdrawnToLockId = null;
      }
    },
  },
  created() {
    this.getSafes();
  },
};
</script>
<style lang="scss">
.search-section .load_inline {
  width: 30px !important;
  height: 25px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
  left: 1px;
  position: absolute !important;
  background: transparent !important;
  margin: 0 !important;

  span {
    right: 0 !important;
    left: 0 !important;
    margin: auto !important;
    border-top: 3px solid var(--mainColor-2) !important;
    border-right: 3px solid transparent !important;
  }
}
</style>

