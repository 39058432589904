<template>
  <div class="search-section">
    <div class="divider">
      <h4>بحث في حسابات المؤسسات</h4>
      <hr
        role="separator"
        aria-orientation="horizontal"
        class="v-divider theme--dark"
      />
    </div>
    <div class="row">
      <div class="col-lg-6 py-0">
        <div class="input_wrapper top_label auto_complete">
          <label class="form-label"> إسم المؤسسة</label>
          <v-autocomplete
            v-model="searchData.enterprice"
            :search-input.sync="searchOptions.search_enterprices"
            :items="enterprices"
            @change="$emit('filterTable', searchData)"
            item-text="name_ar"
            item-value="name_ar"
            :hide-no-data="!enterprices"
            dense
          ></v-autocomplete>
          <button class="button_style_1 load_inline" v-if="load_enterprices">
            <span class="btn_loader"></span>
          </button>
        </div>
      </div>

      <div class="col-lg-6 py-0">
        <div class="input_wrapper top_label auto_complete">
          <label class="form-label">اسم البنك</label>
          <input
            type="text"
            class="form-control"
            v-model.trim="searchData.bank"
             @change="$emit('filterTable', searchData)"
          />
        </div>
      </div>
      <div class="col-lg-12 text-center py-0">
        <button
          class="button_style_1"
          @click="$emit('filterTable', searchData)"
        >
          بحث
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      load_enterprices: false,
      load_banks: false,
      // search
      searchData: {
        enterprice: null,
        bank: null,
      },
      searchOptions: {
        search_enterprices: null,
        search_banks: "",
      },
      enterprices: null,
      banks: null,
    };
  },
  watch: {
    [`searchOptions.search_enterprices`](newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.enterprices = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_enterprices(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_enterprices(newVal);
        } else {
          this.get_filterd_enterprices(newVal);
        }
      }
    },
    [`searchOptions.search_banks`](newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.banks = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_banks(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_banks(newVal);
        } else {
          this.get_filterd_banks(newVal);
        }
      }
    },
  },

  methods: {
    // get_filterd_enterprices
    get_filterd_enterprices(keyword) {
      this.load_enterprices = true;
      this.$axios({
        method: "GET",
        url: `help/enterprises?keyword=${keyword}`,
      })
        .then((res) => {
          this.enterprices = res.data.data;
          this.load_enterprices = false;
        })
        .catch((err) => {
          this.load_enterprices = false;
        });
    },
    // get_filterd_enterprices
    get_filterd_banks(keyword) {
      this.load_banks = true;
      this.$axios({
        method: "GET",
        url: `client/bank/get_all?keyword=${keyword}`,
      })
        .then((res) => {
          this.banks = res.data.data;
          this.load_banks = false;
        })
        .catch((err) => {
          this.load_banks = false;
        });
    },
  },
};
</script>

<style lang="scss">
.search-section .load_inline {
  width: 30px !important;
  height: 25px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
  left: 1px;
  position: absolute !important;
  background: transparent !important;
  margin: 0 !important;

  span {
    right: 0 !important;
    left: 0 !important;
    margin: auto !important;
    border-top: 3px solid var(--mainColor-2) !important;
    border-right: 3px solid transparent !important;
  }
}
</style>

