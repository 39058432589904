<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/companyTransfers"
        search_title="تنفيذ شغل الشركات"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("edit") }}</h4>
        </div>

        <!-- ==== Start Form ==== -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-lg-12 py-0">
                <!--Divider-->
                <div class="divider">
                  <h4>المبلغ و الدفوعات</h4>
                  <v-divider></v-divider>
                </div>
                <!--Divider-->
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="data.all_amount"
                    disabled
                  />
                  <label for="name_input" class="form-label"
                    >المبلغ الإجمالي
                  </label>
                </div>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="data.paied_amount"
                  />
                  <label for="name_input" class="form-label"
                    >المبلغ المدفوع</label
                  >
                </div>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    readonly
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="remained_amount"
                  />
                  <label for="name_input" class="form-label"
                    >المبلغ المتبقي</label
                  >
                </div>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label mb-3">
                  <label class="form-label"> العملة </label>
                  <input
                    readonly
                    type="text"
                    class="form-control"
                    v-model.trim="data.currency"
                  />
                </div>
              </div>
              <div class="col-lg-12 py-0">
                <!--Divider-->
                <div class="divider">
                  <h4>حساب مؤسسة</h4>
                  <v-divider></v-divider>
                </div>
                <!--Divider-->
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> حساب المؤسسة</label>
                  <v-autocomplete
                    v-model="data.enterpriceAccount"
                    :search-input.sync="searchOptions.search_enterprices"
                    :items="enterprices"
                    item-text="name"
                    return-object
                    :hide-no-data="!enterprices"
                    dense
                  ></v-autocomplete>
                  <button
                    class="button_style_1 load_inline"
                    v-if="load_enterprices"
                  >
                    <span class="btn_loader"></span>
                  </button>
                </div>

                <h6
                  class="mt-0 mb-0 mx-0 amount-section"
                  v-if="data.enterpriceAccount"
                  :class="{
                    redColor: data.enterpriceAccount.amount < 0,
                    greenColor: data.enterpriceAccount.amount > 0,
                  }"
                >
                  الرصيد :
                  {{ Number(data.enterpriceAccount.amount).toLocaleString() }}
                </h6>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="data.organization_amount"
                    :disabled="data.account ? disabled : ''"
                  />
                  <label for="name_input" class="form-label"
                    >نسبة المؤسسة</label
                  >
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    readonly
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="organizationAll"
                  />
                  <label for="name_input" class="form-label">الإجمالي </label>
                </div>
              </div>
              <div class="col-lg-12 py-0">
                <!--Divider-->
                <div class="divider">
                  <h4>العميل</h4>
                  <v-divider></v-divider>
                </div>
                <!--Divider-->
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> إسم العميل</label>
                  <v-autocomplete
                    v-model="data.clientName"
                    :search-input.sync="searchOptions.search_users"
                    :items="users"
                    item-text="name"
                    return-object
                    :hide-no-data="!users"
                    dense
                  ></v-autocomplete>
                  <button class="button_style_1 load_inline" v-if="load_users">
                    <span class="btn_loader"></span>
                  </button>
                </div>
                <h6
                  class="mt-0 mb-0 mx-0 amount-section"
                  v-if="data.clientName"
                  :class="{
                    redColor: data.clientName.amount < 0,
                    greenColor: data.clientName.amount > 0,
                  }"
                >
                  الرصيد :
                  {{ Number(data.clientName.amount).toLocaleString() }}
                </h6>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.user_amount"
                    :disabled="data.account ? disabled : ''"
                  />
                  <label for="name_input" class="form-label">نسبة العميل</label>
                </div>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.user_commesion"
                    :disabled="data.account ? disabled : ''"
                  />
                  <label for="name_input" class="form-label"
                    >عمولة العميل</label
                  >
                </div>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    readonly
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="clientAll"
                  />
                  <label for="name_input" class="form-label">الإجمالي </label>
                </div>
              </div>

              <div class="col-lg-12 py-0">
                <!--Divider-->
                <div class="divider">
                  <h4>بيانات الشركة</h4>
                  <v-divider></v-divider>
                </div>
                <!--Divider-->
              </div>
              <div class="col-lg-12 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="accountNum"
                    v-model="data.ibanNumber"
                    @input="emptyBankData"
                    v-on:keydown.enter.prevent="getCompanyData"
                    disabled
                  />
                  <label for="accountNum" class="form-label"
                    >IBAN NO or A/C NO</label
                  >
                </div>
              </div>

              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">{{
                    $t("companies.data.name")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="accountNum"
                    v-model="data.company_name"
                    disabled
                  />
                  <button class="button_style_1 loadSwift" v-if="loadSwift">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>

              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="accountNum"
                    v-model="data.swift_number"
                    disabled
                  />
                  <label for="accountNum" class="form-label">{{
                    $t("companies.data.swiftNumber")
                  }}</label>
                  <button class="button_style_1 loadSwift" v-if="loadSwift">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>

              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="bank"
                    v-model="data.bank_name"
                    disabled
                  />
                  <label for="bank" class="form-label">{{
                    $t("forms.labels.bank_name")
                  }}</label>
                  <button class="button_style_1 loadSwift" v-if="loadSwift">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="bank"
                    v-model="data.country"
                    disabled
                  />
                  <label for="bank" class="form-label">الدولة</label>
                  <button class="button_style_1 loadSwift" v-if="loadSwift">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="bank"
                    v-model="data.address"
                    disabled
                  />
                  <label for="bank" class="form-label">العنوان</label>
                  <button class="button_style_1 loadSwift" v-if="loadSwift">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">السلع</label>
                  <multiselect
                    v-model="data.products"
                    :options="products"
                    label="name"
                    track-by="name"
                    placeholder=""
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                    :multiple="true"
                    disabled
                  >
                  </multiselect>
                  <button class="button_style_1 loadSwift" v-if="loadSwift">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              تعديل
              <span class="btn_loader m-2" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- ==== End Form ==== -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      load_enterprices: false,
      load_users: false,

      searchOptions: {
        search_enterprices: "",
        search_users: "",
      },
      enterprices: [],
      users: null,

      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "تعديل تنفيذ شغل لشركة",
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // Date_menues
      dateMenu: false,
      debit_dateMenu: false,

      add_Company_dialog: false,

      // search_users
      search_users: "",
      // search_accounts
      search_accounts: "",

      // ========== create_data
      data: {
        enterpriceAccount: null,
        organization_amount: null,
        organizationAll: null,
        clientName: null,
        user_amount: null,
        user_commesion: null,
        clientAll: null,
        all_amount: null,
        paied_amount: null,
        remained_amount: null,
        currency: null,
        ibanNumber: null,
        company_id: null,
        company_name: null,
        swift_number: null,
        bank_name: null,
        country: null,
        address: null,
        products: [],
      },

      // ========== Fixed_data
      companies: [
        {id: 1, name: "company"},
        {id: 2, name: "company"},
        {id: 3, name: "company"},
      ],
      products: [
        {id: 1, name: "product"},
        {id: 2, name: "product"},
        {id: 3, name: "product"},
      ],
      categories: [],
      banks: [],
      companyLogo: {
        file: null,
        name: null,
        path: null,
      },
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },
      users: [],
      enterpriceAccounts: [],
      rows: [
        {
          id: 1,
          paied_amount: 220,
          remained_amount: 110,
          date: "2022-4-6",
        },
        {
          id: 2,
          paied_amount: 220,
          remained_amount: 110,
          date: "2022-4-6",
        },
      ],
      loadSwift: false,
      currencies: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "#",
          value: "index",
          sortable: false,
          align: "center",
          width: 10,
        },
        {text: "المبلغ المدفوع", value: "paied_amount", align: "center"},
        {text: "المبلغ المتبقي", value: "remained_amount", align: "center"},
        {text: "التاريخ", value: "date", align: "center"},
      ];
    },
    organizationAll() {
      const num = this.data.paied_amount * this.data.organization_amount;
      return parseFloat(num.toFixed()).toLocaleString();
    },
    clientAll() {
      const num =
        this.data.paied_amount *
        this.data.user_amount *
        this.data.user_commesion;
      return parseFloat(num.toFixed()).toLocaleString();
    },
    total() {
      const num = this.data.paied_amount * this.data.comission;
      return parseFloat(num.toFixed()).toLocaleString();
    },
    remained_amount() {
      const paidNum = parseInt(this.data.paied_amount);
      const num = this.data.all_amount - paidNum;
      return parseFloat(num.toFixed()).toLocaleString();
    },
  },

  watch: {
    [`searchOptions.search_users`](newVal, oldVal) {
      if (
        (!newVal || newVal.length < 3) &&
        this.data.enterpriceAccount == null
      ) {
        this.users = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_users(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_users(newVal);
        } else {
          this.get_filterd_users(newVal);
        }
      }
    },
    [`searchOptions.search_enterprices`](newVal, oldVal) {
      if (
        (!newVal || newVal.length < 3) &&
        this.data.enterpriceAccount == null
      ) {
        this.enterprices = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_enterprices(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_enterprices(newVal);
        } else {
          this.get_filterd_enterprices(newVal);
        }
      }
    },
    [`data.bank`](newVal) {
      this.data.swift_number = newVal.swift_number;
    },

    [`data.swift_number`](newVal) {
      this.data.bank =
        this.banks.find((item) => item.swift_number == newVal) || null;
    },
  },

  methods: {
    // ============== Get Data
    getData() {
      // Single company
      this.$axios({
        method: "GET",
        url: `client/company_transfer/${this.$route.params.id}`,
      })
        .then((res) => {
          this.data.clientName = res.data.data.customer;
          this.data.all_amount = res.data.data.total;
          this.data.ibanNumber = res.data.data.company.account_number;
          this.data.currency = res.data.data.currency;
          if (res.data.data.account != null) {
            this.data.enterpriceAccount = res.data.data.account;
            this.data.organization_amount = res.data.data.enterprise_rate;
            this.data.user_amount = res.data.data.customer_rate;
            this.data.user_commesion = res.data.data.customer_commission;
          }
          this.getCompanyData();
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
      // ===== Categories
      this.$axios({
        method: "GET",
        url: "client/category",
      })
        .then((res) => {
          this.categories = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });

      // ===== Banks
      this.$axios({
        method: "GET",
        url: "client/bank",
      }).then((res) => {
        this.banks = res.data.data;
      });

      // ===== Banks
      this.$axios({
        method: "GET",
        url: "client/company",
      }).then((res) => {
        this.companies = res.data.data;
      });
      this.$axios({
        method: "GET",
        url: `client/currency`,
      })
        .then((res) => {
          this.currencies = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    emptyBankData() {
      this.data.company_name = null;
      this.data.swift_number = null;
      this.data.bank_name = null;
      this.data.country = null;
      this.data.address = null;
      this.data.products = null;
    },
    getCompanyData() {
      this.loadSwift = true;
      this.$axios({
        method: "GET",
        url: "client/company",
        params: {
          number: this.data.ibanNumber,
        },
      })
        .then((res) => {
          if (res.data.data.length > 0 && this.data.ibanNumber.length != 0) {
            res.data.data.forEach((ele) => {
              this.data.company_name = ele.name;
              this.data.company_id = ele.id;
              this.data.swift_number = ele.swift_number;
              this.data.bank_name = ele.bank.name;
              this.data.country = ele.country.name_ar;
              this.data.address = ele.address;
              this.data.products = ele.products;
            });
          } else {
            this.data.company_name = null;
            this.data.swift_number = null;
            this.data.bank_name = null;
            this.data.country = null;
            this.data.address = null;
            this.data.products = null;
          }
          this.loadSwift = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // Get Companies
    getCompanies() {
      this.$axios({
        method: "GET",
        url: `client/company`,
      }).then((res) => {
        this.companies = res.data.data;
      });
    },

    // Get Companies
    getProducts() {
      this.$axios({
        method: "GET",
        url: `client/product`,
      }).then((res) => {
        this.products = res.data.data;
      });
    },
    getData() {
      this.$axios({
        method: "GET",
        url: `client/currency_transformation/${this.$route.params.id}`,
      }).then((res) => {
        this.transferType = res.data.data.type;
        this.data.amount = res.data.data.amount;
        this.data.multiplication = res.data.data.multiply;
        this.data.division = res.data.data.dividing;
        this.data.safe = res.data.data.currency_wallet;
        if (this.transferType == "customer") {
          this.data.username = res.data.data.balance_customer;
          this.data.clientAccountToTransfer =
            res.data.data.transferred_balance_customer;
        }
        if (this.transferType == "enterprise") {
          this.data.orgAccount = res.data.data.account;
          this.data.orgAccountToTransfer = res.data.data.transferred_account;
        }
      });
    },
    // get_filterd_users
    get_filterd_users(keyword) {
      this.load_users = true;
      this.$axios({
        method: "GET",
        url: `client/customer/search?keyword=${keyword}`,
      })
        .then((res) => {
          this.users = res.data.data;
          this.load_users = false;
        })
        .catch((err) => {
          this.load_users = false;
        });
    },

    // get_filterd_enterprices
    get_filterd_enterprices(keyword) {
      this.load_enterprices = true;
      this.$axios({
        method: "GET",
        url: `help/accounts?keyword=${keyword}`,
      })
        .then((res) => {
          this.enterprices = res.data.data;
          this.load_enterprices = false;
        })
        .catch((err) => {
          this.load_enterprices = false;
        });
    },

    // START:: HANDLING SELECTED FILE METHOD
    handelSelectedFile(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD

    // START:: HANDLING SELECTED FILE METHOD
    handelCompanyLogo(e) {
      this.companyLogo.file = e.target.files[0];
      this.companyLogo.name = e.target.files[0].name;
      this.companyLogo.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD

    // START:: HANDLING SELECTED FILE METHOD
    handelCommerceLogo(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD

    // Validate Data
    // Validate Data
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.enterpriceAccount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة إسم حساب المؤسسة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.organization_amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة نسبة المؤسسة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.clientName) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة إسم العميل",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.user_amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة نسبة العميل",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.user_commesion) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة عمولة العميل",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.ibanNumber) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة رقم الحساب او ال iban",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.all_amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة المبلغ الإجمالي",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.paied_amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة المبلغ المدفوع ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.ibanNumber) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة رقم الحساب او ال iban",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.submitData();
          return;
        }
      }
    },

    // Submit Data
    submitData() {
      const submit_data = new FormData();
      submit_data.append("account_id", this.data.enterpriceAccount.id);
      submit_data.append("enterprise_rate", this.data.organization_amount);
      submit_data.append(
        "enterprise_total",
        Number(this.organizationAll.replace(/\D/g, ""))
      );
      submit_data.append("balance_customer_id", this.data.clientName.id);
      submit_data.append("customer_rate", this.data.user_amount);
      submit_data.append("customer_commission", this.data.user_commesion);
      submit_data.append(
        "customer_total",
        Number(this.clientAll.replace(/\D/g, ""))
      );
      submit_data.append("total", this.data.all_amount);
      submit_data.append("paid", this.data.paied_amount);
      submit_data.append(
        "rest",
        Number(this.remained_amount.replace(/\D/g, ""))
      );
      submit_data.append("company_id", this.data.company_id);
      submit_data.append("company_transfer_id", this.$route.params.id);
      this.$axios({
        method: "POST",
        url: "client/company_work",
        data: submit_data,
      })
        .then(() => {
          this.btnIsLoading = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "topRight",
          });
          this.$router.push({path: "/companyAction"});
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
  },

  created() {
    this.getData();
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("lock_and_deals")
    ) {
      this.$router.push("/");
    }
  },
};
</script>
