<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        type="english"
        search_route="/allCompanies"
        search_title="companies"
      />
      <!-- End Breadcrumb -->
      <div class="custom_card">
        <!-- START:: CARD TITLE -->
        <div class="card-header">
          <h4 class="card-title">Edit Company Data</h4>
        </div>
        <!-- END:: CARD TITLE -->

        <!-- START:: CREATE FORM WRAPPER -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-lg-12 py-0">
                <!--Divider-->
                <div class="divider">
                  <h4>Company Data</h4>
                  <v-divider></v-divider>
                </div>
                <!--Divider-->
              </div>

              <div class="col-lg-4 py-0">
                <!-- START:: TITLE INPUT -->
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control dir-ltr-dots"
                    id="name_input"
                    v-model.trim="data.name"
                  />
                  <label for="name_input" class="form-label">{{
                    $t("companies.data.name")
                  }}</label>
                  <button
                    class="button_style_1 loadSwift"
                    v-if="loadCompanyData"
                  >
                    <span class="btn_loader"></span>
                  </button>
                </div>
                <!-- END:: TITLE INPUT -->
              </div>
              <!-- END:: INPUT WRAPPER -->
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control dir-ltr-dots"
                    id="name_input3"
                    v-model.trim="data.address"
                  />
                  <label for="name_input3" class="form-label">Address</label>
                  <button
                    class="button_style_1 loadSwift"
                    v-if="loadCompanyData"
                  >
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-4 py-0">
                <!-- START:: MULTIPLE SELECT INPUT -->
                <div class="input_wrapper top_label">
                  <label class="form-label"> Country</label>
                  <multiselect
                    v-model="data.country"
                    :options="countries"
                    label="name_ar"
                    track-by="id"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                  >
                  </multiselect>
                  <button
                    class="button_style_1 loadSwift"
                    v-if="loadCompanyData"
                  >
                    <span class="btn_loader"></span>
                  </button>
                </div>
                <!-- END:: MULTIPLE SELECT INPUT -->
              </div>
              <!-- END:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">Product</label>
                  <multiselect
                    v-model="data.products"
                    :options="products"
                    label="name"
                    track-by="id"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    :multiple="true"
                  >
                  </multiselect>
                  <button
                    class="button_style_1 loadSwift"
                    v-if="loadCompanyData"
                  >
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label file_input">
                  <span class="file_input_label"> * Company Logo </span>
                  <label
                    for="file_input_1"
                    class="form-label"
                    v-if="!companyLogo.name"
                  ></label>
                  <label
                    for="file_input_1"
                    class="form-label dir-ltr-dots"
                    v-else
                  >
                    {{ companyLogo.name }}
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    id="file_input_1"
                    @change="handelCompanyLogo"
                  />
                  <button
                    class="button_style_1 loadSwift"
                    v-if="loadCompanyData"
                  >
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!--Divider-->
              <div class="divider">
                <h4>Bank Data</h4>
                <v-divider></v-divider>
              </div>
              <!--Divider-->

              <!--START:: SWITCH ACCOUNT -->
              <div class="col-lg-12 py-0">
                <!-- START:: RADIO BUTTON INPUT -->
                <div class="input_wrapper radio_button_input">
                  <div class="flex_wrapper">
                    <v-radio-group v-model="radioVal">
                      <v-radio
                        label=" A/C NO"
                        color="indigo lighten-2"
                        value="account"
                      ></v-radio>
                      <v-radio
                        label="IBAN NO"
                        color="indigo lighten-2"
                        value="iban"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <!-- END:: RADIO BUTTON INPUT -->
              </div>
              <!--END:: SWITCH ACCOUNT -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-4 py-0">
                <!-- START:: NUMBER INPUT ACCOUNT NUMBER-->
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="accountNum"
                    v-model="data.accountNumber"
                    v-on:keydown.enter.prevent="getCompanyData"
                  />
                  <label
                    v-if="radioVal == 'account'"
                    for="accountNum"
                    class="form-label"
                  >
                    A/C NO</label
                  >
                  <label
                    v-if="radioVal == 'iban'"
                    for="accountNum"
                    class="form-label"
                    >IBAN NO</label
                  >
                </div>

                <!-- END:: NUMBER INPUT ACCOUNT NUMBER-->
              </div>
              <!-- END:: INPUT WRAPPER -->
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-4 py-0">
                <!-- START:: NUMBER INPUT SWIFT NUMBER-->
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="accountNum"
                    v-model="data.swiftNumber"
                    @input="data.bank = null"
                    v-on:keydown.enter.prevent="getBankData"
                  />
                  <label for="accountNum" class="form-label">{{
                    $t("companies.data.swiftNumber")
                  }}</label>
                  <button
                    class="button_style_1 loadSwift"
                    v-if="loadCompanyData"
                  >
                    <span class="btn_loader"></span>
                  </button>
                </div>
                <!-- END:: NUMBER INPUT SWIFT NUMBER-->
              </div>
              <!-- START:: SINGLE SELECT INPUT -->
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label"> Bank Name </label>
                  <multiselect
                    v-model="data.bank"
                    :options="banks"
                    label="name"
                    track-by="name"
                    placeholder=""
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                  >
                  </multiselect>
                  <button class="button_style_1 loadSwift" v-if="loadSwift">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>
              <!-- END:: SINGLE SELECT INPUT -->
              <!-- END:: INPUT WRAPPER -->
            </div>
          </div>

          <div class="buttons_wrapper">
            <!-- START:: BUTTON -->
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              Edit
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
            <!-- END:: BUTTON -->
          </div>
        </form>
        <!-- END:: CREATE FORM WRAPPER -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Update",

  props: ["id"],

  data() {
    return {
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: "Home Page",
          disabled: false,
          href: "/",
        },
        {
          text: "Edit Company",
          disabled: true,
          href: "",
        },
      ],

      // START:: BUTTON LOADER HANDLING DATA
      btnIsLoading: false,
      // END:: BUTTON LOADER HANDLING DATA

      // START:: SINGLE SELECT DATA
      categories: [],
      banks: [],
      clients: [],
      search_users: "",
      countries: [],
      products: [],
      // END:: SINGLE SELECT DATA

      // START:: COMPANY LOGO
      companyLogo: {
        file: null,
        name: null,
        path: null,
      },
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },
      // END:: COMPANY LOGO

      radioVal: "account",

      // START:: CREATE DATA
      data: {
        clientName: "",
        amount: null,
        name: null,
        country: null,
        address: null,
        products: [],
        bank: "",
        accountNumber: null,
        swiftNumber: null,
      },
      // END:: CREATE DATA
      users: null,
      loadSwift: false,
      loadCompanyData: false,
    };
  },
  watch: {
    [`search_users`](newVal, oldVal) {
      if (!newVal & (oldVal == "")) {
        this.get_filterd_users(oldVal);
      } else if ((newVal == "") & (oldVal != "")) {
        this.get_filterd_users(newVal);
      } else {
        this.get_filterd_users(newVal);
      }
    },
  },
  methods: {
    getBankData() {
      this.loadSwift = true;
      this.$axios({
        method: "GET",
        url: "client/bank/get_all",
        params: {
          swift: this.data.swiftNumber,
        },
      })
        .then((res) => {
          if (res.data.data.length > 0 && this.data.swiftNumber.length != 0) {
            res.data.data.forEach((ele) => {
              this.data.bank = ele;
            });
          } else {
            this.data.bank = null;
          }
          this.loadSwift = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // get_filterd_users
    get_filterd_users(keyword) {
      this.$axios({
        method: "GET",
        url: `client/customer/search?keyword=${keyword}`,
      }).then((res) => {
        this.users = res.data.data;
      });
    },
    // START:: VALIDATE CREATE FORM
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.name) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة إسم الشركة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.address) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة عنوان الشركة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.country) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إختيار الدولة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (this.data.products.length == 0) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إختيار السلع",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.accountNumber) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة رقم الحساب او ال iban الخاص بالشركة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.bank) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إختيار البنك للشركة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.swiftNumber) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة رقم الswift للشركة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          const submit_data = new FormData();
          submit_data.append("name", this.data.name);
          this.data.products.forEach((element, index) => {
            submit_data.append(`products[${index}]`, element.id);
          });
          if (this.companyLogo.file != null) {
            submit_data.append("image", this.companyLogo.file);
          }
          submit_data.append("country_id", this.data.country.id);
          submit_data.append("address", this.data.address);
          submit_data.append("account_number", this.data.accountNumber);
          submit_data.append("bank_id", this.data.bank.id);
          submit_data.append("swift_number", this.data.swiftNumber);
          this.$axios({
            method: "POST",
            url: `client/company/update_company/` + this.$route.params.id,
            data: submit_data,
          })
            .then(() => {
              this.btnIsLoading = false;
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("editSuccess"),
                position: "topRight",
              });
              this.$router.push({path: "/allCompanies"});
            })
            .catch((err) => {
              this.btnIsLoading = false;
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "topRight",
              });
            });
          return;
        }
      }
    },
    // END:: VALIDATE CREATE FORM
    getData() {
      // Categories
      this.$axios({
        method: "GET",
        url: "client/category",
      })
        .then((res) => {
          this.categories = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });

      // Single company
      this.$axios({
        method: "GET",
        url: `client/company/${this.id}`,
      })
        .then((res) => {
          this.data.clientName = res.data.data.customer;
          this.data.name = res.data.data.name;
          this.data.country = res.data.data.country;
          this.data.products = res.data.data.products;
          // this.data.category = res.data.data.categories;
          this.data.accountNumber = res.data.data.account_number;
          // this.data.ibanNumber = res.data.data.iban_number;
          this.data.swiftNumber = res.data.data.swift_number;
          // this.selectedFile.name = res.data.data.commercial_image;
          this.companyLogo.name = res.data.data.image;
          this.data.bank = res.data.data.bank;
          // this.data.daraba_num = res.data.data.tax_number;
          // this.data.commerce_num = res.data.data.commercial_number;
          this.data.address = res.data.data.address;
        })
        .catch((err) => {
          console.log(err);
        });
      this.$axios({
        method: "GET",
        url: "client/bank",
      }).then((res) => {
        this.banks = res.data.data;
      });
      this.$axios({
        method: "GET",
        url: `client/product`,
      }).then((res) => {
        this.products = res.data.data;
      });
      this.$axios({
        method: "GET",
        url: `client/country`,
      }).then((res) => {
        this.countries = res.data.data;
      });
    },

    // START:: HANDLING SELECTED FILE METHOD
    handelCompanyLogo(e) {
      this.companyLogo.file = e.target.files[0];
      this.companyLogo.name = e.target.files[0].name;
      this.companyLogo.path = URL.createObjectURL(e.target.files[0]);
    },
    handelCommerceLogo(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    getCompanyData() {
      this.loadCompanyData = true;
      this.loadSwift = true;
      this.$axios({
        method: "GET",
        url: "client/company/filter",
        params: {
          number: this.data.accountNumber,
        },
      })
        .then((res) => {
          if (this.data.accountNumber.length != 0 && res.data.data != null) {
            if (res.data.is_accepted == false) {
              this.$iziToast.warning({
                timeout: 2000,
                message: "الشركة مسجلة من قبل و لكن لم يتم الموافقه عليها",
                position: "topCenter",
              });
            } else {
              this.$iziToast.info({
                timeout: 2000,
                message: "الشركة مسجلة من قبل و تم الموافقه عليها",
                position: "topCenter",
              });
            }
            this.data.name = res.data.data.name;
            this.data.products = res.data.data.products;
            this.companyLogo.name = res.data.data.commercial_image;
            this.data.country = res.data.data.country;
            this.data.address = res.data.data.address;
            this.data.swiftNumber = res.data.data.swift_number;
            this.data.bank = res.data.data.bank;
          } else {
            this.$iziToast.error({
              timeout: 2000,
              message: "لم يتم تسجيل الشركة من قبل",
              position: "topCenter",
            });
            this.data.name = null;
            this.data.products = null;
            this.companyLogo.name = null;
            this.data.country = null;
            this.data.address = null;
            this.data.swiftNumber = null;
            this.data.bank = null;
          }
          this.loadCompanyData = false;
          this.loadSwift = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadCompanyData = false;
          this.loadSwift = false;
        });
    },
    // END:: HANDLING SELECTED FILE METHOD
  },

  created() {
    this.getData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      userType.includes("confirm_companies") &&
      !userType.includes("companies")
    ) {
      this.$router.push("/allCompanies");
    } else if (
      !userType.includes("superadmin") &&
      !userType.includes("companies")
    ) {
      this.$router.push("/");
    }
  },
};
</script>
