<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/products/sub"
        search_title="منتجات السلع"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("addNew") }}</h4>
        </div>

        <!-- ==== Start Form ==== -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-12 py-0" v-if="categories">
                <div class="input_wrapper top_label">
                  <label class="form-label">إختيار السلعه</label>
                  <multiselect
                    v-model="data.productId"
                    :options="categories"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                    :multiple="false"
                  >
                  </multiselect>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="name_input"
                    v-model.trim="data.name"
                  />
                  <label for="name_input" class="form-label">اسم المنتج</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="name_input"
                    v-model.trim="data.price"
                  />
                  <label for="name_input" class="form-label">سعر المنتج</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              إضافة
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- ==== End Form ==== -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "إضافة منتجات لسلعه",
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // Date_menues
      dateMenu: false,
      debit_dateMenu: false,

      // search_users
      search_users: "",

      // ========== create_data
      data: {
        productId: null,
        name: null,
        price: null,
      },

      // START:: SELECTED FILE DATA
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },
      // END:: SELECTED FILE DATA

      // START:: MULTIPLE SELECT DATA
      categories: null,
      // END:: MULTIPLE SELECT DATA
    };
  },

  methods: {
    // Get Categories
    getCategories() {
      this.$axios({
        method: "GET",
        url: `client/product`,
      }).then((res) => {
        this.categories = res.data.data;
        this.loading = false;
      });
    },

    // Uplode Image
    uplodefiles(data) {
      this.data.files = data;
    },

    // Validate Data
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.productId) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال إسم السلعة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.name) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال إسم المنتج الخاص بالسلعه ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.price) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال سعر المنتج الخاص بالسلعه ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.submitData();
          return;
        }
      }
    },
    // Submit Data
    emptyFormData() {
      this.data.name = null;
      this.data.price = null;
    },
    submitData() {
      const submit_data = new FormData();
      submit_data.append("product_id", this.data.productId.id);
      submit_data.append("name", this.data.name);
      submit_data.append("price", this.data.price);
      this.$axios({
        method: "POST",
        url: "client/item",
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "topRight",
          });
          this.emptyFormData();
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
  },

  created() {
    this.getCategories();
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (!userType.includes("superadmin") && !userType.includes("companies")) {
      this.$router.push("/");
    }
  },
};
</script>
