<template>
  <div>
    <div class="profile_wrapper most_profile fadeIn">
      <div class="profile_header_wrapper custom_card">
        <vue-particles
          color="#C2C6DC"
          :particleOpacity="0.7"
          :particlesNumber="180"
          shapeType="circle"
          :particleSize="4"
          linesColor="#C2C6DC"
          :linesWidth="1"
          :lineLinked="true"
          :lineOpacity="0.3"
          :linesDistance="100"
          :moveSpeed="4"
          :hoverEffect="true"
          :clickEffect="true"
          hoverMode="repulse"
          clickMode="push"
        >
        </vue-particles>

        <div class="profile_header_data">
          <div class="avatar_wrapper">
            <img :src="data.avatar" width="100" height="100" alt="Avatar" />
          </div>

          <div class="name_wrapper">
            <h3>{{ data.name }}</h3>
          </div>
        </div>
      </div>

      <!-- START:: PROFILE INNER DATA -->
      <transition name="fadeIn" mode="out-in">
        <router-view></router-view>
      </transition>
      <!-- END:: PROFILE INNER DATA -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],

  data() {
    return {
      data: {
        avatar:
          "https://khlod.aait-d.com/mo3alem-mosa3ed/public/storage/images/slider/1648368995_9jHDhNnR5GUu.png",
        name: "Omar Momen",
      },
    };
  },

  methods: {
    getData() {
      this.$axios({
        method: "GET",
        url: `lecturers/${this.id}`,
      }).then((res) => {});
    },
  },

  created() {
    // this.getData();
  },
};
</script>

<style></style>

