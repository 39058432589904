<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :show_print="true"
        :excelFile="true"
        :excelData="excelData"
      />
      <!-- End Breadcrumb -->
      <!-- start of searchSection  -->
      <SearchSection @filterTable="searchTable"></SearchSection>
      <!-- end of searchSection  -->
      <!-- notFound  -->
      <Notfound v-if="!searchItems"></Notfound>
      <div v-else>
        <!-- Start Statistics Card-->
        <ul class="all-state-count">
          <li>
            <div class="state-count">
              <span>عدد اصحاب المؤسسات:</span> <span>{{ table_length }}</span>
            </div>
          </li>
        </ul>
        <!-- End Statistics Card-->

        <!-- Start Main Section -->
        <main class="table-print main-table">
          <!-- start of  header of printSection  -->
          <PrintHead
            :title="'أصحاب المؤسسات'"
            :number="table_length"
          ></PrintHead>
          <!-- end of header of printSection  -->
          <v-data-table
            class="thumb"
            :headers="headers"
            :items="rows"
            :search="search"
            :loading="lodaing"
            :loading-text="$t('table.loadingData')"
            v-model="selected"
            :single-select="singleSelect"
            :items-per-page="rows.length"
            item-key="id"
            hide-default-footer
          >
            <!-- ================== You Can use any slots you want ================== -->
            <!-- Select header field -->
            <template v-slot:[`header.desc`]="{ header }">
              {{ header.text + " ..." }}
            </template>
            <!-- revision status-->
            <template v-slot:[`item.review_status`]="{ item }">
              <v-chip color="#d7c100" v-if="item.review_status == 0">
                منتظر
              </v-chip>
              <v-chip color="green" v-else> تأكيد </v-chip>
            </template>
            <!-- Confirm revision-->
            <template v-slot:[`item.revision_done`]="{ item }">
              <!--  -->
              <div class="_actions actions2">
                <button
                  type="button"
                  class="accept confirm_btn ml-0"
                  @click="revisionDone(item)"
                  v-if="item.review_status == 0"
                >
                  <i class="far fa-check"></i>
                </button>
                <span v-else> ---- </span>
              </div>
            </template>
            <template v-slot:[`item.index`]="{ index }">
              {{ index + 1 }}
            </template>
            <!-- ====== Select row field ====== -->
            <!-- title -->
            <template v-slot:[`item.title`]="{ item }">
              {{ item.title }}
            </template>

            <!-- descreption -->
            <template v-slot:[`item.desc`]="{ item }">
              {{ item.desc.slice(0, 40) + "..." }}
            </template>

            <!-- avatar -->
            <template v-slot:[`item.avatar`]="{ item }">
              <img @click="show_model_1" class="image" :src="item.avatar" />
            </template>
            <template v-slot:[`item.operator_customer`]="{ item }">
              <span v-if="!item.operator_customer"> ----- </span>
              <span v-else> {{ item.operator_customer.name }} </span>
            </template>

            <!-- Select no data State -->
            <template v-slot:no-data>
              {{ $t("table.noData") }}
            </template>

            <!-- Select actions-->
            <template v-slot:[`item.actions`]="{ item }">
              <div class="_actions">
                <v-icon class="show" small @click="showItem(item)">
                  fal fa-eye
                </v-icon>
                <v-icon class="edit" small @click="editItem(item)">
                  fal fa-edit
                </v-icon>
                <v-icon class="delete" small @click="deleteItem(item)">
                  fal fa-trash
                </v-icon>
              </div>
            </template>

            <!-- ======================== Start Top Section ======================== -->
            <template v-slot:top>
              <!-- Delete dialog -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">{{
                    $t("table.deletedialog.areYouSure")
                  }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="deleteItemConfirm"
                      >{{ $t("table.deletedialog.ok") }}
                      <span
                        class="btn_loader_model"
                        v-if="loadingBtnDialog"
                      ></span
                    ></v-btn>
                    <v-btn color="#F44336" @click="dialogDelete = false">{{
                      $t("table.deletedialog.cancel")
                    }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- confirm dialog -->
              <v-dialog v-model="dialogConfirm" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">
                    هل تمت المراجعه علي العملية ؟
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="revisionConfirm"
                      >{{ $t("table.deletedialog.ok") }}
                      <span
                        class="btn_loader_model"
                        v-if="loadingBtnDialog"
                      ></span
                    ></v-btn>
                    <v-btn color="#F44336" @click="dialogConfirm = false">{{
                      $t("table.deletedialog.cancel")
                    }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Row -->
            </template>
            <!-- ======================== End Top Section ======================== -->
          </v-data-table>
        </main>
        <!-- End Main Section -->
      </div>

      <!-- Start Image_Model -->
      <base-model
        @closeModel="model_1.show_model = false"
        :show="model_1.show_model"
      >
        <div class="image">
          <img
            v-if="model_1.model_img_src"
            :src="model_1.model_img_src"
            alt="..."
          />
        </div>
      </base-model>
      <!-- End Image_Model -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
import SearchSection from "@/components/Search/enterpriceOwners";
export default {
  components: {
    StatsCard,
    SearchSection,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // search
      searchItems: null,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: "الرئيسية",
          disabled: false,
          href: "/",
        },
        {
          text: "اصحاب المؤسسات",
          disabled: true,
          href: "",
        },
      ],
      statisticsItems: [
        {
          id: 2,
          title: "اصحاب المؤسسات",
          data: "20",
          icon: "fal fa-users-crown",
        },
      ],

      // ========== Statistics

      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,
      dialogConfirm: false,
      itemtoConfirm: null,
      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== Loding
      lodaing: false,
      table_length: null,
      // excel data
      excelData: {
        name: "اصحاب المؤسسات",
        items: null,
        json_fields: {
          "اسم صاحب المؤسسة": "name",
          "رقم الهاتف": "phone",
          "اسم العميل المسئول": "customer.name",
          "المسئول عن التشغيل": "operator_customer.name",
          "عدد المؤسسات": "enterprises_count",
          "تاريخ الانشاء": "created_at",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        var userType = JSON.parse(this.$store.state.usertype) || null;
        if (
          userType.includes("superadmin") ||
          (userType.includes("enterprise") &&
            userType.includes("confirm_enterprise"))
        ) {
          return [
            {
              text: "#",
              value: "index",
              sortable: false,
              align: "center",
              width: 10,
            },
            {
              text: "اسم صاحب المؤسسة",
              value: "name",
              align: "center",
              width: 120,
            },
            {
              text: "رقم الهاتف",
              value: "phone",
              align: "center",
              sortable: false,
              width: 100,
            },
            {
              text: "اسم العميل المسئول",
              value: "customer.name",
              align: "center",
              width: 120,
            },
            {
              text: "نسبه المسئول",
              value: "rate",
              align: "center",
              width: 80,
            },
            {
              text: "عدد المؤسسات",
              value: "enterprises_count",
              align: "center",
              width: 50,
              sortable: false,
            },
            {
              text: "تاريخ الانشاء",
              value: "created_at",
              align: "center",
              width: 80,
            },
            {
              text: "المراجعة",
              value: "review_status",
              align: "center",
              sortable: false,
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 50,
            },
            {
              text: "تأكيد",
              value: "revision_done",
              align: "center",
              sortable: false,
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 50,
            },
            {
              text: "التحكم",
              value: "actions",
              sortable: false,
              align: "center",
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 80,
            },
          ];
        } else if (!userType.includes("confirm_enterprise")) {
          return [
            {
              text: "#",
              value: "index",
              sortable: false,
              align: "center",
              width: 10,
            },
            {
              text: "اسم صاحب المؤسسة",
              value: "name",
              align: "center",
              width: 120,
            },
            {
              text: "رقم الهاتف",
              value: "phone",
              align: "center",
              sortable: false,
              width: 100,
            },
            {
              text: "اسم العميل المسئول",
              value: "customer.name",
              align: "center",
              width: 120,
            },
            {
              text: "المسئول عن التشغيل",
              value: "operator_customer",
              align: "center",
              width: 120,
            },
            {
              text: "عدد المؤسسات",
              value: "enterprises_count",
              align: "center",
              width: 50,
              sortable: false,
            },
            {
              text: "تاريخ الانشاء",
              value: "created_at",
              align: "center",
              width: 80,
            },
            {
              text: "المراجعة",
              value: "review_status",
              align: "center",
              sortable: false,
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 50,
            },
            {
              text: "التحكم",
              value: "actions",
              sortable: false,
              align: "center",
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 80,
            },
          ];
        } else {
          return [
            {
              text: "#",
              value: "index",
              sortable: false,
              align: "center",
              width: 10,
            },
            {
              text: "اسم صاحب المؤسسة",
              value: "name",
              align: "center",
              width: 120,
            },
            {
              text: "رقم الهاتف",
              value: "phone",
              align: "center",
              sortable: false,
              width: 100,
            },
            {
              text: "اسم العميل المسئول",
              value: "customer.name",
              align: "center",
              width: 120,
            },
            {
              text: "المسئول عن التشغيل",
              value: "operator_customer",
              align: "center",
              width: 120,
            },
            {
              text: "عدد المؤسسات",
              value: "enterprises_count",
              align: "center",
              width: 50,
              sortable: false,
            },
            {
              text: "تاريخ الانشاء",
              value: "created_at",
              align: "center",
              width: 80,
            },
            {
              text: "المراجعة",
              value: "review_status",
              align: "center",
              sortable: false,
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 50,
            },
            {
              text: "تأكيد",
              value: "revision_done",
              align: "center",
              sortable: false,
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 50,
            },
          ];
        }
      }
    },
  },

  methods: {
    // searchTable
    searchTable(items) {
      this.rows = [];
      this.searchItems = items;
      this.setRows();
    },
    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/enterpriceOwners/add" });
    },
    showItem(item) {
      this.$router.push({ path: "/enterpriceOwners/show/" + item.id });
    },
    editItem(item) {
      this.$router.push({ path: "/enterpriceOwners/edit/" + item.id });
    },
    // confirm item
    revisionDone(item) {
      this.dialogConfirm = true;
      this.itemtoConfirm = item;
    },
    revisionConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "POST",
        url: `client/owner/review/${this.itemtoConfirm.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogConfirm = false;
          this.$iziToast.success({
            timeout: 2000,
            message: "تم التأكيد بنجاح",
            position: "topRight",
          });
        })
        .catch((err) => {
          this.dialogConfirm = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/owner/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("CRUD.Delete"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
      // Submit the delete service then submit this
    },
    // ==================== End CRUD ====================

    setRows() {
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/owner",
        params: {
          owner: this.searchItems ? this.searchItems.enterpriceOwner : null,
          customer: this.searchItems ? this.searchItems.username : null,
        },
      })
        .then((res) => {
          this.rows = res.data.data;
          this.table_length = this.rows.length;
          this.excelData.items = this.rows;
          this.lodaing = false;
        })
        .catch((err) => {
          console.log(err);
          this.lodaing = false;
        });
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("enterprise") &&
      !userType.includes("confirm_enterprise")
    ) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped></style>
