<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="" v-else>
      <div class="wrraper" v-if="allData">
        <div class="row">
          <div class="col-md-12">
            <div class="show_title">
              <span>بيانات صاحب المؤسسة</span>
            </div>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-user-tie'"
              :number="allData.name"
              :title="'اسم صاحب المؤسسة'"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-money-check-edit'"
              :number="allData.address"
              :title="'العنوان الوطني'"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
              :link="allData.address"
            ></stats-card>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-at'"
              :number="allData.email"
              :title="'البريد الاكتروني'"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-images'"
              number="صورة الهوية"
              :title="'صورة الهوية'"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
              :link="allData.identity"
            ></stats-card>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-phone-alt'"
              :number="allData.phone"
              :title="'رقم الهاتف'"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-calendar-alt'"
              :number="allData.created_at"
              :title="'تاريخ الانشاء '"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>
          <div class="col-md-12">
            <div class="show_title">
              <span>بيانات ابشر</span>
            </div>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-money-check-edit'"
              :number="allData.absher_name"
              :title="'رقم الهوية'"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-lock-alt'"
              :title="'كلمة المرور'"
              :number="allData.absher_password"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>
          <!-- ----------------------------------- -->
          <div class="col-md-12">
            <div class="show_title">
              <span>بيانات العميل المسئول </span>
            </div>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-user-friends'"
              :title="'اسم العميل'"
              :number="allData.customer.name"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-percent'"
              :title="' عمولة العميل'"
              :number="allData.rate"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-percent'"
              :title="'القسمة'"
              :number="allData.divide"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>
          <!-- ----------------------------------- -->
          <div class="col-md-12">
            <div class="show_title">
              <span>بيانات المسئول عن تشغيل المؤسسات </span>
            </div>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-user-friends'"
              :title="' المسئول عن تشغيل المؤسسات'"
              :number="allData.operator_customer ? allData.operator_customer.name : 'لا يوجد'"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-percent '"
              :title="' ضرب'"
              :number="allData.operator_rate"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-percent'"
              :title="'القسمة'"
              :number="allData.operator_divide"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>
          <div class="col-md-12">
            <div class="show_title">
              <span>بيانات مسئول السعودية</span>
            </div>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-user-friends'"
              :title="'اسم المسئول'"
              :number="allData.responsible_name"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-phone-alt'"
              :title="'رقم الهاتف'"
              :number="allData.responsible_phone"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>

          <div class="col-md-12">
            <div class="show_title">
              <span>المؤسسات الخاصة بالمستخدم</span>
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-lg-3 col-md-4 col-12">
              <div class="wrraper" @click="filterClick(item.title)">
                <stats-card
                  :icon="'fal fa-building'"
                  :title="'المؤسسات'"
                  :number="rows.length"
                  :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
                  :bgColor="'transparent'"
                ></stats-card>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="wrapper mb-16 pb-10">
              <!-- Start Main Section -->
              <main class="main-table">
                <v-data-table
                  class="thumb"
                  :headers="headers"
                  :items="rows"
                  :search="search"
                  :custom-filter="filterSearch"
                  :loading="lodaing"
                  :loading-text="$t('table.loadingData')"
                  v-model="selected"
                  :single-select="singleSelect"
                  :items-per-page="rows.length"
                  item-key="id"
                  hide-default-footer
                >
                  <!-- ================== You Can use any slots you want ================== -->
                  <!-- Select header field -->
                  <template v-slot:[`header.desc`]="{ header }">
                    {{ header.text + " ..." }}
                  </template>
                  <!-- revision status-->
                  <template v-slot:[`item.review_status`]="{ item }">
                    <v-chip color="#d7c100" v-if="item.review_status == 0">
                      منتظر
                    </v-chip>
                    <v-chip color="green" v-else> تأكيد </v-chip>
                  </template>
                  <!-- Confirm revision-->
                  <template v-slot:[`item.revision_done`]="{ item }">
                    <!--  -->
                    <div class="_actions actions2">
                      <button
                        type="button"
                        class="accept confirm_btn ml-0"
                        @click="revisionDone(item)"
                        v-if="item.review_status == 0"
                      >
                        <i class="far fa-check"></i>
                      </button>
                      <span v-else> ---- </span>
                    </div>
                  </template>
                  <template v-slot:[`item.index`]="{ index }">
                    {{ index + 1 }}
                  </template>
                  <!-- ====== Select row field ====== -->
                  <!-- title -->
                  <template v-slot:[`item.title`]="{ item }">
                    {{ item.title }}
                  </template>
                  <template v-slot:[`item.name_ar`]="{ item }">
                    <router-link :to="'/enterprices/show/' + item.id">
                      {{ item.name_ar }}
                    </router-link>
                  </template>
                  <!-- descreption -->
                  <template v-slot:[`item.desc`]="{ item }">
                    {{ item.desc.slice(0, 40) + "..." }}
                  </template>

                  <!-- avatar -->
                  <template v-slot:[`item.avatar`]="{ item }">
                    <img
                      @click="show_model_1"
                      class="image"
                      :src="item.avatar"
                    />
                  </template>

                  <!-- Select no data State -->
                  <template v-slot:no-data>
                    {{ $t("table.noData") }}
                  </template>

                  <!-- Select actions-->
                  <template v-slot:[`item.actions`]="{ item }">
                    <div class="_actions">
                      <v-icon class="show" small @click="showItem(item)">
                        fal fa-eye
                      </v-icon>
                      <v-icon class="edit" small @click="editItem(item)">
                        fal fa-edit
                      </v-icon>
                      <v-icon class="delete" small @click="deleteItem(item)">
                        fal fa-trash
                      </v-icon>
                    </div>
                  </template>

                  <!-- ======================== Start Top Section ======================== -->
                  <template v-slot:top>
                    <!-- Delete dialog -->
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="text-h5 justify-center">{{
                          $t("table.deletedialog.areYouSure")
                        }}</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="#1B5E20" @click="deleteItemConfirm"
                            >{{ $t("table.deletedialog.ok") }}
                            <span
                              class="btn_loader_model"
                              v-if="loadingBtnDialog"
                            ></span
                          ></v-btn>
                          <v-btn
                            color="#F44336"
                            @click="dialogDelete = false"
                            >{{ $t("table.deletedialog.cancel") }}</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <!-- confirm dialog -->
                    <v-dialog v-model="dialogConfirm" max-width="500px">
                      <v-card>
                        <v-card-title class="text-h5 justify-center">
                          هل تمت المراجعه علي العملية ؟
                        </v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="#1B5E20" @click="revisionConfirm"
                            >{{ $t("table.deletedialog.ok") }}
                            <span
                              class="btn_loader_model"
                              v-if="loadingBtnDialog"
                            ></span
                          ></v-btn>
                          <v-btn
                            color="#F44336"
                            @click="dialogConfirm = false"
                            >{{ $t("table.deletedialog.cancel") }}</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <!-- Row -->
                  </template>
                  <!-- ======================== End Top Section ======================== -->
                </v-data-table>
              </main>
              <!-- End Main Section -->
            </div>
          </div>
        </div>
        <!-- Start Image_Model -->
        <base-model
          @closeModel="model_1.show_model = false"
          :show="model_1.show_model"
        >
          <div class="image">
            <img
              v-if="model_1.model_img_src"
              :src="model_1.model_img_src"
              alt="..."
            />
          </div>
        </base-model>
      </div>
    </div>
  </div>
</template>

<script>
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
import { mapGetters } from "vuex";
export default {
  props: ["id"],
  components: {
    StatsCard,
  },
  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // loader
      loading: true,
      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },
      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,
      dialogConfirm: false,
      itemtoConfirm: null,
      // ========== elected Data
      selected: [],
      singleSelect: false,

      //ALL DATA
      allData: null,

      // ========== Loding
      lodaing: false,
    };
  },
  methods: {
    // ===== Search Method =====
    filterClick(word) {
      if (!this.lodaing) {
        if (word != "all") {
          this.search = word;
        } else {
          this.search = "";
        }
        this.filterSearch();
      }
    },

    filterSearch(value, search, item) {
      return (
        value != null &&
        search != null &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },
    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/enterprices/add" });
    },
    showItem(item) {
      this.$router.push({ path: "/enterprices/show/" + item.id });
    },
    editItem(item) {
      this.$router.push({ path: "/enterprices/edit/" + item.id });
    },
    // confirm item
    revisionDone(item) {
      this.dialogConfirm = true;
      this.itemtoConfirm = item;
    },
    revisionConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "POST",
        url: `cities/review/${this.itemtoConfirm.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogConfirm = false;
          this.$iziToast.success({
            timeout: 2000,
            message: "تم التأكيد بنجاح",
            position: "topRight",
          });
        })
        .catch((err) => {
          this.dialogConfirm = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `cities/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("CRUD.Delete"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
      // Submit the delete service then submit this
    },
    // ==================== End CRUD ====================

    // setRows() {
    //   this.lodaing = true;
    //   this.$axios({
    //     method: "GET",
    //     url: "cities",
    //     params: { page: this.paginations.current_page },
    //   })
    //     .then((res) => {
    //       this.paginations.last_page = res.data.meta.last_page;
    //       this.rows = res.data.data;
    //       this.lodaing = false;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.lodaing = false;
    //     });
    // },

    // ================= GET DATA =============
    getData() {
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/owner/" + this.id,
      })
        .then((res) => {
          this.allData = res.data.data;
          this.model_1.model_img_src = this.allData.identity;
          this.rows = this.allData.enterprises;
          this.lodaing = false;
        })
        .catch((err) => {
          console.log(err);
          this.lodaing = false;
        });
    },
  },

  computed: {
    current_theme() {
      return this.$store.getters["theme_module/current_theme"];
    },
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          // {
          //   text: "الصورة", // the name to display
          //   align: "center", // [center, start]
          //   value: "avatar", // Key must be equal to the name in rows objects
          //   sortable: false,
          // },
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          { text: "اسم المؤسسة", value: "name_ar", align: "center" },
          { text: "تاريخ الانشاء", value: "created_at", align: "center" },
          { text: "عدد الحسابات", value: "accounts_number", align: "center" },
          {
            text: "المراجعة",
            value: "review_status",
            align: "center",
            sortable: false,
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
          {
            text: "تأكيد",
            value: "revision_done",
            align: "center",
            sortable: false,
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
        ];
      } else {
        return [
          // {
          //   text: "Avatar",
          //   align: "center",
          //   value: "avatar",
          // },
          { text: "Enterprice Name", value: "name_ar", align: "center" },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ];
      }
    },
  },
  // ======= hooks
  created() {
    this.getData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (!userType.includes("superadmin") && !userType.includes("enterprise")) {
      this.$router.push("/");
    }
  },
};
</script>

<style></style>
