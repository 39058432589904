<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/privillages"
        search_title="الصلاحيات"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("addNew") }}</h4>
        </div>

        <!-- ==== Start Form ==== -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="name_en_input"
                    @input="checkIfInputIsEmpty"
                    v-model.trim="data.name"
                  />
                  <label for="name_en_input" class="form-label">{{
                    $t("privillages.name")
                  }}</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <!-- START:: SINGLE SELECT INPUT -->
                <div class="input_wrapper top_label">
                  <label class="form-label">{{
                    $t("privillages.privillage")
                  }}</label>
                  <multiselect
                    v-model="data.single_select_value"
                    :options="permissions_map"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                    :multiple="true"
                  >
                  </multiselect>
                </div>
                <!-- END:: SINGLE SELECT INPUT -->
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: PERMISSION WRAPPER -->
              <div class="col-lg-12 py-0">
                <!-- START:: PERMISSION SHAPE -->
                <div class="permisions">
                  <!-- --------------------- -->
                  <div
                    v-for="permission in data.single_select_value"
                    :key="permission.id"
                    class="col-lg-12 switch_part d-flex justify-content-between align-items-center"
                  >
                    <div class="form-group">
                      <h4>{{ permission.label }} :</h4>
                    </div>

                    <div class="form-group">
                      <h5>عرض :</h5>
                      <label class="switch">
                        <!-- checked -->
                        <input
                          type="checkbox"
                          name="permissions"
                          v-model="permission.is_show"
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>

                    <div class="form-group">
                      <h5>اضافة :</h5>
                      <label class="switch">
                        <input
                          type="checkbox"
                          name="permissions"
                          v-model="permission.is_create"
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>

                    <div class="form-group">
                      <h5>تعديل :</h5>
                      <label class="switch">
                        <input
                          type="checkbox"
                          name="permissions"
                          v-model="permission.is_edit"
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>

                    <div class="form-group">
                      <h5>حذف :</h5>
                      <label class="switch">
                        <input
                          type="checkbox"
                          name="permissions"
                          v-model="permission.is_destroy"
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <!-- --------------------- -->
                </div>
                <!-- END:: PERMISSION SHAPE -->
              </div>
              <!-- END:: PERMISSION WRAPPER -->
            </div>
          </div>

          <div class="buttons_wrapper">
            <!-- START:: BUTTON -->
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              {{ $t("Forms.submit") }}
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
            <!-- END:: BUTTON -->
          </div>
        </form>
        <!-- ==== End Form ==== -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.privillages.title"),
          disabled: false,
          href: "/privillages",
        },
        {
          text: this.$t("breadcrumb.privillages.add"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // START:: SINGLE SELECT DATA
      permissions: [
        {
          name: "الصلاحيات",
          route_name: "/allPermission",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "المديرين",
          route_name: "/allManagers",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
      ],
      // END:: SINGLE SELECT DATA

      // search_users
      search_users: "",

      // ========== create_data
      data: {
        name: null,
        single_select_value: "",
      },

      // ========== Fixed_data
      safes: [
        {id: 1, name: "safe 1"},
        {id: 1, name: "safe 2"},
      ],
      currencies: [
        {id: 1, name: "currency 1"},
        {id: 1, name: "currency 2"},
      ],
      types: ["type 1", "type 2"],
      users: [],
    };
  },

  watch: {
    [`search_users`](newVal) {
      this.get_filterd_users(newVal);
    },
  },

  methods: {
    // Get Data
    getData() {
      this.$axios({
        method: "GET",
        url: `categories`,
      }).then((res) => {
        this.categories = res.data.data;
      });
    },

    // get_filterd_users
    get_filterd_users(newVal) {
      if (newVal == "o") {
        this.users = ["omar"];
      } else {
        this.users = ["omar", "momen", "zoheir", "mahmoud", "essam", "diaa"];
      }
    },

    // Uplode Image
    uplodefiles(data) {
      this.data.files = data;
    },

    // START:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)
    checkIfInputIsEmpty(e) {
      let inputElement = e.currentTarget;
      if (inputElement.value.length > 0) {
        inputElement.classList.add("not_empty");
      } else {
        inputElement.classList.remove("not_empty");
      }
    },
    // END:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)

    // START:: VALIDATE CREATE FORM
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.name_ar) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("privillages.validation.name_ar"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.name_en) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("privillages.validation.name_en"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.single_select_value) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("privillages.validation.privillage"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("global.addSuccess"),
            position: "topRight",
          });
          this.$router.push({path: "/allPrivillages"});
          this.btnIsLoading = false;
          return;
        }
      }
    },
    // END:: VALIDATE CREATE FORM

    // Submit Data
    submitData() {
      const submit_data = new FormData();
      submit_data.append("teacher_slider", this.data.image.img_file);

      this.$axios({
        method: "POST",
        url: "slider",
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "topRight",
          });
          this.$router.push({path: "/privillages"});
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
  },
  computed: {
    permissions_map() {
      return this.permissions.map((item) => ({
        label: item.name,
        value: item.route_name,
        name: item.name,
        route_name: item.route_name,
        is_show: false,
        is_create: false,
        is_edit: false,
        is_destroy: false,
      }));
    },
  },

  created() {
    // this.getData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>

<style scoped>
/* ============================== */
.switch_part label.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
  margin: 0 10px;
}
.switch_part .switch input {
  opacity: 0;
  width: 1px;
  height: 1px;
}
.switch_part .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch_part .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}
.switch_part input:checked + .slider {
  background-color: #5b625e;
}
.switch_part input:focus + .slider {
  box-shadow: 0 0 1px #5b625e;
}
.switch_part input:checked + .slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}
/* Rounded sliders */
.switch_part .slider.round {
  border-radius: 34px;
}
.switch_part .slider.round:before {
  border-radius: 50%;
}
.switch_part .form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.switch_part h5 {
  font-size: 13px;
  font-weight: bold;
  margin: 0;
}
.switch_part .form-group h5 {
  font-size: 13px;
  font-weight: bold;
  margin: 0;
}
.switch_part .form-group h4 {
  font-size: 13px;
  font-weight: bold;
  width: 140px;
}
</style>
