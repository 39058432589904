<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb :items="items" />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">إضافة تسليمات</h4>
        </div>

        <!-- START:: CREATE FORM WRAPPER -->
        <form @submit.prevent="validateForm">
          <div class="container">
            <div class="row justify-content-between">
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label">إسم المستلم</label>
                  <v-autocomplete
                    v-model="data.receiverName"
                    :search-input.sync="search_users"
                    :items="users"
                    item-text="name"
                    item-value="id"
                    dense
                  ></v-autocomplete>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="tel"
                    class="form-control"
                    v-model.trim="phone"
                    readonly
                  />
                  <label for="name_input" class="form-label"
                    >رقم هاتف المستلم</label
                  >
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label">القائم بالتسليم</label>
                  <v-autocomplete
                    v-model="data.deliverer"
                    :search-input.sync="search_deliverers"
                    :items="deliverers"
                    item-text="name"
                    item-value="id"
                    dense
                  ></v-autocomplete>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.amount"
                  />
                  <label for="name_input" class="form-label">المبلغ</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0" v-if="currenciesValues">
                <div class="input_wrapper top_label">
                  <label class="form-label"> العملة </label>
                  <multiselect
                    v-model="data.currency"
                    :options="currenciesValues"
                    label="name_ar"
                    track-by="name_ar"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0" v-if="safes">
                <div class="input_wrapper top_label mb-3">
                  <label class="form-label"> الخزنة </label>
                  <multiselect
                    v-model="data.safe"
                    :options="safes"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-12 py-0">
                <div class="input_wrapper top_label date_input">
                  <label class="form-label">{{
                    $t("forms.labels.date")
                  }}</label>
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        v-model="data.date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="data.date"
                      @input="dateMenu = false"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label"> وصف </label>
                  <textarea
                    v-on:keydown.enter.prevent="validateCreateForm"
                    class="form-control"
                    rows="5"
                    v-model.trim="data.description"
                  ></textarea>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label"> ملاحظات </label>
                  <textarea
                    v-on:keydown.enter.prevent="validateCreateForm"
                    class="form-control"
                    rows="5"
                    v-model.trim="data.notes"
                  ></textarea>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              {{ $t("Forms.submit") }}
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- END:: CREATE FORM WRAPPER -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddExpenses",

  data() {
    return {
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.deliverables.title"),
          disabled: false,
          href: "/deliverables",
        },
        {
          text: this.$t("breadcrumb.deliverables.add"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========= Date Menu
      dateMenu: false,

      // search_users
      search_users: "",

      // search_deliverers
      search_deliverers: "",

      // ========== create_data
      data: {
        receiverName: null,
        phoneNumber: null,
        deliverer: null,
        amount: null,
        currency: {},
        safe: {},
        date: null,
        description: null,
        notes: null,
      },

      // ========== Fixed Data
      safes: null,
      currenciesValues: null,
      users: [],
      deliverers: [],
      // ========== Fixed_data
    };
  },

  watch: {
    [`search_users`](newVal) {
      this.get_filterd_users(newVal);
    },
    [`search_deliverers`](newVal) {
      this.get_filterd_deliverers(newVal);
    },
  },

  computed: {
    phone() {
      if (this.data.receiverName) {
        let selectedUser = this.users.find(
          (item) => item.id == this.data.receiverName
        );
        return selectedUser.phone;
      } else {
        return null;
      }
    },
  },

  methods: {
    // START:: GET DATA
    getSafes() {
      // START:: GET SAFES
      this.$axios({
        method: "GET",
        url: `client/wallet`,
      })
        .then((res) => {
          // console.log("Safes ==>", res.data.data);
          this.safes = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
      // END:: GET SAFES
    },

    getCurrencies() {
      // START:: GET SAFES
      this.$axios({
        method: "GET",
        url: `client/currency`,
      })
        .then((res) => {
          this.currenciesValues = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
      // END:: GET SAFES
    },
    // END:: GET DATA

    // get_filterd_users
    get_filterd_users(keyword) {
      this.$axios({
        method: "GET",
        url: `client/customer`,
        params: {keyword: keyword},
      }).then((res) => {
        this.users = res.data.data;
      });
    },

    // get_filterd_deliverers
    get_filterd_deliverers(keyword) {
      this.$axios({
        method: "GET",
        url: `client/user`,
        params: {keyword: keyword},
      }).then((res) => {
        this.deliverers = res.data.data;
      });
    },

    // START:: VALIDATE CREATE FORM
    validateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.receiverName) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب تحديد إسم المستلم",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.deliverer) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب تحديد القائم بالتسليم",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال المبلغ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (Object.keys(this.data.currency).length == 0) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إختيار العملة ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (Object.keys(this.data.safe).length == 0) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إختيار خزنة ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.date) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إختيار تاريخ العملية ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.description) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال وصف",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.submitForm();
        }
      }
    },
    // END:: VALIDATE CREATE FORM
    emptyFormData() {
      this.data.receiverName = null;
      this.data.deliverer = null;
      this.data.amount = null;
      this.getSafes();
      this.data.safe = null;
      this.data.currency = null;
      this.data.date = new Date().toISOString().slice(0, 10);
      this.data.description = null;
      this.data.notes = null;
    },
    // START:: SUBMIT FORM
    submitForm() {
      const submit_data = new FormData();

      // START:: APPEND REQUEST DATA
      submit_data.append("customer_id", this.data.receiverName);
      submit_data.append("employee_id", this.data.deliverer);
      submit_data.append("amount", this.data.amount);
      submit_data.append("wallet_id", this.data.safe.id);
      submit_data.append("currency_id", this.data.currency.id);
      submit_data.append("date", this.data.date);
      submit_data.append("desc", this.data.description);
      if (this.data.notes) {
        submit_data.append("notes", this.data.notes);
      }
      // END:: APPEND REQUEST DATA

      // START:: SEND REQUEST
      this.$axios({
        method: "POST",
        url: `client/delivery`,
        data: submit_data,
      })
        .then((res) => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "topRight",
          });
          this.emptyFormData();
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
      // END:: SEND REQUEST
    },
    // END:: SUBMIT FORM
  },

  mounted() {
    this.getSafes();
    this.getCurrencies();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>
