<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_title="عمليات نقل أرصدة الخزن"
        search_route="/locks/transfer-balances"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">تعديل عملية نقل رصيد</h4>
        </div>

        <!-- START:: CREATE FORM WRAPPER -->
        <form @submit.prevent="validateForm">
          <div class="container">
            <div class="row justify-content-between">
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="data.amount"
                  />
                  <label for="name_input" class="form-label">المبلغ</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0" v-if="currencies">
                <div class="input_wrapper top_label">
                  <label class="form-label"> العملة </label>
                  <multiselect
                    v-model="data.currency"
                    :options="currencies"
                    label="name_ar"
                    track-by="name_ar"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label mb-3">
                  <label class="form-label"> الخزنة المسحوب منها </label>
                  <multiselect
                    v-model="data.withdrawnFromLock"
                    :options="safes"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label mb-3">
                  <label class="form-label"> الخزنة المسحوب إليها </label>
                  <multiselect
                    v-model="data.withdrawnToLock"
                    :options="safes"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-12 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label"> وصف </label>
                  <textarea
                    class="form-control"
                    rows="5"
                    v-model.trim="data.description"
                  ></textarea>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              تعديل
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- END:: CREATE FORM WRAPPER -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddTransferBalance",

  props: ["id"],

  data() {
    return {
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "نقل الأرصدة",
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========= Date Menu
      dateMenu: false,

      // ========== create_data
      data: {
        amount: null,
        currency: {},
        withdrawnFromLock: {},
        withdrawnToLock: {},
        description: null,
      },

      safes: [],
      currencies: [],
      // ========== Fixed Data
    };
  },

  methods: {
    // START:: GET SAFES
    getSafes() {
      this.$axios({
        method: "GET",
        url: `client/wallet`,
      })
        .then((res) => {
          this.safes = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    // END:: GET SAFES

    // START:: GET CURRENCIES
    getCurrencies() {
      this.$axios({
        method: "GET",
        url: `client/currency`,
      })
        .then((res) => {
          this.currencies = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    // END:: GET CURRENCIES

    getData() {
      this.$axios({
        method: "GET",
        url: `client/transferwallet/${this.id}`,
      }).then((res) => {
        this.data.amount = res.data.data.amount;
        this.data.currency = res.data.data.currency;
        this.data.withdrawnFromLock = res.data.data.from_wallet;
        this.data.withdrawnToLock = res.data.data.to_wallet;
        this.data.description = res.data.data.desc;
      });
    },

    submitForm() {
      let submit_data = new FormData();
      submit_data.append("from_wallet_id", this.data.withdrawnFromLock.id);
      submit_data.append("to_wallet_id", this.data.withdrawnToLock.id);
      submit_data.append("currency_id", this.data.currency.id);
      submit_data.append("amount", +this.data.amount);
      submit_data.append("desc", this.data.description);
      submit_data.append("_method", "PUT"); // Static

      this.$axios({
        method: "POST",
        url: `client/transferwallet/${this.id}`,
        data: submit_data,
      })
        .then((res) => {
          this.btnIsLoading = false;
          this.$iziToast.success({
            timeout: 2000,
            message: "تمت العملية بنجاح",
            position: "topRight",
          });
        })
        .catch((err) => {
          this.btnIsLoading = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },

    // START:: VALIDATE CREATE FORM

    validateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال المبلغ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (Object.keys(this.data.currency).length == 0) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إختيار العملة ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (Object.keys(this.data.withdrawnFromLock).length == 0) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب اختيار الخزنة المسحوب منها",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (Object.keys(this.data.withdrawnToLock).length == 0) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب اختيار الخزنة المسحوب اليها",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (Object.keys(this.data.description).length == 0) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب ادخال وصف",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          // START:: SEND REQUEST
          this.submitForm();
          // END:: SEND REQUEST
        }
      }
    },
    // END:: VALIDATE CREATE FORM
  },
  created() {
    this.getSafes();
    this.getCurrencies();
    this.getData();

    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      userType.includes("confirm_lock_and_deals") &&
      !userType.includes("lock_and_deals")
    ) {
      this.$router.push("/locks/transfer-balances");
    } else if (
      !userType.includes("superadmin") &&
      !userType.includes("lock_and_deals")
    ) {
      this.$router.push("/");
    }
  },
};
</script>
