<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="" v-else>
      <div class="wrraper enterprice_account" v-if="allData">
        <div class="row">
          <div class="col-md-12">
            <div class="show_title">
              <span>بيانات المؤسسة</span>
            </div>
          </div>
          <!-- ------------------STATRT OF STATES--------------------------- -->
          <div class="col-md-3">
            <stats-card
              :icon="'fal fa-pencil'"
              :title="'اسم المؤسسة'"
              :number="allData.enterprise.name_ar"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>
          <div class="col-md-3">
            <stats-card
              :icon="'fal fa-user-tie'"
              :title="'اسم صحاب المؤسسة'"
              :number="allData.enterprise.owner_name"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
              :routeLink="allData.enterprise.owner_id"
            ></stats-card>
          </div>
          <div class="col-md-3" v-if="allData.head_letter">
            <stats-card
              :icon="'fal fa-file-alt'"
              :title="'صورة كارت الصراف'"
              :number="'صورة كارت الصراف'"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
              :link="allData.head_letter"
            ></stats-card>
          </div>
          <div class="col-md-3">
            <stats-card
              :icon="'fal fa-dollar-sign '"
              :title="'الرصيد'"
              :number="Number(allData.amount).toLocaleString() + ' ريال '"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-university'"
              :title="'اسم البنك'"
              :number="allData.bank_name"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-sort-numeric-up-alt'"
              :title="'A/C NO'"
              :number="allData.bank_account_number"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-chart-line'"
              :title="'حالة الحساب'"
              :number="allData.active == 0 ? 'غير مفعل' : 'مفعل'"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>

          <!-- ------------------END OF STATES--------------------------- -->

          <!-- ------------------START OF QUESTION--------------------------- -->
          <div class="col-md-12">
            <div class="show_title">
              <span>هل تم تقديم الطلب لفتح حساب؟</span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="show_answer">
              <div>
                <span v-if="allData.is_account_opened.status == 1"> نعم </span>
                <span v-if="allData.is_account_opened.status == 0"> لا </span>
              </div>
              <div>
                <button
                  class="btn"
                  type="button"
                  @click="addComment('is_account_opened')"
                >
                  إضافة تعليق
                </button>
                <button
                  class="btn mr-6"
                  type="button"
                  @click="show_model('open_account')"
                >
                  {{ $t("enterpriceAccount.showComments") }}
                </button>
              </div>
            </div>
          </div>
          <!-- ------------------END OF QUESTION--------------------------- -->
          <!-- ------------------START OF QUESTION--------------------------- -->
          <div class="col-md-12">
            <div class="show_title">
              <span>هل تم تقديم الطلب اونلاين؟</span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="show_answer">
              <div>
                <span v-if="allData.is_online_request.status == 1"> نعم </span>
                <span v-if="allData.is_online_request.status == 0"> لا </span>
              </div>
              <div>
                <button
                  class="btn"
                  type="button"
                  @click="addComment('is_online_request')"
                >
                  إضافة تعليق
                </button>
                <button
                  class="btn mr-6"
                  type="button"
                  @click="show_model('online_account')"
                >
                  {{ $t("enterpriceAccount.showComments") }}
                </button>
              </div>
            </div>
          </div>
          <!-- ------------------END OF QUESTION--------------------------- -->
          <!-- ------------------START OF QUESTION--------------------------- -->
          <div class="col-md-12">
            <div class="show_title">
              <span>هل تم تقديم طلب إضافة صرافه ؟ </span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="show_answer">
              <div>
                <span
                  v-if="
                    allData.is_request_atm.status == 1 &&
                    allData.is_atm_opened == 0
                  "
                  >نعم و بإنتظار إضافة الصرافة</span
                >
                <span v-if="allData.is_request_atm.status == 0">لا</span>
                <span v-if="allData.is_atm_opened == 1">
                  نعم و تم إضافة صرافة بنجاح
                </span>
              </div>

              <div>
                <button class="btn ml-6" type="button" @click="atmForm = true">
                  إضافة صراف
                </button>
                <button
                  class="btn"
                  type="button"
                  @click="addComment('is_request_atm')"
                >
                  إضافة تعليق
                </button>
                <button
                  class="btn mr-6"
                  type="button"
                  @click="show_model('open_atm')"
                >
                  {{ $t("enterpriceAccount.showComments") }}
                </button>
              </div>
            </div>
          </div>
          <!-- ------------------END OF QUESTION--------------------------- -->
          <!-- ------------------START OF الصرافات TABLE--------------------------- -->
          <div class="col-md-12" v-if="allData.is_request_atm.status != 0">
            <div class="show_title">
              <span>الصرافات</span>
            </div>
          </div>
          <div class="col-md-12" v-if="allData.is_request_atm.status != 0">
            <div class="create_wrapper">
              <main class="main-table">
                <v-data-table
                  class="elevation-1 thumb"
                  :headers="headers"
                  :items="rows"
                  :search="search"
                  :custom-filter="filterSearch"
                  :loading="lodaing"
                  :loading-text="$t('table.loadingData')"
                  v-model="selected"
                  :single-select="singleSelect"
                  :items-per-page="rows.length"
                  item-key="id"
                  hide-default-footer
                >
                  <template v-slot:[`item.index`]="{index}">
                    {{ index + 1 }}
                  </template>
                  <!-- Select header field -->
                  <template v-slot:[`header.desc`]="{header}">
                    {{ header.text + " ..." }}
                  </template>
                  <!-- title -->
                  <template v-slot:[`item.file`]="{item}">
                    <a :href="item.file" target="_blank">
                      <i class="fas fa-eye"></i>
                    </a>
                  </template>
                  <template v-slot:[`item.status`]="{item}">
                    <v-chip color="green" v-if="item.status == 1">
                      مفعل
                    </v-chip>
                    <v-chip color="red" v-if="item.status == 0"> منتهي </v-chip>
                  </template>
                  <!-- descreption -->
                  <template v-slot:[`item.desc`]="{item}">
                    {{ item.desc.slice(0, 40) + "..." }}
                  </template>

                  <!-- avatar -->
                  <template v-slot:[`item.avatar`]="{item}">
                    <img
                      @click="show_model_1"
                      class="image"
                      :src="item.avatar"
                    />
                  </template>

                  <!-- Select no data State -->
                  <template v-slot:no-data>
                    {{ $t("table.noData") }}
                  </template>

                  <!-- Select actions-->
                  <template v-slot:[`item.actions`]="{item}">
                    <div class="_actions">
                      <v-icon class="delete" small @click="deleteItem(item)">
                        fal fa-trash
                      </v-icon>
                    </div>
                  </template>
                  <template v-slot:top>
                    <!-- Delete dialog -->
                    <v-dialog
                      v-model="dialogDelete"
                      @click:outside="resetInputs"
                      max-width="500px"
                    >
                      <v-card>
                        <v-card-title class="text-h5 justify-center">{{
                          $t("table.deletedialog.areYouSure")
                        }}</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="#1B5E20" @click="deleteItemConfirm"
                            >{{ $t("table.deletedialog.ok") }}
                            <span
                              class="btn_loader_model"
                              v-if="loadingBtnDialog"
                            ></span
                          ></v-btn>
                          <v-btn
                            color="#F44336"
                            @click="dialogDelete = false"
                            >{{ $t("table.deletedialog.cancel") }}</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </v-data-table>
              </main>
              <!-- End Main Section -->
            </div>
          </div>
          <!-- ------------------END OF الصرافات TABLE--------------------------- -->
          <!-- ------------------START OF QUESTION--------------------------- -->
          <div class="col-md-12">
            <div class="show_title">
              <span>هل تم تسليم الصرافة للمندوب ؟</span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="show_answer">
              <div>
                <span v-if="allData.is_atm_delivered.status == 1"> نعم </span>
                <span v-if="allData.is_atm_delivered.status == 0"> لا </span>
              </div>
              <div>
                <button
                  class="btn"
                  type="button"
                  @click="addComment('is_atm_delivered')"
                >
                  إضافة تعليق
                </button>
                <button
                  class="btn mr-6"
                  type="button"
                  @click="show_model('is_atm_delivered')"
                >
                  {{ $t("enterpriceAccount.showComments") }}
                </button>
              </div>
            </div>
          </div>
          <!-- ------------------END OF QUESTION--------------------------- -->
        </div>
        <!-- ------------------start of show all comments--------------------------- -->
        <base-model
          @closeModel="model.show_model = false"
          :show="model.show_model"
        >
          <div class="comments">
            <h2 class="mb-7 mt-3">{{ dialog_text }}</h2>
            <div v-if="dialog_comments.length != 0">
              <div
                class="comment_card"
                v-for="comment in dialog_comments"
                :key="comment.id"
              >
                <p>
                  {{ comment.comment }}
                </p>
                <span>{{ comment.created_at }}</span>
              </div>
            </div>
          </div>
        </base-model>
        <!-- --------------------end of show all comments------------------------- -->
        <!-- ------------------start of add comments--------------------------- -->

        <v-dialog
          v-model="showAddComment"
          @click:outside="resetInputs"
          max-width="800px"
        >
          <div class="custom_card mb-0">
            <h4 class="mt-5 mb-4 text-center">إضافة تعليق</h4>
            <!-- START:: CREATE FORM WRAPPER -->
            <form @submit.prevent="submitComment">
              <div class="container">
                <div class="row justify-content-between">
                  <!-- START:: INPUT WRAPPER -->
                  <div class="col-lg-12 py-0">
                    <div class="input_wrapper top_label">
                      <label for="textarea_1" class="form-label">
                        تفاصيل التعليق</label
                      >
                      <textarea
                        v-on:keydown.enter.prevent="submitComment"
                        class="form-control"
                        id="textarea_1"
                        rows="5"
                        v-model="commentDetails"
                      ></textarea>
                    </div>
                  </div>
                  <!-- END:: INPUT WRAPPER -->
                </div>
              </div>

              <div class="buttons_wrapper">
                <button
                  class="button_style_1"
                  :class="{buttonIndex: btnIsLoading}"
                  :disabled="btnIsLoading"
                >
                  إضافة
                  <span class="btn_loader" v-if="btnIsLoading"></span>
                </button>
              </div>
            </form>
            <!-- END:: CREATE FORM Comment -->
          </div>
        </v-dialog>
        <!-- ----------------------end of add comments----------------------- -->
        <!-- ----------------------start of add receipt----------------------- -->
        <v-dialog
          v-model="receipt"
          @click:outside="resetInputs"
          max-width="800px"
        >
          <div class="custom_card mb-0">
            <h4 class="mb-0 mt-3 text-center">إضافة إيصال</h4>
            <form @submit.prevent="addReceipt">
              <div class="container">
                <div class="row justify-content-between">
                  <div class="col-lg-12 py-0">
                    <div class="input_wrapper top_label file_input">
                      <span class="file_input_label">صورة الإيصال</span>
                      <label
                        for="file_input_1"
                        class="form-label"
                        v-if="!selectedFile.file"
                      ></label>
                      <label for="file_input_1" class="form-label" v-else>
                        {{ selectedFile.name }}
                      </label>
                      <input
                        type="file"
                        class="form-control"
                        id="file_input_1"
                        @change="handelSelectedFile"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12 py-0">
                    <div class="input_wrapper top_label">
                      <input
                        type="text"
                        class="form-control"
                        v-model.trim="data.value"
                      />
                      <label for="name_input2" class="form-label">القيمة</label>
                    </div>
                  </div>
                  <!-- END:: INPUT WRAPPER -->
                </div>
              </div>

              <div class="buttons_wrapper">
                <button
                  class="button_style_1"
                  :class="{buttonIndex: btnIsLoading}"
                  :disabled="btnIsLoading"
                >
                  {{ $t("Forms.submit") }}
                  <span class="btn_loader" v-if="btnIsLoading"></span>
                </button>
              </div>
            </form>
            <!-- END:: CREATE FORM WRAPPER -->
          </div>
        </v-dialog>
        <!-- ----------------------end of add receipt----------------------- -->
        <!-- Start Add Withdraw -->

        <v-dialog
          v-model="showAddMoney"
          @click:outside="resetInputs"
          max-width="800px"
        >
          <div class="custom_card mb-0">
            <h4 class="mt-3 text-center mb-0">إضافة رصيد</h4>
            <!-- START:: CREATE FORM WRAPPER -->
            <form @submit.prevent="submitMoney">
              <div class="container">
                <div class="row justify-content-between">
                  <!-- START:: INPUT WRAPPER -->
                  <div class="col-lg-12 py-0">
                    <div class="input_wrapper top_label">
                      <input
                        type="text"
                        class="form-control"
                        v-model.trim="data.amount"
                      />
                      <label for="name_input" class="form-label">المبلغ</label>
                    </div>
                  </div>
                  <!-- END:: INPUT WRAPPER -->

                  <!-- START:: INPUT WRAPPER -->
                  <div class="col-lg-12 py-0">
                    <div class="input_wrapper top_label">
                      <label for="textarea_1" class="form-label"
                        >التفاصيل</label
                      >
                      <textarea
                        v-on:keydown.enter.prevent="submitMoney"
                        class="form-control"
                        id="textarea_1"
                        rows="5"
                        v-model="data.details"
                      ></textarea>
                    </div>
                  </div>
                  <!-- END:: INPUT WRAPPER -->
                </div>
              </div>

              <div class="buttons_wrapper">
                <button
                  class="button_style_1"
                  :class="{buttonIndex: btnIsLoading}"
                  :disabled="btnIsLoading"
                >
                  {{ $t("Forms.submit") }}
                  <span class="btn_loader" v-if="btnIsLoading"></span>
                </button>
              </div>
            </form>
            <!-- END:: CREATE FORM WRAPPER -->
          </div>
        </v-dialog>
        <!-- End Add Withdraw -->
        <!-- Start Add Atm -->

        <v-dialog
          v-model="atmForm"
          @click:outside="resetInputs"
          max-width="800px"
        >
          <div class="custom_card mb-0">
            <h4 class="mb-0 mt-3 text-center">إضافة صراف</h4>
            <!-- START:: CREATE FORM WRAPPER -->
            <form @submit.prevent="addAtm">
              <div class="container">
                <div class="row justify-content-between">
                  <!-- START:: INPUT WRAPPER -->
                  <div class="col-lg-12 py-0">
                    <div class="input_wrapper top_label file_input">
                      <span class="file_input_label">{{
                        $t("enterpriceAccount.data.atmFile")
                      }}</span>
                      <label
                        for="file_input_1"
                        class="form-label"
                        v-if="!selectedFile.name"
                      ></label>
                      <label for="file_input_1" class="form-label" v-else>
                        {{ selectedFile.name }}
                      </label>
                      <input
                        type="file"
                        class="form-control"
                        id="file_input_1"
                        @change="handelSelectedFile"
                      />
                    </div>
                  </div>
                  <!-- END:: INPUT WRAPPER -->

                  <!-- START:: INPUT WRAPPER -->
                  <div class="col-lg-12 py-0">
                    <div class="input_wrapper top_label">
                      <input
                        type="text"
                        class="form-control"
                        v-model.trim="data.atmNum"
                        autocomplete="false"
                      />
                      <label for="name_input" class="form-label">{{
                        $t("enterpriceAccount.data.atmNum")
                      }}</label>
                    </div>
                  </div>
                  <!-- END:: INPUT WRAPPER -->

                  <!-- START:: INPUT WRAPPER -->
                  <div class="col-lg-12 py-0">
                    <div class="input_wrapper top_label">
                      <label for="password_input_1" class="form-label">{{
                        $t("enterpriceAccount.data.password")
                      }}</label>
                      <input
                        type="password"
                        class="form-control"
                        id="password_input_1"
                        v-model="data.password"
                        autocomplete="false"
                      />
                      <button
                        type="button"
                        class="toggle_pass_btn"
                        @click="togglePasswordVisibility"
                      >
                        <i class="fal fa-eye open_eye"></i>
                        <i class="fal fa-eye-slash closed_eye"></i>
                      </button>
                    </div>
                  </div>
                  <!-- END:: INPUT WRAPPER -->

                  <!-- START:: INPUT WRAPPER -->
                  <div class="col-lg-12 py-0">
                    <div class="input_wrapper radio_button_input">
                      <div class="flex_wrapper">
                        <v-radio-group v-model="data.status">
                          <v-radio
                            :label="$t('enterpriceAccount.data.active')"
                            color="indigo lighten-2"
                            value="1"
                          ></v-radio>
                          <v-radio
                            :label="$t('enterpriceAccount.data.expired')"
                            color="indigo lighten-2"
                            value="0"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </div>
                  </div>
                  <!-- END:: INPUT WRAPPER -->
                </div>
              </div>

              <div class="buttons_wrapper">
                <button
                  class="button_style_1"
                  :class="{buttonIndex: btnIsLoading}"
                  :disabled="btnIsLoading"
                >
                  {{ $t("Forms.submit") }}
                  <span class="btn_loader" v-if="btnIsLoading"></span>
                </button>
              </div>
            </form>
            <!-- END:: CREATE FORM WRAPPER -->
          </div>
        </v-dialog>
        <!-- End Add Atm -->
        <!-- Start edit Atm -->

        <v-dialog
          v-model="showEditAtm"
          @click:outside="resetInputs"
          max-width="800px"
        >
          <div class="custom_card mb-0">
            <h4 class="mb-0 mt-3 text-center">تعديل صراف</h4>
            <!-- START:: CREATE FORM WRAPPER -->
            <form @submit.prevent="editAtmSubmit">
              <div class="container">
                <div class="row justify-content-between">
                  <!-- START:: INPUT WRAPPER -->
                  <div class="col-lg-12 py-0">
                    <div class="input_wrapper top_label file_input">
                      <span class="file_input_label">{{
                        $t("enterpriceAccount.data.atmFile")
                      }}</span>
                      <label
                        for="file_input_1"
                        class="form-label"
                        v-if="!selectedFile.name"
                      ></label>
                      <label for="file_input_1" class="form-label" v-else>
                        {{ selectedFile.name }}
                      </label>
                      <input
                        type="file"
                        class="form-control"
                        id="file_input_1"
                        @change="handelSelectedFile"
                      />
                    </div>
                  </div>
                  <!-- END:: INPUT WRAPPER -->

                  <!-- START:: INPUT WRAPPER -->
                  <div class="col-lg-12 py-0">
                    <div class="input_wrapper top_label">
                      <input
                        type="text"
                        class="form-control"
                        v-model.trim="data.atmNum"
                        autocomplete="false"
                      />
                      <label for="name_input" class="form-label">{{
                        $t("enterpriceAccount.data.atmNum")
                      }}</label>
                    </div>
                  </div>
                  <!-- END:: INPUT WRAPPER -->

                  <!-- START:: INPUT WRAPPER -->
                  <div class="col-lg-12 py-0">
                    <div class="input_wrapper top_label">
                      <label for="password_input_1" class="form-label">{{
                        $t("enterpriceAccount.data.password")
                      }}</label>
                      <input
                        type="password"
                        class="form-control"
                        id="password_input_1"
                        v-model="data.password"
                        autocomplete="false"
                      />
                      <button
                        type="button"
                        class="toggle_pass_btn"
                        @click="togglePasswordVisibility"
                      >
                        <i class="fal fa-eye open_eye"></i>
                        <i class="fal fa-eye-slash closed_eye"></i>
                      </button>
                    </div>
                  </div>
                  <!-- END:: INPUT WRAPPER -->

                  <!-- START:: INPUT WRAPPER -->
                  <div class="col-lg-12 py-0">
                    <div class="input_wrapper radio_button_input">
                      <div class="flex_wrapper">
                        <v-radio-group v-model="data.status">
                          <v-radio
                            :label="$t('enterpriceAccount.data.active')"
                            color="indigo lighten-2"
                            value="1"
                          ></v-radio>
                          <v-radio
                            :label="$t('enterpriceAccount.data.expired')"
                            color="indigo lighten-2"
                            value="0"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </div>
                  </div>
                  <!-- END:: INPUT WRAPPER -->
                </div>
              </div>

              <div class="buttons_wrapper">
                <button
                  class="button_style_1"
                  :class="{buttonIndex: btnIsLoading}"
                  :disabled="btnIsLoading"
                >
                  {{ $t("Forms.submit") }}
                  <span class="btn_loader" v-if="btnIsLoading"></span>
                </button>
              </div>
            </form>
            <!-- END:: CREATE FORM WRAPPER -->
          </div>
        </v-dialog>
        <!-- End edit Atm -->
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
export default {
  props: ["id"],
  components: {
    StatsCard,
  },
  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      loading: true,
      // ========== allData
      allData: "",
      rows: [], // injected in created
      rows2: [], // injected in created
      data: {
        value: null,
        avatar: null,
        name: null,
        taxNum: null,
        amount: null,
        details: null,
        atmNum: null,
        password: null,
        status: null,
        atmItemId: null,
      },
      // START:: SELECTED FILE DATA
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },
      // END:: SELECTED FILE DATA
      // ========== start of Model
      model: {
        show_model: false,
        model_img_src: "",
      },
      model_1: {
        show_model: false,
      },
      model_2: {
        show_model: false,
      },
      // ========== end of Model
      // ========== start of search
      search: "",
      search2: "",
      // ========== end of search
      // ========== start of dialog
      dialog_text: "",
      dialog_comments: "",
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,
      // ========== end of dialog
      // ========== Btn Laoding
      btnIsLoading: false,
      showAddComment: false,
      commentDetails: "",
      commentType: "",
      // ========== Loding
      lodaing: false,
      // ========== :: RECEIPT
      receipt: false,
      // ========== :: selected
      selected: [],
      singleSelect: false,
      // ========== pagination
      paginations: {
        current_page: 1,
        last_page: 2,
      },
      // =========== money
      showAddMoney: null,
      // START:: ATM STATUS
      atmForm: false,
      showEditAtm: false,
      // END:: ATM STATUS
      showEditItem: false,
    };
  },
  methods: {
    addComment(type) {
      this.showAddComment = true;
      this.commentType = type;
    },
    submitComment() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.commentDetails) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة تعليق",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          const submit_data = new FormData();
          submit_data.append("account_id", this.$route.params.id);
          submit_data.append("type", this.commentType);
          submit_data.append("comment", this.commentDetails);
          this.$axios({
            method: "post",
            url: "client/account_comment",
            data: submit_data,
          })
            .then(() => {
              this.$iziToast.success({
                timeout: 2000,
                message: "تمت الاضافة بنجاح",
                position: "topRight",
              });
              this.getData();
              this.btnIsLoading = false;
              this.showAddComment = false;
              this.commentDetails = "";
            })
            .catch((err) => {
              this.btnIsLoading = false;
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "topRight",
              });
            });
          return;
        }
      }
    },
    // Model
    show_model(type) {
      this.model.show_model = true;
      if (type == "open_account") {
        this.dialog_text = "التعليقات الخاصة بطلب فتح حساب";
        this.dialog_comments = this.allData.is_account_opened.comments;
      } else if (type == "online_account") {
        this.dialog_text = "التعليقات الخاصة بطلب اون لاين";
        this.dialog_comments = this.allData.is_online_request.comments;
      } else if (type == "open_atm") {
        this.dialog_text = "التعليقات الخاصة بطلب إضافة صرافة";
        this.dialog_comments = this.allData.is_request_atm.comments;
      } else if (type == "deposit") {
        this.dialog_text = "التعليقات الخاصة بإضافة رصيد";
        this.dialog_comments = this.allData.credits_comments;
      } else if (type == "is_atm_delivered") {
        this.dialog_text = "التعليقات الخاصة بتسليم الصرافة للمندوب";
        this.dialog_comments = this.allData.is_atm_delivered.comments;
      }
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/atm/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("CRUD.Delete"),
            position: "topRight",
          });
          this.getData();
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
      // Submit the delete service then submit this
    },
    //Get Data
    getData() {
      this.$axios.get("client/account/" + this.id).then((res) => {
        this.allData = res.data.data;
        this.rows2 = res.data.data.deposits;
        this.setRows();
      });
    },
    //Get Data

    // START:: TOGGLE PASSWORD VISIBILITY METHOD
    togglePasswordVisibility(e) {
      let passwordElement = e.currentTarget.parentElement.children[1];
      let innerPasswordElement = e.currentTarget.parentElement.children[0];
      let passwordTogglerBtn = e.currentTarget;
      if (
        passwordElement.type == "password" ||
        innerPasswordElement.type == "password"
      ) {
        passwordElement.type = "text";
        innerPasswordElement.type = "text";
        passwordTogglerBtn.classList.add("password_is_visible");
      } else if (
        passwordElement.type == "text" ||
        innerPasswordElement.type == "text"
      ) {
        passwordElement.type = "password";
        innerPasswordElement.type = "password";
        passwordTogglerBtn.classList.remove("password_is_visible");
      }
    },
    // END:: TOGGLE PASSWORD VISIBILITY METHOD

    // START:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)
    checkIfInputIsEmpty(e) {
      let inputElement = e.currentTarget;
      if (inputElement.value.length > 0) {
        inputElement.classList.add("not_empty");
      } else {
        inputElement.classList.remove("not_empty");
      }
    },
    // END:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)

    // START:: HANDLING SELECTED FILE METHOD
    handelSelectedFile(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD
    addReceipt() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.selectedFile.file) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة صورة الإيصال",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.value) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة قيمة الإيصال",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          const requestData = new FormData();
          requestData.append("type", "deposit");
          requestData.append("value", this.data.value);
          requestData.append("account_id", this.$route.params.id);
          requestData.append("image", this.selectedFile.file);
          this.$axios({
            method: "post",
            url: "client/deposit",
            data: requestData,
          })
            .then(() => {
              this.getData();
              this.$iziToast.success({
                timeout: 2000,
                message: "تمت الاضافة بنجاح",
                position: "topRight",
              });
              this.btnIsLoading = false;
              this.receipt = false;
            })
            .catch((err) => {
              this.btnIsLoading = false;
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "topRight",
              });
            });
          return;
        }
      }
    },
    acceptOrReject(type, id) {
      this.lodaing = true;
      if (type == "accept") {
        this.$axios({
          method: "post",
          url: "client/deposit/accept_deposit/" + id,
        })
          .then(() => {
            this.getData();
            this.lodaing = false;
          })
          .catch((err) => {
            this.btnIsLoading = false;
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "topRight",
            });
            this.lodaing = false;
          });
        return;
      } else if (type == "reject") {
        this.$axios({
          method: "post",
          url: "client/deposit/refuse_deposit/" + id,
        })
          .then(() => {
            this.getData();
            this.lodaing = false;
          })
          .catch((err) => {
            this.btnIsLoading = false;
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "topRight",
            });
            this.lodaing = false;
          });
        return;
      }
    },
    // ===================== end of receipt
    filterClick(word) {
      if (!this.lodaing) {
        if (word != "all") {
          this.search = word;
        } else {
          this.search = "";
        }
        this.filterSearch();
      }
    },

    filterSearch(value, search, item) {
      return (
        value != null &&
        search != null &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },

    // START:: Uplode Avatar
    uplodeImg_1(obj) {
      this.data.avatar = obj;
    },
    // END:: Uplode Avatar

    // Model
    show_model_1() {
      this.model_1.show_model = true;
    },

    // Model
    show_model_2() {
      this.model_2.show_model = true;
    },
    // money
    setRows() {
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/atm",
        params: {page: this.paginations.current_page},
      })
        .then((res) => {
          this.paginations.last_page = res.data.meta.last_page;
          this.rows = res.data.data;
          this.lodaing = false;
        })
        .catch((err) => {
          console.log(err);
          this.lodaing = false;
        });
    },
    addMoney() {
      this.showAddMoney = true;
    },
    submitMoney() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة قيمة الرصيد",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.details) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة تفاصيل علي الرصيد",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          const requestData = new FormData();
          requestData.append("value", this.data.amount);
          requestData.append("account_id", this.$route.params.id);
          requestData.append("comment", this.data.details);
          this.$axios({
            method: "post",
            url: "client/deposit",
            data: requestData,
          })
            .then(() => {
              this.getData();
              this.$iziToast.success({
                timeout: 2000,
                message: "تمت إضافة رصيد بنجاح",
                position: "topRight",
              });
              this.btnIsLoading = false;
              this.showAddMoney = false;
            })
            .catch((err) => {
              this.btnIsLoading = false;
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "topRight",
              });
            });
          return;
        }
      }
    },
    // ============== add atm
    editAtm(item) {
      this.showEditAtm = true;
      this.$axios({
        method: "GET",
        url: "client/atm/" + item.id,
      }).then((res) => {
        this.data.atmItemId = res.data.data.id;
        this.selectedFile.name = res.data.data.file;
        this.data.atmNum = res.data.data.number;
        this.data.password = res.data.data.password;
        this.data.status = String(res.data.data.status);
      });
    },
    editAtmSubmit() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.atmNum) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة رقم الصراف",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.password) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة كلمة مرور الصراف",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.status) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إختيار حالة الصراف",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          const requestData = new FormData();
          requestData.append("account_id", this.$route.params.id);
          if (this.selectedFile.file != null) {
            requestData.append("file", this.selectedFile.file);
          }
          requestData.append("number", this.data.atmNum);
          requestData.append("password", this.data.password);
          requestData.append("status", this.data.status);
          this.$axios({
            method: "post",
            url: "client/atm/update_atm/" + this.data.atmItemId,
            data: requestData,
          })
            .then(() => {
              this.getData();
              this.$iziToast.success({
                timeout: 2000,
                message: "تم التعديل بنجاح",
                position: "topRight",
              });
              this.getData();
              this.btnIsLoading = false;
              this.showEditAtm = false;
            })
            .catch((err) => {
              this.btnIsLoading = false;
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "topRight",
              });
            });
          return;
        }
      }
    },
    addAtm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.selectedFile.file) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة ملف الصراف",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.atmNum) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة رقم الصراف",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.password) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة كلمة مرور الصراف",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.status) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إختيار حالة الصراف",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          const requestData = new FormData();
          requestData.append("account_id", this.$route.params.id);
          requestData.append("file", this.selectedFile.file);
          requestData.append("number", this.data.atmNum);
          requestData.append("password", this.data.password);
          requestData.append("status", this.data.status);
          this.$axios({
            method: "post",
            url: "client/atm",
            data: requestData,
          })
            .then(() => {
              this.getData();
              this.$iziToast.success({
                timeout: 2000,
                message: "تمت الاضافة بنجاح",
                position: "topRight",
              });
              this.getData();
              this.btnIsLoading = false;
              this.atmForm = false;
            })
            .catch((err) => {
              this.btnIsLoading = false;
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "topRight",
              });
            });
          return;
        }
      }
    },
    resetInputs() {
      this.data.value = null;
      this.data.avatar = null;
      this.data.name = null;
      this.data.taxNum = null;
      this.data.amount = null;
      this.data.details = null;
      this.data.atmNum = null;
      this.data.password = null;
      this.data.status = null;
      this.selectedFile.file = null;
      this.selectedFile.name = null;
    },
  },
  computed: {
    current_theme() {
      return this.$store.getters["theme_module/current_theme"];
    },
    ...mapGetters({
      lang: "lang_module/lang",
    }),
    headers() {
      // =================== header of atm
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {text: "رقم الصرافة", value: "number", align: "center"},
          {text: "كلمة المرور", value: "password", align: "center"},
          {text: "ملف الصرافة", value: "file", align: "center"},
          {text: "وقت الانشاء", value: "created_at", align: "center"},
          {text: "الحالة", value: "status", align: "center"},
          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
        ];
      }
    },
    // =================== header of receipt
    headers2() {
      if (this.lang == "ar") {
        return [
          {text: "قيمة الإيصال", value: "value", align: "center"},
          {
            text: "صورة الإيصال", // the name to display
            align: "center", // [center, start]
            value: "image", // Key must be equal to the name in rows objects
          },
          {
            text: "حالة الإيصال", // the name to display
            align: "center", // [center, start]
            value: "status", // Key must be equal to the name in rows objects
          },
          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
        ];
      }
    },
  },
  // ======= hooks
  created() {
    this.getData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (!userType.includes("superadmin") && !userType.includes("enterprise")) {
      this.$router.push("/");
    }
  },
};
</script>

<style></style>
