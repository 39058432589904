<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/employees"
        search_title="الموظفين"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("edit") }}</h4>
        </div>

        <!-- ==== Start Form ==== -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label for="textarea_1" class="form-label">{{
                    $t("forms.labels.name")
                  }}</label>
                  <input
                    type="text"
                    v-model="data.username"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label for="textarea_1" class="form-label">{{
                    $t("forms.labels.phone")
                  }}</label>
                  <input
                    type="text"
                    v-model="data.phone"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label for="textarea_1" class="form-label">{{
                    $t("forms.labels.email")
                  }}</label>
                  <input
                    type="email"
                    v-model="data.email"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label for="textarea_1" class="form-label"
                    >خزن الموظف (المودع فيها)</label
                  >
                  <multiselect
                    v-model="data.employee_safes_withdraw"
                    :options="safes"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                    multiple
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label for="textarea_1" class="form-label"
                    >خزن الموظف (المحول اليها)</label
                  >
                  <multiselect
                    v-model="data.employee_safes_deposit"
                    :options="safes"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                    multiple
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label"> حسابات العملاء</label>
                  <multiselect
                    v-model="data.clients"
                    :options="users"
                    label="name"
                    track-by="name"
                    placeholder=""
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                    multiple
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label for="textarea_1" class="form-label">الفرع</label>
                  <multiselect
                    v-model="data.employee_branch"
                    :options="branches"
                    label="name_ar"
                    track-by="name_ar"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label for="textarea_1" class="form-label"
                    >صلاحيات الموظف</label
                  >
                  <multiselect
                    v-model="data.employee_privileges"
                    :options="permissions"
                    label="title"
                    track-by="title"
                    placeholder=""
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                    multiple
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label for="password_input_1" class="form-label">{{
                    $t("forms.labels.password")
                  }}</label>
                  <input
                    v-model="data.password"
                    type="password"
                    class="form-control"
                    id="password_input_1"
                  />
                  <button
                    type="button"
                    class="toggle_pass_btn"
                    @click="helper_togglePasswordVisibility"
                  >
                    <i class="fal fa-eye open_eye"></i>
                    <i class="fal fa-eye-slash closed_eye"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              {{ $t("forms.submit") }}
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- ==== End Form ==== -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",
  props: ["id"],

  data() {
    return {
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.employees.edit"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // Date_menues
      dateMenu: false,
      debit_dateMenu: false,

      // search_users
      search_users: "",

      // ========== create_data
      data: {
        username: null,
        email: null,
        phone: null,
        clients: [],
        employee_safes_withdraw: [],
        employee_safes_deposit: [],
        employee_privileges: [],
        employee_branch: null,
        password: null,
      },

      // ========== Fixed_data
      users: [],
      safes: [],
      branches: [],
      permissions: [],
    };
  },

  methods: {
    // Get Data
    getData() {
      //===permissions
      this.$axios({
        method: "GET",
        url: `help/permissions`,
      }).then((res) => {
        this.permissions = res.data.data;
      });
      //===Branches
      this.$axios({
        method: "GET",
        url: `client/branch`,
      }).then((res) => {
        this.branches = res.data.data;
      });

      //===Safes
      this.$axios({
        method: "GET",
        url: `client/wallet`,
      }).then((res) => {
        this.safes = res.data.data;
      });

      //Get Data
      this.$axios({
        method: "GET",
        url: "client/user/" + this.id,
      }).then((res) => {
        console.log(res.data.data);
        this.data = {
          username: res.data.data.name,
          phone: res.data.data.phone,
          email: res.data.data.email,
          clients: res.data.data.customers,
          employee_safes_withdraw: res.data.data.wallets,
          employee_safes_deposit: res.data.data.in_wallets,
          employee_privileges: res.data.data.permissions,
          employee_branch: res.data.data.branch,
        };
      });
    },

    // Validate Data
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.username) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.name"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.email) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.email"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (this.data.employee_safes_withdraw.length == 0) {
          this.$iziToast.error({
            timeout: 2000,
            message: "حقل خزن السحب مطلوبة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (this.data.employee_safes_deposit.length == 0) {
          this.$iziToast.error({
            timeout: 2000,
            message: "حقل خزن الايداع مطلوبة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (this.data.employee_privileges.length == 0) {
          this.$iziToast.error({
            timeout: 2000,
            message: "حقل الصلاحيات مطلوب",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.submitData();
          return;
        }
      }
    },
    // get_filterd_users
    get_filterd_users() {
      this.$axios({
        method: "GET",
        url: `client/customer/search`,
      }).then((res) => {
        this.users = res.data.data;
      });
    },
    // Submit Data
    submitData() {
      const submit_data = new FormData();
      submit_data.append("fullname", this.data.username);
      if (this.data.password) {
        submit_data.append("password", this.data.password);
      }
      submit_data.append("branch_id", this.data.employee_branch.id);
      this.data.employee_privileges.forEach((element, index) => {
        submit_data.append(`permissions[${index}]`, element.key);
      });
      if (this.data.clients.length != 0) {
        this.data.clients.forEach((element, index) => {
          submit_data.append(`balance_customers[${index}]`, element.id);
        });
      }
      submit_data.append("email", this.data.email);
      submit_data.append("phone", this.data.phone);
      this.data.employee_safes_withdraw.forEach((element, index) => {
        submit_data.append(`wallets[${index}]`, element.id);
      });
      this.data.employee_safes_deposit.forEach((element, index) => {
        submit_data.append(`in_wallets[${index}]`, element.id);
      });

      this.$axios({
        method: "POST",
        url: "client/user/update_user/" + this.id,
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("editSuccess"),
            position: "topRight",
          });
          this.$router.push({path: "/employees"});
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
  },

  created() {
    this.getData();
    this.get_filterd_users();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>
