<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb :items="items" :show_print="false" :excelFile="false" />

      <!-- End Breadcrumb -->

      <!-- start of searchSection  -->
      <SearchSection
        @filterTable="searchTable"
        title="أرصدة نموذج 4 تصدير"
      ></SearchSection>
      <!-- end of searchSection  -->

      <!-- notFound  -->
      <Notfound v-if="!searchItems"></Notfound>
      <div v-else>
        <div class="clients">
          <ul class="all-state-count">
            <li>
              <div class="state-count">
                <span>عدد عملاء التصدير :</span>
                <span>{{ table_clients_length }}</span>
              </div>
            </li>
          </ul>
          <main class="main-table table-print">
            <v-data-table
              class="thumb"
              :headers="clients_headers"
              :items="clients_rows"
              :loading="lodaing"
              :loading-text="$t('table.loadingData')"
              v-model="selected"
              :single-select="singleSelect"
              item-key="id"
              :items-per-page="clients_rows.length"
              hide-default-footer
            >
              <!-- ================== You Can use any slots you want ================== -->
              <!-- ====== Select row field ====== -->
              <!-- Select no data State -->
              <template v-slot:no-data>
                {{ $t("table.noData") }}
              </template>
              <template v-slot:[`item.index`]="{index}">
                {{ index + 1 }}
              </template>
              <template v-slot:[`item.amount`]="{item}">
                <span
                  :class="{
                    redColor: item.amount < 0,
                    greenColor: item.amount > 0,
                  }"
                  >{{ Number(item.amount).toLocaleString() }}</span
                >
              </template>
            </v-data-table>
          </main>
          <div class="showMoney">
            <ul class="last_ul">
              <li style="width: 100%;">
                <div class="state-count">
                  <span> إجمالي رصيد العملاء : </span>
                  <span
                    :class="{
                      redColor: Number(customerTotal) < 0,
                      greenColor: Number(customerTotal) > 0,
                    }"
                  >
                    {{ Number(customerTotal).toLocaleString() }}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="banks">
          <ul class="all-state-count">
            <li>
              <div class="state-count">
                <span>عدد بنوك تصدير :</span>
                <span>{{ table_banks_length }}</span>
              </div>
            </li>
          </ul>
          <main class="main-table table-print">
            <v-data-table
              class="thumb"
              :headers="banks_headers"
              :items="banks_rows"
              :loading="lodaing"
              :loading-text="$t('table.loadingData')"
              v-model="selected"
              :single-select="singleSelect"
              item-key="id"
              :items-per-page="banks_rows.length"
              hide-default-footer
            >
              <!-- ================== You Can use any slots you want ================== -->
              <!-- ====== Select row field ====== -->
              <!-- Select no data State -->
              <template v-slot:no-data>
                {{ $t("table.noData") }}
              </template>
              <template v-slot:[`item.index`]="{index}">
                {{ index + 1 }}
              </template>
              <template v-slot:[`item.amount`]="{item}">
                <span
                  :class="{
                    redColor: item.amount < 0,
                    greenColor: item.amount > 0,
                  }"
                  >{{ Number(item.amount).toLocaleString() }}</span
                >
              </template>
            </v-data-table>
          </main>
          <div class="showMoney">
          <ul class="last_ul">
            <li style="width: 100%;">
              <div class="state-count">
                <span> إجمالي رصيد البنوك : </span>
                <span
                  :class="{
                    redColor: Number(bankTotal) < 0,
                    greenColor: Number(bankTotal) > 0,
                  }"
                >
                  {{ Number(bankTotal).toLocaleString() }}
                </span>
              </div>
            </li>
          </ul>
        </div>
        </div>
        <div class="showMoney">
          <ul class="last_ul">
            <li style="width: 100%;">
              <div class="state-count">
                <span> فرق أرصدة البنوك و العملاء : </span>
                <span
                  :class="{
                    redColor: Number(diffTotal) < 0,
                    greenColor: Number(diffTotal) > 0,
                  }"
                >
                  {{ Number(diffTotal).toLocaleString() }}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- End Image_Model -->
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import StatsCard from "../../../components/Charts/ChartComponent.vue";
import SearchSection from "@/components/Search/export-report";
export default {
  components: {
    StatsCard,
    SearchSection,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // search
      searchItems: null,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: "الرئيسية",
          disabled: false,
          href: "/",
        },
        {
          text: "أرصدة  نموذج 4 تصدير",
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "all",
          number: "10",
        },
      ],

      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      clients_rows: [], // injected in created
      banks_rows: [], // injected in created
      show_clients: false,
      show_banks: false,
      customerTotal: null,
      bankTotal: null,
      diffTotal: null,
      // ========== elected Data
      selected: [],
      singleSelect: false,

      modal: {
        descriptionModalIsOpen: false,
        notesModalIsOpen: false,
      },

      selectedItemDesc: null,
      selectedItemNotes: null,

      // ========== Loding
      lodaing: false,
      table_clients_length: null,
      table_banks_length: null,
      user_data: null,
      showReport: false,
      allAmountUser: null,
      allAmountEnterprice: null,
      user_currency: null,
      // excel data
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    clients_headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: "اسم العميل",
            value: "name",
            align: "center",
          },
          {
            text: "الرصيد",
            value: "amount",
            align: "center",
          },
        ];
      }
    },
    banks_headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: "اسم البنك",
            value: "name",
            align: "center",
          },
          {
            text: "الرصيد",
            value: "amount",
            align: "center",
          },
        ];
      }
    },
  },

  methods: {
    // searchTable
    searchTable(items) {
      if (items.lockId) {
        this.show_banks = true;
      } else {
        this.show_banks = false;
      }
      if (items.username) {
        this.show_clients = true;
      } else {
        this.show_clients = false;
      }
      this.rows = [];
      this.searchItems = items;
      this.setRows();
    },

    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({path: "/export_app/add"});
    },
    showItem(item) {
      this.$router.push({path: "/export_app/show/" + item.id});
    },
    editItem(item) {
      this.$router.push({path: "/export_app/edit/" + item.id});
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/bank_eg_operation/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("CRUD.Delete"),
            position: "topRight",
          });
          this.setRows();
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
      // Submit the delete service then submit this
    },
    // ==================== End CRUD ====================

    setRows() {
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/bank_eg/all",
        params: {
          bank_eg_id: this.searchItems ? this.searchItems.lockId : null,
          customer_eg_id: this.searchItems ? this.searchItems.username : null,
          from_date: this.searchItems ? this.searchItems.from : null,
          to_date: this.searchItems ? this.searchItems.to : null,
        },
      })
        .then((res) => {
          this.clients_rows = res.data.data.customersData;
          console.log(this.clients_rows);
          this.customerTotal = res.data.data.customersTotal;
          this.table_clients_length = this.clients_rows.length;
          this.banks_rows = res.data.data.banksData;
          this.bankTotal = res.data.data.banksTotal;
          this.table_banks_length = this.banks_rows.length;
          this.diffTotal = res.data.data.diff;
          this.lodaing = false;
        })
        .catch((err) => {
          console.log(err);
          this.lodaing = false;
        });
    },
  },

  // ======= hooks
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("referane_export")
    ) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped>
.actions2 {
  display: flex;
  justify-content: center;
  align-items: center;
  .confirm_btn {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-inline-end: 7px;
    &.accept {
      background-color: #5ab55e;
    }
    &.reject {
      background-color: #f55246;
    }
  }
}
</style>
