<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/importFiles/all"
        search_title="ملفات الاستيراد"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("addNew") }}</h4>
        </div>

        <!-- ==== Start Form ==== -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.file_number"
                  />
                  <label for="name_input" class="form-label"
                    >رقم نموذج 4
                  </label>
                </div>
              </div>

              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.polis_number"
                  />
                  <label for="name_input" class="form-label"
                    >رقم البوليصة</label
                  >
                </div>
              </div>

              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.amount"
                  />
                  <label for="name_input" class="form-label">المبلغ</label>
                </div>
              </div>

              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">الشركة</label>
                  <multiselect
                    v-model="data.company"
                    :options="companies"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">الميناء</label>
                  <multiselect
                    v-model="data.port"
                    :options="ports"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label file_input">
                  <span class="file_input_label"> ملف البنك </span>
                  <label
                    for="reseat_image"
                    class="form-label"
                    v-if="!selectedFile.file"
                  ></label>
                  <label for="reseat_image" class="form-label" v-else>
                    {{ selectedFile.name }}
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    id="reseat_image"
                    @change="handelSelectedFile"
                  />
                </div>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label date_input">
                  <label class="form-label">تاريخ وصول المستند</label>
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        v-model="data.date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="data.date"
                      @input="dateMenu = false"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.containerCount"
                  />
                  <label for="name_input" class="form-label"
                    >عدد الحاويات</label
                  >
                </div>
              </div>
              <div
                class="col-lg-6 py-0"
                v-for="(i, index) in Number(data.containerCount)"
                :key="index"
              >
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    step="any"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.containerData[i]"
                  />
                  <label for="name_input" class="form-label"
                    >رقم الحاوية ( {{ i }} )</label
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              إضافة
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- ==== End Form ==== -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // loader
      loading: false,

      load_users: false,
      // search
      searchData: {
        username: null,
      },
      searchOptions: {
        search_users: "",
      },
      users: null,

      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "اضافة ملف استيراد",
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // Date_menues
      dateMenu: false,
      debit_dateMenu: false,

      // search_users
      search_users: "",

      // START:: SELECTED FILE DATA
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },

      // ========== create_data
      data: {
        file_number: null,
        polis_number: null,
        amount: null,
        company: null,
        containerCount: null,
        containerData: [],
        containers: null,
        port: null,
        date: new Date().toISOString().slice(0, 10),
      },

      // ========== Fixed_data
      banks: null,
      companies: null,
      ports: null,
    };
  },

  computed: {
    total() {
      const num = this.data.amount * this.data.transfare_amount;
      return parseFloat(num.toFixed()).toLocaleString();
    },
  },

  watch: {
    [`searchOptions.search_users`](newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.users = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_users(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_users(newVal);
        } else {
          this.get_filterd_users(newVal);
        }
      }
    },
  },

  methods: {
    // get_filterd_users
    get_filterd_users(keyword) {
      this.load_users = true;
      this.$axios({
        method: "GET",
        url: `client/customer/search?keyword=${keyword}`,
      })
        .then((res) => {
          this.users = res.data.data;
          this.load_users = false;
        })
        .catch((err) => {
          this.load_users = false;
        });
    },

    // Get Data
    getCompanies() {
      this.loading = true;
      // START:: GET SAFES
      this.$axios({
        method: "GET",
        url: `client/company_import`,
      })
        .then((res) => {
          this.companies = res.data.data;
          this.getPort();
        })
        .catch((err) => {
          console.log(err.response.data);
        });
      // END:: GET SAFES
    },

    getPort() {
      // START:: GET BANKS
      this.$axios({
        method: "GET",
        url: `client/port`,
      })
        .then((res) => {
          this.ports = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
      // END:: GET BANKS
    },

    // START:: HANDLING SELECTED FILE METHOD
    handelSelectedFile(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD

    // Validate Data
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.file_number) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجى ادخال رقم نموذج 4",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.polis_number) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجى ادخال رقم البوليصة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.amount"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.company) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجى اختيار الشركة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.port) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجى اختيار الميناء",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.selectedFile.file) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدراج ملف البنك ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.date) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.date"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.containerCount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجى ادخال عدد الحاويات",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.submitData();
          return;
        }
      }
    },
    emptyFormData() {
      this.data.file_number = null;
      this.data.polis_number = null;
      this.data.company = null;
      this.data.amount = null;
      this.data.port = null;
      this.selectedFile.file = null;
      this.selectedFile.name = null;
      this.data.date = new Date().toISOString().slice(0, 10);
      this.data.containerCount = null;
      this.data.containerData = [];
    },
    // Submit Data
    submitData() {
      const submit_data = new FormData();
      if (this.data.file_number) {
        submit_data.append("document_number", this.data.file_number);
      }
      submit_data.append("bl_number", this.data.polis_number);
      submit_data.append("total", this.data.amount);
      submit_data.append("company_import_id", this.data.company.id);
      submit_data.append("port_id", this.data.port.id);
      submit_data.append("bank_image", this.selectedFile.file);
      submit_data.append("arrival_bank_document_date", this.data.date);
      submit_data.append("containers", this.data.containerCount);
      this.data.containerData.forEach((element, index) => {
        submit_data.append(`container[${index}][number]`, element);
      });
      // START:: APPEND REQUEST DATA
      this.$axios({
        method: "POST",
        url: "client/import",
        data: submit_data,
      })
        .then((res) => {
          this.$iziToast.success({
            timeout: 5000,
            message: this.$t("addSuccess"),
            position: "topRight",
          });
          this.emptyFormData();
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
  },

  created() {
    this.getCompanies();
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("containerOperations")
    ) {
      this.$router.push("/");
    }
  },
};
</script>
