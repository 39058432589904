<template>
  <div
    class="invoice"
    dir="ltr"
    :style="{
      color: myStyles.invoice.color,
      'font-family': myStyles.invoice.family,
    }"
  >
    <!-- ======================= COMPNAY NAME ===================== -->
    <div
      class="wrapper"
      :style="{
        'justify-content': myStyles.title.align,
        display: 'flex',
      }"
    >
      <h1
        class="title"
        :style="{
          'font-size': '25px !important',
          color: myStyles.title.color,
          'font-family': myStyles.title.family,
          'font-weight': 'bold',
        }"
      >
        Company Name
      </h1>
    </div>
    <!-- ======================== COMPANY ADDRESS ======================== -->
    <div
      class="wrapper"
      :style="{
        'justify-content': myStyles.title.align,
        'margin-bottom': '20px',
        display: 'flex',
      }"
    >
      <p
        :style="{
          'font-size': '20px !important',
          color: myStyles.address.color,
          'font-family': myStyles.address.family,
        }"
      >
        address
      </p>
    </div>
    <!-- ======================= IMTERPRISE ======================== -->
    <div
      class="flex_section_1"
      :style="{
        'flex-direction': myStyles.flex_section_1.directionRow,
        color: myStyles.flex_section_1.color,
        'font-family': myStyles.flex_section_1.family,
        display: 'flex',
      }"
    >
      <!-- ======================= IMTERPRISE NAME ======================== -->
      <div class="toInfo" style="width: 50%;">
        <h5 style="font-weight: bold; font-size: 20px !important;">TO:-</h5>
        <p class="address" style="font-size: 20px !important;">
          ADVANCED PAYMENT BY SOFTWARE ADVANCED PAYMENT BY SOFTWARE
        </p>
      </div>
      <!-- ======================= INVOICE ======================== -->
      <div
        class="invoiceInfo"
        :style="{
          width: '50%',
          'flex-direction': myStyles.invoiceInfo.directionCol,
          'font-family': myStyles.invoiceInfo.family,
        }"
      >
        <!-- ======================= INVOICE NO ======================== -->
        <div class="keyValue">
          <span style="font-weight: bold; font-size: 17px !important; margin-right: 5px"
            >INVOICE NO:
          </span>
          <span style="font-size: 17px !important;">MR1227</span>
        </div>
        <!-- ======================= INVOICE DATE ======================== -->
        <div class="keyValue">
          <span style="font-weight: bold; font-size: 17px !important; margin-right: 5px"
            >DATE:
          </span>
          <span style="font-size: 17px !important;">June 22,2021</span>
        </div>
        <!-- ======================= PAYMENT TERMS ======================== -->
        <div class="keyValue">
          <span style="font-weight: bold; font-size: 17px !important; margin-right: 5px"
            >PAYMENT TERMS:
          </span>
          <span style="font-size: 17px !important;"
            >ADVANCE PAYMENT BY SWIFT TRANSFER</span
          >
        </div>
        <!-- ======================= PAYMENT TERMS ======================== -->
        <div class="keyValue">
          <span style="font-weight: bold; font-size: 17px !important;">
            PARTIAL PAYMENTS ARE ACCEPTED
          </span>
        </div>
        <!-- ======================= INVOICE COUNTRY ======================== -->
        <div class="keyValue">
          <span style="font-weight: bold; font-size: 17px !important; margin-right: 5px"
            >ORIGIN OF GOODS:
          </span>
          <span style="font-size: 17px !important;">INDIA</span>
        </div>
      </div>
    </div>
    <!-- ======================= TABLE OF PRODUCTS ======================== -->
    <table
      class="table table-bordered my-5"
      :style="{
        'border-color': myStyles.table.color,
        color: myStyles.table.color,
        'font-family': myStyles.table.family,
      }"
    >
      <thead
        :style="{
          'border-color': myStyles.thead.color,
          background: myStyles.thead.background,
          color: '#000',
        }"
      >
        <tr>
          <th scope="col" style="font-size: 17px !important;">DESCRIPTION</th>
          <th scope="col" style="font-size: 17px !important;">UNIT PRICE</th>
          <th scope="col" style="font-size: 17px !important;">QTY</th>
          <th scope="col" style="font-size: 17px !important;">TOTAL</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(item, index) in rows"
          :key="index"
          :style="{
            'border-color': myStyles.thead.color,
            background: myStyles.tr_1.background,
            color: '#000',
          }"
        >
          <td style="font-size: 17px !important;">{{ item.product.name }}</td>
          <td style="font-size: 17px !important;">
            $ {{ parseFloat(item.price.toFixed()).toLocaleString() }}
          </td>
          <td style="font-size: 17px !important;">{{ item.quantity }}</td>
          <td style="font-size: 17px !important;">
            $
            {{
              parseFloat(
                (item.quantity * item.price).toFixed()
              ).toLocaleString()
            }}
          </td>
        </tr>
        <tr
          class="total"
          :style="{
            'border-color': myStyles.thead.color,
            background: myStyles.tr_2.background,
            color: '#000',
          }"
        >
          <td colspan="3" style="font-size: 18px !important;">
            TOTAL :- ONE HUNDRED TWENTY-SIX THOUSAND US DOLLARS
          </td>
          <td style="font-weight: bold;">$ {{ sumAmount }}</td>
        </tr>
      </tbody>
    </table>
    <!-- ======================= INVOICE FOOTER ======================== -->
    <footer
      :style="{
        'flex-direction': myStyles.footer.directionRow,
        'justify-content': 'space-between',
        color: myStyles.footer.color,
        'font-family': myStyles.footer.family,
        display: 'flex',
      }"
    >
      <!-- ======================= INVOICE SLUG ======================== -->
      <div class="image">
        <img
          :src="companyLogo"
          alt=""
          style="height: 150px;"
        />
      </div>
      <!-- ======================= COMPANY DETAILS ======================== -->
      <div
        class="accountDetails"
        :style="{
          'font-weight': 'bold',
          border: `1px ${myStyles.accountDetails.border} ${myStyles.accountDetails.color}`,
          color: myStyles.accountDetails.color,
          'font-family': myStyles.accountDetails.family,
          'padding': '10px'
        }"
      >
        <h5 style="font-size: 20px !important;">ACCOUNT DETAILS :-</h5>
        <div class="keyValue">
          <span style="font-weight: bold; font-size: 18px; margin-right: 5px">NAME: </span>
          <span style="font-weight: normal;">MR1227</span>
        </div>
        <div class="keyValue">
          <span style="font-weight: bold; font-size: 18px; margin-right: 5px">A/C NO: </span>
          <span style="font-weight: normal; font-size: 18px;">MR1227</span>
        </div>
        <div class="keyValue">
          <span style="font-weight: bold; font-size: 18px; margin-right: 5px">SWIFT CODE: </span>
          <span style="font-weight: normal; font-size: 18px;">MR1227</span>
        </div>
        <div class="keyValue">
          <span style="font-weight: bold; font-size: 18px; margin-right: 5px">BANK NAME: </span>
          <span style="font-weight: normal; font-size: 18px;">MR1227</span>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  props: ["myStyles", "rows", "sumAmount", "companyLogo"],
  data() {
    return {};
  },
};
</script>
