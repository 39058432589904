<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/CompanyAction"
        search_title="تنفيذ شغل الشركات"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">عملية تنفيذ شغل شركات</h4>
        </div>

        <!-- ==== Start Form ==== -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-lg-12 py-0">
                <!--Divider-->
                <div class="divider">
                  <h4>Amount and payments</h4>
                  <v-divider></v-divider>
                </div>
                <!--Divider-->
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="data.all_amount"
                  />
                  <label for="name_input" class="form-label"
                    >Total Amount
                  </label>
                </div>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="data.paied_amount"
                  />
                  <label for="name_input" class="form-label">
                    Amount paid</label
                  >
                </div>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    readonly
                    type="text"
                    class="form-control"
                    v-model.trim="remained_amount"
                  />
                  <label for="name_input" class="form-label"
                    >Remaining amount</label
                  >
                </div>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label mb-3">
                  <label class="form-label"> Currency </label>
                  <input
                    readonly
                    type="text"
                    class="form-control"
                    v-model.trim="data.currency"
                  />
                </div>
              </div>
              <div class="col-lg-12 py-0">
                <!--Divider-->
                <div class="divider">
                  <h4>Enterprice Account</h4>
                  <v-divider></v-divider>
                </div>
                <!--Divider-->
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> Enterprice Bank Account</label>
                  <v-autocomplete
                    v-model="data.enterpriceAccount"
                    :search-input.sync="searchOptions.search_enterprices"
                    :items="enterprices"
                    item-text="name"
                    return-object
                    :hide-no-data="!enterprices"
                    dense
                  ></v-autocomplete>
                  <button
                    class="button_style_1 load_inline"
                    v-if="load_enterprices"
                  >
                    <span class="btn_loader"></span>
                  </button>
                </div>

                <h6
                  class="mt-0 mb-0 mx-0 amount-section"
                  v-if="data.enterpriceAccount"
                  :class="{
                    redColor: data.enterpriceAccount.amount < 0,
                    greenColor: data.enterpriceAccount.amount > 0,
                  }"
                >
                  Amount :
                  {{ Number(data.enterpriceAccount.amount).toLocaleString() }}
                </h6>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="data.organization_amount"
                  />
                  <label for="name_input" class="form-label">
                    Enterprice Rate</label
                  >
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    readonly
                    type="text"
                    class="form-control"
                    v-model.trim="organizationAll"
                  />
                  <label for="name_input" class="form-label">Total </label>
                </div>
              </div>
              <div class="col-lg-12 py-0">
                <!--Divider-->
                <div class="divider">
                  <h4>Client</h4>
                  <v-divider></v-divider>
                </div>
                <!--Divider-->
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> Client Name</label>
                  <v-autocomplete
                    v-model="data.clientName"
                    :search-input.sync="searchOptions.search_users"
                    :items="users"
                    item-text="name"
                    return-object
                    :hide-no-data="!users"
                    dense
                  ></v-autocomplete>
                  <button class="button_style_1 load_inline" v-if="load_users">
                    <span class="btn_loader"></span>
                  </button>
                </div>
                <h6
                  class="mt-0 mb-0 mx-0 amount-section"
                  v-if="data.clientName"
                  :class="{
                    redColor: data.clientName.amount < 0,
                    greenColor: data.clientName.amount > 0,
                  }"
                >
                  Amount :
                  {{ Number(data.clientName.amount).toLocaleString() }}
                </h6>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.user_amount"
                  />
                  <label for="name_input" class="form-label">
                    Client Rate</label
                  >
                </div>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.user_commesion"
                  />
                  <label for="name_input" class="form-label">
                    Client Commesion</label
                  >
                </div>
              </div>
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    readonly
                    type="text"
                    class="form-control"
                    v-model.trim="clientAll"
                  />
                  <label for="name_input" class="form-label">Total </label>
                </div>
              </div>

              <div class="col-lg-12 py-0">
                <!--Divider-->
                <div class="divider">
                  <h4>Company Data</h4>
                  <v-divider></v-divider>
                </div>
                <!--Divider-->
              </div>
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="accountNum"
                    v-model="data.ibanNumber"
                    @input="emptyBankData"
                    v-on:keydown.enter.prevent="getCompanyData"
                  />
                  <label for="accountNum" class="form-label"
                    >IBAN NO or A/C NO</label
                  >
                </div>
              </div>
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="accountNum"
                    v-model="data.refNum"
                  />
                  <label for="accountNum" class="form-label"
                    >Reference NO</label
                  >
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">{{
                    $t("companies.data.name")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="accountNum"
                    v-model="data.company_name"
                  />
                  <button class="button_style_1 loadSwift" v-if="loadSwift">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>

              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="accountNum"
                    v-model="data.swift_number"
                  />
                  <label for="accountNum" class="form-label">{{
                    $t("companies.data.swiftNumber")
                  }}</label>
                  <button class="button_style_1 loadSwift" v-if="loadSwift">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>

              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="bank"
                    v-model="data.bank_name"
                  />
                  <label for="bank" class="form-label">Bank Name</label>
                  <button class="button_style_1 loadSwift" v-if="loadSwift">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="bank"
                    v-model="data.country"
                  />
                  <label for="bank" class="form-label">Country</label>
                  <button class="button_style_1 loadSwift" v-if="loadSwift">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    id="bank"
                    v-model="data.address"
                  />
                  <label for="bank" class="form-label">Address</label>
                  <button class="button_style_1 loadSwift" v-if="loadSwift">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">Product</label>
                  <multiselect
                    v-model="data.products"
                    :options="products"
                    label="name"
                    track-by="name"
                    placeholder=""
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                    :multiple="true"
                  >
                  </multiselect>
                  <button class="button_style_1 loadSwift" v-if="loadSwift">
                    <span class="btn_loader"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              تأكيد
              <span class="btn_loader m-2" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- ==== End Form ==== -->

        <!-- ==== Start Delete Modal ==== -->
        <v-dialog v-model="dialogDelete" persistent max-width="750px">
          <v-card class="popup-data">
            <v-card-title class="text-h5 justify-center">
              سوف يتم حفظ العملية بالبيانات التالية
            </v-card-title>
            <div class="row px-3">
              <!--Divider-->
              <div class="col-12 py-0 mb-2">
                <div class="divider">
                  <h4>Amount and payments</h4>
                  <v-divider></v-divider>
                </div>
              </div>
              <!--Divider-->

              <div class="col-lg-12 py-0">
                <h5>Amount paid</h5>
                <h6>{{ data.paied_amount }} {{ data.currency }}</h6>
              </div>

              <!--Divider-->
              <div class="col-12 py-0 mb-2">
                <div class="divider">
                  <h4>Client</h4>
                  <v-divider></v-divider>
                </div>
              </div>
              <!--Divider-->

              <div class="col-lg-5 py-0">
                <h5>Client Name</h5>
                <h6 v-if="data.clientName">{{ data.clientName.name }}</h6>
              </div>

              <div class="col-lg-2 py-0">
                <h5>Rate</h5>
                <h6>{{ data.user_amount }}</h6>
              </div>

              <div class="col-lg-2 py-0">
                <h5>Commesion</h5>
                <h6>{{ data.user_commesion }}</h6>
              </div>

              <div class="col-lg-3 py-0">
                <h5>Total</h5>
                <h6>{{ clientAll }}</h6>
              </div>
            </div>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="#1B5E20"
                :class="{buttonIndex: loadingBtnDialog}"
                :disabled="loadingBtnDialog"
                @click="submitData"
                >تأكيد
                <span class="btn_loader_model" v-if="loadingBtnDialog"></span
              ></v-btn>
              <v-btn
                color="#F44336"
                :class="{buttonIndex: loadingBtnDialog}"
                :disabled="loadingBtnDialog"
                @click="toggleConfirmationModal"
                >{{ $t("table.deletedialog.cancel") }}</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- ==== End Delete Modal ==== -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      load_enterprices: false,
      load_users: false,

      searchOptions: {
        search_enterprices: "",
        search_users: "",
      },
      enterprices: [],
      users: null,

      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "تعديل تنفيذ شغل لشركة",
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========== dialog Status
      dialogDelete: false,

      // Date_menues
      dateMenu: false,
      debit_dateMenu: false,

      add_Company_dialog: false,

      // search_users
      search_users: "",
      // search_accounts
      search_accounts: "",

      // ========== create_data
      data: {
        account: null,
        enterpriceAccount: null,
        organization_amount: 3.756,
        organizationAll: null,
        clientName: null,
        user_amount: null,
        user_commesion: null,
        clientAll: null,
        all_amount: null,
        paied_amount: null,
        remained_amount: null,
        currency: null,
        ibanNumber: null,
        refNum: null,
        company_id: null,
        company_name: null,
        swift_number: null,
        bank_name: null,
        country: null,
        address: null,
        products: [],
      },

      // ========== Fixed_data
      companies: [
        {id: 1, name: "company"},
        {id: 2, name: "company"},
        {id: 3, name: "company"},
      ],
      products: [
        {id: 1, name: "product"},
        {id: 2, name: "product"},
        {id: 3, name: "product"},
      ],
      categories: [],
      banks: [],
      companyLogo: {
        file: null,
        name: null,
        path: null,
      },
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },
      users: [],
      enterpriceAccounts: [],
      rows: [
        {
          id: 1,
          paied_amount: 220,
          remained_amount: 110,
          date: "2022-4-6",
        },
        {
          id: 2,
          paied_amount: 220,
          remained_amount: 110,
          date: "2022-4-6",
        },
      ],
      loadSwift: false,
      currencies: [],
      loadingBtnDialog: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "#",
          value: "index",
          sortable: false,
          align: "center",
          width: 10,
        },
        {text: "المبلغ المدفوع", value: "paied_amount", align: "center"},
        {text: "المبلغ المتبقي", value: "remained_amount", align: "center"},
        {text: "التاريخ", value: "date", align: "center"},
      ];
    },
    organizationAll() {
      const num = this.data.paied_amount * this.data.organization_amount;
      return parseFloat(num.toFixed()).toLocaleString();
    },
    clientAll() {
      const num =
        this.data.paied_amount *
        this.data.user_amount *
        this.data.user_commesion;
      return parseFloat(num.toFixed()).toLocaleString();
    },
    total() {
      const num = this.data.paied_amount * this.data.comission;
      return parseFloat(num.toFixed()).toLocaleString();
    },
    remained_amount() {
      const num = this.data.all_amount - this.data.paied_amount;
      return parseFloat(num.toFixed()).toLocaleString();
    },
  },

  watch: {
    [`searchOptions.search_users`](newVal, oldVal) {
      if ((!newVal || newVal.length < 3) && this.data.clientName == null) {
        this.users = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_users(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_users(newVal);
        } else {
          this.get_filterd_users(newVal);
        }
      }
    },
    [`searchOptions.search_enterprices`](newVal, oldVal) {
      if (
        (!newVal || newVal.length < 3) &&
        this.data.enterpriceAccount == null
      ) {
        this.enterprices = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_enterprices(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_enterprices(newVal);
        } else {
          this.get_filterd_enterprices(newVal);
        }
      }
    },
    [`data.bank`](newVal) {
      this.data.swift_number = newVal.swift_number;
    },

    [`data.swift_number`](newVal) {
      this.data.bank =
        this.banks.find((item) => item.swift_number == newVal) || null;
    },
  },

  methods: {
    // ============== Get Data
    getData() {
      // Single company
      this.$axios({
        method: "GET",
        url: `client/company_transfer/${this.$route.params.id}`,
      })
        .then((res) => {
          this.data.clientName = res.data.data.customer;
          this.data.all_amount = res.data.data.total;
          this.data.ibanNumber = res.data.data.company.account_number;
          this.data.currency = res.data.data.currency;
          this.data.account = res.data.data.account;
          if(res.data.data.reference) {
            this.data.refNum = res.data.data.reference
          }
          if (res.data.data.account != null) {
            this.data.enterpriceAccount = res.data.data.account;
            this.data.organization_amount = res.data.data.enterprise_rate;
            this.data.user_amount = res.data.data.customer_rate;
            this.data.user_commesion = res.data.data.customer_commission;
          }
          this.getCompanyData();
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
      // ===== Categories
      this.$axios({
        method: "GET",
        url: "client/category",
      })
        .then((res) => {
          this.categories = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });

      // ===== Banks
      this.$axios({
        method: "GET",
        url: "client/bank",
      }).then((res) => {
        this.banks = res.data.data;
      });

      // ===== Banks
      this.$axios({
        method: "GET",
        url: "client/company",
      }).then((res) => {
        this.companies = res.data.data;
      });
      this.$axios({
        method: "GET",
        url: `client/currency`,
      })
        .then((res) => {
          this.currencies = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    emptyBankData() {
      this.data.company_name = null;
      this.data.swift_number = null;
      this.data.bank_name = null;
      this.data.country = null;
      this.data.address = null;
      this.data.products = null;
    },
    getCompanyData() {
      this.loadSwift = true;
      this.$axios({
        method: "GET",
        url: "client/company/filter",
        params: {
          number: this.data.ibanNumber,
        },
      })
        .then((res) => {
          if (
            this.data.ibanNumber.length != 0 &&
            res.data.data != null &&
            res.data.is_accepted == true
          ) {
            this.data.company_name = res.data.data.name;
            this.data.company_id = res.data.data.id;
            this.data.swift_number = res.data.data.swift_number;
            this.data.bank_name = res.data.data.bank.name;
            this.data.country = res.data.data.country.name_ar;
            this.data.address = res.data.data.address;
            this.data.products = res.data.data.products;
          } else if (
            this.data.ibanNumber.length != 0 &&
            res.data.data != null &&
            res.data.is_accepted == false
          ) {
            this.$iziToast.warning({
              timeout: 2000,
              message: "الشركة مسجلة و لكن لم يتم الموافقه عليها",
              position: "topCenter",
            });
            this.data.company_name = null;
            this.data.company_id = null;
            this.data.swift_number = null;
            this.data.bank_name = null;
            this.data.country = null;
            this.data.address = null;
            this.data.products = [];
          } else {
            this.$iziToast.error({
              timeout: 2000,
              message: "لا يوجد حساب شركة بهذا الرقم",
              position: "topCenter",
            });
            this.data.company_name = null;
            this.data.company_id = null;
            this.data.swift_number = null;
            this.data.bank_name = null;
            this.data.country = null;
            this.data.address = null;
            this.data.products = [];
          }
          this.loadSwift = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // Get Companies
    getCompanies() {
      this.$axios({
        method: "GET",
        url: `client/company`,
      }).then((res) => {
        this.companies = res.data.data;
      });
    },

    // Get Companies
    getProducts() {
      this.$axios({
        method: "GET",
        url: `client/product`,
      }).then((res) => {
        this.products = res.data.data;
      });
    },

    // get_filterd_users
    get_filterd_users(keyword) {
      this.load_users = true;
      this.$axios({
        method: "GET",
        url: `client/customer/search?keyword=${keyword}`,
      })
        .then((res) => {
          this.users = res.data.data;
          this.load_users = false;
        })
        .catch((err) => {
          this.load_users = false;
        });
    },

    // get_filterd_enterprices
    get_filterd_enterprices(keyword) {
      this.load_enterprices = true;
      this.$axios({
        method: "GET",
        url: `help/accounts?keyword=${keyword}`,
      })
        .then((res) => {
          this.enterprices = res.data.data;
          this.load_enterprices = false;
        })
        .catch((err) => {
          this.load_enterprices = false;
        });
    },

    // START:: HANDLING SELECTED FILE METHOD
    handelSelectedFile(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD

    // START:: HANDLING SELECTED FILE METHOD
    handelCompanyLogo(e) {
      this.companyLogo.file = e.target.files[0];
      this.companyLogo.name = e.target.files[0].name;
      this.companyLogo.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD

    // START:: HANDLING SELECTED FILE METHOD
    handelCommerceLogo(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD

    // START:: TOGGLE CONFIRMATION MODAL
    toggleConfirmationModal() {
      this.dialogDelete = !this.dialogDelete;
    },
    //END:: TOGGLE CONFIRMATION MODAL

    // Validate Data
    // Validate Data
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.enterpriceAccount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة إسم حساب المؤسسة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.organization_amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة نسبة المؤسسة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.clientName) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة إسم العميل",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.user_amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة نسبة العميل",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.user_commesion) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة عمولة العميل",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.ibanNumber) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة رقم الحساب او ال iban",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.all_amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة المبلغ الإجمالي",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.paied_amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة المبلغ المدفوع ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.ibanNumber) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة رقم الحساب او ال iban",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.btnIsLoading = false;
          this.toggleConfirmationModal();
          return;
        }
      }
    },

    // Submit Data
    submitData() {
      this.loadingBtnDialog = true;
      const submit_data = new FormData();
      submit_data.append("account_id", this.data.enterpriceAccount.id);
      submit_data.append("enterprise_rate", this.data.organization_amount);
      submit_data.append(
        "enterprise_total",
        Number(this.organizationAll.replace(/\D/g, ""))
      );
      submit_data.append("balance_customer_id", this.data.clientName.id);
      submit_data.append("customer_rate", this.data.user_amount);
      submit_data.append("customer_commission", this.data.user_commesion);
      submit_data.append(
        "customer_total",
        Number(this.clientAll.replace(/\D/g, ""))
      );
      submit_data.append("total", this.data.all_amount);
      submit_data.append("paid", this.data.paied_amount);
      submit_data.append(
        "rest",
        Number(this.remained_amount.replace(/\D/g, ""))
      );
      if(this.data.refNum) {
        submit_data.append("reference", this.data.refNum);
      }
      submit_data.append("company_id", this.data.company_id);
      submit_data.append("company_transfer_id", this.$route.params.id);
      this.$axios({
        method: "POST",
        url: "client/company_work",
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "topRight",
          });
          this.loadingBtnDialog = false;
          this.$router.push({path: "/companyAction"});
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.loadingBtnDialog = false;
        });
    },
  },

  created() {
    this.getData();
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      userType.includes("confirm_company_actions") &&
      !userType.includes("company_actions")
    ) {
      this.$router.push("/enterpriceActions");
    } else if (
      !userType.includes("superadmin") &&
      !userType.includes("company_actions")
    ) {
      this.$router.push("/");
    }
  },
};
</script>
