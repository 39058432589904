<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper enterprice_account" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/enterpriceATM"
        search_title="إيداع بنكي أو ATM تعديل"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("CRUD.Update.main_title") }}</h4>
        </div>

        <!-- START:: CREATE FORM WRAPPER -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="data.amount"
                  />
                  <label for="name_input" class="form-label"> المبلغ </label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="data.transfare_amount"
                  />
                  <label for="name_input2" class="form-label">قسمه</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model.trim="data.comission"
                  />
                  <label for="name_input3" class="form-label">ضرب</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-3 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    readonly
                    class="form-control"
                    v-model.trim="total"
                  />
                  <label for="name_input4" class="form-label">الاجمالي</label>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">{{
                    $t("forms.labels.safe")
                  }}</label>
                  <multiselect
                    v-model="data.safe"
                    :options="safes"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <!-- START:: SINGLE SELECT INPUT -->
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> إسم العميل</label>
                  <v-autocomplete
                    v-model="data.username"
                    :search-input.sync="searchOptions.search_users"
                    :items="users"
                    item-text="name"
                    return-object
                    :hide-no-data="!users"
                    dense
                  ></v-autocomplete>
                  <button class="button_style_1 load_inline" v-if="load_users">
                    <span class="btn_loader"></span>
                  </button>
                </div>
                <h6
                  class="mt-0 mb-0 mx-0 amount-section"
                  :class="{
                    redColor: data.username.amount < 0,
                    greenColor: data.username.amount > 0,
                  }"
                  v-if="data.username"
                >
                  الرصيد :
                  {{ Number(data.username.amount).toLocaleString() }}
                </h6>
              </div>
              <!-- END:: SINGLE SELECT INPUT -->

              <!-- START:: SINGLE SELECT INPUT -->
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> حساب المؤسسة</label>
                  <v-autocomplete
                    v-model="data.enterpriceAccount"
                    :search-input.sync="searchOptions.search_enterprices"
                    :items="enterprices"
                    item-text="name"
                    return-object
                    :hide-no-data="!enterprices"
                    dense
                  ></v-autocomplete>
                  <button
                    class="button_style_1 load_inline"
                    v-if="load_enterprices"
                  >
                    <span class="btn_loader"></span>
                  </button>
                </div>
                <h6
                  class="mt-0 mb-0 mx-0 amount-section"
                  v-if="data.enterpriceAccount"
                  :class="{
                    redColor: data.enterpriceAccount.amount < 0,
                    greenColor: data.enterpriceAccount.amount > 0,
                  }"
                >
                  الرصيد :
                  <span v-if="data.enterpriceAccount.amount">
                    {{
                      Number(data.enterpriceAccount.amount).toLocaleString()
                    }}</span
                  >
                  <span v-else> 0 </span>
                </h6>
              </div>
              <!-- END:: SINGLE SELECT INPUT -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <label for="textarea_1" class="form-label">وصف</label>
                  <textarea
                    v-on:keydown.enter.prevent="validateCreateForm"
                    class="form-control"
                    rows="5"
                    v-model="data.desc"
                  ></textarea>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!-- START:: INPUT WRAPPER -->
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <label for="textarea_1" class="form-label">ملاحظات</label>
                  <textarea
                    v-on:keydown.enter.prevent="validateCreateForm"
                    class="form-control"
                    rows="5"
                    v-model="data.notes"
                  ></textarea>
                </div>
              </div>
              <!-- END:: INPUT WRAPPER -->

              <!--START:: ASK TO OPEN ACCOUNT -->
              <div class="col-lg-12 py-0">
                <div class="sub_title">
                  <span>الحالة</span>
                </div>
                <!-- START:: RADIO BUTTON INPUT -->
                <div class="input_wrapper radio_button_input">
                  <div class="flex_wrapper">
                    <v-radio-group v-model="radioButtonsValue">
                      <v-radio
                        label="يوجد"
                        color="indigo lighten-2"
                        value="true"
                      ></v-radio>
                      <v-radio
                        label="لا يوجد"
                        color="indigo lighten-2"
                        value="false"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <!-- END:: RADIO BUTTON INPUT -->
              </div>
              <!--END:: ASK TO OPEN ACCOUNT -->
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              تعديل
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- END:: CREATE FORM WRAPPER -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",
  props: ["id"],
  data() {
    return {
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: "الرئيسية",
          disabled: false,
          href: "/",
        },
        {
          text: "تعديل إيداع بنكي أو ATM",
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      radioButtonsValue: null,
      radioButtonsValue2: null,
      radioButtonsValue3: null,
      radioButtonsValue4: null,

      // START:: enterpriceAccounts
      enterpriceAccounts: [],
      // END:: enterpriceAccounts

      // search_users
      search_users: "",

      // search_accounts
      search_accounts: "",

      // ========== create_data
      data: {
        enterpriceAccount: null,
        username: null,
        amount: null,
        transfare_amount: 3.756,
        comission: 0.01,
        total: null,
        safe: null,
        desc: null,
        notes: null,
      },
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },

      load_users: false,
      load_enterprices: false,

      // search
      searchOptions: {
        search_users: "",
        search_enterprices: "",
      },
      safes: [],
      users: [],
      enterprices: [],
    };
  },

  watch: {
    [`searchOptions.search_users`](newVal, oldVal) {
      if ((!newVal || newVal.length < 3) && this.data.username == null) {
        this.users = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_users(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_users(newVal);
        } else {
          this.get_filterd_users(newVal);
        }
      }
    },

    [`searchOptions.search_enterprices`](newVal, oldVal) {
      if (
        (!newVal || newVal.length < 3) &&
        this.data.enterpriceAccount == null
      ) {
        this.enterprices = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_enterprices(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_enterprices(newVal);
        } else {
          this.get_filterd_enterprices(newVal);
        }
      }
    },
  },

  methods: {
    // START:: GET SAFES
    getSafes() {
      this.$axios({
        method: "GET",
        url: `client/wallet?type=out`,
      })
        .then((res) => {
          this.safes = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    // END:: GET SAFES
    // get_filterd_users
    get_filterd_users(keyword) {
      this.load_users = true;
      this.$axios({
        method: "GET",
        url: `client/customer/search?keyword=${keyword}`,
      })
        .then((res) => {
          this.users = res.data.data;
          this.load_users = false;
        })
        .catch((err) => {
          this.load_users = false;
        });
    },

    // get_filterd_enterprices
    get_filterd_enterprices(keyword) {
      this.load_enterprices = true;
      this.$axios({
        method: "GET",
        url: `help/accounts?keyword=${keyword}`,
      })
        .then((res) => {
          this.enterprices = res.data.data.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
          this.load_enterprices = false;
          console.log(this.enterprices);
        })
        .catch((err) => {
          this.load_enterprices = false;
        });
    },

    //END:: GET ENTERPRICES
    // START:: TOGGLE PASSWORD VISIBILITY METHOD
    togglePasswordVisibility(e) {
      let passwordElement = e.currentTarget.parentElement.children[1];
      let innerPasswordElement = e.currentTarget.parentElement.children[0];
      let passwordTogglerBtn = e.currentTarget;
      if (
        passwordElement.type == "password" ||
        innerPasswordElement.type == "password"
      ) {
        passwordElement.type = "text";
        innerPasswordElement.type = "text";
        passwordTogglerBtn.classList.add("password_is_visible");
      } else if (
        passwordElement.type == "text" ||
        innerPasswordElement.type == "text"
      ) {
        passwordElement.type = "password";
        innerPasswordElement.type = "password";
        passwordTogglerBtn.classList.remove("password_is_visible");
      }
    },
    // END:: TOGGLE PASSWORD VISIBILITY METHOD

    // START:: ADD NEW ITEM
    addItem() {
      this.atms.push({
        id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
        password: null,
        atmNum: null,
        file: null,
        name: null,
        path: null,
        status: "",
      });
    },
    // END:: ADD NEW ITEM

    // START:: GET DATA
    getAllData() {
      this.$axios({
        method: "GET",
        url: "client/credit_enterprise/" + this.$route.params.id,
      }).then((res) => {
        this.data.enterpriceAccount = res.data.data.account;
        this.data.username = res.data.data.customer;
        this.data.amount = res.data.data.amount;
        this.data.comission = res.data.data.commission;
        this.data.transfare_amount = res.data.data.rate;
        this.data.safe = res.data.data.wallet;
        // this.total = res.data.data.total;
        this.data.desc = res.data.data.desc;
        this.data.notes = res.data.data.notes;
        this.radioButtonsValue = res.data.data.active;
      });
    },
    // END:: GET DATA

    //START:: GET BANKS
    get_banks() {
      this.$axios({
        method: "GET",
        url: "client/bank",
      }).then((res) => {
        this.banks = res.data.data;
      });
    },
    //END:: GET BANKS

    // START:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)
    checkIfInputIsEmpty(e) {
      let inputElement = e.currentTarget;
      if (inputElement.value.length > 0) {
        inputElement.classList.add("not_empty");
      } else {
        inputElement.classList.remove("not_empty");
      }
    },
    // END:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)

    // START:: VALIDATE CREATE FORM
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;

        if (!this.data.amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال المبلغ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.transfare_amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال النسبة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.comission) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال العمولة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.safe) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.safe"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.username) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال إسم العميل",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.enterpriceAccount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال المؤسسة",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.desc) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال وصف",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.radioButtonsValue) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إختيار حالة العملية",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          const submit_data = {
            type: "atm",
            amount: this.data.amount,
            rate: this.data.transfare_amount,
            commission: this.data.comission,
            total: Number(this.total.replace(/\D/g, "")),
            wallet_id: this.data.safe.id,
            balance_customer_id: this.data.username.id,
            account_id: this.data.enterpriceAccount.id,
            desc: this.data.desc,
            notes: this.data.notes,
            active: this.radioButtonsValue,
          };
          this.$axios({
            method: "PUT",
            url: "client/credit_enterprise/" + this.$route.params.id,
            data: submit_data,
          })
            .then(() => {
              this.btnIsLoading = false;
              this.$iziToast.success({
                timeout: 2000,
                message: "تمت الاضافة بنجاح",
                position: "topRight",
              });
              if (this.radioButtonsValue == "true") {
                this.$router.push("/enterpriceATM/found");
              }
              if (this.radioButtonsValue == "false") {
                this.$router.push("/enterpriceATM/notFound");
              }
            })
            .catch((err) => {
              this.btnIsLoading = false;
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "topRight",
              });
            });
          return;
        }
      }
    },
    // END:: VALIDATE CREATE FORM

    getData() {
      // data
      this.$axios({
        method: "GET",
        url: `countries`,
      }).then((res) => {
        if (this.$store.getters["lang_module/lang"] == "ar") {
          this.countries = res.data.data.map((item) => {
            return {
              id: item.id,
              name: item.ar.name,
            };
          });
        } else {
          this.countries = res.data.data.map((item) => {
            return {
              id: item.id,
              name: item.en.name,
            };
          });
        }
      });
    },

    // START:: HANDLING SELECTED FILE METHOD
    handelSelected(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD
  },

  computed: {
    total() {
      const num =
        (this.data.amount * this.data.comission) / this.data.transfare_amount;
      return parseFloat(num.toFixed()).toLocaleString();
    },
  },

  created() {
    this.getSafes();
    this.get_banks();
    this.getAllData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      userType.includes("confirm_enterprise_add_money") &&
      !userType.includes("enterprise_add_money")
    ) {
      this.$router.push("/enterpriceATM");
    } else if (
      !userType.includes("superadmin") &&
      !userType.includes("enterprise_add_money")
    ) {
      this.$router.push("/");
    }
  },
};
</script>
