<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/banks"
        search_title="البنوك"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("addNew") }}</h4>
        </div>

        <!-- ==== Start Form ==== -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.bank_name"
                  />
                  <label for="name_input" class="form-label">{{
                    $t("forms.labels.bank_name")
                  }}</label>
                </div>
              </div>

              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="text"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.soft_code"
                  />
                  <label for="name_input" class="form-label">{{
                    $t("forms.labels.soft_code")
                  }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              إضافة
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- ==== End Form ==== -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.banks.add"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // Date_menues
      dateMenu: false,
      debit_dateMenu: false,

      // search_users
      search_users: "",

      // ========== create_data
      data: {
        bank_name: null,
        soft_code: null,
      },
    };
  },

  watch: {
    [`search_users`](newVal) {
      this.get_filterd_users(newVal);
    },
  },

  methods: {
    // get_filterd_users
    get_filterd_users(newVal) {
      if (newVal == "o") {
        this.users = ["omar"];
      } else {
        this.users = ["omar", "momen", "zoheir", "mahmoud", "essam", "diaa"];
      }
    },

    // Validate Data
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.bank_name) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.bank_name"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.soft_code) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.soft_code"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.submitData();
          return;
        }
      }
    },
    emptyFormData() {
      this.data.bank_name = null;
      this.data.soft_code = null;
    },
    // Submit Data
    submitData() {
      const submit_data = new FormData();
      submit_data.append("name", this.data.bank_name);
      submit_data.append("swift_number", this.data.soft_code);

      this.$axios({
        method: "POST",
        url: "client/bank",
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "topRight",
          });
          this.emptyFormData();
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
  },

  created() {
    // this.getData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("lock_and_deals")
    ) {
      this.$router.push("/");
    }
  },
};
</script>
