<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :show_print="true"
        :excelFile="true"
        :excelData="excelData"
      />
      <!-- End Breadcrumb -->
      <!-- start of searchSection  -->
      <SearchSection @filterTable="searchTable"></SearchSection>
      <!-- end of searchSection  -->
      <!-- notFound  -->
      <Loader v-if="lodaing" class="sub-loader"></Loader>
      <div v-else>
        <Notfound v-if="!searchItems"></Notfound>
        <div v-else>
          <!-- Start Statistics Card-->
          <ul class="all-state-count">
            <li>
              <div class="state-count">
                <span>عدد عملاء تحويل الشركات:</span>
                <span>{{ table_length }}</span>
              </div>
            </li>
          </ul>
          <!-- End Statistics Card-->

          <!-- Start Main Section -->
          <main class="table-print main-table">
            <!-- start of  header of printSection  -->
            <PrintHead
              :title="'عملاء تحويل الشركات'"
              :number="table_length"
            ></PrintHead>
            <!-- end of header of printSection  -->
            <div class="user-companies-tables" v-for="i in rows" :key="i.id">
              <div class="user-name">
                <span> Client Name </span>
                <p>{{ i.name }}</p>
              </div>
              <v-data-table
                class="thumb table_company"
                :headers="headers"
                :items="i.transfers"
                :loading="lodaing"
                :loading-text="$t('table.loadingData')"
                v-model="selected"
                :single-select="singleSelect"
                item-key="id"
                :items-per-page="i.transfers.length"
                hide-default-footer
              >
                <!-- Select no data State -->
                <template v-slot:no-data>
                  {{ $t("table.noData") }}
                </template>
                <template v-slot:[`item.index`]="{index}">
                  {{ index + 1 }}
                </template>
                <!-- Status -->
                <!-- Select row field -->

                <template v-slot:[`item.amount`]="{item}">
                  {{ Number(item.amount).toLocaleString() }} {{ item.currency }}
                </template>
                <template v-slot:[`item.reference`]="{item}">
                  <span v-if="item.reference"> {{ item.reference }} </span>
                  <span v-else> ----- </span>
                </template>
                <template v-slot:[`item.status`]="{item}">
                  <v-chip
                    color="#d7c100"
                    class="mr-1"
                    v-if="item.status == 'pending'"
                  >
                    Pending
                  </v-chip>
                  <v-chip
                    color="green"
                    class="mr-1"
                    v-if="item.status == 'confirmed'"
                  >
                    Confirmed
                  </v-chip>
                  <v-chip
                    color="red"
                    class="mr-1"
                    v-if="item.status == 'refused'"
                  >
                    Refused
                  </v-chip>
                  <v-chip
                    color="green"
                    class="mr-1"
                    v-if="item.status == 'finished'"
                  >
                    Finished
                  </v-chip>
                </template>
                <!-- Confirm-->
                <template v-slot:[`item.pdf_file`]="{item}">
                  <button
                    type="button"
                    @click="generateReport(item)"
                    style="font-size: 20px;"
                  >
                    <i class="fal fa-download"></i>
                  </button>
                </template>
                <!-- Select actions-->
                <template v-slot:[`item.actions`]="{item}">
                  <div class="_actions">
                    <v-icon
                      class="edit"
                      small
                      @click="editItem(item)"
                      v-if="item.status == 'pending'"
                    >
                      fal fa-edit
                    </v-icon>
                    <!-- <v-icon class="delete" small @click="deleteItem(item)">
                      fal fa-trash
                    </v-icon> -->
                  </div>
                </template>

                <!-- ======================== Start Top Section ======================== -->
                <template v-slot:top>
                  <!-- Delete dialog -->
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5 justify-center">{{
                        $t("table.deletedialog.areYouSure")
                      }}</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#1B5E20" @click="deleteItemConfirm"
                          >{{ $t("table.deletedialog.ok") }}
                          <span
                            class="btn_loader_model"
                            v-if="loadingBtnDialog"
                          ></span
                        ></v-btn>
                        <v-btn color="#F44336" @click="dialogDelete = false">{{
                          $t("table.deletedialog.cancel")
                        }}</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
                <!-- ======================== End Top Section ======================== -->
              </v-data-table>
            </div>
          </main>
          <!-- End Main Section -->
        </div>
      </div>

      <!-- End Pagination -->

      <!-- Start Image_Model -->
      <base-model
        @closeModel="model_1.show_model = false"
        :show="model_1.show_model"
      >
        <div class="image">
          <img
            v-if="model_1.model_img_src"
            :src="model_1.model_img_src"
            alt="..."
          />
        </div>
      </base-model>
      <!-- End Image_Model -->
      <!-- End Filter Menu -->
    </div>
    <!-- pdf file  -->
    <section class="pdf-print">
      <div class="pdf-file text-left">
        <div class="text">
          <h3>Company Transfar Data</h3>
          <div class="sub">
            <div class="items">
              <p>Client Name</p>
              <p>{{ pdfContent.clientName }}</p>
            </div>
          </div>
          <div class="sub">
            <div class="items">
              <p>Product</p>
              <p>{{ pdfContent.products }}</p>
            </div>
            <div class="items">
              <p>Name</p>
              <p>{{ pdfContent.fileName }}</p>
            </div>
            <div class="items">
              <p>BENEFICIARY ADDRESS</p>
              <p>{{ pdfContent.address }} , {{ pdfContent.country }}</p>
            </div>
            <div class="items">
              <p>A/C NO</p>
              <p>{{ pdfContent.iban }}</p>
            </div>
            <div class="items" v-if="pdfContent.refNum">
              <p>REFERENCE NO</p>
              <p>{{ pdfContent.refNum }}</p>
            </div>
            <div class="items">
              <p>SWIFT CODE</p>
              <p>{{ pdfContent.swift }}</p>
            </div>
            <div class="items">
              <p>BANK NAME</p>
              <p>{{ pdfContent.bankName }}</p>
            </div>
          </div>
          <div class="sub">
            <div class="items">
              <p>Amount</p>
              <p>{{ pdfContent.amount }}</p>
            </div>
            <div class="items">
              <p>Transfer Date</p>
              <p>{{ pdfContent.created_at }}</p>
            </div>
            <div class="items">
              <p>Transfer Status</p>
              <p v-if="pdfContent.status == 'pending'">Pending</p>
              <p v-if="pdfContent.status == 'confirmed'">Confirmed</p>
              <p v-if="pdfContent.status == 'refused'">Refused</p>
              <p v-if="pdfContent.status == 'finished'">Finished</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
import SearchSection from "@/components/Search/companyTransfers";
export default {
  components: {
    StatsCard,
    SearchSection,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // search
      searchItems: null,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "كل تحويلات الشركات",
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "تحويلات الشركات",
          number: "1",
        },
      ],

      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Filter
      show_filter: false,

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // Custom Modal Data

      // ========== Loding
      lodaing: false,
      table_length: null,
      // pdf
      pdfContent: {
        fileName: null,
        clientName: null,
        country: null,
        address: null,
        iban: null,
        refNum: null,
        bankName: null,
        swift: null,
        products: null,
        amount: null,
        created_at: null,
        status: null,
      },
      // excel data
      excelData: {
        name: "تحويلات الشركات",
        items: null,
        json_fields: {
          "Client Name": {
            field: "name",
            callback: (value) => {
              var target = this.rows.find((ele) => {
                return ele.name == value;
              });
              var theLength = target.transfers.length;
              return (
                `<table><tbody><tr><td style='text-align: center; vertical-align: middle;' rowspan='${theLength}'>` +
                value +
                "</td></tr></tbody></table>"
              );
            },
          },
          Amount: {
            field: "transfers",
            callback: (value) => {
              let finalVal = "<table><tbody>";
              value.forEach((val, index) => {
                if (index == value.length - 1) {
                  finalVal +=
                    "<tr><td>" +
                    Number(val.amount).toLocaleString() +
                    "</td></tr>" +
                    "</tbody></table>";
                } else {
                  finalVal +=
                    "<tr><td>" +
                    Number(val.amount).toLocaleString() +
                    "</td></tr>";
                }
              });
              return finalVal;
            },
          },
          "Company Name": {
            field: "transfers",
            callback: (value) => {
              let finalVal = "<table><tbody>";
              value.forEach((val, index) => {
                if (index == value.length - 1) {
                  finalVal +=
                    "<tr><td>" +
                    val.company.name +
                    "</td></tr>" +
                    "</tbody></table>";
                } else {
                  finalVal += "<tr><td>" + val.company.name + "</td></tr>";
                }
              });
              return finalVal;
            },
          },
          "SWIFT Code": {
            field: "transfers",
            callback: (value) => {
              let finalVal = "<table><tbody>";
              value.forEach((val, index) => {
                if (index == value.length - 1) {
                  finalVal +=
                    "<tr><td>" +
                    val.company.swift_number +
                    "</td></tr>" +
                    "</tbody></table>";
                } else {
                  finalVal +=
                    "<tr><td>" + val.company.swift_number + "</td></tr>";
                }
              });
              return finalVal;
            },
          },
          "reference no": {
            field: "transfers",
            callback: (value) => {
              let finalVal = "<table><tbody>";
              value.forEach((val, index) => {
                if (index == value.length - 1) {
                  finalVal +=
                    "<tr><td>" +
                    val.reference +
                    "</td></tr>" +
                    "</tbody></table>";
                } else {
                  finalVal +=
                    "<tr><td>" + val.reference +  + "</td></tr>";
                }
              });
              return finalVal;
            },
          },
          Country: {
            field: "transfers",
            callback: (value) => {
              let finalVal = "<table><tbody>";
              value.forEach((val, index) => {
                if (index == value.length - 1) {
                  finalVal +=
                    "<tr><td>" +
                    val.company.country.name_ar +
                    "</td></tr>" +
                    "</tbody></table>";
                } else {
                  finalVal +=
                    "<tr><td>" + val.company.country.name_ar + "</td></tr>";
                }
              });
              return finalVal;
            },
          },
          "Created at": {
            field: "transfers",
            callback: (value) => {
              let finalVal = "<table><tbody>";
              value.forEach((val, index) => {
                if (index == value.length - 1) {
                  finalVal +=
                    "<tr><td>" +
                    val.created_at +
                    "</td></tr>" +
                    "</tbody></table>";
                } else {
                  finalVal += "<tr><td>" + val.created_at + "</td></tr>";
                }
              });
              return finalVal;
            },
          },
          Status: {
            field: "transfers",
            callback: (value) => {
              let finalVal = "<table><tbody>";
              value.forEach((val, index) => {
                if (index == value.length - 1) {
                  finalVal +=
                    "<tr><td>" + val.status + "</td></tr>" + "</tbody></table>";
                } else {
                  finalVal += "<tr><td>" + val.status + "</td></tr>";
                }
              });
              return finalVal;
            },
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: " Amount",
            value: "amount",
            align: "center",
            sortable: false,
            width: 120,
          },
          {
            text: "Company Name",
            value: "company.name",
            align: "center",
            width: 150,
          },
          {
            text: "Ref NO",
            value: "reference",
            align: "center",
            sortable: false,
            width: 180,
          },
          {
            text: "SWIFT Code",
            value: "company.swift_number",
            align: "center",
            sortable: false,
            width: 150,
          },
          {
            text: "Country",
            value: "company.country.name_ar",
            align: "center",
            sortable: false,
            width: 100,
          },
          {
            text: "Product",
            value: "company.products[0].name",
            align: "center",
            sortable: false,
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 120,
          },
          {
            text: "Created at",
            value: "created_at",
            align: "center",
            width: 120,
          },
          {
            text: "Status",
            value: "status",
            align: "center",
            width: 100,
          },
          {
            text: "PDF File",
            value: "pdf_file",
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            sortable: false,
            width: 80,
          },
          {
            text: "Control",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 80,
          },
        ];
      }
    },
  },

  methods: {
    // generate pdf
    generateReport(companyData) {
      document.title = companyData.company.name;
      this.pdfContent.fileName = companyData.company.name;
      this.pdfContent.clientName = companyData.customer.name;
      this.pdfContent.country = companyData.company.country.name_ar;
      this.pdfContent.address = companyData.company.address;
      this.pdfContent.iban = companyData.company.account_number;
      this.pdfContent.refNum = companyData.reference;
      this.pdfContent.swift = companyData.company.swift_number;
      this.pdfContent.bankName = companyData.company.bank.name;
      this.pdfContent.products = companyData.company.products[0].name;
      this.pdfContent.amount =
        companyData.currency +
        " " +
        Number(companyData.amount).toLocaleString();
      this.pdfContent.created_at = companyData.created_at;
      this.pdfContent.status = companyData.status;
      setTimeout(() => {
        var iframePrint = document.querySelector(".iframePrint");
        var iframeHead = document.head.innerHTML;
        var printContents = document.querySelector(".pdf-print").innerHTML;
        iframePrint.contentWindow.document.write(iframeHead + printContents);
        setTimeout(() => {
          window.frames["printf"].print();
          iframePrint.contentWindow.document.close();
          document.title = "Golden Eyes";
        }, 500);
      }, 500);
    },
    // searchTable
    searchTable(items) {
      this.rows = [];
      this.searchItems = items;
      this.setRows();
    },
    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({path: "/debits/add"});
    },
    showItem(item) {
      this.dialogShow = true;
      this.itemToShow = item;
    },
    editItem(item) {
      this.$router.push({path: "/companyTransfers/edit/" + item.id});
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/company_transfer/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // Set Rows
    setRows() {
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/company_transfer",
        params: {
          customer: this.searchItems ? this.searchItems.username : null,
          company: this.searchItems ? this.searchItems.company : null,
          from_date: this.searchItems ? this.searchItems.from : null,
          to_date: this.searchItems ? this.searchItems.to : null,
        },
      })
        .then((res) => {
          this.rows = res.data.data;
          this.table_length = this.rows.length;
          this.excelData.items = this.rows;
          this.lodaing = false;
        })
        .catch((err) => {
          this.lodaing = false;
        });
    },
  },

  // ======= hooks
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("company_actions")
    ) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped></style>
