<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="" v-else>
      <div class="wrraper enterprice_account" v-if="allData">
        <div class="row">
          <div class="col-md-12">
            <div class="show_title">
              <span>بيانات المؤسسة</span>
            </div>
          </div>
          <!-- --------------------------------------------- -->
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-pencil'"
              :title="'اسم المؤسسة'"
              :number="allData.enterprise.name_ar"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-user-tie'"
              :title="'اسم صحاب المؤسسة'"
              :number="allData.enterprise.owner_name"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
              :routeLink="allData.enterprise.owner_id"
            ></stats-card>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-university'"
              :title="'اسم البنك'"
              :number="allData.bank.name"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-sort-numeric-up-alt'"
              :title="'A/C NO'"
              :number="allData.bank_account_number"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-chart-line'"
              :title="'حالة الحساب'"
              :number="allData.active == 0 ? 'غير مفعل' : 'مفعل'"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
            ></stats-card>
          </div>
          <div class="col-md-4">
            <stats-card
              :icon="'fal fa-file-alt'"
              :title="'Head Letter'"
              :number="'Head Letter'"
              :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
              :bgColor="'transparent'"
              :show="true"
              :link="allData.head_letter"
            ></stats-card>
          </div>
          <!-- --------------------------------------------- -->
          <div class="col-md-12">
            <div class="show_title">
              <span>هل تم تقديم الطلب لفتح حساب؟</span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="show_answer">
              <div>
                <span v-if="allData.is_account_opened.status == 1"> نعم </span>
                <span v-if="allData.is_account_opened.status == 0"> لا </span>
              </div>
              <div>
                <button
                  class="btn"
                  type="button"
                  @click="addComment('is_account_opened')"
                >
                  إضافة تعليق
                </button>
                <button
                  class="btn mr-6"
                  type="button"
                  @click="show_model('open_account')"
                >
                  {{ $t("enterpriceAccount.showComments") }}
                </button>
              </div>
            </div>
          </div>
          <!-- --------------------------------------------- -->
          <div class="col-md-12">
            <div class="show_title">
              <span>هل تم تقديم الطلب اونلاين؟</span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="show_answer">
              <div>
                <span v-if="allData.is_online_request.status == 1"> نعم </span>
                <span v-if="allData.is_online_request.status == 0"> لا </span>
              </div>
              <div>
                <button
                  class="btn"
                  type="button"
                  @click="addComment('is_online_request')"
                >
                  إضافة تعليق
                </button>
                <button
                  class="btn mr-6"
                  type="button"
                  @click="show_model('online_account')"
                >
                  {{ $t("enterpriceAccount.showComments") }}
                </button>
              </div>
            </div>
          </div>
          <!-- --------------------------------------------- -->
          <div class="col-md-12">
            <div class="show_title">
              <span>هل تم تقديم طلب إضافة صرافه ؟ </span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="show_answer">
              <div>
                <span
                  v-if="
                    allData.is_request_atm.status == 1 &&
                    allData.is_atm_opened == 0
                  "
                  >نعم و بإنتظار إضافة الصرافة</span
                >
                <span v-if="allData.is_request_atm.status == 0">لا</span>
                <span v-if="allData.is_atm_opened == 1">
                  نعم و تم إضافة صرافة بنجاح
                </span>
              </div>

              <div>
                <button
                  class="btn"
                  type="button"
                  @click="addComment('is_request_atm')"
                >
                  إضافة تعليق
                </button>
                <button
                  class="btn mr-6"
                  type="button"
                  @click="show_model('open_atm')"
                >
                  {{ $t("enterpriceAccount.showComments") }}
                </button>
              </div>
            </div>
          </div>
          <!-- --------------------------------------------- -->
          <div class="col-md-12">
            <div class="show_title">
              <span>الرصيد</span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="show_answer">
              <div>
                <span v-if="allData.amount != null"
                  >{{ allData.amount }} ريال</span
                >
                <span v-else>لا يوجد</span>
              </div>
              <div>
                <button class="btn" type="button" @click="addMoney()">
                  إضافة رصيد
                </button>
                <button
                  class="btn mr-6"
                  type="button"
                  @click="show_model('deposit')"
                >
                  {{ $t("enterpriceAccount.showComments") }}
                </button>
              </div>
            </div>
          </div>
          <!-- --------------------------------------------- -->
          <div class="col-md-12">
            <div class="show_title mb-4">
              <span>العمليات التى تمت على الرصيد</span>
            </div>
            <div class="show_answer" v-if="allData.actions.length == 0">
              <span>لا يوجد</span>
            </div>
            <div v-for="action in allData.actions" :key="action.id" v-else>
              <!-- main comment  -->
              <div class="comments comments-new mb-4">
                <div class="comment_card">
                  <div class="top_det">
                    <!-- if ahmed osama accept deposit -->
                    <p
                      v-if="
                        (action.main_status == 'accepted' &&
                          action.type == 'deposit') ||
                        (action.main_status == 'confirmed' &&
                          action.type == 'deposit')
                      "
                    >
                      تم الموافقة علي طلب اضافة إيصال بقيمة
                      {{ action.value }} ريال من قبل
                      {{ action.user.name }}
                    </p>
                    <!-- if ahmed osama accept deposit and mousa return it back  -->
                    <p
                      v-if="
                        action.main_status == 'cancelled' &&
                        action.type == 'deposit'
                      "
                    >
                      تم إرجاع العملية الخاصة ب " الموافقة علي طلب اضافة إيصال
                      بقيمة
                      {{ action.value }} ريال من قبل {{ action.user.name }} "
                    </p>
                    <!-- if ahmed osama add money -->
                    <p
                      v-if="
                        (action.main_status == 'accepted' &&
                          action.type == null) ||
                        (action.main_status == 'confirmed' &&
                          action.type == null)
                      "
                    >
                      تم إضافة رصيد من قبل {{ action.user.name }} بتعليق (
                      {{ action.main_comment.comment }} )
                    </p>
                    <!-- if ahmed osama add money and mousa return it back  -->
                    <p
                      v-if="
                        action.main_status == 'cancelled' && action.type == null
                      "
                    >
                      تم إرجاع العملية الخاصة ب "إضافة رصيد من قبل "
                      {{ action.user.name }} بتعليق (
                      {{ action.main_comment.comment }} )
                    </p>
                  </div>
                  <span>{{ action.created_at }}</span>
                </div>
                <!-- if mousa accepted or cancelled -->
                <div
                  class="accept_reject_status"
                  v-if="
                    action.main_status == 'confirmed' ||
                    action.main_status == 'cancelled'
                  "
                >
                  <!-- if mousa accepted -->
                  <div class="accept" v-if="action.main_status == 'confirmed'">
                    <span class="icon">
                      <i class="far fa-check-circle"></i>
                    </span>
                    <p>تأكيد</p>
                  </div>
                  <!-- if mousa cancelled -->
                  <div class="reject" v-if="action.main_status == 'cancelled'">
                    <span class="icon">
                      <i class="far fa-times-circle"></i>
                    </span>
                    <p>تم الإرجاع للتعديل</p>
                  </div>
                </div>
              </div>
              <!-- sub comment  -->
              <div class="all-sub-comments" v-if="action.comment.length != 0">
                <div
                  class="comments comments-new sub-comment mb-4"
                  v-for="item in action.comment"
                  :key="item.id"
                >
                  <div class="comment_card">
                    <div class="top_det">
                      <p>
                        قام {{ item.user.name }} بإضافة تعليق (
                        {{ item.comment }} )
                      </p>
                    </div>
                    <span>{{ item.created_at }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- --------------------------------------------- -->
        </div>
        <!-- ------------------start of show all comments--------------------------- -->
        <base-model
          @closeModel="model.show_model = false"
          :show="model.show_model"
        >
          <div class="comments">
            <h2 class="mb-7 mt-3">{{ dialog_text }}</h2>
            <div v-if="dialog_comments.length != 0">
              <div
                class="comment_card"
                v-for="comment in dialog_comments"
                :key="comment.id"
              >
                <p>
                  {{ comment.comment }}
                </p>
                <span>{{ comment.created_at }}</span>
              </div>
            </div>
          </div>
        </base-model>
        <!-- --------------------end of show all comments------------------------- -->
        <!-- ------------------start of add comments--------------------------- -->

        <v-dialog
          v-model="showAddComment"
          @click:outside="resetInputs"
          max-width="800px"
        >
          <div class="custom_card mb-0">
            <h4 class="mt-5 mb-4 text-center">إضافة تعليق</h4>
            <!-- START:: CREATE FORM WRAPPER -->
            <form @submit.prevent="submitComment">
              <div class="container">
                <div class="row justify-content-between">
                  <!-- START:: INPUT WRAPPER -->
                  <div class="col-lg-12 py-0">
                    <div class="input_wrapper top_label">
                      <label for="textarea_1" class="form-label">
                        تفاصيل التعليق</label
                      >
                      <textarea
                        v-on:keydown.enter.prevent="submitComment"
                        class="form-control"
                        id="textarea_1"
                        rows="5"
                        v-model="commentDetails"
                      ></textarea>
                    </div>
                  </div>
                  <!-- END:: INPUT WRAPPER -->
                </div>
              </div>

              <div class="buttons_wrapper">
                <button
                  class="button_style_1"
                  :class="{buttonIndex: btnIsLoading}"
                  :disabled="btnIsLoading"
                >
                  إضافة
                  <span class="btn_loader" v-if="btnIsLoading"></span>
                </button>
              </div>
            </form>
            <!-- END:: CREATE FORM Comment -->
          </div>
        </v-dialog>
        <!-- ----------------------end of add comments----------------------- -->
        <!-- Start Add Withdraw -->

        <v-dialog
          v-model="showAddMoney"
          @click:outside="resetInputs"
          max-width="800px"
        >
          <div class="custom_card mb-0">
            <h4 class="mt-3 text-center mb-0">إضافة رصيد</h4>
            <!-- START:: CREATE FORM WRAPPER -->
            <form @submit.prevent="submitMoney">
              <div class="container">
                <div class="row justify-content-between">
                  <!-- START:: INPUT WRAPPER -->
                  <div class="col-lg-12 py-0">
                    <div class="input_wrapper top_label">
                      <input
                        type="text"
                        class="form-control"
                        v-model.trim="data.amount"
                      />
                      <label for="name_input" class="form-label">المبلغ</label>
                    </div>
                  </div>
                  <!-- END:: INPUT WRAPPER -->

                  <!-- START:: INPUT WRAPPER -->
                  <div class="col-lg-12 py-0">
                    <div class="input_wrapper top_label">
                      <label for="textarea_1" class="form-label"
                        >التفاصيل</label
                      >
                      <textarea
                        v-on:keydown.enter.prevent="submitMoney"
                        class="form-control"
                        id="textarea_1"
                        rows="5"
                        v-model="data.details"
                      ></textarea>
                    </div>
                  </div>
                  <!-- END:: INPUT WRAPPER -->
                </div>
              </div>

              <div class="buttons_wrapper">
                <button
                  class="button_style_1"
                  :class="{buttonIndex: btnIsLoading}"
                  :disabled="btnIsLoading"
                >
                  {{ $t("Forms.submit") }}
                  <span class="btn_loader" v-if="btnIsLoading"></span>
                </button>
              </div>
            </form>
            <!-- END:: CREATE FORM WRAPPER -->
          </div>
        </v-dialog>
        <!-- End Add Withdraw -->
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
export default {
  props: ["id"],
  components: {
    StatsCard,
  },
  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // loader
      loading: true,
      // ========== allData
      allData: "",
      data: {
        amount: null,
        details: null,
      },
      // START:: SELECTED FILE DATA
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },
      // END:: SELECTED FILE DATA

      // ========== Model
      model: {
        show_model: false,
        model_img_src: "",
      },
      dialog_text: "",
      dialog_comments: "",
      // ========== Btn Laoding
      btnIsLoading: false,
      showAddComment: false,
      commentDetails: "",
      commentType: "",
      // ========== Loding
      lodaing: false,
      // =========== money
      showAddMoney: null,
    };
  },
  methods: {
    addComment(type) {
      this.showAddComment = true;
      this.commentType = type;
    },
    submitComment() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.commentDetails) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة تعليق",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          const submit_data = new FormData();
          submit_data.append("account_id", this.$route.params.id);
          submit_data.append("type", this.commentType);
          submit_data.append("comment", this.commentDetails);
          this.$axios({
            method: "post",
            url: "client/account_comment",
            data: submit_data,
          })
            .then(() => {
              this.$iziToast.success({
                timeout: 2000,
                message: "تمت الاضافة بنجاح",
                position: "topRight",
              });
              this.getData();
              this.btnIsLoading = false;
              this.showAddComment = false;
              this.commentDetails = "";
            })
            .catch((err) => {
              this.btnIsLoading = false;
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "topRight",
              });
              this.showAddComment = false;
            });
          return;
        }
      }
    },
    // Model
    show_model(type) {
      this.model.show_model = true;
      if (type == "open_account") {
        this.dialog_text = "التعليقات الخاصة بطلب فتح حساب";
        this.dialog_comments = this.allData.is_account_opened.comments;
      } else if (type == "online_account") {
        this.dialog_text = "التعليقات الخاصة بطلب اون لاين";
        this.dialog_comments = this.allData.is_online_request.comments;
      } else if (type == "open_atm") {
        this.dialog_text = "التعليقات الخاصة بطلب إضافة صرافة";
        this.dialog_comments = this.allData.is_request_atm.comments;
      } else if (type == "deposit") {
        this.dialog_text = "التعليقات الخاصة بإضافة رصيد";
        this.dialog_comments = this.allData.credits_comments;
      }
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `cities/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("CRUD.Delete"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
      // Submit the delete service then submit this
    },

    //Get Data
    getData() {
      this.$axios.get("client/account/" + this.id).then((res) => {
        this.allData = res.data.data;
      });
    },
    //Get Data

    // START:: TOGGLE PASSWORD VISIBILITY METHOD
    togglePasswordVisibility(e) {
      let passwordElement = e.currentTarget.parentElement.children[1];
      let innerPasswordElement = e.currentTarget.parentElement.children[0];
      let passwordTogglerBtn = e.currentTarget;
      if (
        passwordElement.type == "password" ||
        innerPasswordElement.type == "password"
      ) {
        passwordElement.type = "text";
        innerPasswordElement.type = "text";
        passwordTogglerBtn.classList.add("password_is_visible");
      } else if (
        passwordElement.type == "text" ||
        innerPasswordElement.type == "text"
      ) {
        passwordElement.type = "password";
        innerPasswordElement.type = "password";
        passwordTogglerBtn.classList.remove("password_is_visible");
      }
    },
    // END:: TOGGLE PASSWORD VISIBILITY METHOD

    // START:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)
    checkIfInputIsEmpty(e) {
      let inputElement = e.currentTarget;
      if (inputElement.value.length > 0) {
        inputElement.classList.add("not_empty");
      } else {
        inputElement.classList.remove("not_empty");
      }
    },
    // END:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)

    // START:: HANDLING SELECTED FILE METHOD
    handelSelectedFile(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD
    addMoney() {
      this.showAddMoney = true;
    },
    submitMoney() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة قيمة الرصيد",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.details) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إضافة تفاصيل علي الرصيد",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          const requestData = new FormData();
          requestData.append("value", this.data.amount);
          requestData.append("account_id", this.$route.params.id);
          requestData.append("comment", this.data.details);
          this.$axios({
            method: "post",
            url: "client/deposit",
            data: requestData,
          })
            .then(() => {
              this.getData();
              this.$iziToast.success({
                timeout: 2000,
                message: "تمت إضافة رصيد بنجاح",
                position: "topRight",
              });
              this.btnIsLoading = false;
              this.showAddMoney = false;
            })
            .catch((err) => {
              this.btnIsLoading = false;
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "topRight",
              });
            });
          return;
        }
      }
    },
    resetInputs() {
      this.data.amount = null;
      this.data.details = null;
    },
  },
  computed: {
    current_theme() {
      return this.$store.getters["theme_module/current_theme"];
    },
    ...mapGetters({
      lang: "lang_module/lang",
    }),
  },
  // ======= hooks
  created() {
    this.getData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>

<style></style>
