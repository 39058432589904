<template>
  <div class="sidebar">
    <!-- Big Screen Sidebar -->
    <v-navigation-drawer
      v-if="!isSmallScreen"
      class="main_sidebar"
      permanent
      :expand-on-hover="!menueMinWidth"
    >
      <!-- Upper Scection -->
      <v-list>
        <div class="top-logo" :class="{ smallLogo: !menueMinWidth }">
          <div class="my-logo">
            <router-link to="/"
              ><img
                v-if="menueMinWidth"
                :src="
                  current_theme == 'light'
                    ? require('@/assets/images/icons/logo-white.svg')
                    : require('@/assets/images/icons/logo.svg')
                "
              />
              <img v-else class="sm-img" src="@/assets/images/icons/logo.svg" />
            </router-link>
          </div>
          <div class="full-wid">
            <button
              @click="toggleSideBarFullWidth"
              class="btn ful-w"
              :class="{ ful_back: menueMinWidth }"
            ></button>
          </div>
        </div>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <template v-for="(item, index) in $store.state.side_bar">
          <!-- divider  -->
          <div class="divider_new" v-if="item.label" :key="item.title">
            <span>{{ item.label }}</span>
          </div>
          <!-- divider  -->
          <v-list-item
            link
            :key="index"
            v-if="item.url != null"
            :to="item.url"
            v-model="item.active"
            :class="{
              'v-list-item--active':
                (item.activationUrl &&
                  item.activationUrl.includes($route.path)) ||
                (item.activationUrl &&
                  item.activationUrl.includes($route.name)),
            }"
          >
            <v-icon>{{ item.action }}</v-icon>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>

          <v-list-group
            class="parnet"
            :class="{
              trachColor: item.delete_link,
              activeRoute:
                item.routeLinks.includes($route.path) ||
                item.routeLinks.includes($route.name),
            }"
            v-else
            :value="true"
            :key="index"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <template v-for="(child, index) in item.items">
              <v-list-item
                :key="index"
                link
                :to="child.url"
                :class="{
                  'v-list-item--active':
                    (child.activationUrl &&
                      child.activationUrl.includes($route.path)) ||
                    (child.activationUrl &&
                      child.activationUrl.includes($route.name)),
                }"
              >
                <v-list-item-content v-if="!child.items">
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                  <span class="unreadNum" v-if="child.unreadNum">
                    {{ child.unreadNum }}
                  </span>
                </v-list-item-content>
                <v-list-group
                  class="not_item"
                  :dir="lang == 'en' ? 'rtl' : 'ltr'"
                  v-else
                  v-model="child.active"
                  :value="true"
                  no-action
                  sub-group
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title class="sub_item_group">{{
                        child.title
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item
                    v-for="(sub_child, index) in child.items"
                    :key="index"
                    link
                    :to="sub_child.url"
                    class="sub_list_item"
                  >
                    <v-list-item-title
                      class="tit_sub"
                      v-text="sub_child.title"
                    ></v-list-item-title>
                  </v-list-item>
                </v-list-group>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>

    <!-- Small Screen Sidebar -->
    <aside v-else class="smallScreenSidebar main_sidebar" ref="smallSidebar">
      <span @click="toggleMenu" class="close"
        ><i class="fas fa-times"></i
      ></span>

      <!-- <v-list>
        <div class="top-logo">
          <div class="my-logo">
            <router-link to="/"
              ><img src="@/assets/images/icons/logo.svg"
            /></router-link>
          </div>
        </div>
      </v-list> -->

      <v-list>
        <template v-for="(item, index) in $store.state.side_bar">
          <!-- divider  -->
          <div class="divider_new" v-if="item.label" :key="item.title">
            <span>{{ item.label }}</span>
          </div>
          <!-- divider  -->
          <v-list-item
            @click="toggleMenu"
            link
            :key="index"
            v-if="item.url != null"
            :to="item.url"
            v-model="item.active"
            :class="{ trachColor: item.delete_link }"
          >
            <v-icon>{{ item.action }}</v-icon>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
          <v-list-group
            class="parnet"
            :class="{ trachColor: item.delete_link }"
            v-else
            :value="true"
            :key="index"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(child, index) in item.items"
              :key="index"
              link
              :to="child.url"
              @click="toggleMenu"
            >
              <v-list-item-content v-if="!child.items">
                <v-list-item-title v-text="child.title"></v-list-item-title>
                <span class="unreadNum" v-if="child.unreadNum">
                  {{ child.unreadNum }}
                </span>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>

      <!-- <ul class="navIcons">
        <li v-if="current_theme == 'dark'" @click="changeTheme('light')">
          <i class="fal fa-sun"></i>
        </li>
        <li v-if="current_theme == 'light'" @click="changeTheme('dark')">
          <i class="fal fa-moon"></i>
        </li>

        <li v-if="lang == 'ar'" @click="changeLang('en')">
          <i class="fal fa-globe"></i>
        </li>
        <li v-if="lang == 'en'" @click="changeLang('ar')">
          <i class="fal fa-globe"></i>
        </li>
      </ul> -->
    </aside>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menueMinWidth: true,
      sideWidth: null,
      admins: [
        ["Management", "mdi-account-multiple-outline"],
        ["Settings", "mdi-cog-outline"],
      ],
      cruds: [
        ["Create", "mdi-plus-outline"],
        ["Read", "mdi-file-outline"],
        ["Update", "mdi-update"],
        ["Delete", "mdi-delete"],
      ],
      sideBar: null,
    };
  },
  methods: {
    toggleSideBarFullWidth() {
      this.menueMinWidth = !this.menueMinWidth;
      this.$store.dispatch("sideNav_module/onResize");
    },

    toggleMenu() {
      this.$refs.smallSidebar.classList.toggle("active");
    },

    changeTheme(value) {
      this.$store.dispatch("theme/changeTheme", value);
      if (value == "light") {
        this.$vuetify.theme.dark = false;
      } else {
        this.$vuetify.theme.dark = true;
      }
    },

    changeLang(value) {
      this.$store.dispatch("lang_module/switchLang", value);
    },
    stillOpen() {
      const Json_Sidebar = JSON.parse(JSON.stringify(this.$t("sidebar")));

      this.sidebar = Json_Sidebar;
    },
  },

  computed: {
    isSmallScreen() {
      return this.$store.getters["sideNav_module/isSmallScreen"];
    },

    current_theme() {
      return this.$store.getters["theme_module/current_theme"];
    },

    lang() {
      return this.$store.getters["lang_module/lang"];
    },
  },

  created() {
    this.$store.dispatch("getTranshState");
  },
};
</script>

<style lang="scss"></style>
